import c from '../util/const';
import sapi from "../util/sapi";

import sharedActions from './shared-actions';

import Promise from 'bluebird';
import log from "../util/log";
import _ from "lodash";
import DocPermissionsHelper from "../helpers/doc-permissions-helper";
import msgHelper from "../helpers/msg-helper";
import utils from "../util/util";

const workspaceActions = {

  init(workspace){
    let me = this;

    return (dispatch, getState) => {
      
      dispatch(me.updateWorkspace(workspace));

      let calls = [
        dispatch(me.refreshThreads(workspace.forum_id, workspace.host_uid, true)),
        dispatch(me.refreshDocs(workspace.forum_id, workspace.host_uid)),
      ]

      if(!workspace.host_uid){
        calls.push(dispatch(me.refreshGuests(workspace.forum_id, workspace.host_uid)))
        calls.push(dispatch(me.refreshLogo()))
      }
      else{
        calls.push(dispatch(me.refreshLogo(workspace.forum_id, workspace.host_uid)))
      }

      return Promise.all(calls)
        .then(() => {
          log.log('workspace init done');
          return true;
        })
    }
  },
  
  findGuestInActiveThreadParticipants(guest_uid){
    return (dispatch, getState) => {
      let threadParticipantLookup = _.get(getState(), 'workspace.threadParticipantLookup');
      let thread = _.get(getState(), 'workspace.activeThread');
      let accountInfoGuest = _.get(getState(), 'shared.accountInfoGuest', {});
      
      let guests = threadParticipantLookup[thread.chat_id];
      
      if(accountInfoGuest.guest_uid === guest_uid){
        //This is kind of nasty...add yourself as a fake guest.
        return accountInfoGuest;
      }
  
      let found = _.find(guests, (g) => {
        return g.guest_uid === guest_uid
      })
  
      return found;
    }
  },
  
  cleanup(){
    return {
      type: c.actions.workspace.cleanup
    }
  },

  updateWorkspace(workspace){
    return {
      type: c.actions.workspace.updateWorkspace,
      workspace
    }
  },

  clearActiveThread(){
    return (dispatch) => {
      return dispatch({
        type: c.actions.workspace.setActiveThread,
        thread : null
      })
    }
  },
  
  clearActiveDM(){
    return (dispatch) => {
      dispatch({
        type: c.actions.workspace.updateActiveDMMessages,
        messages: [],
        messageBlocks: [],
        docs: []
      })
      return dispatch({
        type: c.actions.workspace.setActiveDM,
        dm : null
      })
    }
  },
  
  setActiveThread(forum_id, host_uid, thread) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch(this.setActiveDocAssociatedThread(null))
        dispatch({
          type: c.actions.workspace.setActiveDoc,
          doc: null,
        })
        dispatch({
          type: c.actions.workspace.setActiveDocAssociatedThread,
          associatedThread: null
        })
        dispatch({
          type: c.actions.workspace.setActiveDM,
          dm: null
        })
        dispatch({
          type: c.actions.workspace.setActiveThread,
          thread
        })
  
        dispatch({
          type: c.actions.workspace.updateThreadMessages,
          mesg_edit_flag: _.get(getState(), 'workspace.activeThreadMesgEditFlag') || false,
          messages: null,
          messageBlocks: null,
          data_date: null,
          docs: null
        })
        
        dispatch(this.refreshThreadMessages(forum_id, host_uid, thread.chat_id, null))
        resolve(thread);
      })
    }
  },
  
  setActiveDoc(doc){
    return (dispatch) => {
      dispatch({
        type: c.actions.workspace.setActiveThread,
        thread : null
      })
      dispatch({
        type: c.actions.workspace.setActiveDM,
        dm : null
      })
      dispatch({
        type: c.actions.workspace.setActiveDoc,
        doc,
      })
    }
  },
  
  setActiveDocAssociatedThread(associatedThread){
    return (dispatch) => {
      dispatch({
        type: c.actions.workspace.setActiveDocAssociatedThread,
        associatedThread
      })
    }
  },

  refreshWorkspace(forum_id){
    let me = this;
    return (dispatch) => {
      return dispatch(sharedActions.updateWorkspaces())
        .then((workspaces) => {
          let foundWorkspace = _.find(workspaces, ['forum_id', forum_id])
          if(!foundWorkspace){
            throw new Error('Unable to find workspace');
          }

          dispatch(me.updateWorkspace(foundWorkspace));
        })
    }
  },

  refreshLogo(forum_id, host_uid){
    return (dispatch) => {
      return sapi.Workspace.logo(host_uid, forum_id)
          .then((res) => {
            
            let logoRes = res.data.logo;
            
            //Weird, but the backend returns a single pixel response when there's no logo.
            if(logoRes === c.BLANK_LOGO){
              dispatch({
                type : c.actions.workspace.updateLogo,
                data : null
              })
            }
            else{
              dispatch({
                type : c.actions.workspace.updateLogo,
                data : logoRes
              })
            }
          })
    }
  },
  
  fetchThreadDocs(forum_id, host_uid, chat_id){
    return sapi.Docs.list(forum_id, host_uid, chat_id)
      .then((docs) => {
        let found = _.find(docs.data, (data) => {
          return data.chat_id === chat_id;
        })
        
        return (found && found.docs) ? found.docs : [];
      })
  },
  
  refreshThreadMessages(forum_id, host_uid, chat_id, data_date){
    return (dispatch, getState) => {
      let promises = [
        sapi.Threads.messages(forum_id, host_uid, chat_id, data_date),
        this.fetchThreadDocs(forum_id, host_uid, chat_id)
      ];
      
      return Promise.all(promises)
        .then((res) => {
          
          let { accountInfo, threadTransactionQueue } = getState().shared;
          let pendingMsgs = msgHelper.buildPendingMessageList(chat_id, accountInfo, threadTransactionQueue);
  
          if (data_date) {
            let foundIds = [];
            let newMsgs = res[0].data.mesg_data
            let existingMsgs = getState().workspace.activeThreadMessages;
            let combinedMsgs = [];
            _.each(existingMsgs, (existingMsg) => {
              let found = _.find(newMsgs, (newMsg) =>  {
                return newMsg.mesg_id === existingMsg.mesg_id
              })
              if (found) {
                foundIds.push(found.mesg_id);
                combinedMsgs.push(found);
              }
              else {
                combinedMsgs.push(existingMsg);
              }
            })
            
            //Add all the new messages that we haven't added yet.
            _.each(newMsgs, (msg) => {
              if(foundIds.indexOf(msg.mesg_id) < 0){
                combinedMsgs.push(msg);
              }
            })

            dispatch({
              type: c.actions.workspace.updateThreadMessages,
              mesg_edit_flag: res[0].data.mesg_edit_flag,
              messages: combinedMsgs,
              messageBlocks: msgHelper.getMessageBlocks(_.concat(pendingMsgs, combinedMsgs)),
              data_date: res[0].data.data_date,
              docs: res[1]
            })
          }
          else {
            dispatch({
              type: c.actions.workspace.updateThreadMessages,
              mesg_edit_flag: res[0].data.mesg_edit_flag,
              messages: res[0].data.mesg_data,
              messageBlocks: msgHelper.getMessageBlocks(_.concat(pendingMsgs, res[0].data.mesg_data)),
              data_date: res[0].data.data_date,
              docs: res[1]
            })
          }
        })
    }
  },
  
  refreshActiveDMMessages(){
    return (dispatch, getState) => {
      
      let { activeDM } = getState().workspace;
      if(!activeDM){
        return Promise.resolve(true);
      }
      
      dispatch(this.refreshDMMessages(activeDM))
    }
  },
  
  refreshDMMessages(dm) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
  
        let finishedDocs = false;
        let finishedMessages = false;
        
        if (dm.guest_uid) {
          sapi.DM.docList(dm.guest_uid)
            .then((res) => {
              let docs = [];
              _.each(res.data, (block) => {
                _.each(block.docs, (doc) => {
                  doc.$chatId = block.chat_id;
                  docs.push(doc);
                })
              })
              return docs;
            })
            .then((res) => {
              dispatch({
                type: c.actions.workspace.updateActiveDMMessages,
                docs: res ? res : []
              })
              finishedDocs = true;
              if(finishedDocs && finishedMessages){
                resolve(true);
              }
            })
        }
        else {
          finishedDocs = true;
        }
  
        sapi.DM.messages(dm.guest_uid)
          .then((res) => {
            
            let {accountInfo, threadTransactionQueue} = getState().shared;
            let pendingMsgs = msgHelper.buildPendingMessageList(dm.guest_uid, accountInfo, threadTransactionQueue);
            
            dispatch({
              type: c.actions.workspace.updateActiveDMMessages,
              messages: res.data,
              messageBlocks: msgHelper.getMessageBlocks(_.concat(pendingMsgs, res.data)),
            })
            finishedMessages = true;
            if(finishedDocs && finishedMessages){
              resolve(true);
            }
          })
      })
    }
  },
  
  setActiveDM(dm){
    
    return (dispatch) => {
      dispatch(this.setActiveDocAssociatedThread(null))
  
      return dispatch(this.refreshDMMessages(dm))
        .then((res) => {
          dispatch({
            type: c.actions.workspace.setActiveDoc,
            doc : null,
          })
          dispatch({
            type: c.actions.workspace.setActiveDocAssociatedThread,
            associatedThread : null
          })
          dispatch({
            type: c.actions.workspace.setActiveThread,
            thread : null
          })
          dispatch({
            type: c.actions.workspace.setActiveDM,
            dm
          })
        })
    }
  },
  
  refreshWorkspaceThreadGuests(forum_id, host_uid, workspaceThreads){
    return (dispatch, getState) => {
      let currentAccountUid = getState().shared.accountInfo.uid;
      let participantLookup = {};
      return Promise.map(workspaceThreads, (t) => {
          return sapi.Threads.guests(forum_id, host_uid, t.chat_id)
            .then((res) => {
              participantLookup[t.chat_id] = _.remove(res.data, (u) => { return u.guest_uid !== currentAccountUid})
            })
        })
        .then(() => {
          dispatch({
            type: c.actions.workspace.updateThreadParticipantLookup,
            lookup: participantLookup
          })
          
          //We wait for just a sec to allow dispatch to update the thread lookup above.
          return utils.waitFor(50);
        })
    }
  },
  
  refreshThreads(forum_id, host_uid, doRefreshGuests){
    return (dispatch, getState) => {
      let threadList = null;
      return sapi.Threads.get(forum_id, host_uid || null)
        .then((threadRes) => {
          threadList = threadRes.data
          
          if(doRefreshGuests){
            return dispatch(this.refreshWorkspaceThreadGuests(forum_id, host_uid, threadList));
          }
          else{
            return Promise.resolve(true);
          }
        })
        .then(() => {
          dispatch({
            type: c.actions.workspace.updateThreads,
            threads: threadList
          })
          let { activeThread } = getState().workspace;
          if(activeThread){
            let foundThread = _.find(threadList, (t) => t.chat_id === activeThread.chat_id)
            if(foundThread){
              dispatch({
                type: c.actions.workspace.setActiveThread,
                thread : foundThread
              })
            }
          }
        })
    }
  },

  refreshDocs(forum_id, host_uid){
    return (dispatch, getState) => {
      DocPermissionsHelper.clearCachedItem(forum_id);
      
      let docList = null;
      return sapi.Docs.list(forum_id, host_uid)
        .then((docRes) => {
          docList = docRes.data;
  
          let participantLookup = {};
          return Promise.map(docList, (docObj) => {
              return Promise.map(docObj.docs, (doc) => {
                return DocPermissionsHelper.getUserIdsWithPermission(forum_id, doc.doc_id)
                  .then((guest_ids) => {
                    
                    let contacts = [];
                    _.each(guest_ids, (uid) => {
                      let contact = _.find(getState().shared.contacts, (c) => c.guest_uid === uid);
                      if(contact){
                        contacts.push(contact);
                      }
                    })
                    
                    participantLookup[doc.doc_id] = contacts;
                  })
              })
            })
            .then(() => {
              return participantLookup;
            })
        })
        .then((res) => {
          dispatch({
            type: c.actions.workspace.updateDocs,
            docs: docList
          })
          dispatch({
            type: c.actions.workspace.updateDocParticipantLookup,
            lookup: res
          })
  
          let { activeDoc } = getState().workspace;
          if(activeDoc){
            let foundDoc = null;
            _.each(docList, (doc) => {
              if(doc.doc_id === activeDoc.doc_id){
                foundDoc = doc;
              }
            })
    
            if(foundDoc){
              return dispatch(this.setActiveDoc(foundDoc));
            }
          }
  
          let { activeThread } = getState().workspace;
          if(activeThread){
            dispatch(this.refreshThreadMessages(forum_id, host_uid, activeThread.chat_id));
          }
  
          return docList
        })
    }
  },
  
  refreshGuests(forum_id, host_uid){
    return (dispatch) => {
      return sapi.Guests.get(forum_id, host_uid || null)
        .then((guestRes) => {
          dispatch({
            type: c.actions.workspace.updateGuests,
            guests: guestRes.data
          })
          return guestRes.data;
        })
    }
  },
  
  mapToDispatch(dispatch){
    return {
      findGuestInActiveThreadParticipants : (guest_uid) => dispatch(this.findGuestInActiveThreadParticipants(guest_uid)),
      refreshThreads: (forum_id, host_uid, doRefreshGuests) => dispatch(this.refreshThreads(forum_id, host_uid, doRefreshGuests)),
      refreshDocs: (forum_id, host_uid) => dispatch(this.refreshDocs(forum_id, host_uid)),
      refreshGuests: (forum_id, host_uid) => dispatch(this.refreshGuests(forum_id, host_uid)),
      refreshWorkspace: (forum_id) => dispatch(this.refreshWorkspace(forum_id)),
      clearActiveThread: () => dispatch(this.clearActiveThread()),
      setActiveThread: (forum_id, host_uid, thread) => dispatch(this.setActiveThread(forum_id, host_uid, thread)),
      setActiveDM: (dm) => dispatch(this.setActiveDM(dm)),
      clearActiveDM:() => dispatch(this.clearActiveDM()),
      refreshActiveDMMessages : () => dispatch(this.refreshActiveDMMessages()),
      refreshThreadMessages : (forum_id, host_uid, chat_id, data_date) => dispatch(this.refreshThreadMessages(forum_id, host_uid, chat_id, data_date)),
    }
  }
}

export default workspaceActions;
