import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import uploadActions from '../actions/upload-actions';
import modalActions from '../actions/modal-actions';

import workspaceActions from '../actions/workspace-actions'

import PropTypes from 'prop-types';

import log from "../util/log";
import sapi from "../util/sapi";
import _ from "lodash";
import sharedActions from "../actions/shared-actions";
import DownloadHelper from "../components/partials/components/DownloadHelper";
import downloadActions from "../actions/download-actions";

class DownloadManager extends Component {
  
  //This might be more than we need, but this gives us a ton of control over each part of the
  //download process, and failures, retry stuff, etc.
  
  //Import note right now, I can't tell when downloads are finished right now.
  //so I don't clean them up.  I'm not sure there's any real consequence, since it' just some iframes, and how many could they really have?
  //100? 1000?
  constructor(props){
    super(props);
    
    this.onDownloadHelperRef = this.onDownloadHelperRef.bind(this)
  }
  
  onDownloadHelperRef(ref){
    if(!ref){
      return;
    }
    
    setTimeout(() => {
      ref.doDownload();
    }, 100)
  }
  
  doDownload(download){
    this.props.addToCurrentQueue(download);
  }
  
  processQueue(){
    let { pendingDownloadQueue } = this.props;
    
    if(pendingDownloadQueue.length > 0){
      let nextDownload = pendingDownloadQueue[0];
      pendingDownloadQueue.splice(0, 1);
      this.props.updatePendingQueue(pendingDownloadQueue);
      this.doDownload(nextDownload);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.processQueue();
  }
  
  render(){
    let { currentDownloadQueue } = this.props;
    
    return (
      <Fragment>
        {_.map(currentDownloadQueue, (download) => {
          return (
            <DownloadHelper key={download.id}
                            headers={download.headers}
                            url={download.url}
                            onRef={this.onDownloadHelperRef} />
          )
        })}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pendingDownloadQueue : state.download.pendingDownloadQueue,
    currentDownloadQueue: state.download.currentDownloadQueue
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    updatePendingQueue : (queue) => dispatch(downloadActions.updatePendingQueue(queue)),
    addToCurrentQueue : (downloads) => dispatch(downloadActions.addToCurrentQueue(downloads))
  };
};

DownloadManager.propTypes = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadManager));
