import c from '../../../util/const'
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import filters from "../../../helpers/filters";
import Button from "../elements/Button";
import sapi from "../../../util/sapi";
import Image from "../elements/Image";
import Promise from "bluebird";
import PreviewWindow from "../../modals/PreviewWindow";
import utils from "../../../util/util";
import downloadActions from "../../../actions/download-actions";
import _ from "lodash";
import colors from "../../../util/colors";
import log from "../../../util/log";
import PdfPage from "../pdf-preview/PdfPage";
import Scroll from "react-scroll";
import {Waypoint} from "react-waypoint";
import PdfThumbnail from "../pdf-preview/PdfThumbnail";
import FlipMove from 'react-flip-move';
import browser from '../../../util/browser'
import {findDOMNode} from "react-dom";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class ThumbnailPanel extends Component {
  
  THUMBNAIL_SCROLL_CONTAINER = 'pdf-thumbnail-scroll';
  
  THUMBNAIL_WIDTH = 160;
  static THUMBNAIL_PANEL_WIDTH = 200;
  mounted = false;
  isRendering = false;
  renderAgain = false;
  
  constructor(props) {
    super(props);
  
    this.thumbnailCanvasRef = React.createRef();
    this.dragImageCanvasRef = React.createRef();
    this.state = {
      thumbnailCache : {},
      pages : [],
      isAnimating : false,
      pauseListAnimations : false
    }
  }
  
  componentDidMount() {
    if(this.props.onRef){ this.props.onRef(this) }
    
    this.mounted = true;
    if(this.props.showing){
      this.renderVisiblePages();
    }
  }
  
  componentWillUnmount() {
    if(this.props.onRef){ this.props.onRef(undefined) }
    this.mounted = false;
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!this.props.showing || !this.mounted){
      return
    }
    
    if(this.state.pages.length !== this.props.pdfPages.length){
      this.setState({
        pages : _.concat([], this.props.pdfPages)
      })
    }
    
    let isOpening = !prevProps.showing && this.props.showing
    if(isOpening){
      this.renderVisiblePages();
    }
    if(this.props.showing) {
      if (isOpening || prevProps.activePdfPageIndex !== this.props.activePdfPageIndex) {
        let isVisible = this.isScrolledIntoView(this.props.activePdfPageIndex);
        if(!isVisible){
          this.scrollToThumbnail(this.props.activePdfPageIndex);
        }
        this.renderVisiblePages();
      }
    }
  }
  
  scrollToThumbnail(index){
    //This is sneaky, and pretty annoying.
    //If you make this a smooth scroll, it doesn't work.  I think it has something to do with two animated scrollbars at once.
    //If you are scrolling the main pdf view while it changes the thumbnail view,
    //we detect when the active page is out of range, and try to adjust the thumbnail scroll panel
    //to view the new item.  If smooth = true, that just does nothing at all here.  No error, just nothing happens.
    //I hacked at this for a while, but I'm not sure what else to conclude except that there's some browser
    //complication with animating two scrollbars at once.
    //The weird thing is if you change the page by clicking the button (which auto-scrolls to position)
    //then the animated thumbnail scroll works.  Boooooo.
    //It's not that bad having this snap to place, since you're probably not using this scroll view if you're scrolling in the main one.
    Scroll.scroller.scrollTo(`pdf-thumbnail-page-${index}`, {
      smooth: false,
      offset: 1,
      containerId: this.THUMBNAIL_SCROLL_CONTAINER,
    })
  }
  
  isScrolledIntoView(index, partiallyVisible) {
    let el = document.getElementById(`pdf-thumbnail-page-${index}`);
    if(!el){
      return;
    }
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    
    if(!partiallyVisible){
      return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    }
    else{
      return elemTop < window.innerHeight && elemBottom >= 0;
    }
  }
  
  generatePageThumbnail(pageIndex){
    return new Promise((resolve, reject) => {
      let page = this.state.pages[pageIndex];
      let ctx = this.thumbnailCanvasRef.current.getContext('2d', {
        alpha: false
      });
  
      if(!ctx){
        reject('pdf canvas context does not exist yet');
        return;
      }
  
      let outputScale = PdfPage.getOutputScale(ctx);
  
      let viewport = page.getViewport({scale: 1});
      let pageWidth = viewport.width;
      let pageHeight = viewport.height;
      let pageRatio = pageWidth / pageHeight;
  
      let canvasWidth = this.THUMBNAIL_WIDTH;
      let canvasHeight = (canvasWidth / pageRatio) | 0;
      let scale = canvasWidth / pageWidth;
      let drawViewport = viewport.clone({scale});
  
      this.thumbnailCanvasRef.current.width = (canvasWidth * outputScale.sx) | 0;
      this.thumbnailCanvasRef.current.height = (canvasHeight * outputScale.sy) | 0;
      this.thumbnailCanvasRef.current.style.width = canvasWidth + "px";
      this.thumbnailCanvasRef.current.style.height = canvasHeight + "px";
      
      let renderContext = {
        canvasContext: ctx,
        transform : !outputScale.scaled ? null : [outputScale.sx, 0, 0, outputScale.sy, 0, 0],
        viewport: drawViewport
      };
  
      return page.render(renderContext).promise
        .then(() => {
          resolve({
            width : this.thumbnailCanvasRef.current.width / outputScale.sx,
            height : this.thumbnailCanvasRef.current.height / outputScale.sy,
            data : this.thumbnailCanvasRef.current.toDataURL('image/png', 1.0)
          });
        })
    })
  }
  
  generatePageDragImage(pageIndex){
    return new Promise((resolve, reject) => {
      let page = this.state.pages[pageIndex];
      let ctx = this.dragImageCanvasRef.current.getContext('2d', {
        alpha: false
      });
      
      if(!ctx){
        reject('pdf canvas context does not exist yet');
        return;
      }
      
      let outputScale = PdfPage.getOutputScale(ctx);
      
      let viewport = page.getViewport({scale: 1});
      let pageWidth = viewport.width;
      let pageHeight = viewport.height;
      let pageRatio = pageWidth / pageHeight;
      
      //So this looks a little weird.  I think safari handles showing DragPreviewImage images differently than other browsers.
      //Since I don't have control of the image size, I have to generate it at the size that we want to see.
      //Safari appears to not factor in the device pixel ratio somehow.
      let canvasWidth = this.THUMBNAIL_WIDTH / (browser.isSafari() ? 1 : outputScale.sx);
      let canvasHeight = (canvasWidth / pageRatio) | 0;
      let scale = canvasWidth / pageWidth;
      let drawViewport = viewport.clone({scale});
      
      this.dragImageCanvasRef.current.width = (canvasWidth * outputScale.sx) | 0;
      this.dragImageCanvasRef.current.height = (canvasHeight * outputScale.sy) | 0;
      this.dragImageCanvasRef.current.style.width = canvasWidth + "px";
      this.dragImageCanvasRef.current.style.height = canvasHeight + "px";
      
      let renderContext = {
        canvasContext: ctx,
        transform : !outputScale.scaled ? null : [outputScale.sx, 0, 0, outputScale.sy, 0, 0],
        viewport: drawViewport
      };
      
      return page.render(renderContext).promise
        .then(() => {
          resolve({
            width : this.dragImageCanvasRef.current.width / outputScale.sx,
            height : this.dragImageCanvasRef.current.height / outputScale.sy,
            data : this.dragImageCanvasRef.current.toDataURL('image/png', 1.0)
          });
        })
    })
  }
  
  getIndicesInScrollView(){
    
    let visibleIndices = [];
    let lastVisibleIndex = -1;
    _.each(this.state.pages, (page) => {
      if(this.isScrolledIntoView(page.pageIndex, true)){
        visibleIndices.push(page.pageIndex);
        lastVisibleIndex = page.pageIndex;
      }
      else if(lastVisibleIndex > 0){
        //early exit, we found the bottom of the scroll view.
        return false;
      }
    })
    return visibleIndices;
  }
  
  getNextPageIndexToRender(){
    let visibleIndices = this.getIndicesInScrollView();
    log.log('visible indices', visibleIndices);
    let nextUncachedIndex = -1;
    _.each(visibleIndices, (index) => {
      if(!this.state.thumbnailCache[index]){
        if(nextUncachedIndex < 0){
          nextUncachedIndex = index;
        }
        if(index < nextUncachedIndex){
          nextUncachedIndex = index;
        }
      }
    })
    return nextUncachedIndex;
  }
  
  cacheThumbnail(pageIndex, res){
    return new Promise((resolve, reject) => {
  
      let update = _.extend({}, this.state.thumbnailCache);
      update[pageIndex] = res;
      
      this.setState({
        thumbnailCache : update
      }, () => {
        resolve(true);
      })
    })
  }
  
  onPageEnter(index){
    log.log('onPageEnter', index);
    this.renderVisiblePages();
  }
  
  renderVisiblePages(){
    if(this.isRendering){
      this.renderAgain = true;
      return;
    }
    
    this.isRendering = true;
    log.log('isRendering!')
    setTimeout(() => {
      this.cacheVisibleThumbnails()
        .finally(() => {
          if(this.renderAgain){
            log.log('done rendering, but restarting')
            this.isRendering = false;
            this.renderAgain = false;
            this.renderVisiblePages();
          }
          else{
            log.log('done rendering')
            this.isRendering = false;
            this.renderAgain = false;
          }
          
        })
    }, 100)
  }
  
  cacheVisibleThumbnails(){
    let { t } = this.props;
    return new Promise((resolve, reject) => {
      let nextIndex = this.getNextPageIndexToRender();
      if(!this.mounted || nextIndex < 0){
        //nothing to do.
        resolve(true);
        return;
      }
  
      Promise.all([
          this.generatePageThumbnail(nextIndex),
          this.generatePageDragImage(nextIndex)
        ])
        .then((imgRes) => {
          if(imgRes){
            //If it worked, keep it rolling.
            return this.cacheThumbnail(nextIndex, {thumb : imgRes[0], drag : imgRes[1]})
              .finally(() => {
                resolve(this.cacheVisibleThumbnails());
              })
          }
          else{
            log.log('unable to generate thumbnail image, unknown reason')
            //If something didn't work....skip it?
            return this.cacheThumbnail(nextIndex, { error : t('Unable to render thumbnail') })
              .finally(() => {
                resolve(this.cacheVisibleThumbnails());
              })
          }
        })
        .catch((err) => {
          log.log('Error generating thumbnail image', err);
          this.cacheThumbnail(nextIndex, { error : err })
            .finally(() => {
              resolve(this.cacheVisibleThumbnails());
            })
        })
    })
  }
  
  resetThumbnailCache(){
    this.setState({
      thumbnailCache : {},
      pages : _.concat([], this.props.pdfPages)
    }, () => {
      this.renderVisiblePages();
    })
  }
  
  doPageDelete(index){
    let { t } = this.props;
    if(this.state.pages.length === 1){
      this.props.showAlert(t('Unable to delete page'), t("This is the only page in this pdf, and it cannot be deleted."));
      return;
    }
    
    this.setState({pauseListAnimations : true}, () => {
      this.props.onPageDeleteClick(index)
        .then(() => {
          this.resetThumbnailCache();
        })
        .finally(() => {
          this.setState({pauseListAnimations : false})
        })
    })
  }
  
  onDragCancel(){
    this.setState({
      pages : _.concat([], this.props.pdfPages)
    })
  }
  
  doHoverMove(dragIndex, hoverIndex){
    log.log('do hover move', dragIndex, hoverIndex);
    
    let update = _.concat([], this.state.pages);
    let dragItem = update[dragIndex];
    update.splice(dragIndex, 1);
    update.splice(hoverIndex, 0, dragItem);
    
    log.log('hover move update', update);
    this.setState({
      pages : update
    })
  }
  
  doPageMove(from, to){
    log.log('do page move', from, to);
    this.setState({pauseListAnimations : true}, () => {
      this.props.onPageMove(from, to)
        .then(() => {
          this.resetThumbnailCache();
        })
        .finally(() => {
          this.setState({pauseListAnimations : false})
        })
    })
  }
  
  //Technically this is only needed for safari's autoscrolling.
  //We could remove it if that wasn't needed anymore.
  dragThumbnailStart(component) {
    this.setState({
      isDraggingThumbnail: true,
      draggingThumbnailCmp: findDOMNode(component),
      scrollDOM: document.querySelector('.pdf-thumbnail-scroll-container')
    }, () => {
      this.doAutoScrollingForSafari();
    })
  }
  
  //Only needed for safari's autoscrolling.
  dragThumbnailEnd(){
    this.setState({
      isDraggingThumbnail : false,
      draggingThumbnailCmp : null,
      scrollDOM : null
    })
  }
  
  doAutoScrollingForSafari(){
    //Ok, so in most browsers, when you're dragging pdf pages around the browser
    //autoscrolls for you when you drag towards the top/bottom.
    //In safari I guess we have to do this ourselves.  There's supposedly a library
    //that's compatible with react-dnd that fixes this in safari, but I can't get it work.
    
    if (browser.isSafari()) {
      //This is kind of a weird use of this function, but it's an easy way to
      // "Do this while this is true".  :/
      //Could probably wrap this into a more clear helper function.
      //BTW the wait time is effectively a debounce time.
      const step = 20;
      utils.waitForCondition(() => {
          if (this.state.scrollDOM && this.state.draggingThumbnailCmp) {
            const cursor = this.state.draggingThumbnailCmp.getBoundingClientRect();
            const rect = this.state.scrollDOM.getBoundingClientRect();
            //log.log('onDraggingHover cursor, rect', this.state.scrollDOM, this.state.draggingThumbnailCmp);
            if (cursor.top - rect.top < 200) {
              //log.log('scrolling up', this.state.scrollDOM.scrollTop);
              this.state.scrollDOM.scrollTop -= step;
            }
            else if (rect.bottom - cursor.bottom < 200) {
              //log.log('scrolling down', this.state.scrollDOM.scrollTop);
              this.state.scrollDOM.scrollTop += step;
            }
            // else{
            //   log.log('not scrolling, no position change', cursor, rect);
            // }
          }
          // else {
          //   log.log('skipping scroll step, no components');
          // }
        
          return !this.state.isDraggingThumbnail;
        }, 30)
        .then(() => {
          log.log('looks like dragging is done')
        })
    }
  }
  
  render() {
    let {
      showing
    } = this.props;
    let {
      pages,
      pauseListAnimations
    } = this.state;
    
    let { thumbnailCache } = this.state;
    return (
      <Fragment>
        <div id={this.THUMBNAIL_SCROLL_CONTAINER}
             className={`pdf-thumbnail-scroll-container ${showing ? '' : 'not-visible'}`}>
          {showing &&
          <div style={{padding: '20px'}}>
            <FlipMove onFinishAll={() => this.setState({isAnimating: false})}
                      disableAllAnimations={pauseListAnimations}
                      onStartAll={() => this.setState({isAnimating: true})}>
              {_.map(pages, (page, i) => {
                let thumbnailImage = _.get(thumbnailCache[page.pageIndex], 'thumb');
                let dragImage = _.get(thumbnailCache[page.pageIndex], 'drag');
                return (
                  <div key={page.pageIndex}>
                    <Waypoint onEnter={this.onPageEnter.bind(this, page.pageIndex)}>
                      <div>
                        <PdfThumbnail pageIndex={page.pageIndex}
                                      listIndex={i}
                                      isActivePage={page.pageIndex === this.props.activePdfPageIndex}
                                      onThumbnailPageClick={() => this.props.onThumbnailPageClick(page.pageIndex)}
                                      onDeletePageClick={this.doPageDelete.bind(this)}
                                      isEditModeOn={this.props.isEditModeOn}
                                      canvasRef={this.thumbnailCanvasRef}
                                      dragThumbnailEnd={this.dragThumbnailEnd.bind(this)}
                                      dragThumbnailStart={this.dragThumbnailStart.bind(this)}
                                      doHoverMove={(from, to) => this.doHoverMove(from, to)}
                                      commitMove={(from, to) => this.doPageMove(from, to)}
                                      onDragCancel={() => this.onDragCancel(i)}
                                      disableHoverEvents={this.state.isAnimating}
                                      dragImage={dragImage}
                                      thumbnailImage={thumbnailImage}/>
                      </div>
                    </Waypoint>
                  </div>
                )
              })}
            </FlipMove>
          </div>
          }
        </div>
        <canvas height={1}
                width={1}
                className="d-none"
                ref={this.thumbnailCanvasRef}/>
        <canvas height={1}
                width={1}
                className="d-none"
                ref={this.dragImageCanvasRef}/>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

ThumbnailPanel.propTypes = {
  onRef : PropTypes.func.isRequired,
  onPageDeleteClick : PropTypes.func.isRequired,
  onPageMove : PropTypes.func.isRequired,
  showing : PropTypes.bool.isRequired,
  pdfPages : PropTypes.array.isRequired,
  onThumbnailPageClick : PropTypes.func.isRequired,
  activePdfPageIndex : PropTypes.number.isRequired,
  isEditModeOn : PropTypes.bool.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ThumbnailPanel));
