import c from '../util/const';
import Promise from "bluebird";
import sapi from "../util/sapi";
import authActions from './auth-actions';
import accountActions from './account-actions';
import homeActions from './home-actions';
import sharedActions from './shared-actions';
import uploadActions from './upload-actions';
import workspaceActions from './workspace-actions';
import downloadActions from "./download-actions";
import vfSessionStorage from "../util/session-storage";
import log from "../util/log";

const appActions = {

  setApplicationError(error){
    //Blocks the entire application with an error message, and shows users tools to report.
    return {
      type : c.actions.app.setApplicationError,
      error
    }
  },
  
  setBrowserTabActive(isActive){
    return {
      type: c.actions.app.setBrowserTabActive,
      isActive
    }
  },
  
  setQueryString(qs) {
    return {
      type: c.actions.app.setQueryString,
      qs
    }
  },
  
  setQsActionNeeded(actionNeeded) {
    return (dispatch) => {
      dispatch( {
        type: c.actions.app.setQsActionNeeded,
        qsActionNeeded : actionNeeded,
      })
      if(!actionNeeded){
        dispatch(this.setQueryString(null));
        vfSessionStorage.remove(c.sessionstorage.qs);
      }
    }
    
  },
  
  setQsEmail(email) {
    return {
      type: c.actions.app.setQsEmail,
      qsEmail : email
    }
  },

  cleanup(){
    return (dispatch) => {
      dispatch(authActions.logout());
      dispatch(accountActions.cleanup());
      dispatch(homeActions.cleanup());
      dispatch(sharedActions.cleanup());
      dispatch(uploadActions.cleanup());
      dispatch(workspaceActions.cleanup());
      dispatch(downloadActions.cleanup());
    }
  },
  
  resetAuthenticatedAppState(){
    //We use this to ensure the app is in an expected state on log in.
    //There has been cases in the past where app state got set after booting users to login
    //This data then was a problem later because it was still hanging around.
    return (dispatch) => {
      dispatch(accountActions.cleanup());
      dispatch(homeActions.cleanup());
      dispatch(sharedActions.cleanup());
      dispatch(uploadActions.cleanup());
      dispatch(workspaceActions.cleanup());
      dispatch(downloadActions.cleanup());
    }
  },
  
  logout(){
    return (dispatch) => {
      
      return sapi.AccountInfo.logout()
        .catch((err) => {
          log.error('error logging out', err);
        })
        .finally(() => {
          dispatch(this.cleanup());
          
          return {
            type : c.actions.app.logout
          }
        })
    }
  }
}

export default appActions;
