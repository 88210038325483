import c from '../../../util/const'
import log from '../../../util/log'
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import ReactToPrint from 'react-to-print';
import _ from 'lodash'
import PropTypes from 'prop-types';
import filters from "../../../helpers/filters";
import colors from "../../../util/colors";
import MessageBlock from "./MessageBlock";
import workspaceActions from "../../../actions/workspace-actions";

class ChatPrintPreviewSvc extends Component {
  
  constructor(props){
    super(props);
    
    this.printRef = React.createRef();
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }
  
  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  render() {
    let {thread, dm, messageBlocks} = this.props;
    
    //I put d-none on the outside wrapper, but put the ref on the inner div.
    //The ref is what gets passed to the printer, so the style would not apply.
    return (
      <Fragment>
        <div className={'d-none'}>
          <div ref={this.printRef}>
            {_.map(messageBlocks, (block, index) => {
  
              let showDay = false;
              let currentDate = null;
              if(index === 0){
                showDay = true;
                currentDate = filters.momentDate(block.blockList[0].mesg_date);
              }
              else{
                let previousBlock = messageBlocks[index - 1];
    
                let previousDate = filters.momentDate(previousBlock.blockList[0].mesg_date);
                currentDate = filters.momentDate(block.blockList[0].mesg_date);
    
                showDay = !previousDate.isSame(currentDate, 'day');
              }
  
              let guest = dm;
              if(thread){
                guest = this.props.findGuestInActiveThreadParticipants(block.guest_uid)
                if(!guest){
                  //A little weird looking, but if we can't find the guest from thread participants,
                  //just pass in the message itself, since it has guest_uid, first_name, last_name on it.
                  guest = block.blockList[0];
                }
              }
  
              return (
                <Fragment key={block.blockList[0].mesg_id}>
                  {showDay && currentDate &&
                  <div style={styles.msgDateWrap}>
                    <div style={styles.msgDateBlock}>
                      <span className="light-grey-color">{currentDate.format('MMMM Do YYYY')}</span>
                    </div>
                  </div>
                  }
                  <MessageBlock dm={dm}
                                thread={thread}
                                guest={guest}
                                mesg_edit_flag={false}
                                docClick={_.noop}
                                refreshMessages={_.noop}
                                block={block}
                                findDocInfo={this.props.findDocInfo}
                                fulfillSignatureRequestClick={_.noop}
                                editSignatureRequestClick={_.noop}
                                completeSignatureRequestClick={_.noop}/>
                </Fragment>
              )
            })}
          </div>
        </div>
      </Fragment>
    )
  }
}

const styles = {
  msgDateWrap : {
    borderTop : '1px solid ' + colors.LIGHT_GREY,
    margin : '20px auto 0'
  },
  msgDateBlock : {
    position : 'relative',
    backgroundColor : colors.LIGHT,
    textAlign : 'center',
    display : 'table',
    margin : 'auto',
    top : '-9px',
    paddingLeft : '8px',
    paddingRight : '8px',
    fontSize : '11px'
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    findGuestInActiveThreadParticipants : (guest_uid) => dispatch(workspaceActions.findGuestInActiveThreadParticipants(guest_uid)),
  };
};

ChatPrintPreviewSvc.propTypes = {
  onRef : PropTypes.func.isRequired,
  thread: PropTypes.object,
  dm: PropTypes.object,
  messageBlocks: PropTypes.array.isRequired,
  findDocInfo : PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatPrintPreviewSvc);
