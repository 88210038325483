import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";
import c from '../../../util/const'
import TextareaAutosize from 'react-textarea-autosize';

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import Button from "../elements/Button";
import colors from "../../../util/colors";
import UploadHelper from "../components/UploadHelper";
import UserBadge from "../badges/UserBadge";
import utils from "../../../util/util";
import modalActions from "../../../actions/modal-actions";
import UpgradeDialog from "../../modals/UpgradeDialog";
import {withRouter} from "react-router-dom";
import {getMessageForError} from "../../../util/errors";
import MaxChatMessageWarning from "./MaxChatMessageWarning";
import DocExtensionPlaceholder from "../elements/DocExtensionPlaceholder";
import ChatEmojiMenu from "./ChatEmojiMenu";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class ChatPanelFooter extends PureComponent {
  
  constructor(props){
    super(props);
    
    this.state = {
      pendingDocs : [],
      msg : '',
      isSending : false
    }
  }
  
  componentDidMount() {
    if(this.props.onRef){
      this.props.onRef(this)
    }
  }
  
  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }
  
  initPendingMessage(msg, docs){
    this.setState({
      pendingDocs : _.concat([], docs || []),
      msg : msg || ''
    })
  }
  
  updatePendingDocs(docs){
    this.setState({
      pendingDocs : _.concat([], this.state.pendingDocs, docs || []),
    })
  }
  
  getPendingData(){
    return {
      msg : this.state.msg,
      docs : this.state.pendingDocs
    }
  }
  
  clear() {
    this.setState({
      pendingDocs : [],
      msg : '',
      isSending : false
    })
  }
  
  setNotSending() {
    this.setState({
      isSending : false
    })
  }
  
  sendMessage(){
    if(this.state.isSending){
      return;
    }
    else{
      this.setState({isSending : true}, () => {
        this.props.sendMessage(this.state.msg, this.state.pendingDocs);
      });
    }
  }
  
  onMsgChange(evt){
    this.setState({msg : evt.target.value})
  }
  
  removePendingDoc(doc){
    let {pendingDocs} = this.state;
    _.remove(pendingDocs, (f) => {
      return f.uniqueId === doc.uniqueId
    })
    let update = _.concat([], pendingDocs);
    this.setState({
      pendingDocs: update
    })
  }
  
  addCopyDocs(files){
  
    _.each(files, (f) => {
      f.name = f.doc_label;
      f.sizeString = ''; // we don't have this for copy docs.
      f.is_doc_copy = true;
      f.uniqueId = _.uniqueId('vf-doc-attach-')
    })
    
    let {pendingDocs} = this.state;
    let update = _.concat(pendingDocs, files);
    this.setState({
      pendingDocs: update
    })
  }
  
  docFileDrop(files) {
    log.log('got files footer', files);
    let { workspace, thread, t } = this.props;

    if(!thread){
      _.each(files, (f) => {
        f.signature_requested = false;
      })
  
      let {pendingDocs} = this.state;
      let update = _.concat(pendingDocs, files);
      this.setState({
        pendingDocs: update
      })
    }
    else {
      _.each(files, (f) => {
        f.signature_requested = false;
      })
  
      sapi.Docs.preupload(workspace.forum_id, workspace.host_uid, _.map(files, (f) => f.name))
        .then((res) => {
          log.log('preupload res', res);
      
          let {pendingDocs} = this.state;
          let update = _.concat(pendingDocs, files);
          this.setState({
            pendingDocs: update
          })
        })
        .catch((err) => {
          log.log('preupload err', err);
      
          if (sapi.isConnectionError(err)) {
            this.props.showAlert(t("Unable to Connect"), getMessageForError(err, t));
            return;
          }
          else {
            this.props.showUpgradeDialogOrError(UpgradeDialog.UPGRADE_TYPES.DOCS, err, t, (res) => {
              log.log('showUpgradeDialogOrError res', res);
            })
          }
        })
    }
  }
  
  infoBtnClick(){
    let { t } = this.props;
    if(this.props.thread) {
      this.props.showAlert(
        t("Signing Requests"),
        t("If you request a signature on a document, you will get to select the signer, and how and where the document should be signed. After submitting your document, click \"Complete Request\" and follow the instructions. The signer will not be notified until the request is completed. This is only available for Verifyle Pros."))
    }
    else{
      this.props.showAlert(
        t("Signing Requests"),
        t("If you request a signature on a document, you will get to indicate how and where the document should be signed. After submitting your document, click \"Complete Request\" and follow the instructions. The signer will not be notified until the request is completed. This is only available for Verifyle Pros."))
    }
  }
  
  updateSigningChecked(doc, checked){
    let { pendingDocs } = this.state;
    let update = _.concat([], pendingDocs);
    
    _.each(update, (f) => {
      if(f.uniqueId === doc.uniqueId){
        f.signature_requested = checked;
      }
    })
    this.setState({pendingDocs : update})
  }
  
  onRequestSigningInputChange(doc, evt){
  
    let isChecked = false;
    if(doc.signature_requested){
      isChecked = false;
    }
    else{
      isChecked = true;
    }
    
    if(isChecked){
      let { accountInfo } = this.props;
    
      if(accountInfo.class_id < 100){
        //show dialog, cancel signing check.
        this.updateSigningChecked(doc, false);
        this.props.showUpgradeDialogOrError(UpgradeDialog.UPGRADE_TYPES.SIGNING, null, this.props.t, (res) => {
          log.log('showUpgradeDialogOrError res', res);
        })
        return;
      }
    }
    
    this.updateSigningChecked(doc, isChecked);
  }
  
  renderPendingDocUpload(doc){
    let { signingIsAllowed, thread, t } = this.props;
    let isPDFable = utils.isPDFPreview(doc.name);
    let isTooBigToConvert = isPDFable && !utils.fileExtensionIsPDF(doc.name) && doc.sizeBytes > c.limits.maxFileSizeSigningBytes;
    return (
      <div key={doc.uniqueId} className="d-flex" style={styles.docWrap}>
        <span style={styles.previewImgWrap}>
          {doc.preview && <img style={styles.previewImg} src={doc.preview} /> }
          {!doc.preview && <DocExtensionPlaceholder filename={doc.name} /> }
        </span>
        <div className="flex-grow-1 pending-file-upload">
          <p style={styles.docTopRow} className="mb-0 auto-ellipsis">
            <span style={styles.docTitle}>{doc.name}</span><span style={styles.docSize}>{doc.sizeString}</span>
          </p>
          {((thread && signingIsAllowed) || !thread) && isPDFable && !isTooBigToConvert &&
          <div style={styles.docBottomRow} className="mb-0">
            <span className="form-check d-inline-block" style={{lineHeight: '18px'}}>
              <div onClick={this.onRequestSigningInputChange.bind(this, doc)} className="d-inline-block has-pointer pr-2">
                <i style={{fontSize: '18px'}} className={`icon ${doc.signature_requested ? 'ion-android-checkbox green-color' : 'ion-android-checkbox-outline-blank secondary-text-color'}`} />
              </div>
              <label className="form-check-label green-color">{t("Request Signing")}</label>
            </span>
            <span>
              <button className="btn btn-sm btn-link light-grey-color"
                      onClick={this.infoBtnClick.bind(this)}>
                <i className="icon ion-information-circled"/>
              </button>
            </span>
          </div>
          }
        </div>
        <button style={styles.docDeleteBtn}
                onClick={this.removePendingDoc.bind(this, doc)}
                className="btn btn-lg dark-bg light-color">
          <i className="icon ion-close" />
        </button>
      </div>
    )
  }
  
  onEmojiSelect(evt){
    log.log('onEmojiSelect', evt);
  
    this.setState({msg : this.state.msg + evt.native})
  }
  
  render() {
    let {
      msg,
      pendingDocs,
      isSending
    } = this.state;

    let {
      thread,
      t
    } = this.props;
    
    let isDM = !thread;
    
    return (
      <div className={`light-color py-2 ${isDM ? 'dark-bg' : 'primary-bg'}`} style={styles.wrapper}>
  
        <div className="d-flex" style={styles.flexWrapper}>
          <UploadHelper allowMultiple={true}
                        clickOnly={true}
                        onDrop={this.docFileDrop.bind(this)}>
            <Button className={`btn ${isDM ? 'btn-dark' : 'btn-primary'}`} type="button" style={{height : '100%', width : `${UserBadge.MEDIUM_SIZE_WIDTH+ 10}px`}}>
              <i style={{fontSize: '30px'}} className={'ion-paperclip light-color'}/>
            </Button>
          </UploadHelper>
          <div style={styles.centerBlock} className="flex-grow-1 chat-footer-wrap light-bg">
      
            <div className="d-flex flex-column position-relative">
              <ChatEmojiMenu onEmojiSelect={this.onEmojiSelect.bind(this)}
                             isDM={isDM} />
              <MaxChatMessageWarning textLength={msg.length} isDM={isDM} />
              <TextareaAutosize className="form-control chat-footer-textarea no-focus no-resize"
                                style={styles.textarea}
                                onChange={this.onMsgChange.bind(this)}
                                value={msg}
                                placeholder={t("Type a message...")}
                                maxRows={10}
                                minRows={2}/>
        
              {pendingDocs.length > 0 &&
              <div style={styles.docSection}>
                {_.map(pendingDocs, (doc) => {
                  return this.renderPendingDocUpload(doc);
                })}
              </div>
              }
            </div>
    
          </div>
          <Button className={`btn ${isDM ? 'btn-dark' : 'btn-primary'}`}
                  style={{width : `${UserBadge.MEDIUM_SIZE_WIDTH + 10}px`, fontSize: '13.5px'}}
                  disabled={isSending || msg.length === 0 && pendingDocs.length === 0 || msg.length > c.limits.maxThreadMessage}
                  onClick={this.sendMessage.bind(this)}
                  type="button">
            Send
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  wrapper : {
  
  },
  flexWrapper : {
    border : '1px solid ' + colors.LIGHT,
    borderRadius : '.25rem'
  },
  centerBlock : {
  
  },
  textarea : {
    zIndex : 1,
    fontSize : '16px',
    border : 'none',
  },
  attachBtn : {
    borderTopRightRadius : '0px',
    borderBottomRightRadius : '0px'
  },
  sendBtn : {
    borderTopLeftRadius : '0px',
    borderBottomLeftRadius : '0px'
  },
  docSection : {
    backgroundColor : colors.LIGHT,
    border : '1px solid ' + colors.DARK,
    borderRadius : '5px',
    margin : '10px',
    padding: '10px',
    maxHeight: '150px',
    overflowY : 'auto'
  },
  docWrap : {
    marginBottom : '5px'
  },
  docTopRow : {
    marginRight : '10px',
    marginLeft : '10px',
    lineHeight : '16px',
    maxWidth : '100%'
  },
  docBottomRow : {
  
  },
  docTitle : {
    fontSize : '14px',
    fontWeight : 'bold',
    marginRight : '10px',
    color : colors.DARK,
    wordWrap: 'break-word',
    width: '100%',
    whiteSpace: 'normal'
  },
  docSize : {
    fontSize: '12px',
    color : colors.LIGHT_GREY
  },
  previewImgWrap : {
    width : '50px',
    height : '50px',
    textAlign : 'center'
  },
  previewImg : {
    maxWidth : '50px',
    maxHeight : '50px',
    border : '1px solid ' + colors.DARK,
    marginRight : '10px'
  },
  previewImgPlaceholder : {
    maxWidth : '50px',
    maxHeight : '50px',
    marginRight : '10px',
    lineHeight : '40px',
    fontSize : '40px',
    color : colors.DARK
  },
  docDeleteBtn : {
    borderRadius : '50%',
    width : '50px',
    height : '50px'
  }
}

ChatPanelFooter.propTypes = {
  thread: PropTypes.object,
  sendMessage: PropTypes.func.isRequired,
  signingIsAllowed : PropTypes.bool,
  onRef : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    accountInfo : state.shared.accountInfo,
    workspace : state.workspace.workspace
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatPanelFooter)));
