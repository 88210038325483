import React, {PureComponent} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import ColorGenerator from "../../../helpers/color-generator";
import colors from "../../../util/colors";
import _ from 'lodash'

class UserBadge extends PureComponent {
  
  constructor(props){
    super(props);
    
    this.state = {
      charString : null,
      guest_uid : null,
      userColor : null
    }
  }
  
  componentDidMount() {
    this.updateFromProps()
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    let prevGuest = _.get(prevProps, 'guest');
    let nowGuest = _.get(this.props, 'guest');
    if(!_.isEqual(prevGuest, nowGuest)){
      this.updateFromProps();
    }
  }
  
  updateFromProps(){
    let { guest } = this.props;
    if(!guest.first_name || !guest.last_name){
      return;
    }
    
    let charString = guest.first_name[0] + guest.last_name[0]
    
    let guest_uid = guest.guest_uid || guest.uid || null;
    this.setState({
      charString : charString.toUpperCase(),
      guest_uid,
      userColor : ColorGenerator.generateColorFromId(guest_uid)
    })
  }
  
  render(){
    let { small, large } = this.props;
    let { charString, userColor } = this.state;
    
    let colorStyle = {backgroundColor : userColor};
    if(this.props.overrideColor){
      colorStyle = {backgroundColor : this.props.overrideColor}
    }
    
    let badgeStyle = styles.badgeStyle;
    if(small){
      badgeStyle = styles.badgeStyleSmall
    }
    else if(large){
      badgeStyle = styles.badgeStyleLarge;
    }
    
    return (
      <div className="light-color" style={{...badgeStyle, ...colorStyle}}>
        {charString}
      </div>
    )
  }
  
}

UserBadge.MEDIUM_SIZE_WIDTH = 48;
const styles = {
  badgeStyle : {
    fontSize: '22px',
    padding: '5px',
    borderRadius: '6px',
    width: `${UserBadge.MEDIUM_SIZE_WIDTH}px`,
    height: `${UserBadge.MEDIUM_SIZE_WIDTH}px`,
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '36px',
    border: `1px solid ${colors.LIGHT}`,
    wordBreak: 'keep-all'
  },
  badgeStyleSmall : {
    fontSize: '10px',
    padding: '4px',
    borderRadius: '3px',
    width: '26px',
    height: '26px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '15px',
    border: `1px solid ${colors.LIGHT}`,
    wordBreak: 'keep-all'
  },
  badgeStyleLarge : {
    fontSize: '30px',
    padding: '10px',
    borderRadius: '6px',
    width: '70px',
    height: '70px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '45px',
    border: `1px solid ${colors.LIGHT}`,
    wordBreak: 'keep-all'
  }
}

UserBadge.propTypes = {
  guest : PropTypes.object.isRequired,
  overrideColor : PropTypes.string,
  small : PropTypes.bool,
  large : PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserBadge));
