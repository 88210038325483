import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import log from "../../../util/log";
import _ from 'lodash';

import classNames from 'classnames';
import colors from "../../../util/colors";

class FancyInput extends Component {

  constructor(props){
    super(props)
  
    this.inputField = React.createRef();
    
    this.state = {
      isActive: false
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    let previousValue = prevProps.inputValue;
    let curValue = this.props.inputValue;
    
    if(previousValue !== curValue){
      if(!curValue || curValue.length === 0){
        this.setState({isActive: false})
      }
      else{
        this.setState({isActive: true})
      }
    }
  }
  
  componentDidMount(){
    let { inputValue } = this.props;
  
    if(!inputValue || inputValue.length === 0){
      this.setState({isActive: false})
    }
    else{
      this.setState({isActive: true})
    }
  
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
    
    if(this.timer){
      clearTimeout(this.timer);
    }
  }
  
  focus(){
    this.inputField.current.focus();
  }
  
  blur(){
    this.inputField.current.blur();
  }
  
  selectAllText(){
    this.inputField.current.select();
  }
  
  onInputFocus(evt){
    let { onFocus } = this.props;
    
    this.setState({isActive: true});
    
    if(onFocus){
      onFocus(evt);
    }
  }
  
  onInputBlur(){
    let { inputValue } = this.props;
    
    if(!inputValue || inputValue.length === 0){
      this.setState({isActive: false})
    }
  }
  
  onKeyDown(evt){
    let { onDelete } = this.props;
    
    if(onDelete && evt.key === 'Backspace'){
      onDelete();
    }
  }
  
  onKeyPress(evt){
    let { onEnter, inputNumeric, onKeyPress } = this.props;
    
    if(onEnter && evt.key === 'Enter'){
      onEnter();
    }
    else if(inputNumeric){
      const keyCode = evt.keyCode || evt.which;
      let keyValue = +String.fromCharCode(keyCode);
      if(_.isNaN(keyValue) || keyValue < 0 || keyValue > 9){
        evt.preventDefault();
      }
    }
    
    if(onKeyPress){
      onKeyPress(evt);
    }
  }
  
  onPaste(evt){
    let { onPaste } = this.props;
    
    if(onPaste){
      onPaste(evt);
    }
  }
  
  onInputChange(evt){
    let { onChange } = this.props;
    
    if(onChange && evt.target) {
      onChange(evt.target.value);
    }
  }
  
  onInfoClick(evt){
    let { onInfoClick } = this.props;
  
    onInfoClick();
  }
  
  triggerValidationErr(){
    var me = this;
    
    me.setState({
      showShake: true,
    }, () => {
      this.timer = setTimeout(() => {
        me.setState({
          showShake: false
        })
      }, 400) //same as the animation time
    })
  }
  
  render(){
    let {
      placeholder,
      inputDisabled,
      inputReadonly,
      fieldType,
      inputValue,
      isValid,
      infoBtnCls,
      inputCls,
      maxInputLength,
      hideInfoBtn
    } = this.props;
    
    let {
      isActive,
      showShake
    } = this.state;
    
    let wrapStyle = {
      position: 'relative',
      overflow: 'initial',
      WebkitTransition: 'all .25s ease-in',
      transition: 'all .25s ease-in',
      borderRadius : '4px'
    }
    
    if(!inputDisabled){
      wrapStyle.border = '1px solid #fff';
    }
    else{
      wrapStyle.border = '1px solid transparent';
    }
    
    if(showShake){
      wrapStyle.animation = 'shake 400ms ease-in-out';
    }
    
    let labelStyle = {
      pointerEvents: 'none',
      position: 'absolute',
      left: '0px',
      top: '-2px',
      fontSize: '14px',
      margin: '10px',
      padding: '0 3px',
      backgroundColor: 'transparent',
      WebkitTransition: 'all .25s ease-in',
      transition: 'all .25s ease-in'
    }
    
    if(inputDisabled){
      labelStyle.color = 'transparent'
    }
    else{
      labelStyle.color = '#fff'
    }
    
    if(isActive || inputValue.length > 0){
      labelStyle = {
        ...labelStyle,
        top: '-30px',
        fontSize: '12px',
        backgroundColor: colors.DARK
      }
    }
    
    let inputStyle = {
      color: '#fff',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textShadow: '0px 0px 0px #fff',
      WebkitTextFillColor: 'transparent',
      WebkitTransition: 'all .25s ease-in',
      transition: 'all .25s ease-in'
    }
    
    if(isActive){
      inputStyle.color = colors.DARK;
      inputStyle.WebkitTextFillColor = colors.DARK;
      
      wrapStyle.backgroundColor = '#fff';
    }
    
    let inputProps = {};
    if(maxInputLength){
      inputProps.maxLength = maxInputLength;
    }
    
    return (
      <div style={wrapStyle}>
        {placeholder && <label style={labelStyle}>{placeholder}</label>}
        <div className={'input-group'}>
          <input formNoValidate
                 ref={this.inputField}
                 className={classNames(inputCls, 'fancy-input', 'form-control', {'is-invalid': isValid})}
                 style={inputStyle}
                 type={fieldType}
                 autoComplete={'on'}
                 disabled={(inputDisabled || inputReadonly)}
                 value={inputValue}
                 onPaste={this.onPaste.bind(this)}
                 onKeyDown={this.onKeyDown.bind(this)}
                 onKeyPress={this.onKeyPress.bind(this)}
                 onChange={this.onInputChange.bind(this)}
                 onBlur={this.onInputBlur.bind(this)}
                 onFocus={this.onInputFocus.bind(this)}
                 {...inputProps}/>
          <CSSTransition in={!hideInfoBtn && infoBtnCls && !inputDisabled}
                         unmountOnExit
                         timeout={400}
                         classNames={'fade'}>
            <div className="input-group-append">
              <i className={classNames(infoBtnCls, {'primary-color' : isActive}, {'light-color' : !isActive})}
                 style={{paddingLeft: '5px', paddingRight: '5px'}}
                 onClick={this.onInfoClick.bind(this)}/>
            </div>
          </CSSTransition>
        </div>
      </div>
    )
  }
}

FancyInput.propTypes = {
  placeholder: PropTypes.string,
  inputDisabled: PropTypes.bool.isRequired,
  inputReadonly: PropTypes.bool,
  inputNumeric: PropTypes.bool,
  hideInfoBtn: PropTypes.bool,
  isValid: PropTypes.bool,
  fieldType: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  inputCls: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onDelete: PropTypes.func,
  onRef: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onPaste: PropTypes.func,
  infoBtnCls: PropTypes.string,
  onInfoClick: PropTypes.func,
  maxInputLength: PropTypes.number
}

export default FancyInput;
