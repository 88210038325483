import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Image from '../elements/Image';

import api from '../../../util/api'
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

const cardStyle = {
  borderRadius : '10px',
  fontSize: '14px'
}

class InstitutionPanel extends Component {
  
  getImgBlock(inst){
    let { t } = this.props;
    let logoUrl = api.getRoot(`/api/inst/logo/${inst.inst_id}`);
    if(inst.info_json && inst.info_json.url){
      return (
        <div className={'text-center'}>
          <a target={'_blank'} href={inst.info_json.url}>
            <Image src={logoUrl}
                   imgHeight={50}
                   alt={t('institution')}/>
          </a>
        </div>
      )
    }
    else{
      return (
        <div className={'text-center'}>
          <Image src={logoUrl}
                 imgHeight={50}
                 alt={t('Institution')}/>
        </div>
      )
    }
  }
  
  getHeader(inst){
    if(inst.info_json && inst.info_json.header){
      return (
        <div className={'text-center font-weight-bold'}>
          <div>{inst.info_json.header}</div>
        </div>
      )
    }
    else{
      return (
        <div className={'text-center font-weight-bold'}>
          <div>{inst.label}</div>
        </div>
      )
    }
  }
  
  getBodyBlock(inst){
    if(inst.info_json && inst.info_json.body){
      return (
        <div className={'text-center'}>
          {inst.info_json.body}
        </div>
      )
    }
  }
  
  render() {
    let { institution } = this.props;
    
    return (
      <div className={'p-3 light-bg dark-color mb-5 text-center'} style={cardStyle}>
        {this.getImgBlock(institution)}
        {this.getHeader(institution)}
        {this.getBodyBlock(institution)}
      </div>
    );
  }
}

InstitutionPanel.propTypes = {
  institution: PropTypes.object.isRequired,
}

export default withVFTranslation()(InstitutionPanel);
