import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class EditNameCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
      first_name : '',
      last_name : '',
      validation_errors: [],
      isSaving: false,
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    let { accountInfo } = this.props;

    this.setState({
      first_name : accountInfo.first_name,
      last_name : accountInfo.last_name,
      validation_errors: [],
      isSaving: false,
    })
  }

  doValidation(){
    let { first_name, last_name } = this.state;
    let { t } = this.props;
    
    let err = [];
    if(!first_name || first_name.length === 0){
      err.push(t("Please enter your first name"));
    }

    if(!last_name || last_name.length === 0){
      err.push(t("Please enter your last name"));
    }

    this.setState({validation_errors : err})

    return err.length === 0;
  }

  save(){
    if(!this.doValidation()){
      return;
    }

    this.setState({isSaving: true});

    let { first_name, last_name } = this.state;
    let { updateAccountInfo, doClose } = this.props;

    let req = {
      first_name,
      last_name
    }
    sapi.AccountInfo.update(req)
      .then((res) => {
        log.log('save name res', res);

        return updateAccountInfo();
      })
      .then((res) => {
        setTimeout(() => {
          doClose();
        })
      })
      .catch((err) => {
        log.log('error saving name', err);
      })
      .finally(() => {
        this.setState({isSaving: false})
      })
  }

  cancel(){
    let { doClose } = this.props;

    doClose();
  }

  render() {
    let { first_name, last_name, isSaving, validation_errors } = this.state;
    let { t } = this.props;
    
    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("First Name")}</label>
              <input className={'form-control'}
                     type={'text'}
                     value={first_name}
                     onChange={(evt) => this.setState({first_name: evt.target.value})}
                     placeholder={t('First Name')} />
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Last Name")}</label>
              <input className={'form-control'}
                     type={'text'}
                     value={last_name}
                     onChange={(evt) => this.setState({last_name: evt.target.value})}
                     placeholder={t('Last Name')} />
            </div>
          </div>
        </div>
        {validation_errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={validation_errors}/>
          </div>
        </div>
        }
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isSaving} className={'btn btn-secondary mr-2'} onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={isSaving} className={'btn btn-primary '} onClick={this.save.bind(this)}>{t("Save")}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditNameCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo())
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditNameCtrl)));
