const config = {
  debug : false,
  authGoesToVIP: false,

  automatedTesting: false,
  
  versions: {
    web : 1000
  },
  
  prodEndpoint: 'https://admin.verifyle.com',
  endpoints: [
    'https://admin.verifyle.com',
    'https://stageadmin.vfltest.com',
    'https://qa1admin.vfltest.com',
    'https://qa2admin.vfltest.com/',
    'https://dev5admin.vfltest.com',
    'https://dev1admin.vfltest.com',
    'https://ucoadmin.vfltest.com',
  ],
}

export default config;
