import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import { getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import log from "../../util/log";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import moment from 'moment'
import Loading from "../partials/util/Loading";
import filters from '../../helpers/filters'
import companyLogo from "../../resources/logo-w-text-blue.png";
import Image from "../partials/elements/Image";
import ReactToPrint from "react-to-print";
import _ from 'lodash';
import browser from "../../util/browser";
import {withVFTranslation} from "../../util/withVFTranslation";

class SigningMetadataWindow extends Component {
  
  constructor(props) {
    super(props);
  
    this.bodyRef = React.createRef();
    
    // "chat_id" : "5e5c0aaa26d46ba3",
    //   "doc_annotate_id" : "5e7165e7a5fba2dd",
    //   "doc_id" : "5e725498b722fc96",
    //   "email_address" : "swartzk.junk@gmail.com",
    //   "first_name" : "Kevin",
    //   "forum_id" : "5e5c0aaa269c9a3d",
    //   "ip_address" : "67.160.184.66",
    //   "last_name" : "Junk",
    //   "owner_uid" : "5e50247128d24348",
    //   "request_date" : 1584489959,
    //   "sign_data" : {
    //   "signatures" : [
    //     {
    //       "height" : 66,
    //       "pageIndex" : 0,
    //       "scale" : 1,
    //       "signatureType" : "signature",
    //       "width" : 287,
    //       "x" : 451.453125,
    //       "y" : 210
    //     }
    //   ]
    // },
    // "sign_request_id" : "5e7165db166531ac",
    //   "signed_date" : 1584551065,
    //   "signer_uid" : "5e50247128d24348",
    //   "source_doc_id" : "5e7165daaf6a6b5d",
    //   "terms" : "I agree to do business electronically."
    
    this.state = {
      source_doc_info : null,
      signed_doc_info : null,
      sign_requestor : null,
      sig_request : null,
      signer : null,
      mToday : moment(),
      loading : true
    }
  }
  
  componentDidMount() {
    let { t } = this.props;
    this.setState({loading : true})
    this.refreshInfo()
      .then(() => {
        this.setState({loading : false})
      })
      .catch((err) => {
        log.log('error refreshing info', err);
        this.props.showAlert(t('Unable to load Metadata'), getMessageForError(err, t), () => {
          this.closeModal();
        });
      })
  }
  
  fetchUser(guest_uid){
    if(guest_uid === this.props.accountInfoGuest.guest_uid){
      log.log('returning guest', this.props.accountInfoGuest);
      return Promise.resolve(this.props.accountInfoGuest);
    }
    else{
      return new Promise((resolve, reject) => {
        sapi.Contacts.get(guest_uid)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            log.log('error fetching user', err);
            resolve(null);
          })
      })
    }
  }
  
  tryFetchSourceDoc(forum_id, host_uid, doc_id){
    return new Promise((resolve, reject) => {
  
      sapi.Docs.info(forum_id, host_uid, doc_id)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          //nothing to do, source doc not existing is a handled scenario.
          resolve(null);
        })
      
    })
  }
  
  fetchSignatureRequest(){
    // chat_id: "5fc67ba5dc4f721e"
    // doc_annotate_id: "5fc6f453701c290a"
    // doc_id: "5fc7be8ac023197b"
    // email_address: "swartzk.junk@gmail.com"
    // first_name: "Kevin"
    // forum_id: "5fc67ba5dc14f2f8"
    // ip_address: "67.160.184.66"
    // last_name: "Junk"
    // owner_uid: "5fc67b5d81138f3c"
    // request_date: 1606874195
    // sign_data: {signatures: Array(1), smsNumber: "15415109156"}
    // sign_request_id: "5fc6f4536db70671"
    // signed_date: 1606925963
    // signer_uid: "5fc67ba50ab8d353"
    // source_doc_id: "5fc6eeee0bd5407c"
    // terms: "I agree to do business electronically."
    
    let {
      dm_guest_uid,
      metadata,
      host_uid
    } = this.props;
    
    return new Promise((resolve, reject) => {
      let sigPromise = null;
      if(dm_guest_uid){
        sigPromise = sapi.DM.getSignatureRequest(metadata.sign_request_id, dm_guest_uid);
      }
      else{
        sigPromise = sapi.Workspace.getSignatureRequest(metadata.sign_request_id, host_uid);
      }
      
      sigPromise
        .then((res) => {
          log.log('got sig request res', res);
          resolve(res.data);
        })
        .catch((err) => {
          log.log('err getting sig request', err);
          reject(err);
        })
    })
  }
  
  refreshInfo(){
    let { forum_id, host_uid, metadata } = this.props;
    return new Promise((resolve, reject) => {
      Promise.all([
          this.tryFetchSourceDoc(forum_id, host_uid, metadata.source_doc_id),
          sapi.Docs.info(forum_id, host_uid, metadata.doc_id),
          this.fetchUser(metadata.signer_uid),
          this.fetchUser(metadata.owner_uid),
          this.fetchSignatureRequest()
        ])
        .then((res) => {
          log.log('refresh signing info', metadata, res);
          let source_doc_info = res[0];
          let signed_doc_info = res[1].data;
          let signer = res[2];
          let sign_requestor = res[3];
          let sig_request = res[4];
          
          this.setState({
            source_doc_info,
            signed_doc_info,
            signer,
            sign_requestor,
            sig_request
          }, () => {
            resolve(true);
          })
        })
        .catch((err) => {
          log.log('error refreshing info', err);
          reject(err);
        })
    })
  }
  
  closeModal() {
    this.props.close();
  }
  
  renderBody(){
    let {
      source_doc_info,
      signed_doc_info,
      signer,
      sign_requestor,
      sig_request,
      mToday,
      loading
    } = this.state;
    let { metadata, t } = this.props;
    
    if(loading){
      return (
        <>
          <Loading centered size={'sm'} />
        </>
      )
    }
    
    return (
      <>
        <div className="row mb-3">
          <div className={`col text-center allow-preview-imgs browser-${browser.getBrowser().name}`}>
            <Image className={'mt-2'} src={companyLogo} alt={t('Logo')} imgHeight={50}/>
          </div>
        </div>
        <div className={'row mb-3'}>
          <div className={'col-8 text-left'}>
            <h5 className="primary-color font-weight-bold">
              {t("Verifyle Document Signing Audit Report")}
            </h5>
          </div>
          <div className={'col-4 text-right'}>
            <h5 className="primary-color font-weight-bold">
              {mToday.format('MMMM Do YYYY')}
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Source Document")}
            </h5>
            {source_doc_info &&
            <>
              <p className="text-uppercase">
                {t("Filename:")} {source_doc_info.label}
              </p>
              <p className="text-uppercase">
                {t("Created Date:")} {filters.momentUTCFilter(source_doc_info.created_date, "YYYY-MM-DD HH:mm:ss") + t(' UTC')}
              </p>
            </>
            }
            <p className="text-uppercase">
              {t("Document ID:")} {metadata.source_doc_id}
            </p>
            <p className="text-uppercase">
              {t("Status: Signed")}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Signed Document")}
            </h5>
            <p className="text-uppercase">
              {t("Filename:")} {signed_doc_info.label}
            </p>
            <p className="text-uppercase">
              {t("Created Date:")} {filters.momentUTCFilter(signed_doc_info.created_date, "YYYY-MM-DD HH:mm:ss") + t(' UTC')}
            </p>
            <p className="text-uppercase">
              {t("Document ID:")} {metadata.doc_id}
            </p>
            <p className="text-uppercase">
              {t("Sign ID:")} {metadata.sign_request_id}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Signature Requestor")}
            </h5>
            {sign_requestor &&
            <>
              <p className="text-uppercase">
                {t("Name:")} {sign_requestor.first_name} {sign_requestor.last_name}
              </p>
              <p className="text-uppercase">
                {t("Email:")} {sign_requestor.email}
              </p>
            </>
            }
            <p className="text-uppercase">
              {t("User ID:")} {metadata.owner_uid}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Signer")}
            </h5>
            {signer &&
            <>
              <p className="text-uppercase">
                {t("Name:")} {signer.first_name} {signer.last_name}
              </p>
              <p className="text-uppercase">
                {t("Email:")} {signer.email}
              </p>
            </>
            }
            <p className="text-uppercase">
              {t("User ID:")} {metadata.signer_uid}
            </p>
            <p className="text-uppercase">
              {t("IP Address:")} {metadata.ip_address}
            </p>
          </div>
        </div>
        {metadata.terms &&
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Terms")}
            </h5>
            <p className="text-uppercase">
              {t("Text:")} "{metadata.terms}"
            </p>
            <p className="text-uppercase">
              {t("Status: Accepted")}
            </p>
          </div>
        </div>
        }
        {sig_request.sign_data.smsNumber &&
        <div className="row">
          <div className="col text-left">
            <h5 className="primary-color text-uppercase">
              {t("Identity Verification")}
            </h5>
            <p className="text-uppercase">
              {t("Phone Number:")} {sig_request.sign_data.smsNumber}
            </p>
            <p className="text-uppercase">
              {t("Status: Success")}
            </p>
          </div>
        </div>
        }
      </>
    )
  }
  
  onPrintError(err, err2, err3) {
    log.error('onPrint Error', err, err2, err3);
  }
  
  render() {
    let { t } = this.props;
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Document Signing Audit Report")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div ref={this.bodyRef}
             className={`modal-body signing-metadata-window`}>
          {this.renderBody()}
        </div>
        <div className="modal-footer">
          <ReactToPrint onPrintError={this.onPrintError.bind(this)}
                        content={() => this.bodyRef.current}
                        trigger={() => {
                          return (
                            <Button className={'btn btn-secondary'}>
                              <i className="icon ion-printer mr-2" />
                              {t("Print")}
                            </Button>
                          )
                        }}/>
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this)}>{t("Close")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  topRightButton : {
    position : 'absolute',
    top: '5px',
    right : '16px',
    fontSize : '30px',
    zIndex : 1000,
    cursor: 'pointer'
  },
}

SigningMetadataWindow.MODAL_LARGE = true;

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

SigningMetadataWindow.propTypes = {
  close: PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(SigningMetadataWindow));
