import c from '../util/const';
import _ from 'lodash';
import log from "../util/log";

let initialState = {
  pendingDownloadQueue: [],
  currentDownloadQueue: []
}

const upload = (state = initialState, action) => {
  switch (action.type) {
    
    case c.actions.download.updatePendingQueue:
      return {
        ...state,
        pendingDownloadQueue : action.queue
      }
  
    case c.actions.download.addToCurrentQueue:
      return {
        ...state,
        currentDownloadQueue : _.concat(state.currentDownloadQueue, action.downloads)
      }
      
    case c.actions.download.doDownload:
      return {
        ...state,
        pendingDownloadQueue : _.concat(state.pendingDownloadQueue, action.downloads)
      };
      
    case c.actions.download.cleanup:
      return {...initialState}
      
    default:
      return state;
  }
}

export default upload;
