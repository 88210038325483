import c from '../util/const';
import _ from 'lodash'

let initialState = {
  archivedWorkspaces: [],
  unarchivedWorkspaces:[],
  workspaceNotifyCount : 0,
  activeDM : null,
  activeDMMessages: null,
  activeDMMessageBlocks: null,
  activeDMDocs: null,
}

const home = (state = initialState, action) => {
  switch (action.type) {
    
    case c.actions.home.updateWorkspaceLists:
      
      let workspaceNotifyCount = 0;
      _.each(_.concat(action.archivedWorkspaces, action.unarchivedWorkspaces), (ws) => {
        if(ws.notify_flag){
          workspaceNotifyCount++;
        }
      })
      
      return {
        ...state,
        archivedWorkspaces : action.archivedWorkspaces,
        unarchivedWorkspaces : action.unarchivedWorkspaces,
        workspaceNotifyCount
      }
  
    case c.actions.home.setActiveDM:
      return {
        ...state,
        activeDM: action.dm
      }
      
    case c.actions.home.updateActiveDMMessages:
      return {
        ...state,
        ...(action.messages ? {activeDMMessages: action.messages} : {}),
        ...(action.messageBlocks ? {activeDMMessageBlocks: action.messageBlocks} : {}),
        ...(action.docs ? {activeDMDocs: action.docs} : {}),
      };
      
    case c.actions.home.cleanup:
      return {
        ...initialState
      }
      
    default:
      return state;
  }
}

export default home;
