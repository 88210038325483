import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Workspace from "./components/pages/Workspace";
import Home from "./components/pages/Home";
import Confirm from "./components/pages/Confirm";
import Auth from "./components/pages/Auth";
import Account from "./components/pages/Account";
import AdminDashboard from "./components/pages/AdminDashboard";

export default {
  getRoutes() {
    return <Switch>
      <Route exact path="/" component={Auth} />
      <Route exact path="/confirm" component={Confirm} />
      {/*<Route exact path="/home" component={Home} />*/}
      <Route exact path="/admin" component={AdminDashboard} />
      {/*<Route exact path="/account" component={Account} />*/}
      {/*<Route exact path="/workspace/:forum_id" component={Workspace} />*/}
    </Switch>
  }
}
