import {withRouter} from "react-router-dom";
import React, { Component } from 'react';
import {connect} from 'react-redux';
import log from "../../../util/log";
import companyLogo from "../../../resources/logo-w-text-blue.png";
import Image from "../elements/Image";
import moment from "moment";
import sapi from "../../../util/sapi";
import Loading from "./Loading";
import {withVFTranslation} from "../../../util/withVFTranslation";

class ErrorPage extends React.Component {
  
  maxBackoffCount = 5;
  incrementSeconds = 30;
  countdownTimer = null;
  
  constructor(props) {
    super(props);
    
    this.state = {
      nextAttempt : null,
      nextUpdateSeconds : 0,
      numberFailedReconnectAttempts : 0,
      isTestingConnection : false
    };
  }
  
  componentDidMount() {
    this.initTimers();
  }
  
  componentWillUnmount() {
    if(this.countdownTimer){
      clearTimeout(this.countdownTimer)
    }
  }
  
  tick(){
    let nextCheckSeconds = this.state.nextAttempt.diff(moment(), 'seconds');
    if(nextCheckSeconds <= 0){
      this.attemptReconnection();
    }
    else{
      this.setState({
        nextUpdateSeconds : nextCheckSeconds
      })
      
      this.countdownTimer = setTimeout(() => {
        this.tick();
      }, 100)
    }
  }
  
  initTimers(){
    //caps the backoff to maxBackoffCount
    let reconnectAttempt = Math.min(this.maxBackoffCount - 1, this.state.numberFailedReconnectAttempts);
    let nextReconnectAttempt = moment().add((reconnectAttempt * this.incrementSeconds) + this.incrementSeconds, 'seconds');
    
    this.setState({
      nextUpdateSeconds : (this.state.numberFailedReconnectAttempts * this.incrementSeconds) + this.incrementSeconds,
      nextAttempt : nextReconnectAttempt
    }, () => {
      this.tick();
    })
  }
  
  attemptReconnection(){
    if(this.countdownTimer){
      clearTimeout(this.countdownTimer);
    }
    
    this.setState({isTestingConnection : true})
    sapi.Ping.connectionPing()
      .then(() => {
        this.reloadPage();
      })
      .catch((err) => {
        log.log('reconnect failed', this.state);
        this.setState({
          numberFailedReconnectAttempts : this.state.numberFailedReconnectAttempts + 1,
        }, () => {
          this.initTimers();
        });
      })
      .finally(()=> {
        setTimeout(() => {
          this.setState({isTestingConnection: false})
        }, 500)
      })
  }
  
  reloadPage(){
    window.location.reload();
  }
  
  render() {
    let { t } = this.props;
    let { nextUpdateSeconds, isTestingConnection } = this.state;
    
    return (
      <div className="container">
        <div className="row">
          <div className="col text-center" style={{marginTop : '5vh'}}>
            <Image className={'mt-2'} src={companyLogo} alt={'Logo'} imgHeight={70}/>
          </div>
        </div>
        <div className="row">
          <div className="col text-center" style={{marginTop : '10vh'}}>
            <h1>
              <i style={{fontSize : '60px'}} className="icon ion-flash" />
            </h1>
            <h3>
              {t("It looks like there's a problem connecting to our servers.")}
            </h3>
  
            <p className="my-3">
              <button className="btn btn-lg btn-primary"
                      onClick={this.attemptReconnection.bind(this)}
                      disabled={isTestingConnection}>
                {!isTestingConnection ? t('Try Now') : t('Connecting...')}
              </button>
            </p>
            {!isTestingConnection &&
            <>
              <p>
                {t("Automatically reconnecting in")} {nextUpdateSeconds} {t("seconds...")}
              </p>
            </>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    applicationError : state.app.applicationError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorPage)));
