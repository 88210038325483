import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import sapi from '../../../util/sapi';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";
import redirectHelper from '../../../util/redirect-helper';

import vfLocalStorage from '../../../util/local-storage';

import moment from 'moment'

import FancyInput from '../elements/FancyInput';
import PulseButton from '../elements/PulseButton';
import Button from "../elements/Button";
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import AuthCodeInput from "../elements/AuthCodeInput";
import Checkbox from "../elements/Checkbox";
import PropTypes from "prop-types";
import cookieHelper from "../../../helpers/cookie-helper";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class TFACard extends Component {
  
  constructor(props){
    super(props);
    
    this.state = {
      device_label: '',
      validationErr: null,
      trustDeviceChecked: false,
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }
    
    setTimeout(() => {
      this.codeField.focus();
    }, 250)
  }
  
  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  generateDeviceLabel(){
    var nowTicks = '' + moment().unix();
    return "device-" + nowTicks.substr(nowTicks.length - 5);
  }
  
  validateForm() {
    let code = this.codeField.assembleCode();
    
    this.props.setValidationErrors([]);
    this.setState({
      validationErr: null,
    });
    if(!code || code.length !== 6){
      this.setState({
        validationErr: true
      });
      this.codeField.triggerValidationErr();
      return false;
    }
    return true;
  }
  
  doNextButtonClicked(){
    let { device_label } = this.state;
    let { setAuth, tfaAuth, setTFAAuth, history, qs, t } = this.props;
    let code = this.codeField.assembleCode();
    
    if(!this.validateForm()){
      return;
    }
    
    this.props.setLoading(true);
    
    let add_device = device_label.length > 0;
    let label = add_device ? device_label : null;
    
    sapi.TFA.login(code, add_device, label, tfaAuth.token)
      .then((res) => {
        if (res && res.data && res.data.device) {
          //Then we need to save a device token so we can post to the backend on login.
          vfLocalStorage.set(c.localstorage.device, res.data.device);
        }
  
        this.props.setLoading(false);
        setAuth(tfaAuth.sid, tfaAuth.vip, tfaAuth.token);
        setTFAAuth(null);
        cookieHelper.clearTrackingCookies();
        redirectHelper.redirectToApp(history, qs, tfaAuth.vip);
      })
      .catch((err) => {
        log.log('error during tfa submit', err);
        this.props.setValidationErrors([
          getMessageForError(err, t)
        ]);
        this.props.setLoading(false);
      })
  }
  
  onStartOverButtonClick() {
    let { setStep } = this.props;
    
    setStep(c.authSteps.password);
  }
  
  trustDeviceCheckChange(evt){
    this.setState({
      trustDeviceChecked : evt.target.checked,
      device_label : evt.target.checked ? this.generateDeviceLabel() : null
    })
  }
  
  showInfo(){
    let { t } = this.props;
    this.props.showAuthInfo(
      t('Two-Factor Authentication?'),
      <div>
        <div>
          <p>
            {t("With Two-Factor Authentication enabled on your Verifyle account, the security of your data can be maintained even if your password is compromised. Upon enabling, a seed QR-code should have been entered into an authenticator and separately saved. Your authenticator will then compute the code needed at any given moment.")}
          </p>
          <p>
            {t("If your authenticator account or application has been lost, try re-scanning your QR-code. If you don’t have your QR-code you can disable and re-enable Two-Factor Authentication in any active session. If you don’t have an active session, you may not be able to access your account.")}
          </p>
          <p className={'pt-3'}>
            {t("Contact us at")}
            <br/>
            <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
          </p>
        </div>
      </div>
    )
  }
  
  render() {
    let { validationErr, trustDeviceChecked } = this.state;
    let { step, t } = this.props;
    return (
      <div>
        <div className={'row'}>
          <div className={'col'}>
            <AuthCodeInput onRef={ref => this.codeField = ref}
                           onEnter={this.doNextButtonClicked.bind(this)}
                           inputDisabled={false}/>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <Checkbox isChecked={trustDeviceChecked}
                      onChange={this.trustDeviceCheckChange.bind(this)}
                      label={t('Remember this device')}
                      rootCls={'text-center'}
                      labelCls={'light-color'}
            />
          </div>
        </div>
      </div>
    );
  }
}

TFACard.propTypes = {
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  isLoading : PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    tfaAuth: state.auth.tfaAuth,
    qs : state.app.qs,
    step : state.auth.step,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTFAAuth: auth => dispatch(authActions.setTFAAuth(auth)),
    setStep: step => dispatch(authActions.setNextStep(step)),
    setAuth: (sid, vip, token) => dispatch(authActions.setAuth(sid, vip, token)),
    showAuthInfo: (title, contents, cb) => dispatch(modalActions.showAuthInfo(title, contents, cb))
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TFACard)));
