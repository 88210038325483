import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";

import PropTypes from 'prop-types';

import PulseButton from '../elements/PulseButton';
import FancyInput from '../elements/FancyInput';
import Button from '../elements/Button';
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class AuthControls extends Component {
  
  constructor(props){
    super(props);
    
    this.state = {
    
    }
  }
  
  render() {
    let {
      step,
      authValidationErrors,
      isLoading,
      hideControls,
      hideStartOverButton,
      loginWithPayment,
      validCouponDeets,
      t
    } = this.props;
    
    if(hideControls){
      return null;
    }
    return (
      <>
        {step === c.authSteps.password &&
        <div className="row">
          <div className="col text-right">
            <Button className="btn btn-sm btn-link light-color auth-disable-color"
                    tabIndex={-1}
                    disabled={isLoading}
                    onClick={() => this.props.newPasswordLinkClick()}>{t("I need a new password")}</Button>
          </div>
        </div>
        }
  
        {step === c.authSteps.email && loginWithPayment &&
        <div>
          <div className="row">
            <div className="col text-right">
              <Button className="btn btn-sm btn-link light-color auth-disable-color"
                      disabled={isLoading}
                      type={'button'}
                      onClick={() => this.props.promoCodeLinkClick()}>
                {validCouponDeets ? t("Remove promo code") : t("Add promo code")}
              </Button>
            </div>
          </div>
        </div>
        }
        
        <div className="row">
          <div className="col">
            <div style={{lineHeight: '110px'}}>
              {!hideStartOverButton &&
              <Button className="btn btn-sm btn-link light-color auth-disable-color"
                      disabled={isLoading}
                      onClick={() => this.props.startOverBtnClick()}>
                <i className={'ion-chevron-left light-color mr-2'}/>
                {t("Start Over")}
              </Button>
              }
            </div>
          </div>
          <div className="col">
            <PulseButton onClick={() => this.props.nextBtnClick()}
                         isLoading={isLoading}
                         className={'ml-auto'}
                         btnText={t('Next')}/>
          </div>
        </div>
        {_.map(authValidationErrors, (err) => {
          return (
            <div key={err}
                 className="alert alert-danger"
                 role="alert">
              {err}
            </div>
          )
        })}
      </>
    )
  }
}

AuthControls.propTypes = {
  nextBtnClick : PropTypes.func.isRequired,
  startOverBtnClick : PropTypes.func.isRequired,
  isLoading : PropTypes.bool.isRequired,
  authValidationErrors : PropTypes.array.isRequired,
  hideControls : PropTypes.bool.isRequired,
  hideStartOverButton : PropTypes.bool.isRequired,
  
  validCouponDeets : PropTypes.object,
  promoCodeLinkClick : PropTypes.func.isRequired,
  newPasswordLinkClick : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    step : state.auth.step,
    loginWithPayment : state.auth.loginWithPayment
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AuthControls))
