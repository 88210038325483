import * as Sentry from "@sentry/react";
import _ from 'lodash'

//This is pretty much just ported directly from mobile's SentrySvc factory.
//Sentry is kind of annoying to work with.  Their api kind of sucks.
//Two main problems:
// logging objects to Sentry.captureException that are not technically exceptions will just not do anything (dumb)
// they don't let you associate a message with an exception that you log.  So the exceptions don't have any context when logged.
//These things would seem like problems if it was my code, but it's apparently by design.
let sentryHelper = {
  captureErr(err){
    if(Sentry){
      Sentry.captureException(err);
    }
  },
  
  captureMessage(msg, err){
    if(Sentry){
      
      if(err && this.isError(err)){
        return this.captureErr(err);
      }
      
      //I feel like it's pretty stupid that we have to do this.
      //Is it really an unhandled case to send a message with a stack trace associated?
      //this isn't an error, I just want to log it.  bad form guys.  :/
      Sentry.captureMessage(this.stringifyResponse(msg, err));
    }
  },
  
  isError(e){
    return e && e.stack && e.message && typeof e.stack === 'string'
      && typeof e.message === 'string';
  },
  
  stringifyResponse(msg, err){
    
    let messages = Array.prototype.slice.call(arguments, 0);
    let message = [ ];
    let text;
    
    for (let i = 0; i < messages.length; i++ ) {
      if (_.isArray(messages[i])) {
        text = '[Array]';
        try {
          // avoid "TypeError: Converting circular structure to JSON"
          text = JSON.stringify(messages[i]);
        } catch (e) {
          // do nothing
        }
        message.push(text);
      } else if (_.isObject(messages[i])) {
        text = '[Object]';
        try {
          // avoid "TypeError: Converting circular structure to JSON"
          text = JSON.stringify(messages[i]);
        } catch (e) {
          // do nothing
        }
        message.push(text);
      } else {
        message.push(messages[i]);
      }
    }
    
    return  message.join(' ') + '\n';
  }
}

export default sentryHelper;
