class SignatureRequest{
  
  terms = null;
  
  constructor(forum_id, host_uid, doc_id, chat_id, mesg_id, status){
    this.forum_id = forum_id;
    this.host_uid = host_uid;
    this.doc_id = doc_id;
    this.chat_id = chat_id;
    this.mesg_id = mesg_id;
    this.status = status;
  }
  
  setSigner(signer_uid, guest_info){
    this.signer_uid = signer_uid;
    this.guest_info = guest_info;
  }
  
  setRequestor(guest_info){
    this.sign_requestor = guest_info;
  }
  
  setDocInfo(doc_info){
    this.doc_info = doc_info;
  }
  
  setSignatureRequest(sigRequest){
    this.signature_request = sigRequest;
  }
}

SignatureRequest.SIGNATURE_REQUEST_TYPE = {
  SIGNATURE : 'signature',
  INITIALS : 'initials',
  SIGNED_DATE : 'signed_date',
  OTHER : 'other'
}

SignatureRequest.SIGN_STATUS = {
  INCOMPLETE : 'pending request',
  NONE : 'none',
  SIGNED : 'signed',
  EXPIRED : 'expired',
  REQUESTED : 'pending signature',
  UNKNOWN : 'unknown state'
}

export default SignatureRequest;
