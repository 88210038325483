import c from '../util/const';

let initialState = {
  qs: null,
  qsEmail: null,
  qsActionNeeded : false,
  isBrowserTabActive: true,
  applicationError : null
}

const app = (state = initialState, action) => {
  switch (action.type) {
    
    case c.actions.app.setApplicationError:
      return {
        ...state,
        applicationError : action.error
      }
    
    case c.actions.app.setQueryString:
      return {
        ...state,
        qs: {...action.qs}
      };
  
    case c.actions.app.setBrowserTabActive:
      return {
        ...state,
        isBrowserTabActive: action.isActive
      }
      
    case c.actions.app.setQsEmail:
      return {
        ...state,
        qsEmail: action.qsEmail
      };
      
    case c.actions.app.setQsActionNeeded:
      return {
        ...state,
        qsActionNeeded: action.qsActionNeeded
      };
      
    default:
      return state;
  }
}

export default app;
