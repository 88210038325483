import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

import uploadActions from '../actions/upload-actions';
import modalActions from '../actions/modal-actions';

import workspaceActions from '../actions/workspace-actions'

import PropTypes from 'prop-types';

import log from "../util/log";
import sapi from "../util/sapi";
import _ from "lodash";
import sharedActions from "../actions/shared-actions";
import homeActions from "../actions/home-actions";
import UpgradeDialog from "../components/modals/UpgradeDialog";
import {getMessageForError} from "../util/errors";
import {withVFTranslation} from "../util/withVFTranslation";

class UploadManager extends Component {
  
  constructor(props){
    super(props);
    
    this.state = { isUploading : false }
  }
  
  promptToRetry(transaction){
    let me = this;
    let { showConfirm, clearActiveUpload, t } = this.props;
    
    showConfirm(t('Upload failed'), t("Your ") + (transaction.files.length === 1 ? t("file") : t("files")) + t(" could not be uploaded.  Do you want to retry?"), (res) => {
      if(res){
        me.doUploadWithRetry(transaction);
      }
      else{
        this.dequeueTransaction(transaction);
        clearActiveUpload(transaction);
        this.setState({ isUploading : false });
        
        this.refreshTransactionData(transaction);
      }
    })
  }
  
  refreshTransactionData(transaction){
    this.props.updateNotifications()
    if(transaction.guest_uid){
      this.props.updateDMPreviews([transaction.guest_uid])
      this.props.updateDirectMessages();
    
      if(this.props.activeHomeDM){
        this.props.refreshHomeActiveDMMessages();
      }
      else if(this.props.activeWorkspaceDM){
        this.props.refreshWorkspaceActiveDMMessages();
      }
    }
    else{
      this.props.refreshDocs(transaction.forum_id, transaction.host_uid);
    
      if(transaction.chat_id){
        this.props.refreshThreads(transaction.forum_id, transaction.host_uid, true)
        this.props.refreshThreadMessages(transaction.forum_id, transaction.host_uid, transaction.chat_id)
      }
    }
  }
  
  pollForQueueIdFinished(queue_id){
    return new Promise((resolve, reject) => {
      sapi.Threads.attachStatus(queue_id)
        .then((res) => {
          if(res.data && res.data.length > 0){
            let status = _.get(res, 'data[0].queue_status');
            if(status && status === 'done'){
              //don't worry about waiting for this.  just clear the queue item.
              sapi.Threads.removeQueueStatus(queue_id);
              resolve(res);
            }
            else if(status && status === 'error'){
              //don't worry about waiting for this.  just clear the queue item.
              sapi.Threads.removeQueueStatus(queue_id);
              let error = _.get(res, 'data[0].error_item');
              
              if(error && error.error_code){
                reject({
                  code : error.error_code,
                  name : error.error_code,
                  isFailedAttach : true
                })
              }
              else{
                reject(error || res);
              }
            }
            else{
              setTimeout(() => {
                resolve(this.pollForQueueIdFinished(queue_id));
              }, 1000)
            }
          }
          else{
            //If no results come back for this queue_id, just call it success
            log.warn(`No results for queue_id=${queue_id}, returning successful.`);
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
  
  doAttachAndWait(transaction){
    return new Promise((resolve, reject) => {
      let attachPromise = null;
      if(transaction.guest_uid){
        attachPromise = sapi.DM.dmMesgAttach(transaction.guest_uid, transaction.mesg, transaction.files, transaction.last_forum_id)
      }
      else{
        attachPromise = sapi.Threads.threadDocAttach(transaction.forum_id, transaction.host_uid, transaction.chat_id, transaction.mesg, transaction.files);
      }
      
      attachPromise
        .then((res) => {
          if(res.data.queue_id){
            return this.pollForQueueIdFinished(res.data.queue_id);
          }
          else{
            return res;
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
  
  dequeueTransaction(transaction){
    let {
      dequeueThreadDocTransaction,
      dequeueDMDocTransaction,
      dequeueThreadDocAttachTransaction,
      dequeueDMDocAttachTransaction,
    } = this.props;
    
    if(transaction.isDocAttach){
      if(transaction.guest_uid){
        dequeueDMDocAttachTransaction(transaction.transaction_id);
      }
      else{
        dequeueThreadDocAttachTransaction(transaction.transaction_id);
      }
    }
    else{
      if(transaction.guest_uid){
        dequeueDMDocTransaction(transaction.transaction_id);
      }
      else{
        dequeueThreadDocTransaction(transaction.transaction_id);
      }
    }
  }
  
  cleanupErrorTransaction(transaction){
    let {
      clearActiveUpload,
      updateProgress,
    } = this.props;
    
    this.dequeueTransaction(transaction);
    clearActiveUpload(transaction);
    updateProgress(null);
    this.setState({ isUploading : false });
    this.refreshTransactionData(transaction);
  }
  
  doUploadWithRetry(transaction){
    let me = this;
    let {
      setActiveUpload,
      clearActiveUpload,
      updateProgress,
      t
    } = this.props;
    
    log.log('starting upload', transaction);
    this.setState({ isUploading : true });
    setActiveUpload(transaction)
  
    let progressFn = (prog) => {
      updateProgress(prog);
    }
    
    let upload = null;
    if(transaction.isDocAttach){
      upload = this.doAttachAndWait(transaction);
    }
    else {
      if(transaction.guest_uid){
        upload = sapi.DM.upload(transaction.guest_uid, transaction.files, transaction.mesg, transaction.signature_requested, progressFn, transaction.last_forum_id);
      }
      else{
        if(transaction.chat_id){
          upload = sapi.Threads.upload(transaction.forum_id, transaction.host_uid, transaction.chat_id, transaction.files, transaction.mesg, transaction.signature_requested, progressFn)
        }
        else{
          upload = sapi.Docs.upload(transaction.forum_id, transaction.host_uid, transaction.files, progressFn)
        }
      }
    }
    
    upload
      .then((res) => {
        log.log('upload finished', transaction, res);
        
        this.dequeueTransaction(transaction);
        
        clearActiveUpload(transaction);
        updateProgress(null);
        this.setState({ isUploading : false });
  
        this.refreshTransactionData(transaction);
      })
      .catch((err) => {
  
        log.log('error during upload', err);
        
        if(err && err.isFailedAttach){
          this.props.showAlert(t('Error Uploading'), getMessageForError(err, t));
  
          this.cleanupErrorTransaction(transaction);
          return;
        }
        
        let errName = _.get(err, 'name');
        if(errName === 'APP_ACCT_QUOTA'){
          this.props.showUpgradeDialogOrError(UpgradeDialog.UPGRADE_TYPES.DOCS, err, t, (res) => {
            log.log('showUpgradeDialogOrError res', res);
          })
  
          this.cleanupErrorTransaction(transaction);
          return;
        }
        
        //This is for the edge case that guests are uploading to a thread, and they get back
        //A couple specific error types.  We can't retry here, so we show them an error
        //and don't allow retry. bug 2249
        if(transaction.chat_id && transaction.host_uid){
          if(errName === 'APP_FORUM_MAX_COL' || errName === 'APP_PARTIAL_FAIL'){
            //then show special message and cancel
            this.props.showAlert(t('Error Uploading'), t("Your host workspace doesn't have the resources to accommodate this file.  Please contact your host about upgrading their account."))
  
            this.cleanupErrorTransaction(transaction);
            return;
          }
        }
        
        log.log('error uploading', err);
        me.promptToRetry(transaction);
      })
  }
  
  processQueue(){
    let { isUploading } = this.state;
    let { uploadQueue, updateQueue } = this.props;
    
    if(isUploading){
      return;
    }
    
    if(uploadQueue.length > 0){
      //Then queue it up.
      //remove next transaction from queue, and pass to doUploadWithRetry()
      
      let nextTransaction = uploadQueue[0];
      uploadQueue.splice(0, 1);
      updateQueue(uploadQueue);
      this.doUploadWithRetry(nextTransaction);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.processQueue();
  }
  
  render(){ return null }
}

const mapStateToProps = (state) => {
  return {
    uploadQueue : state.upload.uploadQueue,
    activeUpload : state.upload.activeUpload,
    activeThreadDataDate : state.workspace.activeThreadDataDate,
    
    activeHomeDM: state.home.activeDM,
    activeWorkspaceDM: state.workspace.activeDM,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProgress : (progress) => dispatch(uploadActions.updateProgress(progress)),
    setActiveUpload : (transaction) => dispatch(uploadActions.setActiveUpload(transaction)),
    clearActiveUpload : (transaction) => dispatch(uploadActions.clearActiveUpload(transaction)),
    ...modalActions.mapToDispatch(dispatch),
    updateQueue : (queue) => dispatch(uploadActions.updateQueue(queue)),
    refreshDocs : (forum_id, host_uid) => dispatch(workspaceActions.refreshDocs(forum_id, host_uid)),
    refreshThreads: (forum_id, host_uid, doRefreshGuests) => dispatch(workspaceActions.refreshThreads(forum_id, host_uid, doRefreshGuests)),
    refreshThreadMessages : (forum_id, host_uid, chat_id, data_date) => dispatch(workspaceActions.refreshThreadMessages(forum_id, host_uid, chat_id, data_date)),
    refreshWorkspaceActiveDMMessages : () => dispatch(workspaceActions.refreshActiveDMMessages()),
    refreshHomeActiveDMMessages : () => dispatch(homeActions.refreshActiveDMMessages()),
    ...sharedActions.mapToDispatch(dispatch)
  };
};

UploadManager.propTypes = {

}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadManager)));
