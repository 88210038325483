import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import c from '../../util/const';
import Button from '../partials/elements/Button';
import log from "../../util/log";
import _ from "lodash";
import sapi from "../../util/sapi";
import ValidationErrors from "../partials/components/ValidationErrors";
import api from "../../util/api";
import filters from "../../helpers/filters";
import {withVFTranslation} from "../../util/withVFTranslation";

class PromoCodeDialog extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      promo_code: '',
      valid_code_deets: null,
      coupon_code_err: null,
    }
  }
  
  closeModal(res) {
    let { close } = this.props;
    
    close(res);
  }
  
  validateCouponData(data) {
    let { t } = this.props;
    if (!data.valid) {
      return t('This Promo code is no longer valid.');
    }
    
    if (data.amount_off && data.amount_off > 0) {
      return null;
    }
    else if (data.percent_off && data.percent_off > 0) {
      return null;
    }
    else {
      log.error('Unhandled coupon configuration', data);
      return t('This Promo code is not valid.');
    }
  }
  
  getDisplayPrice(price) {
    var split = ('' + price).split('.')
    if (split.length > 1) {
      var cents = +split[1];
      if (cents === 0) {
        return +split[0];
      }
      else {
        return price.toFixed(2);
      }
    }
    else {
      return +price;
    }
  }
  
  applyPromoCode() {
    let { t } = this.props;
    let {promo_code} = this.state;
    
    //In the app this comes from contract/user.upgrade block.  We don't have that
    //Because we don't have a user yet.
    //For now we've decided to just hard code values.
    // let contractUpgrade = _.get(userContract, 'upgrade', {});
    let contractUpgrade = {
      term_cost : c.pricing.PRO_PLAN_COST_DOLLARS
    }
    
    api.Stripe.validateCoupon(promo_code)
      .then((res) => {
        log.log('validate coupon res', res);
        
        let coupon = res.data;
        let msg = this.validateCouponData(coupon);
        if (msg) {
          this.setState({
            coupon_code_err: msg
          })
          return;
        }
        else {
          this.setState({
            coupon_code_err: null
          })
        }
        
        //Then it's valid.
        let couponDeets = null;
        var monthDuration = coupon.duration === t('once') ? 1 : coupon.duration_in_months;
        if (coupon.amount_off && coupon.amount_off > 0) {
          
          //FYI - amount_off comes back in cents
          let price = this.getDisplayPrice((contractUpgrade.term_cost - (coupon.amount_off / 100)));
          couponDeets = {
            name: coupon.name,
            price: price,
            monthly_amount: (price === 0 ? t('Free') : filters.getCurrency(price * 100) + t(' / month')),
            month_duration: monthDuration === 1 ? t('1 month') : monthDuration + t(' months'),
            normal_monthly: '$' + this.getDisplayPrice(contractUpgrade.term_cost),
            coupon: coupon
          };
        }
        else if (coupon.percent_off && coupon.percent_off > 0) {
          
          let price = this.getDisplayPrice((contractUpgrade.term_cost - (contractUpgrade.term_cost * (coupon.percent_off / 100))));
          couponDeets = {
            name: coupon.name,
            price: price,
            monthly_amount: (price === 0 ? t('Free') : filters.getCurrency(price * 100) + t(' / month')),
            month_duration: monthDuration === 1 ? t('1 month') : monthDuration + t(' months'),
            normal_monthly: '$' + this.getDisplayPrice(contractUpgrade.term_cost),
            coupon: coupon
          }
        }
        
        this.setState({
          valid_code_deets: couponDeets
        })
      })
      .catch((err) => {
        log.log('error validating coupon', err);
        
        this.setState({
          coupon_code_err: t('This Promo code is not valid.')
        })
      })
  }
  
  clearCoupon() {
    this.setState({
      valid_code_deets: null,
      promo_code: '',
      coupon_code_err: ''
    })
  }
  
  getCouponControls() {
    let { t } = this.props;
    let {valid_code_deets} = this.state;
    
    return (
      <div className={'text-center'}>
        <h5 style={{fontSize : '18px'}}>
          {valid_code_deets.name} {t("Promo Applied!")}
        </h5>
        <p className="small text-muted">
          {t("This discount is valid for")} {valid_code_deets.month_duration}{t(".")} {t("The regular price kicks in after that.")}
        </p>
      </div>
    )
  }
  
  render() {
    let {
      coupon_code_err,
      promo_code,
      valid_code_deets
    } = this.state;
    let {
      modalProps,
      t
    } = this.props;
  
    let termCostCents = c.pricing.PRO_PLAN_COST_DOLLARS * 100;
    return (
      <div className="modal-content">
        
        <div className="modal-header">
          <h5 className="modal-title">{t("Apply Discount")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className="d-flex">
            <div className="text-left">
              <label className="form-label font-weight-bold">{t("Verifyle Pro")}</label>
            </div>
            <div className="flex-grow-1 text-right">
              <div>
                <label className="form-label text-muted small mb-0"
                       style={(valid_code_deets ? {textDecoration : 'line-through'} : null)}>
                  {filters.getCurrency(termCostCents)} {t("/ month")}
                </label>
              </div>
              {valid_code_deets &&
              <div>
                <label className="form-label text-muted small green-color mb-0">{valid_code_deets.monthly_amount}</label>
              </div>
              }
            </div>
          </div>
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col px-0">
                <>
                  {!valid_code_deets &&
                  <div>
                    <label className="form-label">{t("Promo Code")}</label>
                    <div className="form-inline">
                      <input className={`form-control text-uppercase mr-1 flex-grow-1 ${this.state.promo_code.length > 0 ? '' : ''}`}
                             type={'text'}
                             placeholder={t("Enter Promo Code")}
                             value={promo_code}
                             onChange={(evt) => this.setState({promo_code: evt.target.value.toUpperCase()})}/>
                    </div>
                  </div>
                  }
                  {valid_code_deets &&
                  <div>
                    {this.getCouponControls()}
                  </div>
                  }
    
                  {coupon_code_err &&
                  <div className="my-2">
                    <ValidationErrors errors={[coupon_code_err]}/>
                  </div>
                  }
                </>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {valid_code_deets &&
            <>
              <Button onClick={this.clearCoupon.bind(this)}
                      type={'button'}
                      className={'btn btn-secondary'}>{t("Remove Discount")}</Button>
              <Button onClick={() => this.closeModal(valid_code_deets)}
                      type={'button'}
                      className={'btn btn-primary'}>{t("Done")}</Button>
            </>
          }
          {!valid_code_deets &&
          <>
            <Button onClick={() => this.closeModal(false)}
                    type={'button'}
                    className={'btn btn-secondary'}>{t("Cancel")}</Button>
            <Button disabled={promo_code.length === 0}
                    onClick={this.applyPromoCode.bind(this)}
                    type={'button'}
                    className={'btn btn-primary'}>{t("Apply")}</Button>
          </>
          }
        </div>
      </div>
    )
  }
}

PromoCodeDialog.MODAL_SM = true;

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

PromoCodeDialog.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PromoCodeDialog));
