import config from './config';

const log = {
  log (...args) {
    if(config.debug){
      console.log(...args);
    }
  },

  warn (...args) {
    if(config.debug){
      console.warn(...args);
    }
  },

  error (...args) {
    console.error(...args);
  }
}

export default log;
