import c from '../util/const';
import sapi from "../util/sapi";

import sharedActions from './shared-actions';

import Promise from 'bluebird';
import log from "../util/log";

const accountActions = {

  startup() {
    let me = this;

    return (dispatch) => {

      return Promise.all([
        dispatch(me.updatePasswordInfo()),
        dispatch(sharedActions.updateAccountInfo()), //maybe this isn't clear that it's from the shared ctrl?  Should we do this in the account page?
        dispatch(me.updateSubscriptionList()),
        dispatch(me.updateDownloadAlertState()),
        dispatch(me.updateUserBlockList()),
      ])
    }
  },

  cleanup() {
    return {
      type: c.actions.account.cleanup
    }
  },
  
  updateDownloadAlertState() {
    return (dispatch) => {
      return sapi.NotifyBlock.areDownloadAlertsBlocked()
        .then((res) => {
          dispatch({
            type : c.actions.account.updateDownloadAlertsAreBlocked,
            blocked : res
          })
        })
    }
  },

  updateUserBlockList() {
    return (dispatch) => {
      return sapi.NotifyBlock.userList()
        .then((res) => {
          dispatch({
            type : c.actions.account.updateUserBlockList,
            blockList : res.data
          })
        })
    }
  },

  updatePasswordInfo() {
    return (dispatch) => {
      return sapi.Password.info()
        .then(data => {
          dispatch({
            type: c.actions.account.updatePasswordInfo,
            passwordInfo: data.data
          });
          return true;
        })
    }
  },

  updateSubscriptionList() {
    return (dispatch) => {
      return sapi.Subscription.list()
        .then(data => {
          dispatch({
            type: c.actions.account.updateUserSubscriptionList,
            subscriptionList: data.data
          });
          return true;
        })
    }
  },
}

export default accountActions;
