import React, {PureComponent} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import ColorGenerator from "../../../helpers/color-generator";
import classnames from 'classnames'
import colors from "../../../util/colors";

class NotificationIcon extends PureComponent {
  render(){
    let { value, iconCls, alignBadgeLeft, iconColorOverride, fontSizeOverride } = this.props;
    
    let badgeAlign = {
      right : '0px'
    }
    if(alignBadgeLeft){
      badgeAlign = {
        left : '0px'
      }
    }
    
    let iconColor = {};
    if(iconColorOverride){
      iconColor = {
        color : iconColorOverride
      }
    }
    
    let fontSize = {fontSize : '26px'};
    if(fontSizeOverride){
      fontSize = {fontSize: fontSizeOverride}
    }
    
    return (
      <div className="d-inline-block" style={styles.badgeWrap}>
        <i style={{...styles.icon, ...iconColor, ...fontSize}} className={classnames('icon', iconCls)} />
        <div className={classnames({'d-none' : value <= 0})} style={{...styles.badge, ...badgeAlign}}>{value}</div>
      </div>
    )
  }
  
}

NotificationIcon.BADGE_WIDTH = 21.5;

const styles = {
  badgeWrap : {
    position : 'relative',
  },
  badge : {
    position: 'absolute',
    top : '-4px',
    backgroundColor : colors.NOTIFICATION,
    padding: '3px',
    lineHeight : '13px',
    borderRadius : '50%',
    fontSize: '12px',
    color : colors.LIGHT,
    minHeight : '18px',
    minWidth : '18px',
    pointerEvents : 'none',
    textAlign: 'center'
  },
  icon : {
    color : colors.DARK,
    textAlign : 'center',
    margin : '3px 6px',
    verticalAlign : 'baseline',
    minWidth: `${NotificationIcon.BADGE_WIDTH}px`,
    display: 'inline-block'
  }
}

NotificationIcon.propTypes = {
  value : PropTypes.number.isRequired,
  alignBadgeLeft : PropTypes.bool,
  iconCls : PropTypes.string.isRequired,
  iconColorOverride : PropTypes.string,
  fontSizeOverride : PropTypes.string
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationIcon));
