import sapi from "../util/sapi";

import _ from 'lodash';
import Promise from 'bluebird';

//FYI, this whole class is just a workaround for not having a doc/guest/list call. :/

const DocPermissionsHelper = {
  permissionCache : {},
  userCache: {},
  cacheTpl : {
    forum_id : null,
    promise: null
  },
  
  clearCache(){
    this.permissionCache = {};
    this.userCache = {};
  },
  
  clearCachedItem(forum_id){
    delete this.permissionCache[forum_id];
    this.userCache = {};
  },
  
  getUser(guest_uid) {
    return new Promise((resolve, reject) => {
      if(this.userCache[guest_uid]){
        resolve(this.userCache[guest_uid].promise);
      }
      else{
        let promise = sapi.Contacts.get(guest_uid)
          .then((res) => {
            return res.data;
          })
        this.userCache[guest_uid] = {
          promise
        }
  
        resolve(this.userCache[guest_uid].promise);
      }
    })
  },
  
  getUserIdsWithPermission(forum_id, doc_id){
    let fetchPromise = null;
    if(this.permissionCache[forum_id]){
      fetchPromise = this.permissionCache[forum_id].promise;
    }
    else {
      let promise = sapi.Docs.permissions(forum_id);
    
      this.permissionCache[forum_id] = _.extend({}, this.cacheTpl, {
        forum_id : forum_id,
        promise: promise
      })
    
      fetchPromise = this.permissionCache[forum_id].promise;
    }
  
    return fetchPromise
      .then( (res) => {
        let perms = res.data;
        let doc_ids = _.keys(perms);
  
        // "578ffb8537992375" : {
        //   "guest" : {
        //     "5787d2c2090c7c8a" : "workset",
        //     "5791016418b8484e" : "workset"
        //   }
        // }
  
        let guest_ids = [];
        _.each(doc_ids, (did) => {
          if (did === doc_id) {
            guest_ids = _.keys(perms[did].guest);
          }
        })
        
        return guest_ids;
      })
  },
  
  // getUsersWithPermission(forum_id, doc_id){
  //
  //   return this.getUserIdsWithPermission(forum_id, doc_id)
  //     .then((guest_ids) => {
  //       let calls = [];
  //       _.each(guest_ids, (guest_uid) =>  {
  //         calls.push(this.getUser(guest_uid));
  //       })
  //
  //       return Promise.all(calls);
  //     })
  //     .then((res) => {
  //       let list = [];
  //       _.each(res, (guest) => {
  //         list.push(guest);
  //       })
  //       return list;
  //     })
  // }
}

export default DocPermissionsHelper;
