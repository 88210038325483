const c = {
  api: {
    X_TOKEN: 'x-vf-token',
    X_VF_DEVICE: 'x-vf-device',
    X_FORUM_ID: 'x-forum-id',
    X_FILE_NAME: 'x-file-name',
    X_VF_OTP: 'X-VF-OTP',
    X_CHAT_ID : 'x-chat-id',
    X_DOC_ID: 'x-doc-id',
    X_HOST_UID: 'x-host-uid',
    X_MESG: 'x-mesg',
    X_SIGN_REQUEST_ID: 'x-sign-request-id',
    X_GUEST_UID : 'x-guest-uid',
    X_LAST_FORUM_ID : 'x-last-forum-id',
    X_INST_ID : 'inst_id',
    X_CLASS_ID : 'class_id',
    X_IMPORT_FLAG : 'import_flag',
  
    CONTENT_TYPE: 'Content-Type',
    APPLICATION_OCTET_STREAM: 'application/octet-stream',
    APPLICATION_JSON: 'application/json',
    MULTIPART_FORM_DATA: 'multipart/form-data',
    
    API_RETRY_COUNT: 3,
    TIMEOUT_MS : 30000,
    
    //File uploading requires some different limits than regular api calls.
    //This is the amount of time to take when connecting to the server.
    FILE_UPLOAD_TIMEOUT : 30 * 60 * 1000,
    //30 min - this should be something giant. The upload will fail at the end of this time.
    FILE_UPLOAD_DEADLINE : 30 * 60 * 1000,
  },
  language : {
    en_us : 'en-US',
    fr_fr : 'fr-FR'
  },
  pricing: {
    PRO_PLAN_COST_DOLLARS : 9
  },
  google: {
    captcha_key : '6LdA-bMUAAAAAIJpEXXRX930LECuputHAAfJtRKC',
    //For automated testing, from here
    //https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha-what-should-i-do
    always_pass_recaptcha_key : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  },
  links: {
    mainNoRedirect : 'https://verifyle.com?noredirect=true',
    privacy: 'https://verifyle.com/privacypolicy.html',
    tos: 'https://verifyle.com/terms.html',
    faq: 'https://verifyle.com/faq.html',
    mailToSupport: 'mailto:support@verifyle.com',
    youtubeChannel: 'https://www.youtube.com/channel/UCOP_R1kBITnCyU4QoDqkpAA/videos'
  },
  localstorage: {
    token: 'vf.admin.auth.token',
    email: 'vf.admin.user.email',
    vip: 'vf.admin.auth.vip',
    sid: 'vf.admin.auth.sid',
    device: 'vf.admin.tfa.device',
    endpoint : 'vf.admin.endpoint',
    lastSelectedHomeTab : 'vf.admin.lastSelectedHomeTab',
    wsSort : 'vf.admin.wsSort',
    contactSort : 'vf.admin.contactSort'
  },
  sessionstorage: {
    qs : 'vf.admin.session.qs'
  },
  querystring: {
    inst_id: "inst",
    goto_confirm: "gotocfmstep",
    goto_reset: "gotoresetstep",
    goto_proreg: "goproregstep",
    goto_reg: "gotoregstep",
    goto_dnd: "gotodnd",
    goto_block_user: "gotoblockuser",
    email: 'email',
    confirmation_code: 'code',
    forum_id: 'forum_id',
    doc_id: 'doc_id',
    thread_id: 'thread_id',
    guest_uid: 'guest_uid',
    sign_request_id : 'sign_request_id',
    last_forum_id : 'last_forum_id',
    mesg_id : 'mesg_id',
    logout : 'logout'
  },
  authSteps: {
    email : 'auth.steps.email',
    password : 'auth.steps.password',
    tfa: 'auth.steps.tfa',
    tfaPwdReset: 'auth.steps.tfa-pwd-reset',
    forgotPassword : 'auth.steps.forgot-password',
    signup: 'auth.steps.signup'
  },
  modal: {
    authInfo : 'modal.auth-info',
    newItem : 'modal.new-item',
    newWorkspace : 'modal.new-workspace',
    renameItem : 'modal.rename-item',
    search : 'modal.search',
    preview : 'modal.preview',
    addDoc : 'modal.add-doc',
    notifications: 'modal.notifications',
    downloadMultiple: 'modal.downloadMultiple',
    changePassword: 'modal.changePassword',
    requestSignature: 'modal.requestSignature',
    pdfSignatureRequest: 'modal.pdfSignatureRequest',
    addGuestWindow: 'modal.addGuestWindow',
    addContactWindow: 'modal.addContactWindow',
    manageObjectPermissionsWindow: 'modal.manageObjectPermissionsWindow',
    generatedPasswordDialog : 'modal.generatedPasswordDialog',
    contactInfoWindow : 'modal.contactInfoWindow',
    memberInfoWindow : 'modal.memberInfoWindow',
    docViewHistory: 'modal.docViewHistory',
    mesgEditWindow : 'modal.mesgEditWindow',
    mesgHistoryWindow : 'modal.mesgHistoryWindow',
    upgradeDialog : 'modal.upgradeDialog',
    stripeCheckoutDialog : 'modal.stripeCheckoutDialog',
    tutorialWindow : 'modal.tutorialWindow',
    attachDocToThreadWindow : 'modal.attachDocToThreadWindow',
    signingMetadata : 'modal.signingMetadata',
    contactUsWindow : 'modal.contactUsWindow',
    helpResourceWindow : 'modal.helpResourceWindow',
    upgradeDialogNew : 'modal.upgradeDialogNew',
    addPromoCodeDialog : 'modal.addPromoCodeDialog',
    smsVerificationDialog : 'modal.smsVerificationDialog',
  },
  dialog: {
    confirm : 'modal.confirm',
    contactConfirm : 'modal.contactConfirm',
    alert : 'modal.alert',
    pdf_submit : 'modal.pdf_submit',
    agree_to_terms : 'dialog.agree_to_terms',
    add_signatures : 'dialog.add_signatures',
    submit_signed_doc : 'dialog.submit_signed_doc'
  },
  actions: {
    app: {
      setQueryString: 'app.actions.app.setQueryString',
      setQsEmail: 'app.actions.app.setQsEmail',
      setQsActionNeeded : 'app.actions.app.setQsActionNeeded',
      setBrowserTabActive : 'app.actions.app.setBrowserTabActive',
      setApplicationError : 'app.actions.app.setApplicationError',
      logout: 'app.actions.app.logout'
    },
    auth: {
      setStep: 'app.actions.auth.setStep',
      setInstitution: 'app.actions.auth.setInstitution',
      setEmail: 'app.actions.auth.setEmail',
      setMemberNumber: 'app.actions.auth.setMemberNumber',
      setConfirmationCode: 'app.actions.auth.setConfirmationCode',
      setTFAAuth: 'app.actions.auth.setTFAAuth',
      setAuth: 'app.actions.auth.setAuth',
      logout: 'app.actions.auth.logout',
      setEmailFieldVisible: 'app.actions.auth.setEmailFieldVisible',
      preventEmailDisabled: 'app.actions.auth.preventEmailDisabled',
      loginWithPayment: 'app.actions.auth.loginWithPayment',
    },
    upload: {
      setActiveUpload: 'app.actions.upload.setActiveUpload',
      addToUploadQueue: 'app.actions.upload.addToUploadQueue',
      updateQueue : 'app.actions.upload.updateQueue',
      updateProgress : 'app.actions.upload.updateProgress',
      cleanup : 'app.actions.upload.cleanup'
    },
    download: {
      doDownload: 'app.actions.download.doDownload',
      updatePendingQueue : 'app.actions.download.updatePendingQueue',
      addToCurrentQueue : 'app.actions.download.addToCurrentQueue',
      cleanup : 'app.actions.download.cleanup'
    },
    home: {
      updateWorkspaceLists : 'app.actions.home.updateWorkspaceLists',
      setActiveDM: 'app.actions.home.setActiveDM',
      updateActiveDMMessages: 'app.actions.home.updateActiveDMMessages',
      cleanup : 'app.actions.home.cleanup'
    },
    shared: {
      setLoaded: 'app.actions.shared.setLoaded',
      setStartupFirstCallsLoaded: 'app.actions.shared.setStartupFirstCallsLoaded',
      updateDirectMessages: 'app.actions.shared.updateDirectMessages',
      updateDirectMessagePreviews: 'app.actions.shared.updateDirectMessagePreviews',
      updateWorkspaces: 'app.actions.shared.updateWorkspaces',
      updateAccountInfo: 'app.actions.shared.updateAccountInfo',
      updateNotificationCount: 'app.actions.shared.updateNotificationCount',
      cleanup: 'app.actions.shared.cleanup',
      queueThreadTransaction : 'app.actions.shared.queueThreadTransaction',
      dequeueThreadTransaction : 'app.actions.shared.dequeueThreadTransaction',
      updateAccountSignatures: 'app.actions.shared.updateAccountSignatures',
      updateContacts : 'app.actions.shared.updateContacts',
      updateUserContract: 'app.actions.shred.updateUserContract',
      updateStripeInfo: 'app.actions.shared.updateStripeInfo',
      updateLogo: 'app.actions.shared.updateLogo',
      updateStripePlan100: 'app.actions.shared.updateStripePlan100',
      updateAvailablePlans : 'app.actions.shared.updateAvailablePlans',
    },
    account: {
      updatePasswordInfo: 'app.actions.account.updatePasswordInfo',
      updateUserSubscriptionList: 'app.actions.account.updateUserSubscriptionList',
      updateDownloadAlertsAreBlocked: 'app.actions.account.updateDownloadAlertsAreBlocked',
      updateUserBlockList: 'app.actions.account.updateUserBlockList',
      cleanup: 'app.actions.account.cleanup'
    },
    workspace: {
      updateWorkspace: 'app.actions.workspace.updateWorkspace',
      updateThreads: 'app.actions.workspace.updateThreads',
      updateThreadMessages: 'app.actions.workspace.updateThreadMessages',
      updateActiveDMMessages: 'app.actions.workspace.updateActiveDMMessages',
      updateThreadParticipantLookup : 'app.actions.workspace.updateThreadParticipantLookup',
      updateDocParticipantLookup : 'app.actions.workspace.updateDocParticipantLookup',
      updateDocs: 'app.actions.workspace.updateDocs',
      updateGuests: 'app.actions.workspace.updateGuests',
      updateLogo: 'app.actions.workspace.updatelogo',
      setActiveThread: 'app.actions.workspace.setActiveThread',
      setActiveDM: 'app.actions.workspace.setActiveDM',
      setActiveDoc: 'app.actions.workspace.setActiveDoc',
      setActiveDocAssociatedThread: 'app.actions.workspace.setActiveDocAssociatedThread',
      cleanup: 'app.actions.workspace.cleanup'
    },
    pdf:{
      initialize:'app.actions.pdf.initialize',
      setRawPdf:'app.actions.pdf.setRawPdf',
      setPdfJsModel: 'app.actions.pdf.setPdfJsModel',
      setPdfJsPages: 'app.actions.pdf.setPdfJsPages',
      setPdfJsLoadErr : 'app.actions.pdf.setPdfJsLoadErr',
      loadSignatureRequestData : 'app.actions.pdf.loadSignatureRequestData',
      updateSignatureRequestOverlays : 'app.actions.pdf.updateSignatureRequestOverlays',
      updateSignatureRequest : 'app.actions.pdf.updateSignatureRequest',
      setEditingSignatureRequest : 'app.actions.pdf.setEditingSignatureRequest',
      setWindowSigningMode : 'app.actions.pdf.setWindowSigningMode',
      setPdfWriterLoadErr : 'app.actions.pdf.setPdfWriterLoadErr',
      
      updateV1SignatureOverlays : 'app.actions.pdf.updateV1SignatureOverlays',
      updateV1ActiveOverlays : 'app.actions.pdf.updateV1ActiveOverlays',
      updateV1InactiveOverlays : 'app.actions.pdf.updateV1InactiveOverlays',
      updateV1HasCommittedSignatures : 'app.actions.pdf.updateV1HasCommittedSignatures',
      updateV1HasValidSigning : 'app.actions.pdf.updateV1HasValidSigning',
      updateV1PdfChangesMade : 'app.actions.pdf.updateV1PdfChangesMade',
      resetV1SigningState : 'app.actions.pdf.resetV1SigningState',
      
      cleanup: 'app.actions.pdf.cleanup'
    },
    utility:{
      setWatermarkCanvasRef : 'app.actions.utility.setWatermarkCanvasRef',
      generateWatermarkImg: 'app.actions.utility.generateWatermarkImg',
      cleanup: 'app.actions.utility.cleanup'
    },
    modal: {
      show: 'app.actions.modal.show',
      close: 'app.actions.modal.close',
      cleanup : 'modal.cleanup',
      updateSize: 'app.actions.modal.updateSize'
    }
  },
  pdf:{
    //bug 2475 - Make sure this folder gets versioned, or if a new web version gets released user's could be
    //pointing at a cached version that won't be compatible with the pdf-js version and things
    //won't work until users refresh.
    pdfjs_workerPath : '/pdf/v2.4.456/pdf.worker.js'
  },
  polling: {
    threadPollTimeMs : 10 * 1000
  },
  limits: {
    maxFileSizeSigningBytes : 5242880,
    maxTermsChars : 1000,
    maxThreadMessage : 8192,
    warnThreadMessage : 7500
  },
  account: {
    ACC_EXPIRY_WARN_PERIOD: 1209600,
  },
  DRAG_DROP_TYPES : {
    THREAD_DOC : 'thread_doc',
    PDF_THUMBNAIL : 'pdf_thumbnail'
  },
  analytics: {
    gaTrackingId: 'UA-69118804-1',
    pixelId: '1759862410926204'
  },
  payment: {
    PAY_APPLE: 'PAY_APPLE',
    PAY_GOOGLE: 'PAY_GOOGLE',
  },
  browsers: {
    chrome: 'chrome',
    safari: 'safari',
    ie: 'ie',
    edge: 'edge',
    firefox: 'firefox'
  },
  threads : {
    fileExtensionConflicts : [
      'py',
      'sh',
      'ai',
      'ps',
      'pl',
    ],
  
    fullPathPrefixes : [
      'http',
      'www',
      'ftp'
    ]
  },
  preview: {
    pdfExtensions: [
      'pdf',
      'csv',
      'docx',
      'xlsx',
      'pptx',
      'doc',
      'ppt',
      'xls',
      'txt',
      'log'
    ],
    svgExtensions: ['svg'],
    textExtensions: [],
    imgExtensions: [
      'jpg',
      'jpeg',
      'gif',
      'png'
    ]
  },
  BLANK_LOGO : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
}

export default c;
