import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash'
import Button from '../partials/elements/Button';
import UserBadge from "../partials/badges/UserBadge";
import utils from "../../util/util";
import colors from "../../util/colors";
import {getMessageForError} from "../../util/errors";
import log from "../../util/log";
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";
import ValidationErrors from "../partials/components/ValidationErrors";

class ContactUsWindow extends Component {
  
  constructor(props) {
    super(props);
  
    this.firstInputRef = React.createRef();
    
    this.state = {
      changingPassword : false,
      validationErr : [],
      curPassword : '',
      newPassword : '',
      cfmPassword : '',
    }
  }
  
  componentDidMount() {
    setTimeout(() => {
      this.firstInputRef.current.focus();
    })
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  doChangePassword(){
    if(!this.doValidate()){
      return;
    }
    let { t } = this.props;
    let { newPassword, curPassword} = this.state;
    sapi.Password.reset(curPassword, newPassword)
      .then((res) => {
        this.closeModal(res);
      })
      .catch((err) => {
        log.error('error changing password', err);
        this.setState({
          validationErr : [ getMessageForError(err, t) ]
        })
      })
  }
  
  doValidate() {
    let { t } = this.props;
    let { curPassword, newPassword, cfmPassword } = this.state;
    
    let err = [];
    if(!curPassword && curPassword.length === 0){
      err.push(t('Please enter your current password'));
    }
    if(!newPassword && newPassword.length === 0){
      err.push(t('Please enter your new password'));
    }
    if(!cfmPassword && cfmPassword.length === 0){
      err.push(t('Please confirm your new password'));
    }
    
    if(newPassword && cfmPassword && newPassword !== cfmPassword){
      err.push(t('Your passwords do not match'));
    }
    
    if(newPassword && cfmPassword && newPassword.length < 6){
      err.push(t('Your new password must be at least 6 characters.'));
    }
    
    this.setState({
      validationErr : err
    })
    return err.length === 0;
  }
  
  getChangePasswordContents(){
    let { t } = this.props;
    let {
      validationErr,
      curPassword,
      newPassword,
      cfmPassword
    } = this.state;
  
    return (
      <div className="modal-body">
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Current Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     ref={this.firstInputRef}
                     value={curPassword}
                     onChange={(evt) => this.setState({curPassword: evt.target.value})}
                     placeholder={t('Enter your current password')}/>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("New Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     value={newPassword}
                     onChange={(evt) => this.setState({newPassword: evt.target.value})}
                     placeholder={t('Enter your new password')}/>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Confirm Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     value={cfmPassword}
                     onChange={(evt) => this.setState({cfmPassword: evt.target.value})}
                     placeholder={t('Confirm your new password')}/>
            </div>
          </div>
        </div>
        {validationErr.length > 0 && <ValidationErrors errors={validationErr}/>}
      </div>
    )
  }
  
  render() {
    let { t} = this.props;
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Change Password")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        {this.getChangePasswordContents()}
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'}
                  onClick={this.doChangePassword.bind(this, true)}>{t("Change Password")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  messageBlock : {
    padding: '10px',
    textAlign: 'left',
    borderRadius : '5px'
  },
  blockTime : {
    fontSize : '10px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    paddingLeft : '5px'
  },
  docControlWrap: {
    borderRadius: '20px',
    padding: '6px 3px 5px 3px',
    lineHeight: '26px',
    marginBottom: '8px',
    display: 'inline-block',
    maxWidth: '100%'
  },
  docDeletedWrap: {
    color: colors.SECONDARY_TEXT,
    padding: '6px 3px 5px 3px',
    lineHeight: '26px',
    marginBottom: '8px',
    display: 'inline-block',
    maxWidth: '100%'
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
    workspace : state.workspace.workspace
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

ContactUsWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactUsWindow));
