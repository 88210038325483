import c from '../util/const';

let initialState = {
  email: null,
  memberNumber: null,
  code: null,
  token: null,
  vip: null,
  sid: null,
  step: null,
  tfaAuth: null,
  institution: null,
  emailFieldIsVisible: true,
  preventEmailDisabled : false,
  loginWithPayment : false,
}

const app = (state = initialState, action) => {
  switch (action.type) {
    
    case c.actions.auth.setStep:
      return {
        ...state,
        step: action.step
      };
      
    case c.actions.auth.loginWithPayment:
      return {
        ...state,
        loginWithPayment : action.loginWithPayment
      }
      
    case c.actions.auth.preventEmailDisabled:
      return {
        ...state,
        preventEmailDisabled: action.preventEmailDisabled
      };
  
    case c.actions.auth.setEmailFieldVisible:
      return {
        ...state,
        emailFieldIsVisible: action.emailFieldIsVisible
      };
  
    case c.actions.auth.setInstitution:
      return {
        ...state,
        institution: {...action.institution}
      };
      
    case c.actions.auth.setEmail:
      return {
        ...state,
        email: action.email
      };
  
    case c.actions.auth.setMemberNumber:
      return {
        ...state,
        memberNumber: action.number
      };
  
    case c.actions.auth.setConfirmationCode:
      return {
        ...state,
        code: action.code
      };
      
    case c.actions.auth.setTFAAuth:
      return {
        ...state,
        tfaAuth : action.tfaAuth
      }
      
    case c.actions.auth.setAuth:
      return {
        ...state,
        token: action.token,
        vip: action.vip,
        sid: action.sid
      };
      
    case c.actions.auth.logout:
      return {
        ...initialState,
        email : state.email,
        step : state.step,
      }
    
    default:
      return state;
  }
}

export default app;
