import moment from 'moment';
import _ from 'lodash';

let parseDate = (val) => {
  return new Date(+val * 1000)
}

let momentFilter = (val, format) => {
  if(!val){
    return null;
  }
  
  return moment(parseDate(val)).format(format);
}

let momentUTCFilter = (val, format) => {
  if(!val){
    return null;
  }
  
  return moment(parseDate(val)).utc().format(format);
}

let momentDate = (val) => {
  if(!val){
    return null;
  }
  
  return moment(parseDate(val));
}

let getCurrency = (centsVal) => {
  
  let val = +centsVal;
  if(!val || _.isNaN(val)){
    return `$${val}`;
  }
  
  return '$' + (val / 100).toFixed(2);
}

let getFriendlyDate = (t, date) => {
  let thisDate = moment(parseDate(date));
  
  let today = moment().startOf('d');
  let yesterday = moment(today).subtract(1, 'd').startOf('d');
  let withinOneWeek = moment(today).subtract(7, 'd').startOf('d');
  if (moment(thisDate).isSame(today, 'day')) {
    return thisDate.format('h:mm a')
  }
  else if (moment(thisDate).isSame(yesterday, 'day')) {
    return t('Yesterday ') + thisDate.format('h:mm a')
  }
  else if (moment(thisDate).isAfter(withinOneWeek, 'day')) {
    return thisDate.format('dddd h:mm a')
  }
  else {
    return thisDate.format('M/D/YY h:mm a')
  }
}

let getFileSizeString = (bytes, precision) => {
  bytes = parseInt(bytes, 10);
  
  var kilobyte = 1024;
  var megabyte = kilobyte * 1024;
  var gigabyte = megabyte * 1024;
  var terabyte = gigabyte * 1024;
  
  if ((bytes >= 0) && (bytes < kilobyte)) {
    return bytes + ' B';
  } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
    return (bytes / kilobyte).toFixed(precision) + ' KB';
  } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
    return (bytes / megabyte).toFixed(precision) + ' MB';
  } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
    return (bytes / gigabyte).toFixed(precision) + ' GB';
  } else if (bytes >= terabyte) {
    return (bytes / terabyte).toFixed(precision) + ' TB';
  } else {
    return bytes + ' B';
  }
}

export default {
  getCurrency,
  momentFilter,
  momentUTCFilter,
  getFriendlyDate,
  getFileSizeString,
  momentDate
}
