import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import {withVFTranslation} from "../../util/withVFTranslation";

class UpgradeDialog extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {}
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  getUpgradeText(){
    let { userContract, t } = this.props;
    
    return (
      <div>
        <div className={'row pb-2'}>
          <div className={'col-6 pl-0'}>
          
          </div>
          <div className={'col-3 text-center'}>
            {t("You")}
          </div>
          <div className={'col-3 text-center'}>
            {t("Pro You")}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 pl-0 text-right'}>
            {t("Digital Signing")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {t("N/A")}
          </div>
          <div className={'col-3 text-center green-color'}>
            {t("Unlimited")}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 pl-0 text-right'}>
            {t("Account Logo")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {t("N/A")}
          </div>
          <div className={'col-3 text-center green-color'}>
            {t("Yes")}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 pl-0 text-right'}>
            {t("Hosted Workspaces")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {userContract.current['forum_max_total']}
          </div>
          <div className={'col-3 text-center green-color'}>
            {userContract.upgrade['forum_max_total']}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 pl-0 text-right'}>
            {t("Documents per Workspace")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {userContract.current['forum_max_doc']}
          </div>
          <div className={'col-3 text-center green-color'}>
            {userContract.upgrade['forum_max_doc']}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 pl-0 text-right'}>
            {t("Storage Space")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {+(userContract.current['max_storage'] / 1073741824).toFixed(2)}{t("GB")}
          </div>
          <div className={'col-3 text-center green-color'}>
            {+(userContract.upgrade['max_storage'] / 1073741824).toFixed(2)}{t("GB")}
          </div>
        </div>
      </div>
    )
  }
  
  getUpgradeMessage(){
    let { upgradeType, userContract, t } = this.props;
    
    if(upgradeType === UpgradeDialog.UPGRADE_TYPES.WORKSPACE){
      return t("You've reached your limit on Workspaces.  To increase your limits, upgrade to the Professional Plan for $") + userContract.upgrade.term_cost + t(" per month.")
    }
    else if(upgradeType === UpgradeDialog.UPGRADE_TYPES.DOCS){
      return t("You've reached your limit on Documents.  To increase your limits, upgrade to the Professional Plan for $") + userContract.upgrade.term_cost + t(" per month.")
    }
    else if(upgradeType === UpgradeDialog.UPGRADE_TYPES.STORAGE){
      return t("You've reached your limit on storage.  To increase your limits, upgrade to the Professional Plan for $") + userContract.upgrade.term_cost + t(" per month.")
    }
    else if(upgradeType === UpgradeDialog.UPGRADE_TYPES.SIGNING){
      return t("Only Verifyle Pros can request signatures.  To enable signing, upgrade to the Professional Plan for $") + userContract.upgrade.term_cost + t(" per month.")
    }
  }
  
  render() {
    let {modalProps, t} = this.props;
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Upgrade Account")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p>
            {this.getUpgradeMessage()}
          </p>
          <div>
            {this.getUpgradeText()}
          </div>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this, true)}>{t("OK")}</Button>
        </div>
      </div>
    )
  }
}

UpgradeDialog.UPGRADE_TYPES = {
  WORKSPACE : 'workspace',
  DOCS : 'docs',
  STORAGE : 'storage',
  SIGNING : 'signing'
}

const mapStateToProps = (state) => {
  return {
    userContract : state.shared.userContract,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

UpgradeDialog.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(UpgradeDialog));
