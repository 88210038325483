import c from '../util/const';

let initialState = {
  hasLoaded: false,
  passwordInfo: null,
  subscriptionList: null,
  downloadAlertsAreBlocked: false,
  userBlockList: null
}

const shared = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.account.updatePasswordInfo:
      return {
        ...state,
        passwordInfo: action.passwordInfo
      };

    case c.actions.account.updateUserBlockList:
      return {
        ...state,
        userBlockList : action.blockList
      }

    case c.actions.account.updateDownloadAlertsAreBlocked:
      return {
        ...state,
        downloadAlertsAreBlocked :action.blocked
      }

    case c.actions.account.updateUserSubscriptionList:
      return {
        ...state,
        subscriptionList: action.subscriptionList
      }

    case c.actions.shared.cleanup:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default shared;
