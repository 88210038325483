import c from '../util/const';
import _ from 'lodash'

let initialState = {
  allStartupCallsFinished : false,
  firstStartupCallsFinished : false,
  workspaces: [],
  accountInfo: null,
  accountInfoGuest : null,
  notifyCount: null,
  threadTransactionQueue: [],
  signatures : null,
  directMessages: [],
  dmPreviewLookup: {},
  directMessageNotifyCount : 0,
  contacts : [],
  userContract: null,
  stripeData: null,
  logo : null,
  upgradePlans : null,
  stripeClass100 : null
}

const shared = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.shared.updateWorkspaces:
      return {
        ...state,
        workspaces: action.workspaces
      };
  
    case c.actions.shared.updateLogo:
      return {
        ...state,
        logo : action.data
      }
      
    case c.actions.shared.updateContacts:
      return {
        ...state,
        contacts : action.contacts
      }
  
    case c.actions.shared.updateStripeInfo:
      return {
        ...state,
        stripeData: action.data
      }
      
    case c.actions.shared.updateUserContract:
      return {
        ...state,
        userContract: action.contract
      }
  
    case c.actions.shared.updateAvailablePlans:
      return {
        ...state,
        upgradePlans: action.upgradePlans
      }
      
    case c.actions.shared.updateDirectMessagePreviews:
  
      let updateLookup = _.extend({}, state.dmPreviewLookup);
      _.each(action.directMessagePreviews, (previewItem) => {
        updateLookup[previewItem.guest_uid] = previewItem;
      })
      return {
        ...state,
        dmPreviewLookup: updateLookup
      }
      
    case c.actions.shared.updateDirectMessages:
      let notifyCount = 0;
      _.each(action.directMessages, (dm) => {
        if(dm.notify_flag){
          notifyCount++;
        }
      })
      
      return {
        ...state,
        directMessages: action.directMessages,
        directMessageNotifyCount: notifyCount
      }
      
    case c.actions.shared.updateAccountSignatures:
      return {
        ...state,
        signatures : action.signatures
      }
      
    case c.actions.shared.updateAccountInfo:
      return {
        ...state,
        accountInfo: action.accountInfo,
        accountInfoGuest : action.accountInfoGuest
      };

    case c.actions.shared.updateNotificationCount:
      return {
        ...state,
        notifyCount: action.notifyCount
      };

    case c.actions.shared.setLoaded:
      return {
        ...state,
        allStartupCallsFinished: action.hasLoaded
      };
  
    case c.actions.shared.setStartupFirstCallsLoaded:
      return {
        ...state,
        firstStartupCallsFinished: action.hasLoaded
      };
  
    case c.actions.shared.updateStripePlan100:
      return {
        ...state,
        stripeClass100 : action.stripeClass100
      }
      
    case c.actions.shared.queueThreadTransaction:
      return {
        ...state,
        threadTransactionQueue : _.concat(state.threadTransactionQueue, action.threadTransactionQueue)
      }
  
    case c.actions.shared.dequeueThreadTransaction:
      let copy = _.concat([], state.threadTransactionQueue);
      _.remove(copy, (item) => {
        return item.transaction_id === action.transaction_id;
      })
      
      return {
        ...state,
        threadTransactionQueue :copy
      }
      
    case c.actions.shared.cleanup:
      return {
        ...initialState
      };
      
    default:
      return state;
  }
}

export default shared;
