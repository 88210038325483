class DMSignatureRequest{
  
  terms = null;
  
  constructor(dm_guest_uid, forum_id, host_uid, doc_id, mesg_id, status){
    this.dm_guest_uid = dm_guest_uid;
    this.forum_id = forum_id;
    this.host_uid = host_uid;
    this.doc_id = doc_id;
    this.mesg_id = mesg_id;
    this.status = status;
  }
  
  setSigner(signer_uid, guest_info){
    this.signer_uid = signer_uid;
    this.guest_info = guest_info;
  }
  
  setRequestor(guest_info){
    this.sign_requestor = guest_info;
  }
  
  setDocInfo(doc_info){
    this.doc_info = doc_info;
  }
  
  setSignatureRequest(sigRequest){
    this.signature_request = sigRequest;
  }
}

export default DMSignatureRequest;
