class DocAttach{
  
  static buildDMAttachResult(dest_guest_uid, docs){
    return new DocAttach(null, null, dest_guest_uid, docs)
  }
  
  static buildThreadAttachResult(dest_forum_id, dest_chat_id, docs){
    return new DocAttach(dest_forum_id, dest_chat_id, null, docs)
  }
  
  constructor(dest_forum_id, dest_chat_id, dest_guest_uid, docs){
    this.dest_forum_id = dest_forum_id;
    this.dest_chat_id = dest_chat_id;
    this.dest_guest_uid = dest_guest_uid;
    this.docs = docs;
  }
}

export default DocAttach;
