import c from '../util/const';
import vfLocalStorage from "../util/local-storage";
import DocPermissionsHelper from "../helpers/doc-permissions-helper";
import PendingMsgCache from "../helpers/pending-msg-cache";

const authActions ={

  setNextStep(step) {
    return {
      type: c.actions.auth.setStep,
      step
    }
  },

  setEmailFieldVisible(isVisible){
    return {
      type : c.actions.auth.setEmailFieldVisible,
      emailFieldIsVisible : isVisible
    }
  },

  setLoginWithPayment(loginWithPayment){
    return {
      type : c.actions.auth.loginWithPayment,
      loginWithPayment
    }
  },
  
  setPreventEmailDisabled(preventDisabled){
    return {
      type : c.actions.auth.preventEmailDisabled,
      preventEmailDisabled : preventDisabled
    }
  },

  setConfirmationCode(code) {
    return {
      type: c.actions.auth.setConfirmationCode,
      code
    }
  },

  setTFAAuth(tfaAuth) {
    return {
      type: c.actions.auth.setTFAAuth,
      tfaAuth
    }
  },

  setInstitution(institution){
    return {
      type: c.actions.auth.setInstitution,
      institution
    }
  },

  setAuthFromLocalStorage(){
    let sid = vfLocalStorage.get(c.localstorage.sid);
    let vip = vfLocalStorage.get(c.localstorage.vip);
    let token = vfLocalStorage.get(c.localstorage.token);

    if(!sid || !vip || !token){
      throw new Error("Localstorage not properly configured");
    }

    return this.setAuth(sid, vip, token);
  },

  setAuth(sid, vip, token){
    vfLocalStorage.set(c.localstorage.token, token);
    vfLocalStorage.set(c.localstorage.vip, vip);
    vfLocalStorage.set(c.localstorage.sid, sid);
  
    DocPermissionsHelper.clearCache();
    PendingMsgCache.cleanup();
    
    return {
      type: c.actions.auth.setAuth,
      sid,
      vip,
      token
    }
  },

  setEmail(email) {
    return {
      type: c.actions.auth.setEmail,
      email
    }
  },
  
  setMemberNumber(number) {
    return {
      type: c.actions.auth.setMemberNumber,
      number
    }
  },

  logout(){
    vfLocalStorage.remove(c.localstorage.token);
    vfLocalStorage.remove(c.localstorage.vip);
    vfLocalStorage.remove(c.localstorage.sid);
  
    DocPermissionsHelper.clearCache();
    PendingMsgCache.cleanup();
    
    return {
      type : c.actions.auth.logout
    }
  }
}

export default authActions;
