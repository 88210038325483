import React, {Component} from 'react';
import PropTypes from 'prop-types';
import modalActions from "../../actions/modal-actions";
import {connect} from "react-redux";

//This wrapper helps us manage the loading of modals.
//For now it's mostly just the animation of modals, but this could perform other helper
//functions later.
class ModalContainer extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      doFade: false,
      timer : null
    }
  }
  
  componentDidMount(){
    var me = this;
  
    if(this.props.onRef) {
      this.props.onRef(this)
    }
    
    setTimeout(() => {
      this.setState({
        doFade: true
      })
    })
    
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
    
    if(this.timer){
      clearTimeout(this.timer);
    }
  }
  
  doClose(result) {
    let {callback, closeModal} = this.props;
    
    this.setState({doFade:false});
  
    let timer = setTimeout(() =>{
      this.setState({timer : null});
      
      if(callback) {
        callback(result);
      }
      
      closeModal();
    }, 200)
    
    this.setState({timer});
  }
  
  render() {
    let {children, zIndex} = this.props;
    let {doFade} = this.state;
    
    return (
      <div style={{zIndex}} className={'modal fade' + (doFade ? ' show' : '')} role="dialog">
        {children}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

ModalContainer.propTypes = {
  onRef: PropTypes.func.isRequired,
  closeModal : PropTypes.func.isRequired,
  callback : PropTypes.func.isRequired,
  zIndex : PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
