import utils from "../util/util";
import c from "../util/const";
import _ from "lodash";
import he from "he";
import Autolinker from "autolinker";
import moment from "moment";
import sharedActions from "../actions/shared-actions";
import log from "../util/log";

const msgHelper = {
  
  trimWhitespace(msg){
    return _.trim(msg);
  },
  
  hasNewMessage(currentUserId, beforeMessageBlocks, afterMessageBlocks){
    let preCount = 0;
    let postCount = 0;
    _.each(beforeMessageBlocks, (beforeBlock) => {
      _.each(beforeBlock.blockList, (msg) => {
        if(msg.guest_uid !== currentUserId){
          preCount++;
        }
      })
    })
    
    _.each(afterMessageBlocks, (afterBlock) => {
      _.each(afterBlock.blockList, (msg) => {
        if(msg.guest_uid !== currentUserId){
          postCount++;
        }
      })
    })
    return preCount > 0 && preCount !== postCount;
  },
  
  buildPendingMessageList(object_id, accountInfo, threadTransactionQueue) {
    // for pending docs, we need to replicate the data structure that comes back from the server so we can
    //combine it with the messages that come back before it exists there.j
    //it looks like this:
    // {
    //   "first_name" : "Kevin",
    //   "guest_uid" : "5787c9ce0ca1040a",
    //   "last_name" : "Swartz",
    //   "mesg" : "asfasdfasdf",
    //   "mesg_date" : 1469048132,
    //   "mesg_id" : 0,
    //   "docs" : [
    //     {
    //       "doc_id" : "51f1b5d090e98a6b",
    //       "label" : null
    //     },
    //     {
    //       "doc_id" : "57bcc8e3693eb8b3",
    //       "label" : "file.pdf"
    //     }
    //   ],
    // },
    
    let pendingMsgs = [];
    let chatTransactions = {};
    _.each(threadTransactionQueue, (item) => {
      if(chatTransactions[item.transaction_id]){
        chatTransactions[item.transaction_id].push(item);
      }
      else{
        chatTransactions[item.transaction_id] = [item]
      }
    })
    
    _.each(_.keys(chatTransactions), (transaction_id) => {
      let transactionItems = chatTransactions[transaction_id];
      let pendingMessage = {
        first_name : accountInfo.first_name,
        last_name : accountInfo.last_name,
        guest_uid : accountInfo.uid,
        mesg_date : transactionItems[0].queue_time.unix(),
        mesg_id : transaction_id, //fake it, we don't have one
        mesg : '',
        isPending : true,
        transaction_id,
        docs : []
      }
      
      _.each(transactionItems, (item) => {
        if(item.type === sharedActions.TRANSACTION_TYPES.MESSAGE || item.type === sharedActions.TRANSACTION_TYPES.DM_MESSAGE){
          pendingMessage.mesg = item.mesg
        }
        else if(item.type === sharedActions.TRANSACTION_TYPES.DOC_ATTACH){
          pendingMessage.mesg = item.mesg;
          _.each(item.files, (f) => {
            pendingMessage.docs.push({
              key : f.uniqueId,
              doc_id : f.uniqueId, //fake it, we don't have one
              filename : f.name,
              file_name : f.name,
              sizeString : f.sizeString,
              isPending : true
            })
          })
        }
        else if(item.type === sharedActions.TRANSACTION_TYPES.DOC || item.type === sharedActions.TRANSACTION_TYPES.DM_DOC){
          pendingMessage.mesg = item.mesg;
          _.each(item.files, (f) => {
            pendingMessage.docs.push({
              key : f.uniqueId,
              doc_id : f.uniqueId, //fake it, we don't have one
              filename : f.name,
              file_name : f.name,
              sizeString : f.sizeString,
              isPending : true
            })
          })
        }
      })
      pendingMsgs.push(pendingMessage);
    })
    
    return pendingMsgs;
  },
  
  shouldDisplayTokenWithHTML(match){
    var extension = utils.getFilenameExtension(match);
    if(c.threads.fileExtensionConflicts.indexOf(extension) >= 0){
      //Then we have to look at if this looks like a real path or not.
      //starts with http, or www, or has a "/" in it somewhere
      
      var hasPrefix = false;
      _.each(c.threads.fullPathPrefixes, (prefix) => {
        if(match.indexOf(prefix) === 0){
          hasPrefix = true;
        }
      })
      
      if(hasPrefix){
        return true;
      }
      else{
        if(match.indexOf('/') >= 0){
          return true;
        }
        return false;
      }
    }
    return true;
  },
  
  buildMessageTokens(msg){
    if(!msg || !msg.mesg){
      return [];
    }
    
    let decoded = he.decode(msg.mesg);
    
    var tokenId = 0;
    var tokens = [];
    var currentPos = 0;
    _.each(Autolinker.parse(decoded), (match) => {
      var matchPos = match.getOffset();
      
      var token = decoded.substring(currentPos, matchPos);
      if(token.length > 0) {
        tokens.push({
          text: token,
          useHtml: false,
          id: tokenId++
        })
      }
      
      currentPos += token.length;
      tokens.push({
        text: match.getMatchedText(),
        useHtml: this.shouldDisplayTokenWithHTML(match.getMatchedText()), //Extra check for matched tokens.
        id: tokenId++
      })
      
      currentPos += match.getMatchedText().length;
    })
    
    //Grab the last bit of the string, after the tokens.
    if(currentPos < decoded.length){
      tokens.push({
        text: decoded.substring(currentPos),
        useHtml: false,
        id: tokenId++
      })
    }
    
    return tokens;
  },
  
  getMessageBlocks(messages) {
    let lookup = [];
    let msgs = _.concat([], messages);
    
    msgs.sort( (x, y) => {
      var xUnix = utils.getMomentDate(x.mesg_date).unix();
      var yUnix = utils.getMomentDate(y.mesg_date).unix();
      if (xUnix < yUnix) return -1;
      else if (xUnix > yUnix) return 1;
      return 0;
    });
    
    let lastBlock = null;
    for (let i = 0; i < msgs.length; i++) {
      let thisMsg = msgs[i];
      thisMsg.tokens = this.buildMessageTokens(thisMsg);
      
      let partOfBlock = false;
      if(lastBlock){
        let firstOfLastBlock = lastBlock.blockList[0];
        if (thisMsg.guest_uid !== firstOfLastBlock.guest_uid) {
          partOfBlock = false;
        }
        else{
          let thisMsgDate = utils.getMomentDate(thisMsg.mesg_date);
          let lastMsgDate = utils.getMomentDate(firstOfLastBlock.mesg_date);
          let duration = moment.duration(thisMsgDate.diff(lastMsgDate));
          if(Math.abs(duration.asMinutes()) < 10){
            lastBlock.blockList.push(thisMsg);
            partOfBlock = true;
          }
        }
      }
      
      if(!partOfBlock){
        let newBlock = {
          guest_uid : thisMsg.guest_uid,
          blockList: [thisMsg]
        }
        lookup.push(newBlock);
        lastBlock = newBlock;
      }
    }
    
    return lookup;
  }
  
}

export default msgHelper;
