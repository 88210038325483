import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import c from '../../../util/const';
import filters from "../../../helpers/filters";
import colors from "../../../util/colors";
import UserBadge from "../badges/UserBadge";
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import {connect} from "react-redux";
import ColorGenerator from "../../../helpers/color-generator";
import sapi from "../../../util/sapi";
import {getMessageForError} from "../../../util/errors";
import _ from "lodash";
import utils from "../../../util/util";
import he from "he";
import classnames from "classnames";
import sharedActions from "../../../actions/shared-actions";
import ManageObjectPermissionsWindow from "../../modals/ManageObjectPermissionsWindow";
import log from "../../../util/log";
import Truncate from "react-truncate"
import { isMobile } from 'react-device-detect';
import ReactToPrint from "react-to-print";
import homeActions from "../../../actions/home-actions";
import { DropTarget } from 'react-dnd'
import UploadHelper from "../components/UploadHelper";
import DocAttach from "../../../models/DocAttach";
import {withVFTranslation} from "../../../util/withVFTranslation";

class MemberRow extends PureComponent {
  
  constructor(props){
    super(props);
    
    this.itemClick = this.itemClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.deleteMemberClick = this.deleteMemberClick.bind(this);
    
    this.state = {
      hovering : false,
    }
  }
  
  itemClick(evt){
    let {onItemClick, row, dm} = this.props;
    
    onItemClick(row, dm);
  }
  
  onMouseEnter(){
    if(this.state.hovering){
      return;
    }
    
    this.setState({
      hovering : true
    })
  }
  
  onMouseLeave(){
   this.setState({hovering : false})
  }
  
  deleteMemberClick(evt){
    log.log('delete member click', evt);
    this.props.onRemoveGuest(this.props.row)
  }

  render() {
    let { row, connectDropTarget, t } = this.props;
    let { hovering } = this.state;
    
    return (
      <div onClick={this.itemClick}
           onMouseEnter={this.onMouseEnter}
           onMouseLeave={this.onMouseLeave}
           className={classnames('d-flex flex-row member-row p-2')}>
        <div className="mr-2 d-table">
          <UserBadge guest={row}/>
        </div>
        <div className="d-flex flex-row item-row-wrap guest-row-wrap">
          <div className="flex-grow-1">
            <p className="mb-0 guest-label">
              {row.first_name} {row.last_name}
            </p>
            {row.is_pending &&
            <p className="guest-preview mb-0 secondary-text-color font-italic">
              <Truncate lines={2} ellipsis={t('...')}>
                {t("Pending Import...")}
              </Truncate>
            </p>
            }
            {!row.is_pending &&
            <p className="guest-preview mb-0 secondary-text-color">
              <Truncate lines={2} ellipsis={t('...')}>
                {row.email_address}
              </Truncate>
            </p>
            }
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  iconStyle : {
    height: '48px',
    lineHeight: '48px',
    minWidth: '30px',
    textAlign: 'center'
  },
  attachmentIcon : {
    marginRight: '5px',
    verticalAlign : 'baseline',
    fontSize : '16px'
  },
  
  //duplicated in WorkspaceRow...consolidate this.
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT,
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
}

MemberRow.propTypes = {
  row: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onRemoveGuest : PropTypes.func.isRequired,
}
const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    dmPreviewLookup : state.shared.dmPreviewLookup
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...workspaceActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),
    refreshActiveHomeDMMessages: () => dispatch(homeActions.refreshActiveDMMessages()),
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)((MemberRow)));
