import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import {withVFTranslation} from "../../util/withVFTranslation";

class ConfirmDialog extends Component {
  
  constructor(props) {
    super(props);
  
    this.state = {}
  }
  
  componentDidMount() {
    const {modalProps} = this.props;
    
    if(!modalProps.callback){
      throw Error('Showing confirm without callback');
    }
  }
  
  closeModal(result) {
    let {close} = this.props;
    close(result);
  }
  
  render() {
    let {modalProps, t} = this.props;
    
    let okText = modalProps.okBtn || t('OK');
    let cancelText = modalProps.cancelBtn || t('Cancel')
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{modalProps.title}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p>{modalProps.message}</p>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{cancelText}</Button>
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this, true)}>{okText}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

ConfirmDialog.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog));
