import browser from 'browser-detect';
import _ from 'lodash'

const BROWSERS = {
  CHROME : 'chrome',
  SAFARI : 'safari',
  FIREFOX : 'firefox',
  IE : 'ie',
  EDGE : 'edge'
}

let thisBrowser = null;

const init = () => {
  if(!thisBrowser){
    thisBrowser = browser();
  }
}

const wrap = {
  BROWSERS,
  getBrowser : () => {
    init();
    
    return thisBrowser;
  },
  
  isOneOf : (browsers) => {
    init();
    
    return _.indexOf(browsers, thisBrowser.name) >= 0;
  },
  
  isChrome : () => {
    init();
    
    return wrap.isOneOf([BROWSERS.CHROME]);
  },
  
  isSafari : () => {
    init();
    
    return wrap.isOneOf([BROWSERS.SAFARI]);
  },
  
  isFirefox : () => {
    init();
    
    return wrap.isOneOf([BROWSERS.FIREFOX]);
  },
  
  isIE : () => {
    init();
    
    return wrap.isOneOf([BROWSERS.IE]);
  },
  
  isIE11 : () => {
    init();
    
    return wrap.isIE() && thisBrowser.versionNumber === 11
  },
  
  isEdge : () => {
    init();
    
    return wrap.isOneOf([BROWSERS.EDGE]);
  },
  
}

export default wrap;