
import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import {withVFTranslation} from "../../util/withVFTranslation";

class AgreeToTermsDialog extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {}
  }
  
  componentDidMount() {
    const {modalProps} = this.props;
    
    if(!modalProps.callback){
      throw Error('Showing confirm without callback');
    }
  }
  
  closeModal(result) {
    let {close} = this.props;
    close(result);
  }
  
  render() {
    let {modalProps, t} = this.props;
    let { terms } = modalProps;
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Signing Terms")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p>{t("The person requesting your signature requires that you consent to the following terms:")}</p>
          <div className="m-5">
            <p className="font-italic">{terms}</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("No")}</Button>
          <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this, true)}>{t("Yes")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

AgreeToTermsDialog.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AgreeToTermsDialog));
