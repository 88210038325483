import React, {Component} from 'react';
import PropTypes from 'prop-types';
import c from "../../../util/const";
import {CSSTransition} from "react-transition-group";

class MaxChatMessageWarning extends Component {
  
  render() {
    let { textLength, isDM } = this.props;
    
    let colorCls = isDM ? 'dm' : 'thread';
    
    return (
      <CSSTransition in={textLength > c.limits.warnThreadMessage} timeout={250} unmountOnExit classNames={'fade'}>
        <div className="max-text-hint-wrap">
          <div className={`max-text-hint ${colorCls} ${textLength > c.limits.maxThreadMessage ? 'past-maximum' : ''}`}>
            {textLength} / {c.limits.maxThreadMessage}
          </div>
        </div>
      </CSSTransition>
    )
  }
}

MaxChatMessageWarning.propTypes = {
  textLength : PropTypes.number.isRequired,
  isDM : PropTypes.bool.isRequired
}

export default MaxChatMessageWarning;
