import React, {Component} from 'react';
import PropTypes from 'prop-types';
import c from "../../../util/const";
import {CSSTransition} from "react-transition-group";
import {BiSmile, BiWinkSmile, BiWinkTongue, BiAngry, BiDizzy, BiHappy, BiLaugh, BiMeh, BiMehAlt, BiShocked, BiTired, BiUpsideDown} from 'react-icons/bi'
import colors from "../../../util/colors";
import { Picker } from 'emoji-mart'
import log from "../../../util/log";
import _ from 'lodash';

class ChatEmojiMenu extends Component {
  
  faces = {
    0: BiSmile,
    1: BiWinkSmile,
    2: BiWinkTongue,
    3: BiAngry,
    4: BiDizzy,
    5: BiHappy,
    6: BiLaugh,
    7: BiMeh,
    8: BiMehAlt,
    9: BiShocked,
    10: BiTired,
    11: BiUpsideDown
  }
  
  constructor(props) {
    super(props);
    
    this.faceTimer = null;
    
    this.state = {
      menuOpen : false,
      faceIndex : 0,
      pollTimer : null,
      pollingRunning : false
    }
  }
  
  componentDidMount() {
    // this.mounted = true;
    // this.setState({pollingRunning : true}, () => this.runFaceTimer());
  }
  
  componentWillUnmount() {
    // if (this.state.pollTimer) {
    //   clearTimeout(this.state.pollTimer);
    // }
    // this.setState({
    //   pollingRunning: false,
    //   pollTimer: null
    // });
    // this.mounted = false;
  }
  
  runFaceTimer(){
    if(this.state.pollingRunning && this.mounted){
      let timer = setTimeout(() => {
        if(!this.state.pollingRunning || !this.mounted) {
          return;
        }
      
        // let nextIndex = _.random(0, _.keys(this.faces).length - 1, false);
        // log.log('nextFaceIndex', nextIndex);
        // this.setState({faceIndex : nextIndex})
  
        let nextIndex = 1;
        log.log('nextFaceIndex', nextIndex);
        this.setState({faceIndex : nextIndex})
        setTimeout(() => {
          this.setState({faceIndex : 0})
        }, 400)
      
        this.runFaceTimer();
      }, 10000);
      this.setState({pollTimer : timer})
    }
  }
  
  openMenuClick(){
    this.setState({menuOpen : !this.state.menuOpen})
  }
  
  onSelectEmoji(emoji){
    this.props.onEmojiSelect(emoji);
    
  }
  
  onClickOutsidePicker(){
    log.log('onClickOutsidePicker')
    this.setState({menuOpen : false})
  }
  
  render() {
    let { showMenu, isDM } = this.props;
    let { menuOpen, faceIndex } = this.state;
    //let colorCls = isDM ? 'dm' : 'thread';
    
    let FaceIcon = this.faces[faceIndex];
    
    return (
      <div className="position-relative">
        {menuOpen &&
        <>
          <div className="click-block" style={{zIndex: 100}} onClick={this.onClickOutsidePicker.bind(this)}/>
        </>
        }
        <CSSTransition in={menuOpen} timeout={150} unmountOnExit classNames={'fadeInUp'}>
          <Picker theme={'auto'}
                  native={true}
                  emoji={''}
                  onSelect={this.onSelectEmoji.bind(this)}
                  title={''}
                  enableFrequentEmojiSort={true}
                  showPreview={false}
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '20px',
                    zIndex: 101
                  }}/>
        </CSSTransition>
        <div style={{
          position: 'absolute',
          top: '-7px',
          left: '50%',
          marginLeft: '-9px', //half button width to center
          zIndex: '1000',
        }}>
          <div onClick={this.openMenuClick.bind(this)}
               onMouseOver={() => this.setState({faceIndex: 5})}
               onMouseLeave={() => this.setState({faceIndex: 0})}
               style={{
                 textAlign: 'center',
                 width: '18px',
                 maxHeight: '1rem',
                 backgroundColor: isDM ? colors.DARK : colors.PRIMARY,
                 color: colors.LIGHT,
                 borderBottomRightRadius: '9px',
                 borderBottomLeftRadius: '9px',
                 lineHeight: '1rem',
                 verticalAlign: 'middle',
                 fontSize: '14px',
                 cursor: 'pointer'
               }}>
            <FaceIcon />
          </div>
        </div>
      </div>
    )
  }
}

ChatEmojiMenu.propTypes = {
  onEmojiSelect : PropTypes.func.isRequired,
  isDM : PropTypes.bool.isRequired
}

export default ChatEmojiMenu;
