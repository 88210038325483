import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import UpgradeDialog from "./UpgradeDialog";
import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../util/withVFTranslation";

class NewWorkspaceDialog extends Component {
  
  constructor(props) {
    super(props);
    
    this.firstInputRef = React.createRef()
    
    this.state = {
      newName : '',
      validationErr : null
    }
  }
  
  componentDidMount() {
    setTimeout(() => {
      this.firstInputRef.current.focus();
    })
  }
  
  closeModal(res) {
    this.props.close(res);
  }
  
  doAddWorkspace(newName){
    let { t } = this.props;
    return sapi.Workspace.add(newName)
      .then((res) => {
        log.log('add new res', res);
        this.closeModal(res)
      })
      .catch((err) => {
        log.log('error adding workspace', err);
        if (err && err.name === 'APP_FORUM_MAX') {
          this.props.showUpgradeDialogOrError(UpgradeDialog.UPGRADE_TYPES.WORKSPACE, err, t, (res) => {
            log.log('showUpgradeDialogOrError res', res);
          })
        }
        else if(err) {
          this.setState({
            validationErr: getErrorMessage(err.name, t)
          })
        }
      })
  }
  
  submit(){
    let me = this;
    let {close} = this.props;
  
    this.setState({
      validationErr: null
    })
  
    if (this.doValidate()) {
      let {newName} = this.state;
  
      this.doAddWorkspace(newName);
    }
  }
  
  doValidate() {
    let { t } = this.props;
    let { newName } = this.state;
    
    if(!newName && newName.length === 0){
      this.setState({
        validationErr : t('Please enter a new name')
      })
      return false;
    }
   
    return true;
  }
  
  render() {
    let {modalProps, t} = this.props;
    let { labelText, placeholderText } = modalProps;
    let {newName, validationErr} = this.state;
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{modalProps.title}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{labelText}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={newName}
                       ref={this.firstInputRef}
                       onChange={(evt) => this.setState({newName: evt.target.value})}
                       placeholder={placeholderText} />
              </div>
            </div>
          </div>
          {validationErr && <ValidationErrors errors={[validationErr]} />}
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} onClick={this.submit.bind(this)}>{t("OK")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

NewWorkspaceDialog.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(NewWorkspaceDialog)));
