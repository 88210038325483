import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from '../components/App'
import configureStore from "../store";
import ErrorBoundary from "./partials/util/ErrorBoundary";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { IconContext } from "react-icons";
import * as Sentry from "@sentry/react";

const store = configureStore()

class Root extends Component {
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <IconContext.Provider value={{ className: "react-icons-icon" }}>
          <Provider store={store}>
            <BrowserRouter basename={'/login'}>
              <Sentry.ErrorBoundary>
                <ErrorBoundary>
                  <App/>
                </ErrorBoundary>
              </Sentry.ErrorBoundary>
            </BrowserRouter>
          </Provider>
        </IconContext.Provider>
      </DndProvider>
    )
  }
}

export default Root;
