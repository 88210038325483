import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";
import redirectHelper from '../../../util/redirect-helper';

import FancyInput from "../elements/FancyInput";
import PulseButton from '../elements/PulseButton';
import Button from "../elements/Button";
import authActions from "../../../actions/auth-actions";
import AuthCodeInput from "../elements/AuthCodeInput";
import PropTypes from "prop-types";
import cookieHelper from "../../../helpers/cookie-helper";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class TFAPwdResetCard extends Component {
  
  constructor(props){
    super(props);
    
    this.state = {
      pwd: '',
      pwd_cfm: '',
      codeValidationErr: null,
      pwdValidationErr: null,
      pwdCfmValidationErr: null,
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }
    
    setTimeout(() => {
      this.codeField.focus();
    }, 250)
  }
  
  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  validateForm() {
    let { t } = this.props;
    let { pwd, pwd_cfm, pwdValidationErr, pwdCfmValidationErr } = this.state;
    let code = this.codeField.assembleCode();
    
    this.props.setValidationErrors([]);
    this.setState({
      codeValidationErr: null,
      pwdValidationErr: null,
      pwdCfmValidationErr: null,
    });
    
    let success = true;
    if(!code || code.length !== 6){
      this.setState({
        codeValidationErr: true
      })
      this.codeField.triggerValidationErr();
      success = false;
    }
  
    if(!pwd || pwd.length === 0){
      this.setState({
        pwdValidationErr: true
      })
      this.pwdField.triggerValidationErr();
      success = false;
    }
  
    if(!pwd_cfm || pwd_cfm.length === 0){
      this.setState({
        pwdCfmValidationErr: true
      })
      this.pwdCfmField.triggerValidationErr();
      success = false;
    }
  
    if(!pwdValidationErr && !pwdCfmValidationErr && pwd && pwd_cfm && pwd !== pwd_cfm){
      this.props.setValidationErrors([
        t('Your passwords do not match')
      ])
      this.setState({
        pwdValidationErr: true,
        pwdCfmValidationErr: true,
      })
      success = false;
    }
    
    return success;
  }
  
  doNextButtonClicked(){
    let { pwd } = this.state;
    let { history, setAuth, email, qs, t } = this.props;
    let code = this.codeField.assembleCode();
    
    if(!this.validateForm()){
      return;
    }
    
    this.props.setLoading(true);
    
    api.PasswordResetConfirm.post(email, code, pwd)
      .then((res) => {
        setAuth(res.data.sid, res.data.vip, res.data.token);
        this.props.setLoading(false);
        cookieHelper.clearTrackingCookies();
        redirectHelper.redirectToApp(history, qs, res.data.vip);
      })
      .catch((err) => {
        log.log('error during tfa pwd reset', err);
  
        //This is a bad hack to work around a backend bug.
        //I don't know anything else about this bug, except that the existing extjs frontend has this same hack.
        if (err.name === "APP_REQ_INVALID") {
          err.name = "APP_TFA_FAIL";
        }
        this.props.setValidationErrors([
          getMessageForError(err, t)
        ])
        this.props.setLoading(false);
      })
  }
  
  onStartOverButtonClick() {
    let { setStep } = this.props;
    
    setStep(c.authSteps.password);
  }
  
  onPwdChange(val){
    this.setState ({
      pwd: val
    })
  }
  
  onPwdCfmChange(val){
    this.setState ({
      pwd_cfm: val
    })
  }
  
  render() {
    let { codeValidationErr, pwdValidationErr, pwdCfmValidationErr } = this.state;
    let { step, t } = this.props;
    return (
      <div>
        <div className={'row'}>
          <div className={'col'}>
            <AuthCodeInput onRef={ref => this.codeField = ref}
                           onEnter={this.doNextButtonClicked.bind(this)}
                           inputDisabled={false}/>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <FancyInput onRef={ref => (this.pwdField = ref)}
                          placeholder={t('New Password')}
                          inputDisabled={false}
                          fieldType={'password'}
                          isValid={!pwdValidationErr}
                          inputValue={this.state.pwd}
                          onEnter={this.doNextButtonClicked.bind(this)}
                          onChange={this.onPwdChange.bind(this)}/>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <FancyInput onRef={ref => (this.pwdCfmField = ref)}
                          placeholder={t('New Password (Confirm)')}
                          inputDisabled={false}
                          fieldType={'password'}
                          isValid={!pwdCfmValidationErr}
                          inputValue={this.state.pwd_cfm}
                          onEnter={this.doNextButtonClicked.bind(this)}
                          onChange={this.onPwdCfmChange.bind(this)}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TFAPwdResetCard.propTypes = {
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  isLoading : PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    email : state.auth.email,
    qs: state.app.qs,
    step : state.auth.step,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: step => dispatch(authActions.setNextStep(step)),
    setAuth: (sid, vip, token) => dispatch(authActions.setAuth(sid, vip, token))
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TFAPwdResetCard)));
