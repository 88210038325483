import c from '../../../util/const'
import React, {Component, Fragment} from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import filters from "../../../helpers/filters";
import Button from "../elements/Button";
import sapi from "../../../util/sapi";
import Image from "../elements/Image";
import Promise from "bluebird";
import PreviewWindow from "../../modals/PreviewWindow";
import _ from 'lodash'

import pdfjsLib from "pdfjs-dist/es5/build/pdf";
import log from "../../../util/log";
import PdfPage from "./PdfPage";
import Loading from "../util/Loading";
import {Waypoint} from "react-waypoint";
import GeneralTab from "../account/GeneralTab";
import PdfPreviewHeader from "./PdfPreviewHeader";
import Scroll from "react-scroll";
import PdfSigningOverlay from "./PdfSigningOverlay";
import PdfWritingSvc from "../../../helpers/pdf-writing-svc";
import PDFSignatureRequestHeader from "./PDFSignatureRequestHeader";
import PdfSignatureFulfillOverlay from "./PdfSignatureFulfillOverlay";
import {getMessageForError} from "../../../util/errors";
import SignatureRequest from "../../../models/SignatureRequest";
import sharedActions from "../../../actions/shared-actions";
import PdfSigningV1Header from "./PdfSigningV1Header";
import PdfSignatureRequestOverlay from "./PdfSignatureRequestOverlay";
import utilityActions from "../../../actions/utility-actions";
import colors from "../../../util/colors";
import ThumbnailPanel from "../doc-preview/ThumbnailPanel";
import utils from "../../../util/util";
import pdfPreviewActions from "../../../actions/pdf-preview-actions";
import browser from '../../../util/browser'
import {withRouter} from "react-router-dom";
import enums from "../../../util/enums";
import {withVFTranslation} from "../../../util/withVFTranslation";

class PdfPreview extends Component {
  
  static MODAL_SCROLL_CONTAINER = 'pdf-modal-scroll'
  
  signatureV1OverlayRefs = {};
  signatureV2OverlayRefs = {};
  
  overlayRefs = {};
  pdfPageCanvasLookup = {};
  
  INITIAL_STATE = {
    loading: true,
    visiblePages: {},
    scale: 1,
    disableFastScroll: false,
    refreshing: false,
    showingThumbnails : false,
    activePageIndex : 0,
    isUploading : false,
  }
  
  constructor(props) {
    super(props);
    
    this.sharedCanvasRef = React.createRef();
    this.scrollPanelRef = React.createRef();

    this.onPdfPreviewScroll = this.onPdfPreviewScroll.bind(this);
    props.pdfActions.initialize(props.forum_id, props.host_uid, props.doc_id, props.chat_id);
    
    this.state = _.extend({}, this.INITIAL_STATE);
  }

  componentDidMount() {
    
    //On IE 11, we can't use the onScroll react hook I guess.  Seems like it's something they could take care of
    //pretty easily behind the scenes, but I guess this is the prescribed workaround.  bug
    if(browser.isIE11()){
      window.addEventListener('scroll', this.onPdfPreviewScroll, true);
    }
    
    this.setState({loading: true})
    this.props.pdfActions.loadPdf()
      .then(() => {
        this.showFulfillSignatureRequestUIIfNeeded();
      })
      .catch((err) => {
        log.log('error loading pdf', err);
        this.props.pdfActions.setPdfJsLoadErr(err);
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }
  
  componentWillUnmount() {
    if(browser.isIE11()){
      window.removeEventListener('scroll', this.onPdfPreviewScroll, true);
    }
    
    this.signatureV1OverlayRefs = null;
    this.signatureV2OverlayRefs = null;
    this.pdfPageCanvasLookup = null;
    
    this.props.pdfActions.teardown();
  }
  
  //v2 stuff
  
  static sortV2OverlaysToPageIndex(overlays){
    let lookup = {};
    _.each(overlays, (o) => {
      if(!lookup[o.pageIndex]){
        lookup[o.pageIndex] = [o];
      }
      else{
        lookup[o.pageIndex].push(o);
      }
    })
    return lookup;
  }
  
  showTermsIfNeeded(signatureRequestData){
    if(signatureRequestData.terms) {
      return new Promise((resolve, reject) => {
        this.props.showAgreeToTerms(signatureRequestData.terms, (agreeRes) => {
          resolve(agreeRes);
        })
      })
    }
    else{
      return Promise.resolve(true);
    }
  }
  
  showSMSVerificationIfNeeded(signatureRequestData){
    log.log('show sms verification', signatureRequestData);
    if(signatureRequestData.signature_request.code_check_flag) {
      return new Promise((resolve, reject) => {
        this.props.showSMSVerification(signatureRequestData, (smsRes) => {
          resolve(smsRes);
        })
      })
    }
    else{
      return Promise.resolve(true);
    }
  }
  
  showSaveSignatureDialog(){
    return new Promise((resolve, reject) => {
      this.props.showAddSignatures((res) => {
        resolve(res);
      })
    })
  }
  
  showFulfillSignatureRequestUIIfNeeded(){
    let { t } = this.props;
    //Shows all prerequisite dialogs in order to fulfill a signing request.
    //cancelling from them closes the dialog.
    let {
      isFulfillingSignatureRequest,
      pdfWriterLoadErr,
    } = this.props.pdfState;
    
    if(!isFulfillingSignatureRequest){
      return;
    }
    
    if(pdfWriterLoadErr){
      this.props.pdfActions.setWindowSigningMode(enums.WINDOW_SIGNING_STATUS.NONE);
      this.props.pdfActions.setEditingSignatureRequest(false)
      this.props.showAlert(t('Unable to Sign Document'), t("There was a problem loading this file.  You can still preview it, but signing is disabled.  Saving this file in a PDF reader and re-uploading it may resolve the issue."));
      return;
    }
    
    this.showTermsIfNeeded(this.props.pdfState.signatureRequestData)
      .then((res) => {
        if(!res){
          return Promise.reject('cancel terms');
        }
        return this.showSMSVerificationIfNeeded(this.props.pdfState.signatureRequestData)
      })
      .then((res) => {
        if(!res){
          return Promise.reject('cancel sms verification');
        }
        return this.showSaveSignatureDialog()
      })
      .then((saveSigRes) => {
        if(!saveSigRes){
          return Promise.reject('cancel save sig dialog');
        }
        this.scrollToInitialOverlayIfNeeded();
      })
      .catch((err) => {
        log.log('signature request ui cancel', err);
        this.closeModal(false);
      })
  }
  
  scrollToInitialOverlayIfNeeded(){
    setTimeout(() => {
      let { isFulfillingSignatureRequest, signatureRequestOverlays } = this.props.pdfState;
      if(isFulfillingSignatureRequest) {
        let selectedOverlay = null;
        _.each(signatureRequestOverlays, (overlay) => {
          if (overlay.selected) {
            selectedOverlay = overlay;
          }
        })
        if (selectedOverlay) {
          this.scrollToConfirmOverlay(selectedOverlay)
        }
      }
    }, 500);
  }
  
  setV2SignatureOverlayRef(id, ref){
    if(this.signatureV2OverlayRefs) {
      this.signatureV2OverlayRefs[id] = ref;
    }
  }
  
  writeSignatureWatermarks(sign_request_id){
    let { t } = this.props;
    
    return this.props.generateWatermarkImg(t("Signed via Verifyle: ") + sign_request_id)
      .then((watermarkImg) => {
        return Promise.map(this.props.pdfState.pdfWriter.getAllPages(), (page, index) => {
          
          //I wish we didn't need to know about page rotation here.  We should really try and
          //keep this logic confined to the pdfWriter.
          //since we're hardcoding the watermark coords to bottom right, we need to know if there's a page rotation present.
          
          let pageRotation = page.getRotation();
          let flipIt = pageRotation.angle === 90 || pageRotation.angle === 270;
          
          let watermarkX = null;
          let watermarkY = null;
          if(flipIt){
            watermarkX = page.getHeight() - (watermarkImg.width);
            watermarkY = page.getWidth() - (watermarkImg.height);
          }
          else{
            watermarkX = page.getWidth() - (watermarkImg.width);
            watermarkY = page.getHeight() - (watermarkImg.height);
          }
          
          return this.props.pdfState.pdfWriter.drawImage(index, watermarkX, watermarkY, 1, watermarkImg.width, watermarkImg.height, watermarkImg.data)
        })
      })
      .then(() => {
        log.log('watermarks written successfully');
      })
      .catch((err) => {
        log.error('error writing watermarks', err);
      })
  }
  
  commitFulfilledSignatureRequests(){
    let { scale} = this.state;
    let { signatureRequestOverlays, signatureRequestData } = this.props.pdfState;
    
    return this.writeSignatureWatermarks(signatureRequestData.status.sign_request_id)
      .then(() => {
        return Promise.map(signatureRequestOverlays, (overlay) => {
          let ref = this.signatureV2OverlayRefs[overlay.id];
          
          if (overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE || overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.INITIALS) {
            return ref.generateOverlayImage()
              .then((img) => {
                //Should make this more clear, this adjusts for the overlay button.
                let overlayX = ref.scaleRelativeToOverlay(overlay.coords.x) + ref.scaleRelativeToOverlay(PdfSignatureFulfillOverlay.LEFT_BUTTON_WIDTH);
                let overlayY = ref.scaleRelativeToOverlay(overlay.coords.y);
                // log.log('writing image point', overlay.pageIndex, overlayX, overlayY, scale, img.width, img.height);
                return this.props.pdfState.pdfWriter.drawImage(overlay.pageIndex, overlayX, overlayY, scale, img.width, img.height, img.data)
              })
          }
          else {
            let res = ref.getOverlayDataResult();
            let overlayX = ref.scaleRelativeToOverlay(res.x);
            let overlayY = ref.scaleRelativeToOverlay(res.y);
            // log.log('writing overlay data', overlay.pageIndex, overlayX, overlayY, scale, res.text, res.font, res.fontSize);
            return this.props.pdfState.pdfWriter.drawText(overlay.pageIndex, overlayX, overlayY, scale, res.text, res.font, res.fontSize);
          }
        })
      })
  }
  
  saveFulfilledSignatureRequests(){
  
    //uncomment this to refresh v2 signatures in place
    // this.commitFulfilledSignatureRequests()
    //   .then(() => {
    //     return this.refreshFromPdfData();
    //   })
    // return;
    
    let {
      forum_id,
      host_uid,
      doc_id,
      doc_info,
      chat_id,
      refreshWorkspace,
      refreshDocs,
      t
    } = this.props;
    
    let {
      signatureRequestData
    } = this.props.pdfState;
    
    let suggestedName = utils.makeSuggestedFilename(doc_info.label, true);
    
    this.props.showSubmitSignedDoc(t("Submit Signed Document"), suggestedName, true, (res) => {
      if(res){
        let {filename, mesg} = res;
        
        this.setState({isUploading: true})
        this.commitFulfilledSignatureRequests()
          .then((res) => {
            return this.props.pdfState.pdfWriter.getUintPdfData()
          })
          .then((docData) => {
            if(signatureRequestData.dm_guest_uid){
              let last_forum_id = this.props.workspace ? this.props.workspace.forum_id : null;
              return sapi.DM.uploadXhr(signatureRequestData.dm_guest_uid, doc_id, filename, signatureRequestData.status.sign_request_id, mesg, docData, last_forum_id)
            }
            else{
              if (!chat_id) {
                return sapi.Docs.uploadXhr(forum_id, host_uid, doc_id, filename, docData);
              }
              else {
                return sapi.Threads.uploadXhr(forum_id, host_uid, doc_id, filename, chat_id, signatureRequestData.status.sign_request_id, mesg, docData);
              }
            }
          })
          .then((res) => {
            log.log('upload finished', res);
            
            this.props.updateNotifications();
            if(signatureRequestData.dm_guest_uid){
              this.props.updateDMPreviews([signatureRequestData.dm_guest_uid]);
              return this.props.updateDirectMessages();
            }
            else{
              return Promise.all([
                refreshWorkspace(forum_id),
                refreshDocs(forum_id, host_uid)
              ])
            }
          })
          .then((res) => {
            this.setState({isUploading: false})
            this.closeModal();
          })
          .catch((err) => {
            log.error('error saving signature requests', err);
            this.props.showAlert(t('Unable to save'), this.getErrorMessagePDFSaveSig());
            this.setState({isUploading: false})
          })
      }
      else{
        //canceling the submit dialog closes the window and resets the interaction.
        this.closeModal();
      }
    });
  }
  
  cancelSignatureRequestClick(){
    let { t } = this.props;
    this.props.showConfirm(t("Are you sure?"), t("Are you sure you want to cancel this signature request?"), (res) => {
      if(res){
        let { signatureRequestData } = this.props.pdfState;
        
        let sign_request_id = _.get(signatureRequestData, 'status.sign_request_id');
        if(!sign_request_id){
          this.closeModal();
          return;
        }
        let promise = null;
        if(signatureRequestData.dm_guest_uid){
          promise = sapi.DM.deleteSignatureRequest(sign_request_id, signatureRequestData.dm_guest_uid)
        }
        else{
          promise = sapi.Workspace.deleteSignatureRequest(sign_request_id)
        }
        
        promise
          .then((res) => {
            this.closeModal();
          })
          .catch((err) => {
            log.error('error deleting signature request', err);
            this.props.showAlert(t("Unable to delete signature request"), getMessageForError(err, t));
          })
      }
    }, t('Yes'), t('No'))
  }
  
  submitSignatureRequestClick(){
    let { t } = this.props;
    this.props.pdfActions.saveSignatureRequest()
      .then((res) => {
        log.log('add signature request', res);
        this.props.close(true);
      })
      .catch((err) => {
        log.error('error saving signature request', err);
        this.props.showAlert(t('Error saving signature request'), getMessageForError(err, t))
      })
  }
  
  editSignatureRequestClick(){
    this.props.pdfActions.setEditingSignatureRequest(true);
  }
  
  cancelEditSignatureRequest(){
    this.closeModal();
  }
  
  addSignatureRequestOverlay(newOverlay){
    let { signatureRequestOverlays } = this.props.pdfState;
    let overlayUpdate = _.concat([newOverlay], signatureRequestOverlays);
    
    this.props.pdfActions.updateSignatureRequestOverlays(overlayUpdate);
  }
  
  updateSignatureRequestOverlay(overlayUpdate){
    let { signatureRequestOverlays } = this.props.pdfState;
    let overlays = _.concat([], signatureRequestOverlays);
    
    for(let i = 0; i < overlays.length; i++){
      let overlay = overlays[i];
      if(overlay.id === overlayUpdate.id){
        overlay.coords = overlayUpdate.coords || overlay.coords;
        overlay.signatureType = overlayUpdate.signatureType || overlay.signatureType;
        overlay.signatureCustomLabel = _.has(overlayUpdate, 'signatureCustomLabel') ? overlayUpdate.signatureCustomLabel : overlay.signatureCustomLabel;
        
        i = overlays.length;
      }
    }
  
    this.props.pdfActions.updateSignatureRequestOverlays(overlays);
  }
  
  deleteSignatureRequestOverlay(id){
    let { signatureRequestOverlays } = this.props.pdfState;
    let overlays = _.concat([], signatureRequestOverlays);
    
    _.remove(overlays, (o) => {
      return o.id === id
    })
  
    this.props.pdfActions.updateSignatureRequestOverlays(overlays);
  }
  
  getSignatureRequestHasChanges(){
    let {
      doesSignatureRequestExist,
      signatureRequestData,
      signatureRequestOverlays
    } = this.props.pdfState;
    
    if(!doesSignatureRequestExist){
      return signatureRequestOverlays.length > 0;
    }
    else{
      
      let sign_data = signatureRequestData.signature_request.sign_data;
      if(signatureRequestOverlays.length !== sign_data.signatures.length){
        return true;
      }
      
      let hasChanges = false;
      _.each(signatureRequestOverlays, (overlay) => {
        let found = _.find(sign_data.signatures, (existingSig) => {
          return overlay.x === existingSig.x &&
            overlay.y === existingSig.y &&
            overlay.signatureType === existingSig.signatureType;
        })
        
        if(!found){
          hasChanges = true;
          return false; //break loop
        }
      })
      return hasChanges;
    }
  }
  
  signatureRequestOverlaySelected(id){
    let { signatureRequestOverlays } = this.props.pdfState;
    let overlays = _.concat([], signatureRequestOverlays);
    _.each(overlays, (overlay) => {
      if(overlay.id === id){
        overlay.selected = true;
      }
      else{
        overlay.selected = false;
      }
    })
    this.props.pdfActions.updateSignatureRequestOverlays(overlays);
  }
  
  signatureRequestOverlayConfirmed(id){
    let { signatureRequestOverlays } = this.props.pdfState;
    let overlays = _.concat([], signatureRequestOverlays);
    _.each(overlays, (overlay) => {
      if(overlay.id === id){
        overlay.confirmed = true;
      }
    })
    
    let nextOverlayToSelect = null;
    log.log('on overlay confirm', id, overlays);
    _.each(overlays, (overlay) => {
      if(!overlay.confirmed){
        if(!nextOverlayToSelect){
          nextOverlayToSelect = overlay;
        }
        if(overlay.pageIndex < nextOverlayToSelect.pageIndex) {
          nextOverlayToSelect = overlay;
        }
        else if (overlay.pageIndex === nextOverlayToSelect.pageIndex) {
          let oRef = this.signatureV2OverlayRefs[overlay.id];
          let nRef = this.signatureV2OverlayRefs[nextOverlayToSelect.id];
          let scaledOverlayY = oRef.scaleRelativeToOverlay(overlay.coords.y);
          let scaledNextOverlayY = nRef.scaleRelativeToOverlay(nextOverlayToSelect.coords.y);
          if(scaledOverlayY < scaledNextOverlayY){
            nextOverlayToSelect = overlay;
          }
        }
      }
    })
    
    let confirmCount = 0;
    _.each(overlays, (overlay) => {
      if(nextOverlayToSelect && overlay.id === nextOverlayToSelect.id){
        overlay.selected = true;
      }
      else{
        overlay.selected = false;
      }
      if(overlay.confirmed){
        confirmCount++;
      }
    })
    
    if(nextOverlayToSelect){
      this.scrollToConfirmOverlay(nextOverlayToSelect)
    }
    
    log.log('confirm update', overlays, confirmCount);
    
    this.props.pdfActions.updateSignatureRequestOverlays(overlays, confirmCount);
    
    if(confirmCount === overlays.length){
      this.nextButtonClick();
    }
  }
  
  scrollToConfirmOverlay(overlay){
    let ref = this.signatureV2OverlayRefs[overlay.id];
    Scroll.scroller.scrollTo('pdf-page-' + overlay.pageIndex, {
      duration: 300,
      smooth: true,
      offset: (ref.scaleRelativeToOverlay(overlay.coords.y) - 100),
      containerId: PdfPreview.MODAL_SCROLL_CONTAINER,
    })
    
    //You could restore the scale if you wanted to, but it can be pretty jarring.
    // setTimeout(() => {
    //   this.setState({scale: overlay.scale})
    // })
  }
  
  
  //v1 stuff
  
  warnAboutZoomIfNeeded() {
    let { t } = this.props;
    if (!this.hasValidSignatures(this.props.pdfState.signatureIds, this.props.pdfState.invalidOverlays)) {
      return Promise.resolve(true);
    }
  
    return new Promise((resolve, reject) => {
      this.props.showConfirm(
        t('Are you sure'),
        t("If you change the zoom level, we will commit the signatures to the page in order to preserve their locations.  This will not save your document, but you will not be able to change your signatures.  Are you sure?"),
        (res) => {
          resolve(res);
        })
    })
  }
  
  warnAboutPrintIfNeeded() {
    let { t } = this.props;
    if (!this.hasValidSignatures(this.props.pdfState.signatureIds, this.props.pdfState.invalidOverlays)) {
      return Promise.resolve(true);
    }
  
    return new Promise((resolve, reject) => {
      this.props.showConfirm(
        t('Are you sure'),
        t("If you want to print, we will commit the signatures to the page in order to preserve their locations.  This will not save your document, but you will not be able to change your signatures.  Are you sure?"),
        (res) => {
          resolve(res);
        })
    })
  }
  
  hasValidSignatures(inputSignatureIds, inputInvalidOverlays) {
    
    let signatureIds = inputSignatureIds || [];
    let invalidOverlays = inputInvalidOverlays || [];
    
    if (signatureIds.length === 0) {
      return false;
    }
    else if (signatureIds.length === invalidOverlays.length) {
      return false;
    }
    else{
      let foundInvalid = false;
      _.each(signatureIds, (id) => {
        
        let ref = this.signatureV1OverlayRefs[id];
        if(!ref){
          foundInvalid = true;
          return false;
        }
        
        let hasText = this.signatureV1OverlayRefs[id].hasSignatureText();
        if(!hasText){
          foundInvalid = true;
          return false;
        }
      })
      
      return !foundInvalid;
    }
  }
  
  addSignatureOverlay() {
    let {signatureIds, activeOverlays} = this.props.pdfState;
    let newOverlayId = _.uniqueId('vf-sig-overlay-');
    let sigIds = _.concat([], signatureIds);
    sigIds.push(newOverlayId);
    let activeIds = _.concat([], activeOverlays);
    activeIds.push(newOverlayId);
    
    this.props.pdfActions.updateV1SigningOverlays(sigIds);
    this.props.pdfActions.updateV1ActiveOverlays(activeIds);
  }
  
  setActiveOverlay(id, isActive) {
    let {activeOverlays} = this.props.pdfState;
    
    if (isActive) {
      let activeIds = _.concat([], activeOverlays);
      activeIds.push(id);
      this.props.pdfActions.updateV1ActiveOverlays(activeIds);
    }
    else {
      let activeIds = _.concat([], activeOverlays);
      _.remove(activeIds, (curId) => {
        return curId === id
      })
      this.props.pdfActions.updateV1ActiveOverlays(activeIds);
    }
  }
  
  setV1SignatureOverlayRef(id, ref){
    if(this.signatureV1OverlayRefs){
      this.signatureV1OverlayRefs[id] = ref;
      
      if(ref){
        //Initialize the new overlay position on ref.
        //Pass in a list of other overlay positions to help pick the position
        let overlayPositions = [];
        _.each(this.props.pdfState.signatureIds, (sigId) => {
          if(sigId !== id){
            let position = this.signatureV1OverlayRefs[sigId].getControlledPosition();
            overlayPositions.push({
              signature_id : sigId,
              x : position.x,
              y : position.y
            })
          }
        })
        this.signatureV1OverlayRefs[id].initializePosition(overlayPositions);
      }
    }
  }
  
  deleteSignature(id) {
    let {signatureIds} = this.props.pdfState;
    
    let sigIds = _.concat([], signatureIds);
    _.remove(sigIds, (sigId) => {
      return id === sigId;
    })
    this.props.pdfActions.updateV1SigningOverlays(sigIds);
    this.props.pdfActions.updateHasValidV1Signing(this.hasValidSignatures(sigIds, this.props.pdfState.invalidOverlays))
  }
  
  validateOverlay(id) {
    let {invalidOverlays} = this.props.pdfState;
    
    let sigData = this.signatureV1OverlayRefs[id].getOverlayValues();
    let mappedCoords = this.mapCoordinatesToCanvas(sigData.signaturePosition.x, sigData.signaturePosition.y);
  
    let invalidOverlayUpdate = _.concat([], invalidOverlays);
    if (mappedCoords) {
      if (_.indexOf(invalidOverlayUpdate, id) >= 0) {
        _.remove(invalidOverlayUpdate, (sig) => {
          return sig === id
        })
        this.props.pdfActions.updateV1InactiveOverlays(invalidOverlayUpdate);
      }
    }
    else if (!mappedCoords) {
      if (_.indexOf(invalidOverlays, id) < 0) {
        invalidOverlayUpdate.push(id);
        this.props.pdfActions.updateV1InactiveOverlays(invalidOverlayUpdate);
      }
    }
  
    this.props.pdfActions.updateHasValidV1Signing(this.hasValidSignatures(this.props.pdfState.signatureIds, invalidOverlayUpdate))
  }
  
  commitSignatures() {
    let {scale} = this.state;
    let {
      signatureIds
    } = this.props.pdfState;
    let signatures = [];
    _.each(signatureIds, (id) => {
      let sigData = this.signatureV1OverlayRefs[id].getOverlayValues();
      
      let mappedCoords = this.mapCoordinatesToCanvas(sigData.signaturePosition.x, sigData.signaturePosition.y);
      if (mappedCoords) {
        signatures.push({...mappedCoords, ...sigData, scale});
      }
    })
    
    let promises = [];
    _.each(signatures, (sig) => {
      promises.push(this.props.pdfState.pdfWriter.drawSignature(sig))
    })
    
    return Promise.all(promises)
      .then(() => {
        if (signatures.length > 0) {
          this.props.pdfActions.updateHasCommittedV1Signatures(true);
        }
      })
  }
  
  saveSignatures() {
    let {
      forum_id,
      host_uid,
      doc_id,
      doc_info,
      chat_id,
      dm,
      thread,
      refreshWorkspace,
      refreshDocs,
      t
    } = this.props;
    
    //uncomment this to just commit and refresh the page in place on save.
    // this.commitSignatures()
    //   .then(() => {
    //     return this.refreshFromPdfData();
    //   })
    // return;
    
    let suggestedName = utils.makeSuggestedFilename(doc_info.label);
    this.props.showPdfSubmit(t('Confirm your pdf submission'), suggestedName, thread, dm, (res) => {
      if (res) {
        let {filename, mesg} = res;
        
        this.setState({isUploading: true})
        this.commitSignatures()
          .then((res) => {
            
            this.props.pdfActions.resetV1Signing();
            
            return this.props.pdfState.pdfWriter.getUintPdfData()
          })
          .then((docData) => {
            
            if(chat_id){
              return sapi.Threads.uploadXhr(forum_id, host_uid, doc_id, filename, chat_id, null, mesg, docData);
            }
            else if(dm){
              let last_forum_id = this.props.workspace ? this.props.workspace.forum_id : null;
              return sapi.DM.uploadXhr(dm.guest_uid, doc_id, filename, null, mesg, docData, last_forum_id);
            }
            else{
              return sapi.Docs.uploadXhr(forum_id, host_uid, doc_id, filename, docData);
            }
          })
          .then((res) => {
            log.log('upload finished', res);
            
            this.props.updateNotifications();
            if(dm){
              this.props.updateDMPreviews([dm.guest_uid]);
              return this.props.updateDirectMessages();
            }
            else{
              return Promise.all([
                refreshWorkspace(forum_id),
                refreshDocs(forum_id, host_uid)
              ])
            }
          })
          .then((res) => {
            log.log('refresh finished', res);
            this.setState({isUploading: false})
            this.closeModal();
          })
          .catch((err) => {
            log.error('error submitting pdf', err);
            this.props.showAlert(t('Unable to save'), this.getErrorMessagePDFSaveSig());
            this.setState({isUploading: false})
          })
      }
    })
  }
  
  cancelV1SigningClick() {
    let {
      hasCommittedSignatures,
      signatureIds,
      invalidOverlays,
      pdfChangesMade
    } = this.props.pdfState;
    let { t } = this.props;
    new Promise((resolve, reject) => {
      if (hasCommittedSignatures || signatureIds.length > 0 && invalidOverlays.length > 0 || pdfChangesMade) {
        this.props.showConfirm(t('Are you sure?'), t("Are you sure you want to cancel without saving and uploading?  Your changes will be lost."), (res) => {
          if (res) {
            resolve(true);
          }
          else {
            resolve(false);
          }
        }, t('Yes'), t('No'));
      }
      else {
        resolve(true);
      }
    })
      .then((res) => {
        if (res) {
          if (pdfChangesMade) {
            this.props.pdfActions.loadPdf()
              .then(() => {
                this.refreshVisiblePageCache()
                this.state.thumbnailPanelRef.resetThumbnailCache();
              })
          }
          this.props.pdfActions.setWindowSigningMode(enums.WINDOW_SIGNING_STATUS.NONE)
          this.props.pdfActions.resetV1Signing();
        }
      })
  }
  
  deletePageClick(index){
    let {signatureIds} = this.props.pdfState;
    let deletingSignatures = [];
    _.each(signatureIds, (id) => {
      let sigData = this.signatureV1OverlayRefs[id].getOverlayValues();
      let mappedPageIndex = this.mapToPageIndexByVerticalCoordinates(sigData.signaturePosition.y);
      if(mappedPageIndex >= 0 && mappedPageIndex === index){
        deletingSignatures.push(id);
      }
    })
    log.log('signatures on deleted page', deletingSignatures);
    _.each(deletingSignatures, (sid) => {
      this.deleteSignature(sid);
    })
    
    let update = _.extend({}, this.state.visiblePages);
    delete update[index];
    
    //Weird edge case here.  We use Waypoints to determine when pages go in and out of focus.
    //This works fine for more scroll cases, and delete cases too, because if you delete a page
    //we re-render the whole doc which renders the waypoints too and the state gets reset correctly.
    //If you delete the bottom page, it might not trigger any breakpoints because there are no pages below it,
    //which means there's nowhere to scroll after the delete, meaning no updates from waypoints.
    //This have catches this case, and forces the 3 previous pages in the pdf to be visible.
    if(this.props.pdfState.pages.length - 1 === index) {
      let pagesToMarkVisible = Math.min(this.props.pdfState.pages.length, 3);
      for(let i = 1; i <= pagesToMarkVisible; i++){
        update[index - i] = true;
      }
      this.setState({ visiblePages: update })
    }
    else{
      if(index > 0){
        update[index - 1] = true;
      }
      update[index] = true;
      this.setState({ visiblePages: update })
    }
    
    return this.props.pdfState.pdfWriter.deletePage(index)
      .then((res) => {
        
        return this.reloadPdfData();
      })
      .then(() => {
        this.setVisiblePage(Math.max(0, index - 1))
        this.props.pdfActions.updateV1HasPdfChanges(true)
      })
  }
  
  onPageMove(fromIndex, toIndex){
    log.log('onPageMove', fromIndex, toIndex);
    let pageHeightsBeforeMove = [];
    _.each(this.props.pdfState.pdfWriter.getAllPages(), (page) => {
      //account for margins on top and bottom
      pageHeightsBeforeMove.push(page.getHeight() + (2 * PdfPage.CANVAS_MARGIN));
    })
    
    let pageHeightsAfterMove = _.concat([], pageHeightsBeforeMove);
    let fromIndexHeight = pageHeightsAfterMove[fromIndex];
    pageHeightsAfterMove[fromIndex] = pageHeightsAfterMove[toIndex];
    pageHeightsAfterMove[toIndex] = fromIndexHeight;
    
    let {signatureIds} = this.props.pdfState;
    _.each(signatureIds, (id) => {
      let sigData = this.signatureV1OverlayRefs[id].getOverlayValues();
      let mappedIndex = this.mapToPageIndexByVerticalCoordinates(sigData.signaturePosition.y);
      
      let newIndex = mappedIndex;
      if(fromIndex === newIndex){
        newIndex = toIndex;
      }
      else if(toIndex === newIndex){
        newIndex += 1;
      }
      else{
        if(fromIndex < newIndex){
          newIndex -= 1;
        }
        if(toIndex <= newIndex){
          newIndex += 1;
        }
      }
      
      //the mapped coords are pre-move.
      let preMoveHeightAbove = 0;
      let postMoveHeightAbove = 0;
      for(let i = 0; i < mappedIndex; i++){
        preMoveHeightAbove += pageHeightsBeforeMove[i];
      }
      for(let i = 0; i < newIndex; i++){
        postMoveHeightAbove += pageHeightsAfterMove[i];
      }
      
      let heightChange = 0;
      if(preMoveHeightAbove < postMoveHeightAbove){
        heightChange = postMoveHeightAbove - preMoveHeightAbove;
      }
      else{
        heightChange = -(preMoveHeightAbove - postMoveHeightAbove);
      }
      
      let currentControlledPosition = this.signatureV1OverlayRefs[id].getControlledPosition();
      log.log('updating controlled height', currentControlledPosition.y, currentControlledPosition.y + heightChange);
      this.signatureV1OverlayRefs[id].setControlledPosition(currentControlledPosition.x, currentControlledPosition.y + heightChange)
      
      log.log('move heights for signature', mappedIndex, newIndex, preMoveHeightAbove, postMoveHeightAbove)
    })
    
    return this.props.pdfState.pdfWriter.movePage(fromIndex, toIndex)
      .then((res) => {
        return this.reloadPdfData();
      })
      .then(() => {
        this.setVisiblePage(toIndex)
        this.props.pdfActions.updateV1HasPdfChanges(true)
      })
  }
  
  
  //shared
  
  getErrorMessagePDFSaveSig(){
    let { t } = this.props;
    return t("There was a problem saving your changes to this file.  Saving the file in a PDF reader and re-uploading it may resolve the issue.")
  }
  
  onPdfPreviewScroll =_.debounce(() => {
    this.pickMostVisiblePage();
  }, 25)
  
  pickMostVisiblePage(){
    let scrollWindow = _.get(this, 'scrollPanelRef.current');
    if(!scrollWindow){
      return;
    }
    
    let L = scrollWindow.scrollHeight;
    let K = scrollWindow.scrollTop;
    let V = scrollWindow.offsetHeight;

    if(L === 0 || L === V){
      //If scrollHeight is zero, that's an invalid state.  I guess just return
      //If the scrollHeight is equal to the offsetHeight, there's only one page, so just return it
      //We have to guard against this because they could lead to divide by zero errors below.
      this.setState({ activePageIndex : 0 })
      return;
    }
    
    //This is a formula matt and I worked out (mostly matt) that calculates an active page within the scroll area.
    //To accomodate lots of differently sized pages in a pdf at different zoom levels
    //We can't just pick the center scroll view and call it the active page, or pages at top and bottom might
    //never be selectable.  So instead we shift the active page position within the scroll window using this formula
    //so that at the top of the pdf the active page is based off a point higher in the top half of the pdf
    //and lower in the bottom half, eventually scaling to 0px and the full document height on the other end.
    let midPoint = K + (V / 2);
    let S = K - ((L / 2) - (V / 2));
    let delta = S * (L / (L - V));
    midPoint = midPoint + ((delta / L) * V);
    //log.log('found midpoint', midPoint,  (delta / L));
    
    let bestChoice = this.findNearestPageIndex(midPoint);
    this.setState({ activePageIndex : +bestChoice })
  }
  
  findNearestPageIndex(y) {
    
    let {pages} = this.props.pdfState;
    
    if(!pages || pages.length === 0){
      return 0;
    }
    
    let foundIndex = null;
    _.each(pages, (page, i) => {
      let rect = this.pdfPageCanvasLookup[page.pageIndex].getPagePositionInScroll();
      
      if (rect.top <= y && y <= rect.bottom) {
        // log.log('found scroll match', page.pageIndex, rect);
        //found a match
        foundIndex = page.pageIndex
        return false;
      }
      else if (rect.bottom > y) {
        // log.log('below page', page.pageIndex)
        //Then there's no direct page hit, and we need to compare the pages above and below to see which is closer.
        let nextPage = this.pdfPageCanvasLookup[page.pageIndex];
        if(nextPage){
          let nextRect = nextPage.getCanvasDimensions();
          
          
          let topPageDistance = rect.bottom - y;
          let nextPageDistance = y - nextRect.top;
          
          //Prefer top page if there's a tie.
          if(topPageDistance <= nextPageDistance){
            // log.log('no scroll match, closer to page', page.pageIndex);
            foundIndex = page.pageIndex;
          }
          else{
            // log.log('no scroll match, closer to page', page.pageIndex + 1);
            foundIndex = page.pageIndex + 1;
          }
        }
        else{
          // log.log('no scroll match, at bottom of pdf', page.pageIndex);
          //no next page, return the previous
          foundIndex = page.pageIndex;
        }
        
        return false;
      }
    })
    
    //I've seen the scroll calculations be like < 1px off in some scenarios (IE mostly)
    //Since we guard against this situation at the top of the document, I'm just going to say that
    //If we've looped through all pages and don't have a result yet we should return the bottom page.
    //This function should always return a result.
    //bug 2626
    if(foundIndex === null){
      foundIndex = pages[pages.length - 1].pageIndex;
    }
    
    return foundIndex;
  }
  
  onPageLeave(index) {
    log.log('onPageLeave', index);
    
    let visiblePages = _.extend({}, this.state.visiblePages);
    delete visiblePages[index];
    this.setState({
      visiblePages
    })
  }
  
  onPageEnter(index) {
    log.log('onPageEnter', index)
    let visiblePages = _.extend({}, this.state.visiblePages);
    visiblePages[index] = true;
    this.setState({
      visiblePages
    })
  }
  
  closeModal(res) {
    let {
      hasCommittedSignatures,
      signatureIds,
      invalidOverlays,
      pdfChangesMade
    } = this.props.pdfState;
    let { t } = this.props;
    
    if (hasCommittedSignatures || signatureIds.length > 0 && invalidOverlays.length > 0 || pdfChangesMade) {
      this.props.showConfirm(
        t('Are you sure?'),
        t("Are you sure you want to close this document without saving and uploading?  Your changes will be lost."),
        (res) => {
          if (res) {
            this.props.close();
          }
        }, t('Yes'), t('No'));
    }
    else {
      this.props.close(res);
    }
  }
  
  setScale(scale) {
    let { t } = this.props;
    this.warnAboutZoomIfNeeded()
      .then((res) => {
        if (res) {
          if (!this.hasValidSignatures(this.props.pdfState.signatureIds, this.props.pdfState.invalidOverlays)) {
            this.setState({scale: scale.option})
          }
          else {
            this.commitSignatures()
              .then(() => {
                this.props.pdfActions.updateV1ActiveOverlays([]);
                this.props.pdfActions.updateV1InactiveOverlays([]);
                this.props.pdfActions.updateV1SigningOverlays([]);
                return this.refreshFromPdfData();
              })
              .then(() => {
                this.setState({scale: scale.option})
              })
              .catch((err) => {
                log.warn('error writing signatures', err);
                this.props.showAlert(t('Unable to save'), this.getErrorMessagePDFSaveSig());
              })
          }
        }
      })
  }
  
  setVisiblePage(index, preventAnimation) {
    log.log('setting visible page', index);
    
    if (preventAnimation) {
      //In order to prevent performance problems, we stop listening to scroll events
      //during a fast scroll, because otherwise it will render and unrender all the pages in between.
      //this is also some kind of annoying state gymnastics to deal with here.
      //There's probably a better way?
      this.setState({
        disableFastScroll: true
      }, () => {
        setTimeout(() => {
          Scroll.scroller.scrollTo('pdf-page-' + index, {
            offset: 1,
            containerId: PdfPreview.MODAL_SCROLL_CONTAINER,
          })
          
          setTimeout(() => {
            this.setState({
              disableFastScroll: false
            })
          })
        })
      })
      
      return 0;
    }
    else {
      let scrollDuration = 300;
      Scroll.scroller.scrollTo('pdf-page-' + index, {
        duration: scrollDuration,
        smooth: true,
        offset: 1,
        containerId: PdfPreview.MODAL_SCROLL_CONTAINER,
      })
      return scrollDuration;
    }
  }
  
  initiateV1Signing(){
    this.props.pdfActions.setWindowSigningMode(enums.WINDOW_SIGNING_STATUS.V1_SIGNING)
    if(!this.state.showingThumbnails){
      this.toggleThumbnailPanel();
    }
  }
  
  onInitiateSignatureRequestClick(){
    this.props.onInitiateSignatureRequest();
  }
  
  setPageCanvasRef(id, ref) {
    if (this.pdfPageCanvasLookup) {
      this.pdfPageCanvasLookup[id] = ref;
    }
  }
  
  mapToPageIndexByVerticalCoordinates(y){
    let {pages} = this.props.pdfState;
  
    let found = null;
    _.each(pages, (page) => {
      let rect = this.pdfPageCanvasLookup[page.pageIndex].getCanvasDimensions();
    
      if (rect.top <= y && y <= rect.bottom) {
        found = page.pageIndex;
        return false;
      }
      else if (rect.bottom > y) {
        //early exit, since we traverse these pages in order from top to bottom.
        found = page.pageIndex - 1;
        return false;
      }
    })
  
    return found;
  }
  
  mapCoordinatesToCanvas(x, y) {
    
    let {pages} = this.props.pdfState;
    
    let found = null;
    _.each(pages, (page) => {
      let rect = this.pdfPageCanvasLookup[page.pageIndex].getCanvasDimensions();
      
      if (rect.top <= y && y <= rect.bottom) {
        if (rect.left <= x && x <= rect.right) {
          
          //log.log('map coords to canvas', page.pageIndex, rect);
          //found a match
          found = {
            x: x - rect.left,
            y: y - rect.top,
            pageIndex: page.pageIndex
          }
          return false;
        }
      }
      else if (rect.bottom > y) {
        //early exit, since we traverse these pages in order from top to bottom.
        return false;
      }
    })
    
    return found;
  }
  
  doBeforePrintPreview() {
    let { t } = this.props;
    return new Promise((resolve, reject) => {
      this.warnAboutPrintIfNeeded()
        .then((res) => {
          if (res) {
            
            let { windowSigningState, rawPdf } = this.props.pdfState;
            
            if(windowSigningState === enums.WINDOW_SIGNING_STATUS.NONE){
              resolve(rawPdf);
            }
            else if (!this.hasValidSignatures(this.props.pdfState.signatureIds, this.props.pdfState.invalidOverlays)) {
              resolve(this.props.pdfState.pdfWriter.getUintPdfData());
            }
            else {
              this.commitSignatures()
                .then(() => {
                  resolve(this.props.pdfState.pdfWriter.getUintPdfData());
                  this.props.pdfActions.updateV1ActiveOverlays([]);
                  this.props.pdfActions.updateV1InactiveOverlays([]);
                  this.props.pdfActions.updateV1SigningOverlays([]);
                  return this.refreshFromPdfData();
                })
                .catch((err) => {
                  log.warn('error writing signatures', err);
                  this.props.showAlert(t('Unable to save'), this.getErrorMessagePDFSaveSig());
                  reject(err);
                })
            }
          }
          else {
            reject(false);
          }
        })
    })
  }
  
  reloadPdfData(){
    return this.props.pdfActions.reloadPdfDataFromPdfWriter()
      .then(() => {
        return this.refreshVisiblePageCache();
      })
  }
  
  refreshVisiblePageCache(){
    return new Promise((resolve, reject) => {
      let visiblePages = _.extend({}, this.state.visiblePages);
      this.setState({
        visiblePages : {}
      }, () => {
        setTimeout(() => {
          this.setState({visiblePages}, () => {
            this.pickMostVisiblePage();
            resolve(true);
          })
        })
      })
    })
  }
  
  refreshFromPdfData() {
    
    let {
      showingThumbnails,
      visiblePages
    } = this.state;
    
    let stateToSave = {
      showingThumbnails,
      visiblePages,
      refreshing: true,
      loading: false
    };
    this.setState(_.extend({}, this.INITIAL_STATE, stateToSave))
    return this.props.pdfActions.reloadPdfDataFromPdfWriter()
      .then(() => {
        return this.refreshVisiblePageCache();
      })
      .finally(() => {
        this.setState({refreshing: false})
      })
  }
  
  nextButtonClick(){
    let { isFulfillingSignatureRequest } = this.props.pdfState;
    
    if(isFulfillingSignatureRequest){
      this.saveFulfilledSignatureRequests();
    }
    else{
      this.saveSignatures();
    }
  }
  
  getNextButtonDisabled(){
    let {
      isFulfillingSignatureRequest,
      confirmedSignatureCount,
      signatureRequestOverlays,
      hasCommittedSignatures,
      hasValidV1Signing,
      pdfChangesMade
    } = this.props.pdfState;
    
    if(pdfChangesMade){
      return false;
    }
    if(isFulfillingSignatureRequest){
      return confirmedSignatureCount === 0 || confirmedSignatureCount !== signatureRequestOverlays.length;
    }
    else{
      return !hasCommittedSignatures && !hasValidV1Signing;
    }
  }
  
  needShowPdfLoadError(){
    let {
      pdfLoadErr,
      pdfWriterLoadErr,
      windowSigningState
    } = this.props.pdfState;
    
    //If the pdf couldn't load, easy.  Nothing to do.
    if(pdfLoadErr){
      return true;
    }
    else{
      //If the pdf was able to load, but the writer was not.  Then we need to show a
      //message in cases where they're trying to sign stuff.
      if(windowSigningState !== enums.WINDOW_SIGNING_STATUS.NONE && pdfWriterLoadErr){
        return true;
      }
    }
  }
  
  getTextForPdfLoadError(){
    let { pdfLoadErr, pdfWriterLoadErr} = this.props.pdfState;
    let { doc_info, t } = this.props;
    
    let text = t("Preview currently unavailable.  Please try again later.");
    if(doc_info && !doc_info.previewable_flag){
      text = t("This file cannot be previewed.")
    }
    else if(pdfLoadErr){
      if(pdfLoadErr.code && pdfLoadErr.code === 1){
        text = t("This pdf is encrypted.  It cannot be viewed.")
      }
      else if(pdfLoadErr.isPdfCollection){
        text = t("This document is a pdf portfolio.  It is only viewable within an Adobe pdf viewer.")
      }
    }
    else if(pdfWriterLoadErr){
      text = t("This pdf is encrypted and cannot be signed.")
    }
    
    return text;
  }
  
  toggleThumbnailPanel(){
    this.setState({showingThumbnails : !this.state.showingThumbnails}, () => {
      
      //Becuase signature v1 points are absolutely positioned within the scroll area
      //Their position is affected when the thumbnail panel opens because it steals some of the scroll panel's space.
      //My hack around this is to just adjust each signature point by half of the thumbnail panel.
      //This is because the panel is centered, so the signature points will only move by half of the panel size.
      let {signatureIds} = this.props.pdfState;
      _.each(signatureIds, (id) => {
        let controlledPosition = this.signatureV1OverlayRefs[id].getControlledPosition();
        let offset = (ThumbnailPanel.THUMBNAIL_PANEL_WIDTH / 2);
        controlledPosition.x += this.state.showingThumbnails ? -offset : offset;
        this.signatureV1OverlayRefs[id].setControlledPosition(controlledPosition.x, controlledPosition.y);
      })
      
    })
  }
  
  render() {
    let {
      forum_id,
      host_uid,
      doc_id,
      doc_info,
      dm,
      thread,
      t
    } = this.props;
    
    let {
      pdf,
      pages,
      pdfLoadErr,
      pdfWriterLoadErr,
      windowSigningState,
  
      signatureRequestData,
      signatureRequestOverlays,
      signatureRequestOverlayLookup,
      isEditingSignatureRequest,
      isFulfillingSignatureRequest,
  
      signatureIds,
      activeOverlays,
      invalidOverlays,
    } = this.props.pdfState
    
    let {
      loading,
      refreshing,
      visiblePages,
      scale,
      disableFastScroll,
      isUploading,
      showingThumbnails
    } = this.state;
    
    let showPdfLoadError = this.needShowPdfLoadError();
    
    return (
      <div className="modal-content pdf-preview">
  
        {isUploading &&
        <div className="upload-progress-overlay">
          <div className="spinner">
            <Loading centered={true} size={'sm'}/>
          </div>
        </div>
        }
        
        <div className="modal-header black-bg light-color w-100">
          <h5 className="modal-title auto-ellipsis" style={{paddingLeft: '40px'}}>
            {doc_info.label}
          </h5>
          <button type="button"
                  className="close light-color"
                  onClick={this.closeModal.bind(this)}
                  aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
  
        {loading &&
        <div className="black-bg" style={{padding: '100px'}}>
          <Loading centered size={'sm'}/>
        </div>
        }
        
        {!loading &&
        <PdfPreviewHeader pdf={pdf}
                          isDisabled={!!showPdfLoadError}
                          pdfPreviewLoadErr={pdfLoadErr}
                          pdfWriterLoadErr={pdfWriterLoadErr}
                          forum_id={forum_id}
                          host_uid={host_uid}
                          doc_id={doc_id}
                          scale={scale}
                          doc_info={doc_info}
                          dm={dm}
                          thread={thread}
                          showingThumbnails={showingThumbnails}
                          toggleShowingThumbnails={() => this.toggleThumbnailPanel()}
                          setScale={this.setScale.bind(this)}
                          setVisiblePage={this.setVisiblePage.bind(this)}
                          doBeforePrintPreview={this.doBeforePrintPreview.bind(this)}
                          afterPrintPreview={this.after}
                          sharedCanvasRef={this.sharedCanvasRef}
                          initiateV1Signing={this.initiateV1Signing.bind(this)}
                          windowSigningState={windowSigningState}
                          activePdfPageIndex={this.state.activePageIndex}
                          onInitiateSignatureRequest={this.onInitiateSignatureRequestClick.bind(this)} />
        }
        {!loading && windowSigningState === enums.WINDOW_SIGNING_STATUS.V2_SIGNING && !isFulfillingSignatureRequest && !showPdfLoadError &&
          <PDFSignatureRequestHeader signatureRequestData={signatureRequestData}
                                     cancelSignatureClick={this.cancelSignatureRequestClick.bind(this)}
                                     submitSignatureClick={this.submitSignatureRequestClick.bind(this)}
                                     editRequestClick={this.editSignatureRequestClick.bind(this)}
                                     isEditing={isEditingSignatureRequest}
                                     cancelEditRequestClick={this.cancelEditSignatureRequest.bind(this)}
                                     saveChangesDisabled={signatureRequestOverlays && signatureRequestOverlays.length === 0}
                                     hasChanges={this.getSignatureRequestHasChanges()} />
        }
        {!loading && windowSigningState === enums.WINDOW_SIGNING_STATUS.V1_SIGNING && !showPdfLoadError &&
          <PdfSigningV1Header nextBtnDisabled={this.getNextButtonDisabled()}
                              nextBtnClick={this.nextButtonClick.bind(this)}
                              addAnnotationClick={this.addSignatureOverlay.bind(this)}
                              cancelBtnClick={this.cancelV1SigningClick.bind(this)} />
        }
        <div className="modal-body p-0 dark-bg d-flex">
          <div style={styles.pdfModalBodyWrap}>
            <div style={styles.pdfScrollWrapper}>
              {pages && pages.length > 0 && visiblePages &&
              <ThumbnailPanel showing={showingThumbnails}
                              onRef={(ref) => this.setState({thumbnailPanelRef : ref})}
                              activePdfPageIndex={this.state.activePageIndex}
                              onPageDeleteClick={this.deletePageClick.bind(this)}
                              onPageMove={this.onPageMove.bind(this)}
                              pdfPages={pages}
                              isEditModeOn={windowSigningState === enums.WINDOW_SIGNING_STATUS.V1_SIGNING}
                              onThumbnailPageClick={(index) => this.setVisiblePage(index)}/>
              }
              <div id={PdfPreview.MODAL_SCROLL_CONTAINER}
                   ref={this.scrollPanelRef}
                   style={styles.pdfScrollContainer}
                   onScroll={this.onPdfPreviewScroll.bind(this)}>
                <div className="position-relative">
                  {_.map(signatureIds, (id) => {
                    return <PdfSigningOverlay key={id}
                                              id={id}
                                              onRef={this.setV1SignatureOverlayRef.bind(this)}
                                              isActive={_.indexOf(activeOverlays, id) >= 0}
                                              isValidPosition={_.indexOf(invalidOverlays, id) < 0}
                                              setActiveOverlay={this.setActiveOverlay.bind(this)}
                                              validateOverlay={this.validateOverlay.bind(this)}
                                              deleteSignature={this.deleteSignature.bind(this)}/>
                  })}
                  {!loading && !showPdfLoadError &&
                  <Fragment>
                    {_.map(pages, (page) => {
                      return (
                        <Fragment key={page.pageIndex}>
                          <Waypoint bottomOffset={-1500}
                                    fireOnRapidScroll={disableFastScroll}
                                    onEnter={this.onPageEnter.bind(this, page.pageIndex)}
                                    onLeave={this.onPageLeave.bind(this, page.pageIndex)}>
                            <div id={`pdf-page-${page.pageIndex}`}>
              
                              <PdfPage pdf={pdf}
                                       page={page}
                                       isVisible={!!visiblePages[page.pageIndex]}
                                       sharedCanvasRef={this.sharedCanvasRef}
                                       onCanvasRef={this.setPageCanvasRef.bind(this)}
                                       scale={scale}
                                       windowSigningState={windowSigningState}
                                       isEditingSignatureRequest={windowSigningState === enums.WINDOW_SIGNING_STATUS.V2_SIGNING && isEditingSignatureRequest}
                                       isFulfillingSignatureRequest={isFulfillingSignatureRequest}
                                       signatureRequestOverlays={signatureRequestOverlayLookup[page.pageIndex] || []}
                                       addSignatureRequest={this.addSignatureRequestOverlay.bind(this)}
                                       updateSignatureRequest={this.updateSignatureRequestOverlay.bind(this)}
                                       deleteSignatureRequest={this.deleteSignatureRequestOverlay.bind(this)}
                                       signatureRequestOverlayConfirmed={this.signatureRequestOverlayConfirmed.bind(this)}
                                       signatureRequestOverlaySelected={this.signatureRequestOverlaySelected.bind(this)}
                                       onFulfillSignatureOverlayRef={this.setV2SignatureOverlayRef.bind(this)}/>
                            </div>
                          </Waypoint>
                        </Fragment>
                      )
                    })}
                  </Fragment>
                  }
                  {!loading && showPdfLoadError &&
                  <div className="light-color text-center" style={{padding: '200px 100px'}}>
                    <p>
                      {this.getTextForPdfLoadError()}
                    </p>
                  </div>
                  }
  
                  <canvas height={1}
                          width={1}
                          className="d-none"
                          ref={this.sharedCanvasRef}/>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    )
  }
}

//Not sure these styles are the most efficient in here.
//It's a little tricky to get the thumbnail scroller to have the proper
//layout within a modal, and in a way that doesn't mess with the pdf preview panel.
//Just be careful with these!
//bug 2625 fixed a couple problems with ie 11, in particular fixing a limitation of
//ie 11 where flex box params need explicit height set.  Just a heads up there's more delicate things.
const styles ={
  pdfModalBodyWrap : {
    flex: 1,
    maxWidth: '100%',
    width: '100%'
  },
  pdfScrollWrapper : {
    display : 'flex',
    flexDirection: 'row',
    maxHeight: '100%',
    height: '100%'
  },
  pdfScrollContainer : {
    overflow: 'auto',
    maxHeight: '100%',
    height: '100%',
    display: 'grid',
    flex: 1,
    boxShadow: 'inset 1px 0 0 rgba(255, 255, 255, 0.05)'
  }
}

const mapStateToProps = (state) => {
  return {
    workspace : state.workspace.workspace, //We shouldn't have a dependency on this, but it's the fastest way to get last_forum_id,
    pdfState : {...state.pdfPreview}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    pdfActions : {...pdfPreviewActions.mapToDispatch(dispatch)},
    ...modalActions.mapToDispatch(dispatch),
    refreshWorkspace: (forum_id) => dispatch(workspaceActions.refreshWorkspace(forum_id)),
    refreshDocs: (forum_id, host_uid) => dispatch(workspaceActions.refreshDocs(forum_id, host_uid)),
    ...sharedActions.mapToDispatch(dispatch),
    generateWatermarkImg : (watermarkText) => dispatch(utilityActions.generateWatermarkImg(watermarkText))
  };
};

PdfPreview.propTypes = {
  close: PropTypes.func.isRequired,
  setWindowMode: PropTypes.func,
  doc_info: PropTypes.object.isRequired,
  forum_id: PropTypes.string,
  host_uid: PropTypes.string,
  doc_id: PropTypes.string.isRequired,
  chat_id: PropTypes.string,
  thread: PropTypes.object,
  dm : PropTypes.object,
  
  doesSignatureRequestExist : PropTypes.bool,
  onInitiateSignatureRequest : PropTypes.func.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfPreview));
