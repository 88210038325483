import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import Promise from 'bluebird';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../partials/elements/Button';
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import log from "../../util/log";
import UserBadge from "../partials/badges/UserBadge";
import colors from "../../util/colors";
import {getMessageForError} from "../../util/errors";
import Checkbox from "../partials/elements/Checkbox";
import workspaceActions from "../../actions/workspace-actions";
import Loading from "../partials/util/Loading";
import {withVFTranslation} from "../../util/withVFTranslation";

class ManageObjectPermissionsWindow extends Component {
  
  isSaving = false
  
  constructor(props) {
    super(props);
    
    this.state = {
      originalCheckedIds: null,
      checkedIds : [],
      availableObjects : [],
      selectAllChecked : false,
      loading : false
    }
  }
  
  componentDidMount() {
    let { t } = this.props;
    this.setState({loading : true});
    this.refreshAvailables()
      .catch((err) => {
        log.error('error loading available guests', err);
        this.props.showAlert(t('Error loading guests'), t("There was a problem loading available guests.  Please try again"), () => {
          this.closeModal();
        })
      })
      .finally(() => {
        this.setState({loading : false})
      })
  }
  
  refreshAvailables(){
    let { workspace, guests } = this.props;
    let { object_type, object_id } = this.props.modalProps;
    
    if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD){
      return sapi.Threads.guests(workspace.forum_id, workspace.host_uid, object_id)
        .then((res) => {
          return new Promise((resolve) => {
            let checkedGuestIds = _.map(_.filter(res.data, (g) => {
              return !g.is_owner
            }), (g) => g.guest_uid)
            if(!this.state.originalCheckedIds){
              this.setState({originalCheckedIds : _.concat([], checkedGuestIds)})
            }
            
            this.setState({
              availableObjects : _.concat([], guests),
              checkedIds : _.concat([], checkedGuestIds)
            }, () => {
              this.updateSelectAllToggle();
              resolve(true);
            })
          })
        })
    }
    else{
      return Promise.all([
        sapi.Threads.get(workspace.forum_id, workspace.host_uid),
        sapi.Threads.getPermList(workspace.forum_id)
      ])
        .then((res) => {
          return new Promise((resolve) => {
            let perms = res[1].data;
            let allChats = res[0].data;
            
            let chat_ids = _.keys(perms);
            let chats = [];
            _.each(chat_ids,  (chat_id) => {
              let guestObj = perms[chat_id];
              _.each(_.keys(guestObj.guest), (g_uid) => {
                if (g_uid === object_id) {
                  chats.push(chat_id);
                }
              })
            })
    
            let chatInfos = [];
            _.each(chats, (chat_id) => {
              var index = _.findIndex(allChats, (o) => {
                return o.chat_id === chat_id;
              });
              chatInfos.push(allChats[index]);
            })
            
            let checkedGuestIds = _.map(chatInfos, 'chat_id')
            if(!this.state.originalCheckedIds){
              this.setState({originalCheckedIds : _.concat([], checkedGuestIds)})
            }
            this.setState({
              availableObjects : _.concat([], allChats),
              checkedIds : _.concat([], checkedGuestIds)
            }, () => {
              this.updateSelectAllToggle()
              resolve(true);
            })
  
          })
        })
    }
    
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  submit(){
    if(this.isSaving){
      return;
    }
    this.isSaving = true;
    
    let { workspace, t } = this.props;
    let { originalCheckedIds, checkedIds } = this.state;
    let { object_id, object_type } = this.props.modalProps;
    let grantingIds = _.concat([], this.state.checkedIds);
    let denyingIds = [];
    
    let promise = null;
    if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD){
      denyingIds = _.map(_.filter(this.state.availableObjects, (g) => {
        return grantingIds.indexOf(g.guest_uid) < 0
      }), (g) => g.guest_uid)
      
      promise = sapi.Guests.updateThreadPermissions(workspace.forum_id, object_id, grantingIds, denyingIds);
    }
    else if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.GUEST){
      denyingIds = _.map(_.filter(this.state.availableObjects, (g) => {
        return grantingIds.indexOf(g.chat_id) < 0
      }), (g) => g.chat_id)
      promise = sapi.Guests.updateGuestPermissions(workspace.forum_id, object_id, grantingIds, denyingIds)
    }
    
    promise
      .then((res) => {
        log.log('update thread permission res', res);
        this.closeModal(res);
      })
      .catch((err) => {
        log.error('error saving permissions', err);
        
        //If one of the users who has been selected was deleted, the call will fail, but the changes are applied.
        if(err && err.name === "APP_ACCT_GONE"){
          let changeCount = _.difference(originalCheckedIds || [], checkedIds || []).length;
          let message = '';
          if(changeCount === 1){
            message = t("This user has deleted their account.  Please review your changes and try again.");
          }
          else{
            message = t("The user you selected has deleted their account.  Please review your changes and try again.");
          }
          this.props.showAlert(t("Error updating permissions"), message);
          //This is kind of a hacky workaround to re-initialize the panel,
          //but we don't totally know the state at this point.  We need to refresh everything from the backend
          this.setState({
            originalCheckedIds : null
          }, () => {
            this.refreshAvailables();
          })
        }
        else{
          this.props.showAlert(t("Error updating permissions"), getMessageForError(err, t))
        }
      })
      .finally(() => {
        this.isSaving = false;
      })
  }
  
  addGuestClick(){
    this.props.showAddGuestWindow((addGuestRes) => {
      if (addGuestRes) {
        let {workspace} = this.props;
        Promise.all([
            this.props.refreshGuests(workspace.forum_id, workspace.host_uid),
            this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true)
          ])
          .then((res) => {
            return this.refreshAvailables();
          })
          .then(() => {
            _.each(addGuestRes.data, (g) => {
              this.setItemCheck(g.guest_uid, true);
            })
          })
      }
    })
  }
  
  addThreadClick(){
    let { t } = this.props;
    let { workspace } = this.props.modalProps;
    
    let addNewThread = (label) => {
      return sapi.Threads.add(label, workspace.forum_id)
    }
  
    this.props.showNewItem(t('New Thread'), t('New Thread Name'), t('Enter a name for your new Thread'), addNewThread, (addThreadRes) => {
      if (addThreadRes) {
        this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true)
          .then(() => {
            return this.refreshAvailables();
          })
          .then(() => {
            this.setItemCheck(addThreadRes.data.chat_id, true);
          })
      }
    })
  }
  
  selectAllToggle(evt){
    if(evt.target.checked){
      
      let { object_type } = this.props.modalProps;
      if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD){
        this.setState({
          selectAllChecked : true,
          checkedIds : _.map(this.state.availableObjects, (g) => g.guest_uid)
        })
      }
      else if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.GUEST){
        this.setState({
          selectAllChecked : true,
          checkedIds : _.map(this.state.availableObjects, (g) => g.chat_id)
        })
      }
    }
    else{
      this.setState({
        selectAllChecked : false,
        checkedIds : []
      })
    }
  }
  
  updateSelectAllToggle(){
    this.setState({
      selectAllChecked : this.state.availableObjects.length === this.state.checkedIds.length
    })
  }
  
  setItemCheck(object_id, val){
    let update = _.concat([], this.state.checkedIds)
    if(!val){
      _.remove(update, (checked_id) => { return checked_id === object_id})
    }
    else{
      update.push(object_id);
    }
    this.setState({checkedIds : update}, () => {
      this.updateSelectAllToggle()
    });
  }
  
  itemCheckChange(object_id, evt){
    this.setItemCheck(object_id, evt.target.checked);
  }
  
  renderHeader(){
    let { t } = this.props;
    let { label, object_id, object, object_type } = this.props.modalProps;
    
    if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD){
      return (
        <Fragment>
          <div>
            <h6>{t("Toggle the switches below to grant and revoke access to")}</h6>
          </div>
          <div className="text-center">
            <h4>
              <span className="primary-color">
                <i className={`icon mr-3 ${object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.DOC ? 'ion-document' : 'ion-chatbox'}`} />
                {label}
              </span>
            </h4>
          </div>
          <div>
            <h4 className={'m-0'}>
              {t("Guests")}
              <Button onClick={this.addGuestClick.bind(this)}
                      className={'btn btn-lg btn-icon ion-ios-plus-outline tab-add-btn no-focus'}/>
            </h4>
          </div>
        </Fragment>
      )
    }
    else if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.GUEST){
      return (
        <Fragment>
          <div>
            <h6>{t("Toggle the switches below to grant and revoke access for")}</h6>
          </div>
          <div className="text-center mt-3 mb-2">
            <div className="d-flex justify-content-center">
              <div className={'text-right pr-2'}>
                <UserBadge guest={object}/>
              </div>
              <div className="text-left">
                <div className="guest-label" style={styles.userInfoTitle}>
                  {object.first_name} {object.last_name}
                </div>
                <div className="guest-preview" style={styles.userInfoEmail}>
                  {object.email_address}
                </div>
              </div>
            </div>
          </div>
          <div>
            <h4 className={'m-0'}>
              {t("Workspace Threads")}
              <Button onClick={this.addThreadClick.bind(this)}
                      className={'btn btn-lg btn-icon ion-ios-plus-outline tab-add-btn no-focus'}/>
            </h4>
          </div>
        </Fragment>
      )
    }
  }
  
  renderList(){
    let { object_type} = this.props.modalProps;
    let { availableObjects, checkedIds } = this.state;
    
    if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD) {
      return (
        <Fragment>
          {_.map(availableObjects, (guest) => {
            let userChecked = checkedIds.indexOf(guest.guest_uid) >= 0;
            return (
              <div key={guest.guest_uid} className="d-flex mb-2">
                <div style={styles.userColumn} className="mr-3">
                  <UserBadge guest={guest}/>
                </div>
                <div className="flex-grow-1" style={styles.userColumn}>
                  <h6 className="mb-0"
                      style={userChecked ? styles.selectedUserHeader : styles.unselectedUser}>
                    {guest.first_name} {guest.last_name}
                  </h6>
                  <p className="mb-0"
                     style={userChecked ? styles.selectedUserEmail : styles.unselectedUser}>
                    {guest.email_address}
                  </p>
                </div>
                <div style={styles.userColumn} className="ml-3 mr-2">
                  
                  <span className="custom-control custom-switch custom-switch-lg">
                    <input type="checkbox"
                           className="custom-control-input"
                           onChange={this.itemCheckChange.bind(this, guest.guest_uid)}
                           checked={userChecked}
                           id={"manage-recipient-" + guest.guest_uid}/>
                    <label className="custom-control-label" htmlFor={"manage-recipient-" + guest.guest_uid}/>
                  </span>
                  
                </div>
              </div>
            )
          })}
        </Fragment>
      )
    }
    else if(object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.GUEST) {
      return (
        <Fragment>
          {_.map(availableObjects, (thread) => {
            let isChecked = checkedIds.indexOf(thread.chat_id) >= 0;
            return (
              <div key={thread.chat_id} className="d-flex mb-2">
                <div className="flex-grow-1" style={styles.userColumn}>
                  <h6 className="mb-0"
                      style={isChecked ? styles.selectedUserHeader : styles.unselectedUser}>
                    {thread.label}
                  </h6>
                </div>
                <div style={styles.userColumn} className="ml-3 mr-2">
                  
                  <span className="custom-control custom-switch custom-switch-lg">
                    <input type="checkbox"
                           className="custom-control-input"
                           onChange={this.itemCheckChange.bind(this, thread.chat_id)}
                           checked={isChecked}
                           id={"manage-recipient-" + thread.chat_id}/>
                    <label className="custom-control-label" htmlFor={"manage-recipient-" + thread.chat_id}/>
                  </span>
                  
                </div>
              </div>
            )
          })}
        </Fragment>
      )
    }
  }
  
  render() {
    let { t } = this.props;
    let {originalCheckedIds, checkedIds, selectAllChecked, loading} = this.state;
    let { object_type } = this.props.modalProps;
    
    let hasDifference = (originalCheckedIds && originalCheckedIds.length !== checkedIds.length) || _.difference(originalCheckedIds, checkedIds).length > 0;
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD &&
              <span>
              {t("Manage Recipients")}
              </span>
            }
            {object_type === ManageObjectPermissionsWindow.OBJECT_TYPES.GUEST &&
              <span>
              {t("Manage Guest Access")}
              </span>
            }
          </h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        {loading &&
        <div className="modal-body my-5 py-5">
          <Loading centered size={'sm'}/>
        </div>
        }
        {!loading &&
        <div className="modal-body">
          {this.renderHeader()}
          <div className="d-flex mb-3">
            <div className="mr-3" style={{width: UserBadge.MEDIUM_SIZE_WIDTH + 'px'}} />
            <div className="flex-grow-1">
              <h6 style={{marginTop: '5px'}} className="mb-0 font-weight-bold">
                {t("Select All")}
              </h6>
            </div>
            <div className="ml-3 mr-2">
              <span className="custom-control custom-switch custom-switch-lg">
                <input type="checkbox"
                       className="custom-control-input"
                       onChange={this.selectAllToggle.bind(this)}
                       checked={selectAllChecked}
                       id="manage-object-guest-checkbox" />
                  <label className="custom-control-label" htmlFor={'manage-object-guest-checkbox'} />
              </span>
            </div>
          </div>
          <div style={{
            minHeight : '300px'
          }}>
            {this.renderList()}
          </div>
        </div>
        }
        <div className="modal-footer">
          <Button className="btn btn-secondary" onClick={this.closeModal.bind(this)}>{t("Cancel")}</Button>
          <Button className="btn btn-primary"
                  disabled={!hasDifference}
                  onClick={this.submit.bind(this)}>
            {t("Save")}
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  userColumn: {
    minHeight : '50px'
  },
  selectedUserHeader : {
    fontWeight : 'bold'
  },
  selectedUserEmail : {
  },
  unselectedUser : {
    color : colors.SECONDARY_TEXT,
  },
  customLabelClearBtn : {
    position : 'absolute',
    right : '10px',
    top : '6px',
    lineHeight : '24px',
    color : colors.DARK,
    zIndex : 100,
    cursor : 'pointer'
  },
  userInfoTitle: {
    lineHeight: '14px'
  },
  userInfoEmail: {
    lineHeight: '14px',
    marginTop: '5px',
    color : colors.SECONDARY_TEXT
  },
}

ManageObjectPermissionsWindow.OBJECT_TYPES = {
  THREAD : 'thread',
  DOC : 'doc',
  GUEST : 'guest'
}

const mapStateToProps = (state) => {
  return {
    workspace : state.workspace.workspace,
    guests : state.workspace.guests,
    accountInfo : state.shared.accountInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshGuests: (forum_id, host_uid) => dispatch(workspaceActions.refreshGuests(forum_id, host_uid)),
    refreshThreads: (forum_id, host_uid, doRefreshGuests) => dispatch(workspaceActions.refreshThreads(forum_id, host_uid, doRefreshGuests)),
    ...modalActions.mapToDispatch(dispatch)
  };
};

ManageObjectPermissionsWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageObjectPermissionsWindow));
