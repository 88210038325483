import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Waypoint} from "react-waypoint";
import Button from "../elements/Button";

import classNames from 'classnames';

import log from '../../../util/log';

import _ from 'lodash';
import Scroll from "react-scroll";

const DEFAULT_HEADER_HEIGHT = 48;

class ScrollingAccordion extends PureComponent {
  
  constructor(props){
    super(props);
  
    this.onTitleClick = this.onTitleClick.bind(this);
    this.onHeaderTopEnter = this.onHeaderTopEnter.bind(this);
    this.onHeaderTopLeave = this.onHeaderTopLeave.bind(this);
    this.onHeaderBottomEnter = this.onHeaderBottomEnter.bind(this);
    this.onHeaderBottomLeave = this.onHeaderBottomLeave.bind(this);
    
    this.state = {
      id : _.uniqueId('scroll-accordion'),
      itemsLockedToTop: [],
      itemsLockedToBottom: []
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
    
    if(this.props.items){
      let indices = [];
      _.each(this.props.items, (item, i) => {
        if(i > 0) {
          indices.push(i);
        }
      })
      this.setState({itemsLockedToBottom : indices});
    }
  }
  
  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(null)
    }
  }
  
  getItem(index){
    let { items } = this.props;
    
    return items[index];
  }
  
  getItemId(item){
    return `scroll-accordion-${item.id}`
  }
  
  calculateHeaderOffset(index){
    return -(this.getHeaderHeight() * index) - (this.props.scrollOffset || 0);
  }
  
  onTitleClick = (index, item) => () => {
    let id = this.getItemId(item);
  
    let offset = this.calculateHeaderOffset(index);
    
    Scroll.scroller.scrollTo(id, {
      containerId: this.state.id,
      duration: 500,
      smooth: true,
      offset: offset
    })
  }
  
  scrollElementToView(listIndex, itemId){
    
    let offset = this.calculateHeaderOffset(listIndex + 1);
    
    Scroll.scroller.scrollTo(itemId, {
      containerId: this.state.id,
      duration: 500,
      smooth: true,
      offset: offset
    })
  }
  
  onHeaderTopEnter = (index) => (evt) => {
    // log.log('onHeaderTopenter', index, evt);
    if(evt.previousPosition === Waypoint.above){
      let { itemsLockedToTop } = this.state;
  
      let copy = _.concat([], itemsLockedToTop);
      _.remove(copy, (item) => {
        return item === index;
      })
      this.setState({
        itemsLockedToTop : copy
      })
    }
  }
  
  onHeaderTopLeave = (index) => (evt) =>  {
    // log.log('onHeaderTopLeave', index, evt);
    if(evt.currentPosition === Waypoint.above){
      let { itemsLockedToTop } = this.state;
    
      this.setState({
        itemsLockedToTop : _.concat([], itemsLockedToTop, index)
      })
    }
  }
  
  onHeaderBottomEnter = (index) => (evt) => {
    // log.log('onHeaderBottomEnter', index, evt);
    // We need to remove on enter even if there's no preview position, because in
    // componentDidMount we initialize items locked to bottom with every index.
    if(!evt.previousPosition || evt.previousPosition === Waypoint.below){
      let { itemsLockedToBottom } = this.state;
    
      let copy = _.concat([], itemsLockedToBottom);
      _.remove(copy, (item) => {
        return item === index;
      })
      this.setState({
        itemsLockedToBottom : copy
      })
    }
  }
  
  onHeaderBottomLeave = (index) => (evt) => {
    if(evt.currentPosition === Waypoint.below){
      let { itemsLockedToBottom } = this.state;
    
      this.setState({
        itemsLockedToBottom : _.concat([], itemsLockedToBottom, index)
      })
    }
  }
  
  onNewBtnClick(index){
    let item = this.getItem(index);
    
    item.newBtnClick();
  }
  
  getHeaderHeight(){
    if(this.props.customHeaderHeight){
      return this.props.customHeaderHeight;
    }
    return DEFAULT_HEADER_HEIGHT;
  }
  
  getHeader(item, index, isLocked) {
    return (
      <Fragment>
        {item.headerRenderFn && item.headerRenderFn(item, isLocked) }
        {!item.headerRenderFn &&
        <h4 className={'m-0'}>
          {item.title}
          {item.newBtnClick &&
          <button onClick={this.onNewBtnClick.bind(this, index)}
                  className={'btn btn-lg btn-icon ion-ios-plus-outline tab-add-btn no-focus'}/>
          }
        </h4>
        }
      </Fragment>
    )
  }
  
  render() {
    let {items} = this.props;
    
    let { itemsLockedToTop, itemsLockedToBottom } = this.state;
    
    let height = this.getHeaderHeight();
    return (
      <div id={this.state.id}
           className={'scrollable-col'}>
        {items.map((item, i) => {
          let headerStyle = null;
          let topIndex = _.indexOf(itemsLockedToTop, i);
          let bottomIndex = _.indexOf(itemsLockedToBottom, i);
          if(topIndex >= 0){
            headerStyle = {
              height: height,
              top: `${(height * topIndex)}px`,
            }
          }
          else if(bottomIndex >= 0){
            headerStyle = {
              height: height,
              bottom: `${(height * bottomIndex)}px`,
            }
          }
          
          return (
            <div id={this.getItemId(item)}
                 key={item.id}>
              {headerStyle &&
              <div className={classNames('accordion-header', {'locked' : !item.preventLockStyling})}
                   style={{...styles.headerStyleBase, ...headerStyle}}
                   onClick={this.onTitleClick(i, item)}>
                
                {this.getHeader(item, i, true)}
              </div>
              }
  
              <Waypoint topOffset={(height * i) + 'px'}
                        onEnter={this.onHeaderTopEnter(i)}
                        onLeave={this.onHeaderTopLeave(i)}/>
              
              <div className={classNames('accordion-header')} style={{height: height}}>
                {this.getHeader(item, i, false)}
              </div>
              <Waypoint onEnter={this.onHeaderBottomEnter(i)}
                        onLeave={this.onHeaderBottomLeave(i)}/>
              {item.listRenderFn()}
            </div>
          )
        })}
      </div>
    )
  }
}

const styles = {
  headerStyleBase : {
    position: 'absolute',
    left: '0',
    right: '0'
  },
}

ScrollingAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      headerRenderFn: PropTypes.func, //If supplied, other optional props not used.
      preventLockStyling : PropTypes.bool,
      newBtnClick: PropTypes.func,
      listRenderFn: PropTypes.func.isRequired
    })
  ).isRequired,
  //The code does not currently support headers with different sizes.
  customHeaderHeight:PropTypes.number,
  scrollOffset: PropTypes.number,
  onRef: PropTypes.func
}

export default ScrollingAccordion;
