import _ from 'lodash';
import log from "../util/log";

const searchHelper = {
  
  findInTokens(searchString, itemTokens){
    if(_.trim(searchString).length === 0){
      return true;
    }
    
    let searchTokens = _.trim(searchString).split(' ')
    let consumedTokenIndices = [];
    _.each(searchTokens, (searchToken, index) => {
      if(consumedTokenIndices.indexOf(index) < 0) {
        _.each(itemTokens, (itemToken) => {
          if (itemToken.toUpperCase().indexOf(searchToken.toUpperCase()) >= 0) {
            consumedTokenIndices.push(index);
            return false;
          }
        })
      }
    })
    if(consumedTokenIndices.length === searchTokens.length){
      //log.log('findInTokens result', searchTokens, itemTokens, consumedTokenIndices);
      return true;
    }
  }
}

export default searchHelper;
