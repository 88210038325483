import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import Button from '../partials/elements/Button';
import sapi from "../../util/sapi";
import log from "../../util/log";
import modalActions from "../../actions/modal-actions";
import {getMessageForError} from "../../util/errors";
import Loading from "../partials/util/Loading";
import DocPermissionsHelper from "../../helpers/doc-permissions-helper";
import utils from "../../util/util";
import UserBadge from "../partials/badges/UserBadge";
import filters from "../../helpers/filters";
import ExpandableRow from "../partials/components/ExpandableRow";
import EmptyState from "../partials/components/EmptyState";
import {withVFTranslation} from "../../util/withVFTranslation";

class DocViewHistory extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      readLog : null,
      guests : [],
      docInfo : null,
      expandedGuestUid : null,
      loading : true
    }
  }
  
  componentDidMount() {
    let { t } = this.props;
    this.setState({loading : true})
    this.fetchHistory()
      .then((res) => {
        this.loadHistory(res.doc_info, res.read_log, res.users);
        this.setState({loading : false})
      })
      .catch((err) => {
        log.error('error loading doc info', err);
        this.props.showAlert(t('Error loading Document History'), getMessageForError(err, t), () => {
          this.closeModal();
        })
      })
  }
  
  fetchHistory(){
    return new Promise((resolve, reject) => {
      let { directMessages } = this.props;
      let { forum_id, host_uid, guest_uid, doc_id } = this.props.modalProps;
  
      if(guest_uid){
        let dm = _.find(directMessages, (dm) => {return dm.guest_uid === guest_uid});
        Promise.all([
            sapi.Docs.info(dm.forum_id, dm.host_uid, doc_id),
          ])
          .then((res) => {
            resolve({
              doc_info : res[0].data,
              read_log : res[0].data.read_log,
              users : [dm]
            })
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        Promise.all([
          sapi.Docs.info(forum_id, host_uid, doc_id),
          DocPermissionsHelper.getUserIdsWithPermission(forum_id, doc_id)
        ])
          .then((res) => {
            
            let users = [];
            _.each(res[1], (g_uid) => {
              let found = _.find(this.props.contacts, (c) =>  c.guest_uid === g_uid);
              if(found){
                users.push(found);
              }
            })
            
            resolve({
              doc_info : res[0].data,
              read_log : res[0].data.read_log,
              users
            })
          })
          .catch((err) => {
            reject(err);
          })
      }
    })
  }
  
  loadHistory(doc_info, read_log, users){
    log.log('history data', doc_info, read_log, users);
    let readLog = {};
    let guests = [];
    _.each(read_log, (log) => {
      let user = _.find(users, (contact) => {
        return contact.guest_uid === log.guest_uid;
      })
    
      if(user){
        if(readLog[user.guest_uid]){
          readLog[user.guest_uid].push({
            guest : user,
            readTime : log.read_date
          })
        }
        else{
          guests.push(user);
          readLog[user.guest_uid] = [{
            guest : user,
            readTime : log.read_date
          }]
        }
      }
    })
  
    _.each(users, (contact) => {
      let userExists = !!readLog[contact.guest_uid];
    
      if(!userExists){
        guests.push(contact);
        readLog[contact.guest_uid] = []
      }
    })
  
    log.log('setting read log', readLog);
    this.setState({
      readLog,
      guests,
      docInfo : doc_info,
    })
  }
  
  guestRowClick(guest_uid){
    if(this.state.expandedGuestUid === guest_uid){
      this.setState({expandedGuestUid : null})
    }
    else{
      this.setState({expandedGuestUid : guest_uid})
    }
  }
  
  closeModal() {
    let {close} = this.props;
    
    close();
  }
  
  render() {
    let { t } = this.props;
    let { loading, readLog, guests, expandedGuestUid, docInfo } = this.state;
    if (loading) {
      return (
        <div className="modal-content">
          <div className="modal-body">
            <Loading centered size={'sm'}/>
          </div>
        </div>
      )
    }
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Document Access History")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className="text-center pb-3">
            <h4 className="auto-ellipsis">
              <span className="primary-color">
                <i className={`icon mr-3 ion-document`} />
                {docInfo.label}
              </span>
            </h4>
          </div>
          
          {guests.length === 0 &&
          <EmptyState>
            <div className="text-center secondary-text-color my-5">
              <p>
                {t("No Guests have access to this Document.")}
              </p>
            </div>
          </EmptyState>
          }
          
          {_.map(guests, (guest) => {
            let userLogs = readLog[guest.guest_uid];
            
            return (
              <Fragment key={guest.guest_uid}>
                <div className="d-flex flex-row p-2 has-pointer workspace-row" onClick={this.guestRowClick.bind(this, guest.guest_uid)}>
                  <div className="mr-2">
                    <UserBadge guest={guest} />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mb-0">
                      {guest.first_name} {guest.last_name}
                    </h6>
                    <p className="mb-0 secondary-text-color">
                      {guest.email_address}
                    </p>
                  </div>
                  <div className="text-right my-auto mx-2">
                    {userLogs.length === 0 &&
                    <span className="secondary-text-color font-italic">{t("Not yet accessed")}</span>
                    }
                    {userLogs.length !== 0 &&
                    <span className="secondary-text-color">{userLogs.length === 1 ? t('1 time') : userLogs.length + t(" times")}</span>
                    }
                  </div>
                  <div className="my-auto mx-2 text-right" style={{minWidth: '14px'}}>
                    <i className={`icon ${expandedGuestUid === guest.guest_uid ? 'ion-chevron-down' : 'ion-chevron-right'}`} />
                  </div>
                </div>
                <ExpandableRow isActive={expandedGuestUid === guest.guest_uid}>
                  <div>
                    {userLogs.length === 0 &&
                    <div className="text-center mx-2 my-4 font-italic secondary-text-color">
                      {guest.first_name} {guest.last_name} {t("has not yet accessed this document")}
                    </div>
                    }
                    <div className="py-1" style={{paddingLeft : '62px'}}>
                    {_.map(userLogs, (entry) => {
                      return (
                        <div key={entry.readTime}>
                          {filters.momentFilter(entry.readTime, 'MMMM D, YYYY')}
                          <span className="secondary-text-color ml-2"
                                style={{fontSize: '14px'}}>
                            {filters.momentFilter(entry.readTime, 'h:mm a zz')}
                          </span>
                        </div>
                      )
                    })}
                    </div>
                  </div>
                </ExpandableRow>
              </Fragment>
            )
          })}
          
        </div>
        <div className="modal-footer">
          <Button className="btn btn-secondary" onClick={this.closeModal.bind(this)}>{t("Close")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    directMessages : state.shared.directMessages,
    contacts : state.shared.contacts
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

DocViewHistory.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DocViewHistory));
