import React from "react";
import PropTypes from 'prop-types';
import log from "../../../util/log";

class MouseRectangleSelection extends React.Component {
  
  mouseDownTargetRect = null
  
  constructor(props) {
    super(props);
    this.animationInProgress = null;
    this.state = {
      hold: false,
      selectionBox: false,
      selectionBoxOrigin: [0, 0],
      selectionBoxTarget: [0, 0],
      animation: ""
    };
  }
  
  handleTransformBox() {
    const { selectionBoxOrigin, selectionBoxTarget } = this.state;
    if (
      selectionBoxOrigin[1] > selectionBoxTarget[1] &&
      selectionBoxOrigin[0] > selectionBoxTarget[0]
    )
      return "scaleY(-1) scaleX(-1)";
    
    if (selectionBoxOrigin[1] > selectionBoxTarget[1]) return "scaleY(-1)";
    if (selectionBoxOrigin[0] > selectionBoxTarget[0]) return "scaleX(-1)";
    return null;
  }
  
  closeSelectionBox() {
    if(!this.state.hold){
      return;
    }
  
    this.mouseDownTargetRect = null;
    
    if (this.props.onMouseUp) this.props.onMouseUp();
    if (this.props.onSelectFinished) {
      
      let originX = this.state.selectionBoxOrigin[0];
      let originY = this.state.selectionBoxOrigin[1];
      let targetX = this.state.selectionBoxTarget[0];
      let targetY = this.state.selectionBoxTarget[1];
      
      //flip these if needed.  Expected output is always right side up.
      if(originX > targetX){
        originX = this.state.selectionBoxTarget[0];
        targetX = this.state.selectionBoxOrigin[0];
      }
      if(originY > targetY){
        originY = this.state.selectionBoxTarget[1];
        targetY = this.state.selectionBoxOrigin[1];
      }
      
      this.props.onSelectFinished({
        origin: {x : originX, y : originY},
        target: {x : targetX, y : targetY}
      })
    }
    this.setState({
      hold: false,
      animation: "react-rectangle-selection--fadeout"
    });
    this.animationInProgress = setTimeout(() => {
      this.setState({ animation: "" });
      this.setState({ selectionBox: false });
      this.animationInProgress = null;
    }, 300);
  }
  
  isValidMouseTarget(rect){
    if(!this.mouseDownTargetRect){
      log.error('no mouse down target');
      return false;
    }
    
    if(rect.width < this.mouseDownTargetRect.width || rect.height < this.mouseDownTargetRect.height){
      return false;
    }
    return true;
  }
  
  handleMouseDown(e) {
    if (this.props.disabled) return;
    
    e.stopPropagation();
    
    clearTimeout(this.animationInProgress);
    this.animationInProgress = null;
    this.setState({ selectionBox: false, animation: "" });
    
    if (
      this.state.animation.length > 0 &&
      e.target.id === "react-rectangle-selection"
    ) {
      this.setState({ selectionBox: false, animation: "" });
    }
  
    let rect = e.target.getBoundingClientRect();
    this.mouseDownTargetRect = rect;
    
    if(e.targetTouches && e.targetTouches.length > 0){
      let offsetX = e.targetTouches[0].pageX - rect.left;
      let offsetY = e.targetTouches[0].pageY - rect.top;
      
      this.setState({
        hold: true,
        selectionBoxOrigin: [offsetX, offsetY],
        selectionBoxTarget: [offsetX, offsetY]
      });
    }
    else{
      let offsetX = e.nativeEvent.pageX - rect.left;
      let offsetY = e.nativeEvent.pageY - rect.top;
      
      this.setState({
        hold: true,
        selectionBoxOrigin: [offsetX, offsetY],
        selectionBoxTarget: [offsetX, offsetY]
      });
    }
  }
  
  onMove(evt){
    evt.stopPropagation();
    
    if (this.state.hold && !this.state.selectionBox) {
      if (this.props.onMouseDown) this.props.onMouseDown();
      this.setState({ selectionBox: true });
    }
    
    if (this.state.selectionBox && !this.animationInProgress) {
  
      let rect = evt.target.getBoundingClientRect();
      if(!this.isValidMouseTarget(rect)){
        //log.log('invalid mouse move evt', rect, evt.nativeEvent);
        rect = this.mouseDownTargetRect;
      }
      
      if(evt.targetTouches && evt.targetTouches.length > 0){
        let offsetX = evt.targetTouches[0].pageX - rect.left;
        let offsetY = evt.targetTouches[0].pageY - rect.top;
        this.setState({
          selectionBoxTarget: [offsetX, offsetY]
        });
      }
      else{
        let offsetX = evt.nativeEvent.pageX - rect.left;
        let offsetY = evt.nativeEvent.pageY - rect.top;
        
        //log.log('onMove', offsetX, offsetY, rect, evt.nativeEvent);
        
        this.setState({
          selectionBoxTarget: [offsetX, offsetY]
        });
      }
    
      if(this.props.onSelect) {
        this.props.onSelect(evt, {
          origin: this.state.selectionBoxOrigin,
          target: this.state.selectionBoxTarget
        });
      }
    }
  }
  
  render() {
    if(this.props.disabled){
      return this.props.children;
    }
    
    const baseStyle = {
      zIndex: 10,
      left: this.state.selectionBoxOrigin[0],
      top: this.state.selectionBoxOrigin[1],
      height: Math.abs(
        this.state.selectionBoxTarget[1] - this.state.selectionBoxOrigin[1] - 1
      ),
      width: Math.abs(
        this.state.selectionBoxTarget[0] - this.state.selectionBoxOrigin[0] - 1
      ),
      userSelect: "none",
      transformOrigin: "top left",
      transform: this.handleTransformBox()
    };
    return (
      <div
        style={{ height: "inherit", width: "inherit" }}
        onTouchEnd={() => {
          this.closeSelectionBox();
        }}
        onMouseLeave={() => {
          this.closeSelectionBox();
        }}
        onTouchStart={e => this.handleMouseDown(e)}
        onMouseDown={e => this.handleMouseDown(e)}
        onTouchEndCapture={() => this.closeSelectionBox()}
        onMouseUp={() => this.closeSelectionBox()}
        onMouseMove={e => this.onMove(e)}
        onTouchMove={e => this.onMove(e)}
      >
        {this.state.selectionBox && (
          <div
            className={`react-rectangle-selection ${this.state.animation}`}
            id={"react-rectangle-selection"}
            style={Object.assign(baseStyle, this.props.style)}
          />
        )}
        {this.props.children}
      </div>
    );
  }
}

MouseRectangleSelection.propTypes = {
  onSelect : PropTypes.func,
  onSelectFinished : PropTypes.func,
  onMouseDown : PropTypes.func,
  onMouseUp : PropTypes.func,
  disabled : PropTypes.bool
}

export default MouseRectangleSelection;
