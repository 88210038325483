import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import Button from "../elements/Button";
import classNames from 'classnames';
import Popover from "react-tiny-popover";
import colors from "../../../util/colors";
import {getMessageForError} from "../../../util/errors";
import modalActions from "../../../actions/modal-actions";
import ThreadBadge from "../badges/ThreadBadge";
import ReactToPrint from "react-to-print";
import UserBadge from "../badges/UserBadge";
import sharedActions from "../../../actions/shared-actions";
import ColorGenerator from "../../../helpers/color-generator";
import workspaceActions from "../../../actions/workspace-actions";
import filters from "../../../helpers/filters";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class DMPanelHeader extends PureComponent {
  
  constructor(props) {
    super(props);
    
    this.state = {
      openGuestTooltip : null
    }
  }
  
  hideGuestPopover(){
    this.setState({openGuestTooltip : false})
  }
  
  setNotifyFlag(notify_flag){
    let { dm, showAlert, t } = this.props;
    
    sapi.DM.mark(dm.guest_uid, notify_flag)
      .then((res) => {
        this.updateDirectMessages();
      })
      .catch((err) => {
        log.error('error updating dm', err);
        showAlert(t('Error Updating Thread'), getMessageForError(err, t))
      })
  }
  
  updateDirectMessages(){
    this.props.updateDirectMessages();
  }
  
  viewContact(dm, evt){
    this.props.showContactInfoWindow(dm.guest_uid, (res) => {
      log.log('show contact res', res);
      if(res){
        this.props.updateDirectMessages();
        this.props.updateContacts();
      }
    })
  }
  
  beforePrintPreview(){
    return this.props.onBeforePrintPreview();
  }
  
  getGuestInfoContent(guest) {
    let { contactInfo, t } = this.props;
    return (
      <Fragment>
        <div style={styles.tooltipWrap}>
          <p className="mb-1">
            {guest.first_name} {guest.last_name}
          </p>
          <p className="mb-1">
            {guest.email_address}
          </p>
          {contactInfo && contactInfo.read_date &&
          <p className="mb-1">
            {filters.momentFilter(contactInfo.read_date, 'dddd, MMMM Do YYYY, h:mm:ss a')}
          </p>
          }
          {(!contactInfo || !contactInfo.read_date) &&
          <p className="mb-1 font-italic">
            {t("Not yet viewed")}
          </p>
          }
        </div>
      </Fragment>
    )
  }
  
  render() {
    let {dm, t} = this.props;
    let { openGuestTooltip } = this.state;
    
    let popoverIsOpen = dm && dm.guest_uid === openGuestTooltip;
    
    return (
      <Fragment>
        <div className="dark-bg light-color px-2" style={styles.wrapper}>
          <div className={'d-flex'}>
  
            <div style={styles.contentCenter} className="flex-grow-1" >
              <div>
                <h5 style={{lineHeight: '64px'}} className=" mb-0">
                  <i style={{fontSize: '22px'}} className="icon ion-chatbox light-color mr-2" />
                  <span>
                    {t("Private Messages with")} </span>
                      <Popover
                        isOpen={popoverIsOpen}
                        position={'bottom'}
                        disableReposition
                        onClickOutside={() => this.setState({openGuestTooltip :null})}
                        content={() => this.getGuestInfoContent(dm)}>
                        <Fragment>
                          {dm &&
                          <span className="has-pointer hover-underline"
                                onClick={() => popoverIsOpen ?
                                  this.setState({openGuestTooltip :null}) :
                                  this.setState({openGuestTooltip: dm.guest_uid})}>
                            {dm.first_name} {dm.last_name}
                          </span>
                          }
                        </Fragment>
                      </Popover>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const styles = {
  wrapper : {
    borderTopLeftRadius : '5px',
  },
  tooltipWrap: {
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: colors.DARK,
    color: colors.LIGHT,
    fontSize: '12px'
  },
  popoverButton : {
    border : 'none',
    margin : '3px',
    fontSize: '24px',
    lineHeight : '24px',
    padding : '0px 12px'
  },
  menuHeader : {
    color : colors.LIGHT,
    padding : '10px 15px',
    zIndex : 2
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  contentCenter : {
    overflow : 'hidden',
    fontSize : '14px'
  },
  expandBtn : {
    fontSize : '14px'
  },
  controlWrapper : {
    textAlign: 'right',
    maxWidth: '100px'
  }
}

DMPanelHeader.propTypes = {
  dm: PropTypes.object,
  contactInfo: PropTypes.object,
  onBeforePrintPreview: PropTypes.func.isRequired,
  onPrintPreviewError: PropTypes.func.isRequired,
  onAfterPrintPreview:PropTypes.func.isRequired,
  getPrintPreviewContents:PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch),
    ...workspaceActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DMPanelHeader));
