import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import c from '../../../util/const';
import filters from "../../../helpers/filters";
import colors from "../../../util/colors";
import UserBadge from "../badges/UserBadge";
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import {connect} from "react-redux";
import ColorGenerator from "../../../helpers/color-generator";
import sapi from "../../../util/sapi";
import {getMessageForError} from "../../../util/errors";
import _ from "lodash";
import utils from "../../../util/util";
import he from "he";
import classnames from "classnames";
import Popover from "react-tiny-popover";
import sharedActions from "../../../actions/shared-actions";
import ManageObjectPermissionsWindow from "../../modals/ManageObjectPermissionsWindow";
import log from "../../../util/log";
import Truncate from "react-truncate"
import { isMobile } from 'react-device-detect';
import ReactToPrint from "react-to-print";
import homeActions from "../../../actions/home-actions";
import { DropTarget } from 'react-dnd'
import UploadHelper from "../components/UploadHelper";
import DocAttach from "../../../models/DocAttach";
import {withVFTranslation} from "../../../util/withVFTranslation";

class GuestRow extends PureComponent {
  
  constructor(props){
    super(props);
  
    this.hidePopover = this.hidePopover.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.getPopoverContent = this.getPopoverContent.bind(this);
    this.itemClick = this.itemClick.bind(this);
    this.itemClickMenu = this.itemClickMenu.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    
    this.state = {
      hovering : false,
      showMenu : false
    }
  }
  
  itemClick(evt){
    let {onItemClick, row, dm} = this.props;
  
    this.hidePopover(evt);
    
    onItemClick(row, dm);
  }
  
  itemClickMenu(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.itemClick(evt);
  }
  
  onMouseEnter(){
    if(this.state.hovering){
      return;
    }
    
    this.setState({
      hovering : true
    })
  }
  
  onMouseLeave(){
   this.setState({hovering : false})
  }
  
  setNotifyFlag(notify_flag, evt){
    if(!this.state.showMenu){
      return;
    }
    
    let { row, t } = this.props;
    
    this.hidePopover(evt);
    
    sapi.DM.mark(row.guest_uid, notify_flag)
      .then((res) => {
        this.props.updateDirectMessages();
      })
      .catch((err) => {
        this.props.showAlert(t('Error Updating Thread'), getMessageForError(err, t))
      })
  }
  
  viewContact(dm, evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt)
    this.props.showContactInfoWindow(dm.guest_uid, (res) => {
      log.log('show contact res', res);
      if(res){
        this.props.updateDirectMessages();
        this.props.updateContacts();
        
        if(this.props.workspace){
          this.props.refreshGuests(this.props.workspace.forum_id, this.props.workspace.host_uid);
        }
      }
      
    })
  }
  
  showMenu(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    
    this.setState({showMenu : true})
  }
  
  hidePopover(evt){
    
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  
    this.setState({showMenu : false})
  }
  
  manageThreadPermissions(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
    
    let { dm, workspace } = this.props;
    this.props.showManageObjectPermissionsWindow(dm.guest_uid, `${dm.first_name} ${dm.last_name}`, dm, ManageObjectPermissionsWindow.OBJECT_TYPES.GUEST, workspace, (res) => {
      if(res){
        this.props.updateNotifications();
        this.props.refreshGuests(workspace.forum_id, workspace.host_uid);
        this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true);
        this.props.refreshDocs(workspace.forum_id, workspace.host_uid);
      }
    })
  }
  
  removeGuestFromWorkspace(evt){
    if(!this.state.showMenu){
      return;
    }
    
    let { row, dm, workspace, t } = this.props;
    this.hidePopover(evt);
  
    this.props.showConfirm(
      t("Remove Guest"),
      t("Are you sure you want to remove ") + row.first_name + " " + row.last_name + t(" from this Workspace?"),
      (res) => {
        if(res){
          sapi.Guests.remove(row.guest_uid, workspace.forum_id)
            .then((res) => {
              this.props.updateNotifications();
              this.props.refreshGuests(workspace.forum_id, workspace.host_uid);
              this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true);
              this.props.refreshDocs(workspace.forum_id, workspace.host_uid);
              this.props.updateDirectMessages();
              this.props.onRemoveGuest(row, dm);
            })
            .catch((err) => {
              log.error('Error removing guest', err);
              this.props.showAlert(t('Error Removing Guest'), getMessageForError(err, t))
            })
        }
      })
  }
  
  deleteContact(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
    let { row, dm, t } = this.props;
    this.props.showContactConfirm(
      t('Deleting Contact'),
      row,
      t("WARNING: Deleting here means deleting everywhere. If ") +
      t("this user is a Guest in any of your Workspaces, they will be ") +
      t("removed from those Workspaces. You will lose any access ") +
      t("you have to Workspaces hosted by this contact. And any ") +
      t("Private Messages with this contact will be lost."),
      t("Delete"),
      t("Cancel"),
      (res) => {
        if(res){
          sapi.Contacts.delete(row.guest_uid)
            .then((res) => {
              this.props.updateNotifications();
              this.props.updateDirectMessages();
              this.props.updateContacts();
              this.props.onRemoveGuest(row, dm);
            })
            .catch((err) => {
              log.error("Error deleting contact", err);
              this.props.showAlert(t("Error deleting contact"), getMessageForError(err, t))
            })
        }
      }
    )
  }
  
  beforePrintPreview(){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover();
    
    return this.props.onBeforePrintPreview(this.props.dm.guest_uid);
  }
  
  showDeleteDM(evt) {
    let {
      dm,
      showAlert,
      updateDirectMessages,
      updateDMPreviews,
      refreshActiveDMMessages,
      refreshActiveHomeDMMessages,
      t
    } = this.props;
    
    this.props.showConfirm(
      t('Are you sure?'),
      t("Are you sure you want to delete your messages with ") + dm.first_name + ' ' + dm.last_name + t('?'),
      (res) => {
        if (res) {
          sapi.DM.delete(dm.guest_uid)
            .then((deleteRes) => {
              updateDirectMessages();
              updateDMPreviews([dm.guest_uid]);
              refreshActiveDMMessages();
              refreshActiveHomeDMMessages();
            })
            .catch((err) => {
              log.log('error deleting dm', err);
              showAlert(t('Error Deleting Messages'), getMessageForError(err, t))
            })
        }
      });
  }
  
  showAddExistingDoc(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
    
    let { dm } = this.props;
    log.log('show add doc window', dm);
    this.props.showAddDocWindow(dm.forum_id, dm.guest_uid, null, (res) => {
      log.log('add doc window', res);
      if(res){
        this.props.onDocAttach(res);
      }
    });
  }
  
  getPopoverContent(){
    let { dm, workspace, isActive, t } = this.props;

    return (
      <div onClick={this.hidePopover}>
        <div className="click-block" />
        <ul className="popover-content list-group">
          <a style={{...styles.menuHeader, ...{backgroundColor : ColorGenerator.generateColorFromId(dm.guest_uid)}}}
             className="list-group-item list-group-item-action">
            <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-android-person" />
            {dm.first_name} {dm.last_name}
          </a>
          
          <a onClick={this.itemClickMenu}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-eye"/>
            {t("View Messages")}
          </a>
          <a onClick={this.viewContact.bind(this, dm)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-information-outline"/>
            {t("Contact Information")}
          </a>
  
          {workspace && !workspace.host_uid &&
          <a onClick={this.manageThreadPermissions.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-android-share-alt"/>
            {t("Manage Thread Permissions")}
          </a>
          }
  
          <a onClick={this.showAddExistingDoc.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-paperclip"/>
            {t("Attach from...")}
          </a>
          
          {!dm.notify_flag &&
          <a onClick={this.setNotifyFlag.bind(this, true)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
            {t("Mark as Unread")}
          </a>
          }
          {dm.notify_flag &&
          <a onClick={this.setNotifyFlag.bind(this, false)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
            {t("Mark as Read")}
          </a>
          }
  
          <ReactToPrint onBeforeGetContent={this.beforePrintPreview.bind(this)}
                        onPrintError={this.props.onPrintPreviewError.bind(this)}
                        onAfterPrint={this.props.onAfterPrintPreview.bind(this)}
                        removeAfterPrint={false}
                        content={this.props.getPrintPreviewContents.bind(this)}
                        trigger={() => {
                          return (
                            <a style={styles.menuItem}
                               className="list-group-item list-group-item-action has-pointer">
                              <i style={styles.menuIcons} className="icon ion-printer"/>
                              {t("Print Thread")}
                            </a>
                          )
                        }}/>
  
          {isActive &&
          <a onClick={this.showDeleteDM.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-trash-a"/>
            {t("Delete Messages")}
          </a>
          }
          {workspace && !workspace.host_uid &&
          <a onClick={this.removeGuestFromWorkspace.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer assertive-color">
            <i style={styles.menuIcons} className="icon ion-close"/>
            {t("Remove Guest from Workspace")}
          </a>
          }
          {!workspace &&
          <a onClick={this.deleteContact.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer assertive-color">
            <i style={styles.menuIcons} className="icon ion-close"/>
            {t("Delete Contact")}
          </a>
          }
        </ul>
      </div>
    )
  }
  
  renderDragDropOverlays(){
    let { isOverCurrent, canDrop, t } = this.props;
    
    let contents = null;
    if(!isOverCurrent && !canDrop){
      contents = <div></div>
    }
    // else if(canDrop && !isOverCurrent){
    //   contents = <div style={{...UploadHelper.styles.overlayStyle, ...UploadHelper.styles.canDragDrop}}>You can drag that file here!</div>
    // }
    else if(isOverCurrent){
      contents = <div style={{...UploadHelper.styles.overlayStyle, ...UploadHelper.styles.dragDrop}}>{t("Drop files here!")}</div>
    }
    
    return contents;
  }
  
  render() {
    let { row, dm, isActive, connectDropTarget, t } = this.props;
    let { showMenu, hovering } = this.state;
  
    let previewBlock = dm ? this.props.dmPreviewLookup[dm.guest_uid] : null;
    let hasPreview = previewBlock && previewBlock.preview;
    let previewTime = null;
    let previewMesg = null;
    let hasPreviewDocs = null;
    if (hasPreview) {
      previewTime = utils.getMomentDate(previewBlock.preview.mesg_time);
      previewMesg = he.decode(previewBlock.preview.mesg);
      hasPreviewDocs = previewBlock.preview.mesg_docs > 0;
    }
    
    let notifyFlag = false;
    if(dm){
      notifyFlag = dm.notify_flag;
    }
    
    return connectDropTarget(
      <div onClick={this.itemClick}
           onMouseEnter={this.onMouseEnter}
           onMouseLeave={this.onMouseLeave}
           className={classnames('d-flex flex-row guest-row p-2', {'active' : isActive})}>
        {this.renderDragDropOverlays()}
        <div style={styles.iconStyle}>
          <i className={classnames('icon ion-record', {'transparent-color' : !notifyFlag}, {'primary-color' : notifyFlag && !isActive}, {'light-color' : notifyFlag && isActive})}/>
        </div>
        <div className="mr-2 d-table">
          <UserBadge guest={row}/>
        </div>
        <div className="d-flex flex-row item-row-wrap guest-row-wrap">
          <div className="flex-grow-1">
            <p className="mb-0 guest-label">
              {row.first_name} {row.last_name}
              <Popover
                isOpen={showMenu}
                position={['right', 'bottom']}
                onClickOutside={this.hidePopover}
                content={this.getPopoverContent}>
                <span style={{lineHeight: '18px'}}
                     className="pl-2 d-inline-block"
                     onClick={this.showMenu}>
                  <i style={styles.gearIcon}
                     className={classnames(`icon item-menu-icon ion-gear-b ${isActive ? 'active' : ''}`, {'invisible' : !isMobile && !showMenu && !hovering})}/>
                </span>
              </Popover>
            </p>
            {row.is_pending &&
            <p className="guest-preview mb-0 secondary-text-color font-italic">
              <Truncate lines={2} ellipsis={t('...')}>
                {t("Pending Import...")}
              </Truncate>
            </p>
            }
            {!row.is_pending && hasPreview &&
            <p className="guest-preview mb-0 secondary-text-color">
              {hasPreviewDocs &&
              <i style={styles.attachmentIcon} className={`icon ion-paperclip ${isActive ? 'light-color' : ''}`}/>
              }
              <Truncate lines={2} ellipsis={t('...')}>
                {previewTime.format('MMM D YYYY')}{previewMesg.length > 0 ? ' - ' + previewMesg : ''}
              </Truncate>
            </p>
            }
            {!row.is_pending && !hasPreview &&
            <p className="guest-preview mb-0 secondary-text-color font-italic">
              <Truncate lines={2} ellipsis={t('...')}>
                {t("No messages yet")}
              </Truncate>
            </p>
            }
            {!row.is_pending && !row.email_alert_flag &&
            <p className="blocked-row mb-0 assertive-color font-italic">
              <span className="blocked-badge mr-1">{t("Blocked")}</span>{t("Cannot send you email alerts.")}
            </p>
            }
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  iconStyle : {
    height: '48px',
    lineHeight: '48px',
    minWidth: '30px',
    textAlign: 'center'
  },
  attachmentIcon : {
    marginRight: '5px',
    verticalAlign : 'baseline',
    fontSize : '16px'
  },
  
  //duplicated in WorkspaceRow...consolidate this.
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT,
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
}

GuestRow.propTypes = {
  row: PropTypes.object.isRequired,
  dm: PropTypes.object,
  onItemClick: PropTypes.func.isRequired,
  isActive : PropTypes.bool.isRequired,
  onDocAttach : PropTypes.func.isRequired,
  onRemoveGuest : PropTypes.func.isRequired,
  onBeforePrintPreview: PropTypes.func.isRequired,
  onPrintPreviewError: PropTypes.func.isRequired,
  onAfterPrintPreview:PropTypes.func.isRequired,
  getPrintPreviewContents:PropTypes.func.isRequired
}
const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    dmPreviewLookup : state.shared.dmPreviewLookup
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...workspaceActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),
    refreshActiveHomeDMMessages: () => dispatch(homeActions.refreshActiveDMMessages()),
  };
};

const dropTargetSpec = {
  canDrop(props, monitor) {
    // You can disallow drop based on props or item
    //const item = monitor.getItem()
    return true;
  },
  
  drop(props, monitor, component) {
    // Obtain the dragged item
    const item = monitor.getItem()
    
    log.log('guestRow onDocAttach', item.row, props.row);
  
    let docs = [{
      ...item.row,
      forum_id : item.src_forum_id,
      host_uid : item.src_host_uid,
    }]
    let result = DocAttach.buildDMAttachResult(props.row.guest_uid, docs)
  
    props.onDocAttach(result);
    
    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return props.row;
  }
}

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  }
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(
  DropTarget(
    c.DRAG_DROP_TYPES.THREAD_DOC,
    dropTargetSpec,
    collect
  )(GuestRow)));
