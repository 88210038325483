import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";
import utils from '../../../util/util';
import c from '../../../util/const';

import moment from 'moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import MessageBlock from "./MessageBlock";
import colors from "../../../util/colors";
import Scroll from 'react-scroll';
import Autolinker from "autolinker";
import he from 'he';
import filters from "../../../helpers/filters";
import EmptyState from "../components/EmptyState";
import Button from "../elements/Button";
import ReactToPrint from "react-to-print";
import ChatPrintPreviewSvc from "./ChatPrintPreviewSvc";
import {VariableSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Measure from "react-measure";
import msgHelper from "../../../helpers/msg-helper";
import workspaceActions from "../../../actions/workspace-actions";
import PlaceholderLoaders from "../util/PlaceholderLoaders";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

let scroll = Scroll.animateScroll;

class ChatPanelMessages extends PureComponent {

  constructor(props){
    super(props);
  
    this.mounted = false;
    
    this.onDocClick = this.onDocClick.bind(this);
    
    this.printPreviewRef = React.createRef();
    this.listRef = React.createRef();
    this.scrollableContainerRef = React.createRef();
  
    this.state = {
      doingPrintPreview : false,
      pollingRunning : false,
      pollTimer : null,
      showNewMessageIndicator : false
    }
  }
  
  componentDidMount() {
    this.mounted = true;
  
    this.setState({pollingRunning : true}, () => this.doPollMessages());
    
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  
    this.scrollToBottomWhenReady();
  }
  
  scrollToBottomWhenReady(){
    utils.waitForCondition(() => {
        return !!document.getElementById('chat-scroll-container') && !!this.props.messageBlocks;
      })
      .then(() => {
        let scrollElem = document.getElementById('chat-scroll-container');
        if(scrollElem) {
          scrollElem.addEventListener('scroll', this.onScroll);
  
          setTimeout(() => {
            this.scrollToBottom();
          })
        }
      })
  }
  
  componentWillUnmount(){
    if (this.state.pollTimer) {
      clearTimeout(this.state.pollTimer);
    }
    this.setState({
      pollingRunning: false,
      pollTimer: null
    });
    this.mounted = false;
  
    let scrollElem = document.getElementById('chat-scroll-container');
    scrollElem.removeEventListener('scroll', this.onScroll);
    
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    let doNewMsgCheck = false;
    let prevDM = _.get(prevProps, 'dm');
    let curDM = _.get(this.props, 'dm');
    let prevThread = _.get(prevProps, 'thread');
    let curThread = _.get(this.props, 'thread');
    if(prevDM && curDM && curDM.guest_uid === prevDM.guest_uid){
      doNewMsgCheck = true;
    }
    else if(prevThread && curThread.chat_id === prevThread.chat_id){
      doNewMsgCheck = true;
    }
    
    //only do this check if prevProps.messageBlocks > 0.  We can get away with this, because
    //this is really just for polling long lists.  For the empty state case here, we can overly update if we need to.
    if(doNewMsgCheck && prevProps.messageBlocks && prevProps.messageBlocks.length > 0 && this.props.messageBlocks){
      if(msgHelper.hasNewMessage(this.props.accountInfo.uid, prevProps.messageBlocks, this.props.messageBlocks)){
        this.handleNewDMMessageReceived();
      }
    }
  }
  
  onScroll =_.debounce(() => {
    let distanceFromBottom = this.calculateScrollDistanceFromBottom();
    if(distanceFromBottom < 100){
      this.setState({showNewMessageIndicator : false})
    }
  }, 50)
  
  calculateScrollDistanceFromBottom(){
    let scrollElem = document.getElementById('chat-scroll-container');
    if(!scrollElem){
      return 0;
    }
    let distanceFromTop = scrollElem.scrollTop;
    let height = scrollElem.scrollHeight - scrollElem.clientHeight;
    return height - distanceFromTop;
  }
  
  handleNewDMMessageReceived() {
    log.log('new message received!');
    
    let distanceFromBottom = this.calculateScrollDistanceFromBottom();
    if(distanceFromBottom <= 400){
      this.scrollToBottom(250);
    }
    else{
      this.setState({showNewMessageIndicator : true});
    }
  }
  
  doPollMessages(){
    if(this.state.pollingRunning && this.mounted){
      let timer = setTimeout(() => {
        if(!this.state.pollingRunning || !this.mounted) {
          return;
        }
        
        if(this.props.isBrowserTabActive) {
          log.log('polling messages');
          this.props.refreshMessages();
        }
        
        this.doPollMessages();
      }, c.polling.threadPollTimeMs);
      this.setState({pollTimer : timer})
    }
  }
  
  renderNewMessageIndicator(){
    if(!this.state.showNewMessageIndicator){
      return null;
    }
    let { t } = this.props;
    return (
      <div className="position-absolute fade show text-center"
           style={{
             bottom : 100,
             left : 0,
             right :0,
             zIndex: 1000
           }}
      >
        <button className="btn btn-primary"
                onClick={this.onNewMessageButtonClick.bind(this)}>
          {t("New Message")}
          <br />
          {t("Click to View")}
        </button>
      </div>
    )
  }
  
  onNewMessageButtonClick(){
    this.setState({showNewMessageIndicator : false})
    this.scrollToBottom(250);
  }
  
  doPrintPreview(){
    return new Promise((resolve, reject) => {
      this.setState({
        doingPrintPreview : true
      }, () => {
        utils.waitForCondition(() => {
            return !!this.printPreviewRef;
          })
          .then(() => {
            resolve(true);
          })
      })
    })
    
  }
  
  cleanupPrintPreview(){
    log.log('cleanup print preview')
    this.setState({
      doingPrintPreview : false
    })
  }
  
  scrollToBottom(duration){
    setTimeout(() => {
      scroll.scrollToBottom({
        containerId: 'chat-scroll-container',
        duration : duration || 0
      });
    }, 100)
  }
  
  onDocClick(doc){
    this.props.docClick(doc);
  }
  
  render() {
    let { thread, dm, messageBlocks, t } = this.props;
    let { doingPrintPreview } = this.state;
    
    let chatContents = null;
    if(!messageBlocks){
      chatContents = (
        PlaceholderLoaders.renderMessageBlockPlaceholderRows(5)
      )
    }
    else{
      chatContents = (
        <>
          {messageBlocks.length === 0 &&
          <EmptyState>
            <div className="text-center secondary-text-color" style={{margin : '0px 100px', paddingTop : '30vh'}}>
              <p>
                {t("There are no Documents or Messages in this Thread yet.  Start the conversation below.")}
              </p>
            </div>
          </EmptyState>
          }
          {this.renderNewMessageIndicator()}
          <div>
            {messageBlocks.map((item, index) => {
              let showDay = false;
              let currentDate = null;
              if(index === 0){
                showDay = true;
                currentDate = filters.momentDate(item.blockList[0].mesg_date);
              }
              else{
                let previousBlock = messageBlocks[index - 1];
        
                let previousDate = filters.momentDate(previousBlock.blockList[0].mesg_date);
                currentDate = filters.momentDate(item.blockList[0].mesg_date);
        
                showDay = !previousDate.isSame(currentDate, 'day');
              }
      
              let guest = dm;
              if(thread){
                guest = this.props.findGuestInActiveThreadParticipants(item.guest_uid)
                if(!guest){
                  //A little weird looking, but if we can't find the guest from thread participants,
                  //just pass in the message itself, since it has guest_uid, first_name, last_name on it.
                  guest = item.blockList[0];
                }
              }
      
              return (
                <Fragment key={item.blockList[0].mesg_id}>
                  {showDay && currentDate &&
                  <div style={styles.msgDateWrap}>
                    <div style={styles.msgDateBlock}>
                      <span className="light-grey-color">{currentDate.format('MMMM Do YYYY')}</span>
                    </div>
                  </div>
                  }
                  {(dm || thread) &&
                  <MessageBlock guest={guest}
                                dm={dm}
                                thread={thread}
                                mesg_edit_flag={this.props.mesg_edit_flag}
                                docClick={this.onDocClick}
                                block={item}
                                onMesgEditClick={this.props.onMesgEditClick}
                                onMesgHistoryClick={this.props.onMesgHistoryClick}
                                findDocInfo={this.props.findDocInfo}
                                fulfillSignatureRequestClick={this.props.fulfillSignatureRequestClick}
                                editSignatureRequestClick={this.props.editSignatureRequestClick}
                                completeSignatureRequestClick={this.props.completeSignatureRequestClick} />
                  }
                </Fragment>
              )
            })}
          </div>
        </>
      )
    }
    
    return (
      <>
        {doingPrintPreview &&
        <ChatPrintPreviewSvc onRef={ref => (this.printPreviewRef = ref)}
                             thread={thread}
                             dm={dm}
                             messageBlocks={messageBlocks}
                             findDocInfo={this.props.findDocInfo}/>
        }
        <div style={styles.messagePanelScroll}
             id="chat-scroll-container">
          {chatContents}
        </div>
      </>
    )
  }
}

const styles = {
  messagePanelScroll : {
    height : '100%',
    overflow: 'auto',
  },
  msgDateWrap : {
    borderTop : '1px solid ' + colors.LIGHT_GREY,
    margin : '20px auto 0'
  },
  msgDateBlock : {
    position : 'relative',
    backgroundColor : colors.LIGHT,
    textAlign : 'center',
    display : 'table',
    margin : 'auto',
    top : '-9px',
    paddingLeft : '8px',
    paddingRight : '8px',
    fontSize : '11px'
  }
}

ChatPanelMessages.propTypes = {
  thread: PropTypes.object,
  dm: PropTypes.object,
  messageBlocks: PropTypes.array,
  mesg_edit_flag : PropTypes.bool.isRequired,
  refreshMessages : PropTypes.func.isRequired,
  findDocInfo : PropTypes.func.isRequired,
  onMesgHistoryClick : PropTypes.func,
  onMesgEditClick : PropTypes.func,
  onRef : PropTypes.func,
  docClick : PropTypes.func.isRequired,
  editSignatureRequestClick : PropTypes.func.isRequired,
  completeSignatureRequestClick : PropTypes.func.isRequired,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,
}

ChatPanelMessages.defaultProps = {
  messageBlocks : [],
}

const mapStateToProps = (state) => {
  return {
    isBrowserTabActive : state.app.isBrowserTabActive,
    accountInfo : state.shared.accountInfo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    findGuestInActiveThreadParticipants : (guest_uid) => dispatch(workspaceActions.findGuestInActiveThreadParticipants(guest_uid)),
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChatPanelMessages));
