import c from '../util/const';
import Promise from "bluebird";
import log from "../util/log";
import sapi from "../util/sapi";
import enums from '../util/enums';
import pdfjsLib from "pdfjs-dist/es5/build/pdf";
import _ from "lodash";
import PdfSignatureRequestOverlay from "../components/partials/pdf-preview/PdfSignatureRequestOverlay";
import PdfPreview from "../components/partials/pdf-preview/PdfPreview";

const pdfPreviewActions = {
  
  initialize(forum_id, host_uid, doc_id, chat_id){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.pdf.initialize,
        forum_id,
        host_uid,
        doc_id,
        chat_id,
      })
    }
  },
  
  loadPdf(){
    return (dispatch, getState) => {
      let {forum_id, host_uid, doc_id, pdfWriter} = getState().pdfPreview;
      
      return this.downloadPdfData(forum_id, host_uid, doc_id)
        .then((rawPdf) => {
          dispatch({
            type : c.actions.pdf.setRawPdf,
            rawPdf
          })
          return dispatch(this.loadPdfModels(rawPdf));
        })
    }
  },
  
  loadPdfModels(rawPdf){
    return (dispatch, getState) => {
      
      //This is a promise, but not needed in main promise flow
      dispatch(this.tryLoadPdfWriter(rawPdf));
  
      let init = {
        data: rawPdf,
        cMapUrl: "/node_modules/pdfjs-dist/cmaps/",
        cMapPacked: true,
      }
  
      //NOTE: this had to be relocated to the PUBLIC directory.  There were weird runtime problems referencing
      //it from node_modules
      pdfjsLib.GlobalWorkerOptions.workerSrc = c.pdf.pdfjs_workerPath;
  
      let pdf = null;
      return pdfjsLib.getDocument(init).promise
        .then((pdfRes) => {
          log.log('pdf loaded', pdfRes);
          pdf = pdfRes
          return pdf.getMetadata();
        })
        .then((pdfMetadata) => {
          log.log('got pdf metadata', pdfMetadata);
          let isPdfPortfolio = _.get(pdfMetadata, 'info.IsCollectionPresent');
          if(isPdfPortfolio){
            let pdfLoadErr = { isPdfCollection : true };
            this.setPdfJsLoadErr(pdfLoadErr)
            return Promise.reject(pdfLoadErr);
          }
          
          dispatch({
            type : c.actions.pdf.setPdfJsModel,
            pdf : pdf
          })
  
          let calls = [];
          for (let num = 1; num <= pdf.numPages; num++) {
            //Kind of a hack, the app expects pageIndex to be available on page, but
            //They renamed this property to _pageIndex at some point.
            calls.push(pdf.getPage(num)
              .then((page) => {
                page.pageIndex = page._pageIndex
                return page;
              }));
          }
  
          return Promise.all(calls);
        })
        .then((pageRes) => {
          log.log('pageRes', pageRes);
          dispatch({
            type : c.actions.pdf.setPdfJsPages,
            pages : pageRes
          })
        })
    }
  },
  
  reloadPdfDataFromPdfWriter(){
    return (dispatch, getState) => {
      let { pdfWriter } = getState().pdfPreview;
      return pdfWriter.getUintPdfData()
        .then((rawPdf) => {
  
          dispatch({
            type : c.actions.pdf.setRawPdf,
            rawPdf
          })
          
          return dispatch(this.loadPdfModels(rawPdf))
        })
    }
  },
  
  downloadPdfData(forum_id, host_uid, doc_id){
    return sapi.Docs.view(forum_id, host_uid, doc_id, true)
      .then((res) => {
        return new Uint8Array(res);
      })
  },
  
  tryLoadPdfWriter(){
    return (dispatch, getState) => {
      let {forum_id, host_uid, doc_id} = getState().pdfPreview;
      log.log('tryLoadWriter', forum_id, host_uid, doc_id, getState().pdfPreview)
      let { pdfWriter, rawPdf } = getState().pdfPreview;
      pdfWriter.tearDown()
      pdfWriter.load(rawPdf)
        .then(() => {
          if (!pdfWriter.hasLoaded()) {
            log.log('pdf writer was unable to load pdf')
            dispatch({
              type : c.actions.pdf.setPdfWriterLoadErr,
              pdfWriterLoadErr : "Unable to load PDF"
            })
          }
          else {
            dispatch({
              type : c.actions.pdf.setPdfWriterLoadErr,
              pdfWriterLoadErr : null
            })
          }
        })
        .catch((err) => {
          log.log('pdf writer was unable to load pdf', err);
          dispatch({
            type : c.actions.pdf.setPdfWriterLoadErr,
            pdfWriterLoadErr : err
          })
        })
    }
  },
  
  setPdfJsLoadErr(err){
    return {
      type : c.actions.pdf.setPdfJsLoadErr,
      pdfLoadErr : err
    }
  },
  
  setWindowSigningMode(mode){
    return (dispatch, getState) => {
      dispatch({
        type : c.actions.pdf.setWindowSigningMode,
        mode
      })
    }
  },
  
  //v2 stuff
  
  loadSignatureRequestData(doesSignatureRequestExist, signatureRequestData){
    return (dispatch, getState) => {
      let isEditingSignatureRequest = _.isUndefined(doesSignatureRequestExist) ? false : !doesSignatureRequestExist;
      let isFulfillingSignatureRequest = !!(signatureRequestData && signatureRequestData.sign_requestor);
      
      dispatch({
        type : c.actions.pdf.loadSignatureRequestData,
        isEditingSignatureRequest,
        isFulfillingSignatureRequest,
        signatureRequestData
      })
  
      dispatch({
        type : c.actions.pdf.setWindowSigningMode,
        mode : enums.WINDOW_SIGNING_STATUS.V2_SIGNING
      })
      
      dispatch(this.loadSignatureOverlaysIfNeeded(doesSignatureRequestExist, isFulfillingSignatureRequest, signatureRequestData))
    }
  },
  
  loadSignatureOverlaysIfNeeded(doesSignatureRequestExist, isFulfillingSignatureRequest, signatureRequestData){
    return (dispatch, getState) => {
      log.log('loadSignatureOverlays', doesSignatureRequestExist, signatureRequestData);
      if(doesSignatureRequestExist){
        let sign_data = signatureRequestData.signature_request.sign_data;
        let overlays = [];
    
        let topMostRequest = null;
        _.each(sign_data.signatures, (sig) => {
  
          if(sig.scale !== 1){
            sig = _.extend({}, sig,
              {
                x : sig.x / sig.scale,
                y : sig.y / sig.scale,
                width : sig.width / sig.scale,
                height : sig.height / sig.scale,
                scale : 1
              })
          }
          var scale = sig.scale || 1;
          sig.height = sig.height / scale;
          sig.width = sig.width / scale;
          
          let overlay = {
            id : _.uniqueId('vf-sig-request-overlay-'),
            pageIndex : sig.pageIndex,
            coords : {
              x : sig.x - PdfSignatureRequestOverlay.LEFT_BUTTON_WIDTH, //no need to scale this, because the handle size is relative to the overlay size already.
              y : sig.y
            },
            scale : sig.scale,
            width : sig.width,
            height : sig.height,
            signatureType : sig.signatureType,
            signatureCustomLabel : sig.signatureCustomLabel || ''
          }
      
          if(isFulfillingSignatureRequest){
            if(!topMostRequest){
              topMostRequest = overlay;
            }
        
            if(overlay.pageIndex < topMostRequest.pageIndex) {
              topMostRequest = overlay;
            }
            else if (overlay.pageIndex === topMostRequest.pageIndex && overlay.coords.y < topMostRequest.coords.y) {
              topMostRequest = overlay;
            }
            overlay.confirmed = false;
          }
      
          overlays.push(overlay);
        })
    
        if(isFulfillingSignatureRequest) {
          _.each(overlays, (overlay) => {
            if (topMostRequest.id === overlay.id) {
              overlay.selected = true;
            }
            else {
              overlay.selected = false;
            }
          })
        }
      
        log.log('loading overlays', overlays);
        dispatch(this.updateSignatureRequestOverlays(overlays));
      }
    }
  },
  
  updateSignatureRequest(terms, smsNumber){
    return (dispatch, getState) => {
      dispatch({
        type: c.actions.pdf.updateSignatureRequest,
        terms,
        smsNumber,
      })
    }
  },
  
  saveSignatureRequest(){
    return (dispatch, getState) => {
      let {signatureRequestOverlays, signatureRequestData} = getState().pdfPreview;
  
      let sign_data = {
        signatures : [],
      };
      if(signatureRequestData.smsNumber){
        sign_data.smsNumber = signatureRequestData.smsNumber;
      }
      _.each(signatureRequestOverlays, (overlay) => {
        sign_data.signatures.push({
          pageIndex : overlay.pageIndex,
          x : overlay.coords.x + PdfSignatureRequestOverlay.LEFT_BUTTON_WIDTH,
          y : overlay.coords.y,
          scale : overlay.scale,
          width : overlay.width,
          height : overlay.height,
          signatureType : overlay.signatureType,
          signatureCustomLabel : overlay.signatureCustomLabel
        })
      })
  
      log.log('submitting request', signatureRequestData);
  
      if(signatureRequestData.dm_guest_uid){
        return sapi.DM.addThreadSignatureRequest(
          signatureRequestData.dm_guest_uid,
          signatureRequestData.mesg_id,
          signatureRequestData.doc_id,
          signatureRequestData.signer_uid,
          sign_data,
          null,
          signatureRequestData.terms,
          !!sign_data.smsNumber)
      }
      else{
        return sapi.Workspace.addThreadSignatureRequest(
          signatureRequestData.forum_id,
          signatureRequestData.chat_id,
          signatureRequestData.mesg_id,
          signatureRequestData.doc_id,
          signatureRequestData.signer_uid,
          sign_data,
          null,
          signatureRequestData.terms,
          !!sign_data.smsNumber)
      }
    }
  },
  
  updateSignatureRequestOverlays(overlays, confirmSignatureCount){
    return (dispatch) => {
      
      let args = {
        signatureRequestOverlays : overlays,
        signatureRequestOverlayLookup : PdfPreview.sortV2OverlaysToPageIndex(overlays),
      }
      
      if(_.isNumber(confirmSignatureCount)){
        args.confirmSignatureCount = confirmSignatureCount;
      }
      
      dispatch({
        type : c.actions.pdf.updateSignatureRequestOverlays,
        ...args
      });
    }
  },
  
  setEditingSignatureRequest(isEditingSignatureRequest){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.setEditingSignatureRequest,
        isEditingSignatureRequest,
      })
    }
  },
  
  //v1 stuff
  
  updateV1SigningOverlays(signatureIds){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1SignatureOverlays,
        signatureIds
      })
    }
  },
  
  updateV1ActiveOverlays(activeOverlays){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1ActiveOverlays,
        activeOverlays
      })
    }
  },
  
  updateV1InactiveOverlays(invalidOverlays){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1InactiveOverlays,
        invalidOverlays
      })
    }
  },
  
  updateV1HasPdfChanges(pdfChangesMade){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1PdfChangesMade,
        pdfChangesMade
      })
    }
  },
  
  updateHasValidV1Signing(hasValidV1Signing){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1HasValidSigning,
        hasValidV1Signing
      })
    }
  },
  
  updateHasCommittedV1Signatures(hasCommittedSignatures){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.updateV1HasCommittedSignatures,
        hasCommittedSignatures
      })
    }
  },
  
  resetV1Signing(){
    return (dispatch) => {
      dispatch({
        type : c.actions.pdf.resetV1SigningState
      })
    }
  },
  
  teardown(){
    return {
      type : c.actions.pdf.cleanup
    }
  },
  
  mapToDispatch(dispatch) {
    return {
      initialize: (forum_id, host_uid, doc_id, chat_id) => dispatch(this.initialize(forum_id, host_uid, doc_id, chat_id)),
      loadPdf : () =>  dispatch(this.loadPdf()),
      loadPdfModels: (rawPdf) => dispatch(this.loadPdfModels(rawPdf)),
      setPdfJsLoadErr : (err) => dispatch(this.setPdfJsLoadErr(err)),
      reloadPdfDataFromPdfWriter : () => dispatch(this.reloadPdfDataFromPdfWriter()),
      setWindowSigningMode : (mode) => dispatch(this.setWindowSigningMode(mode)),
  
      setEditingSignatureRequest : (isEditingSignatureRequest) => dispatch(this.setEditingSignatureRequest(isEditingSignatureRequest)),
      loadSignatureRequestData : (doesSignatureRequestExist, signatureRequestData) => dispatch(this.loadSignatureRequestData(doesSignatureRequestExist ,signatureRequestData)),
      updateSignatureRequestOverlays : (overlays, confirmSignatureCount) => dispatch(this.updateSignatureRequestOverlays(overlays, confirmSignatureCount)),
      updateSignatureRequest : (terms, smsNumber) => dispatch(this.updateSignatureRequest(terms, smsNumber)),
      saveSignatureRequest : () => dispatch(this.saveSignatureRequest()),
      
      updateV1HasPdfChanges : (pdfChangesMade) => dispatch(this.updateV1HasPdfChanges(pdfChangesMade)),
      updateHasValidV1Signing : (hasValidV1Signing) => dispatch(this.updateHasValidV1Signing(hasValidV1Signing)),
      updateHasCommittedV1Signatures : (hasCommittedSignatures) => dispatch(this.updateHasCommittedV1Signatures(hasCommittedSignatures)),
      updateV1SigningOverlays : (signatureIds) => dispatch(this.updateV1SigningOverlays(signatureIds)),
      updateV1ActiveOverlays : (activeOverlays) => dispatch(this.updateV1ActiveOverlays(activeOverlays)),
      updateV1InactiveOverlays : (inactiveOverlays) => dispatch(this.updateV1InactiveOverlays(inactiveOverlays)),
      resetV1Signing: () => dispatch(this.resetV1Signing()),
      
      teardown : () => dispatch(this.teardown())
    }
  },
}

export default pdfPreviewActions;
