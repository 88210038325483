import React, {Component} from 'react';
import PropTypes from 'prop-types';
import filters from "../../../helpers/filters";
import uploadActions from "../../../actions/upload-actions";
import modalActions from "../../../actions/modal-actions";
import {connect} from "react-redux";
import log from "../../../util/log";
import ProgressBar from "../elements/ProgressBar";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class UploadingThreadDoc extends Component {
  
  itemClick(){
    let {onItemClick, row} = this.props;
    
    onItemClick(row);
  }
  
  render() {
    let { row, activeUpload, uploadProgress, t } = this.props;
    
    return (
      <div onClick={this.itemClick.bind(this)}
           className={'d-flex flex-row'}>
        <div style={iconStyle}/>
        <div>
          <p className={'mb-0'}>{row.file_name}</p>
          {uploadProgress && activeUpload && activeUpload.key === row.key &&
            <ProgressBar percentProgress={uploadProgress.percent} />
          }
          {!uploadProgress || !activeUpload || activeUpload.key !== row.key &&
          <p className={'mb-0 light-grey-color'}>
            {t("Pending...")}
          </p>
          }
        </div>
      </div>
    )
  }
}

const iconStyle = {
  height: '48px',
  lineHeight: '48px',
  minWidth: '30px',
  textAlign: 'center'
}

UploadingThreadDoc.propTypes = {
  row: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    activeUpload : state.upload.activeUpload,
    uploadProgress : state.upload.uploadProgress
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(UploadingThreadDoc));
