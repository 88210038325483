import c from "./const";
import storageHelper from "./storage-helper";

const vfLocalStorage = {
  get (key) {
    if(!localStorage){
      console.error('local storage does not exist');
      return null;
    }
    
    return localStorage.getItem(key);
  },

  set (key, val) {
    if(!localStorage){
      console.error('local storage does not exist');
      return null;
    }
    
    return localStorage.setItem(key, val);
  },

  remove (key){
    if(!localStorage){
      console.error('local storage does not exist');
      return null;
    }
    
    return localStorage.removeItem(key);
  },

  clear () {
    if(!localStorage){
      console.error('local storage does not exist');
      return null;
    }
    
    return localStorage.clear();
  },
  
  testStorage(){
    return storageHelper.storageAvailable('localStorage')
  },
  
  clearExceptEndpoint(){
    this.remove(c.localstorage.email)
    this.remove(c.localstorage.device)
    this.remove(c.localstorage.lastSelectedHomeTab)
    this.remove(c.localstorage.sid)
    this.remove(c.localstorage.token)
    this.remove(c.localstorage.vip)
    this.remove(c.localstorage.wsSort)
  }
}

export default vfLocalStorage;
