import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";
import c from '../../../util/const'
import TextareaAutosize from 'react-textarea-autosize';

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import Button from "../elements/Button";
import colors from "../../../util/colors";
import UploadHelper from "../components/UploadHelper";
import UserBadge from "../badges/UserBadge";
import utils from "../../../util/util";
import modalActions from "../../../actions/modal-actions";
import UpgradeDialog from "../../modals/UpgradeDialog";
import {withRouter} from "react-router-dom";
import {getMessageForError} from "../../../util/errors";
import MaxChatMessageWarning from "./MaxChatMessageWarning";
import DocExtensionPlaceholder from "../elements/DocExtensionPlaceholder";
import SignatureRequest from "../../../models/SignatureRequest";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class DocSigningStatus extends PureComponent {
  
  constructor(props){
    super(props);
    
    this.onCompleteRequestClick = this.onCompleteRequestClick.bind(this);
    this.onFulfillSignatureRequestClick = this.onFulfillSignatureRequestClick.bind(this);
    this.onEditRequestClick = this.onEditRequestClick.bind(this);
  }
  
  onCompleteRequestClick(){
    let {msg, doc} = this.props;
    this.props.completeRequestClick(msg, doc);
  }
  
  onEditRequestClick(){
    let {msg, doc} = this.props;
    this.props.editRequestClick(msg, doc);
  }
  
  onFulfillSignatureRequestClick(){
    let {msg, doc} = this.props;
    this.props.fulfillSignatureRequestClick(msg, doc);
  }
  
  renderRequestIncomplete(){
    let {accountInfoGuest, msg, t} = this.props;
    if (msg.guest_uid === accountInfoGuest.guest_uid) {
      return (
        <Fragment>
          <div style={styles.signingText}>
            {t("Signing request incomplete")}
            <Button onClick={this.onCompleteRequestClick} className="btn btn-sm btn-signing ml-2">
              {t("Complete Request")}
            </Button>
          </div>
        </Fragment>
      )
    }
  }
  
  renderSigningRequested(doc){
    let { accountInfoGuest, dm, workspace, t } = this.props;
    
    let signer = null;
    let signRequestor = null;
    if(dm){
      if(doc.signer_uid === accountInfoGuest.guest_uid){
        signer = accountInfoGuest;
      }
      else{
        signRequestor = dm;
      }
    }
    else{
      let user = this.props.findInParticipants(doc.signer_uid);
      if(!user){
        return;
      }
      if(user.guest_uid === accountInfoGuest.guest_uid){
        signer = this.props.findInParticipants(workspace.host_uid);
      }
      else if(!workspace.host_uid){
        signRequestor = user;
      }
    }
    
    if(signer){
      return (
        <Fragment>
          <div style={styles.signingText}>
          <span className="mr-1">
            <UserBadge guest={signer} small={true} />
          </span>
            {t("Signing requested")}
            <Button onClick={this.onFulfillSignatureRequestClick} className="btn btn-sm btn-signing ml-2">{t("Review and Sign")}</Button>
          </div>
        </Fragment>
      )
    }
    else if(signRequestor){
      return (
        <Fragment>
          <div style={styles.signingText}>
          <span className="mr-1">
            <UserBadge guest={signRequestor} small={true} />
          </span>
            {t("Signing requested")}
            <Button onClick={this.onEditRequestClick} className="btn btn-sm btn-signing ml-2">{t("Edit Request")}</Button>
          </div>
        </Fragment>
      )
    }
    else{
      return null;
    }
  }
  
  renderSigningCompleted(doc){
    let { accountInfoGuest, dm, t } = this.props;
  
    let user = null;
    let isYou = false;
    if(dm){
      if(doc.signer_uid === accountInfoGuest.guest_uid){
        isYou = true;
        user = _.extend({}, accountInfoGuest);
      }
      else{
        user = _.extend({}, dm);
      }
    }
    else{
      user = this.props.findInParticipants(doc.signer_uid);
      if(!user){
        return;
      }
      isYou = false;
      if(user.guest_uid === accountInfoGuest.guest_uid){
        isYou = true;
      }
    }
    
    return (
      <Fragment>
        <div style={styles.signingText}>
          <span className="mr-1">
            {isYou &&
            <UserBadge guest={user}
                       overrideColor={colors.PRIMARY}
                       small={true}/>
            }
            {!isYou &&
            <UserBadge guest={user} small={true}/>
            }
          </span>
          {isYou &&
          <span>{t("You signed this document")}</span>
          }
          {!isYou &&
          <span>{user.first_name} {t("signed this document")}</span>
          }
        </div>
      </Fragment>
    )
  }
  
  render(){
    let {
      doc,
    } = this.props;
    
    return (
      <>
        <span className="mb-0">
          {doc.sign_status === SignatureRequest.SIGN_STATUS.INCOMPLETE &&
          this.renderRequestIncomplete(doc)
          }
          {doc.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED &&
          this.renderSigningRequested(doc)
          }
          {doc.sign_status === SignatureRequest.SIGN_STATUS.SIGNED &&
          this.renderSigningCompleted(doc)
          }
          </span>
      </>
    )
  }
}

const styles = {
  signingText : {
    fontSize : '14px',
    lineHeight: '24px',
    color: colors.PRIMARY,
    marginLeft: '25px'
  }
}

DocSigningStatus.propTypes = {
  doc: PropTypes.object.isRequired,
  msg : PropTypes.object.isRequired,
  
  completeRequestClick : PropTypes.func.isRequired,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,
  editRequestClick : PropTypes.func.isRequired,
  
  workspace : PropTypes.object,
  dm : PropTypes.object,
  findInParticipants : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DocSigningStatus)));
