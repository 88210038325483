import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import EmptyState from "../../components/EmptyState";
import accountActions from "../../../../actions/account-actions";
import Account from "../../../pages/Account";
import { withTranslation, Trans } from 'react-i18next';
import {withVFTranslation} from "../../../../util/withVFTranslation";

class LanguageCtrl extends Component {
  
  constructor(props){
    super(props);
    
    this.state = {
      selectedLanguage : ''
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  changeLanguage(evt){
    this.props.i18n.changeLanguage(evt.target.value, (err, t) => {
      if (err){
        return console.log('something went wrong changing language', err);
      }
      else{
        this.setState({selectedLanguage : this.props.i18n.language})
      }
    });
  }
  
  init() {
    this.setState({selectedLanguage : this.props.i18n.language})
  }
  
  getLanguageOptions(){
    let { t } = this.props;
    return [
      {
        val : 'en-us',
        display : t('English')
      },
      {
        val : 'dev',
        display : t('Development')
      }
    ]
  }
  
  render() {
    let { selectedLanguage} = this.state;
    let { t } = this.props;
    
    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
        <div className={'row'}>
          <div className={'col'}>
            
            <div className="form-group">
              <label>{t('Language')}</label>
              <select className="form-control"
                      value={selectedLanguage}
                      onChange={this.changeLanguage.bind(this)}>
                {this.getLanguageOptions().map((option) => {
                  return <option key={option.val}
                                 value={option.val}>
                    {option.display}
                  </option>
                })}
              </select>
            </div>
            
          </div>
        </div>
      </div>
    )
  }
}

LanguageCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
  
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageCtrl)));
