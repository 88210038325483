import React, {Component} from 'react';
import {connect} from 'react-redux';
import EmailCard from '../partials/auth/EmailCard';
import PasswordCard from '../partials/auth/PasswordCard';
import ForgotPasswordCard from '../partials/auth/ForgotPasswordCard';
import BoardingCard from '../partials/auth/BoardingCard';
import TFACard from '../partials/auth/TFACard';
import TFAPwdResetCard from '../partials/auth/TFAPwdResetCard';
import c from '../../util/const';
import config from '../../util/config';
import vfLocalStorage from '../../util/local-storage'
import {Helmet} from 'react-helmet'
import authActions from '../../actions/auth-actions';
import InstitutionPanel from "../partials/auth/InstitutionPanel";

import Image from '../partials/elements/Image';

import logo from '../../resources/logo-w-text-white.png';
import {CSSTransition} from "react-transition-group";
import EndpointPicker from "../partials/auth/EndpointPicker";
import _ from "lodash";
import log from "../../util/log";
import StripePaymentCard from "../partials/auth/StripePaymentCard";
import AuthControls from "../partials/auth/AuthControls";
import filters from "../../helpers/filters";
import {withStripeElements} from "../../containers/StripeInjectedComponent";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class Auth extends Component {

  constructor(props) {
    super(props);

    this.state = {
      appLoad : false,
      appLoadTimer : null,
      
      emailCardRef : null,
      pwdCardRef : null,
      boardingCardRef : null,
      forgotPwdCardRef : null,
      tfaCardRef : null,
      tfaPwdResetCardRef : null,
      stripePaymentCardRef : null,
      isAuthLoading : false,
      authValidationErrors : [],
      hideAuthButtonControls : false,
      paymentMethodId : null,
      validCouponDeets : null
    };
  }

  componentWillUnmount() {
    let { appLoadTimer, emailCardRef } = this.state;

    if(appLoadTimer){
      clearTimeout(appLoadTimer);
    }

    if(emailCardRef){
      this.setState({emailCardRef : null})
    }
  }

  componentDidMount() {
    let me = this;
    let {
      setNextStep,
      setEmail,
      step,
      institution
    } = this.props;
    
    let lsEmail = vfLocalStorage.get(c.localstorage.email);
    if(lsEmail){
      
      //If there's a default class id, then we need to allow prompting for member number,
      //and if that's an option, we need to make sure the user lookup call gets passed this member_number
      //So instead of passing them along to password, we need to drop them at the email step. bug 2378
      let defaultClassId = _.get(institution, 'info_json.default_class_id');
      
      //If we're logging in with payment controls, just start them on the email step.
      if(defaultClassId){
        setEmail(lsEmail);
        this.props.setNextStep(c.authSteps.password);
      }
      else{
        //If they already have email populated, just start them at the password screen.
        //We still show the email above anyway, so that's probably what they want. bug 1788
        setEmail(lsEmail);
        setNextStep(c.authSteps.password);
      }
    }
    else {
      setNextStep(c.authSteps.password);
    }

    let timer = setTimeout(() => {
      me.setState({
        appLoad: true
      })
    }, 500);
    me.setState({appLoadTimer : timer});
  }

  getHelpText() {
    let {
      step,
      t
    } = this.props;
    let {
      validCouponDeets
    } = this.state;

    let text = null;
    if(step === c.authSteps.email){
      text = t("Let's get started!");
    }
    else if(step === c.authSteps.password){
      text = t('ADMINISTRATOR LOGIN');
    }
    else if(step === c.authSteps.signup){
      text = t("Looks like you're creating a new account.  What should we call you?")
    }
    else if(step === c.authSteps.tfa || step === c.authSteps.tfaPwdReset){
      text = t("Check your authenticator app for your current code.")
    }

    let headerText = null;
    if(text){
      headerText = (
        <div className={'row'}>
          <div className={'col text-center'}>
            {step === c.authSteps.password &&
            <p className={'light-color mb-1'}
               style={{fontSize: '18px', letterSpacing: '3px'}}>{text}</p>
            }
            {step !== c.authSteps.password &&
            <p className={'light-color mb-1'}
               style={{fontSize: '18px'}}>{text}</p>
            }
          </div>
        </div>
      )
    }
    else{
      headerText = (
        <div className={'row'}>
          <div className={'col'}>
            <p className={'light-color'}
               style={{fontSize: '18px', minHeight: '54px'}} />
          </div>
        </div>
      )
    }
    
    return (
      <div className="mb-4">
        {headerText}
      </div>
    )
  }
  
  getCardRefForStep(){
    let { step } = this.props;
    
    let ref = null;
    if(step === c.authSteps.email){
      ref = this.state.emailCardRef;
    }
    else if(step === c.authSteps.forgotPassword){
      ref = this.state.forgotPwdCardRef;
    }
    else if(step === c.authSteps.password){
      ref = this.state.pwdCardRef;
    }
    else if(step === c.authSteps.signup){
      ref = this.state.boardingCardRef;
    }
    else if(step === c.authSteps.tfa){
      ref = this.state.tfaCardRef;
    }
    else if(step === c.authSteps.tfaPwdReset){
      ref = this.state.tfaPwdResetCardRef;
    }
    
    return ref;
  }
  
  setAuthLoading(isLoading){
    this.setState({isAuthLoading : isLoading});
  }
  
  setAuthErrors(errors){
    this.setState({authValidationErrors : errors});
  }
  
  setHideAuthButtonControls(hideControls){
    this.setState({
      hideAuthButtonControls : hideControls
    })
  }
  
  submitPaymentMethod(){
    return new Promise((resolve, reject) => {
      this.state.stripePaymentCardRef.doFormSubmit()
        .then((res) => {
          log.log('got payment submit res', res);
          this.setState({
            paymentMethodId : res
          }, () => {
            resolve(true);
          })
        })
        .catch((err) => {
          log.log('caught error processing payment', err);
          this.setAuthErrors([err]);
          reject(null);
        })
    })
  }
  
  onNextBtnClick(){
    let ref = this.getCardRefForStep();
    if(!ref){
      log.warn('No ref found for next button click');
      return;
    }
  
    //Each card has this function implemented.
    ref.doNextButtonClicked();
  }
  
  doStartOverButtonClick(){
    
    this.setState({
      isAuthLoading : false,
      authValidationErrors : [],
      hideAuthButtonControls : false
    })
    
    let ref = this.getCardRefForStep();
    if(!ref){
      log.warn('No ref found for next button click');
      return;
    }
  
    if(ref.onStartOverButtonClick) {
      //Each card has this function implemented.
      ref.onStartOverButtonClick();
    }
  }
  
  newPasswordLinkClick(){
    this.props.setNextStep(c.authSteps.tfaPwdReset);
  }
  
  addRemovePromoCodeClick(){
    if(this.state.validCouponDeets){
      this.setState({validCouponDeets : null});
      return;
    }
    
    this.props.showPromoCodeWindow((res) => {
      log.log('showPromoCode res', res);
      if(res){
        this.setState({validCouponDeets : res})
      }
    })
  }
  
  render() {
    let {
      step,
      institution,
      emailFieldIsVisible,
      preventEmailDisabled,
      stripe,
      elements,
      t
    } = this.props;
    let {
      appLoad,
      emailCardRef,
      isAuthLoading,
      authValidationErrors,
      hideAuthButtonControls,
      paymentMethodId,
      validCouponDeets
    } = this.state;
    
    return (
      <div className={'celestial-bg auth-wrapper'}>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>
        <div className={'container'}>
          {config.debug && step === c.authSteps.password && <EndpointPicker />}
          <div className={'row justify-content-center'}>
            <div className={'auth-header-img col'}>
              <Image src={(logo)}
                     className={'mx-auto d-block'}
                     imgHeight={90}
                     alt={t('logo')}/>
            </div>
          </div>
          <CSSTransition in={appLoad} timeout={400} unmountOnExit classNames={'fade'}>
            <div className={'row justify-content-center'}>
              <div style={{marginTop : '3vh'}} className={'col-sm-8 col-md-6 col-lg-5 col-xl-4'}>
                <div className={'card auth-card'}>
                  <div className={'card-body'}>
                    { institution && <InstitutionPanel institution={institution}/> }
                    { emailFieldIsVisible && this.getHelpText()}
                    {emailFieldIsVisible &&
                    <EmailCard preventNavOnEmailFocus={preventEmailDisabled}
                               isLoading={isAuthLoading}
                               setLoading={this.setAuthLoading.bind(this)}
                               setValidationErrors={this.setAuthErrors.bind(this)}
                               onRef={(ref) => {this.setState({emailCardRef: ref})}}/>
                    }
                    <CSSTransition in={step === c.authSteps.password}
                                   unmountOnExit
                                   timeout={400}
                                   classNames={'fade'}>
                      <PasswordCard isLoading={isAuthLoading}
                                    emailCardRef={emailCardRef}
                                    stripe={stripe}
                                    paymentMethodId={paymentMethodId}
                                    validCouponDeets={validCouponDeets}
                                    targetClassId={paymentMethodId ? 100 : -1}
                                    setLoading={this.setAuthLoading.bind(this)}
                                    setValidationErrors={this.setAuthErrors.bind(this)}
                                    onRef={(ref) => { this.setState({pwdCardRef : ref}) }}/>
                    </CSSTransition>
                    <CSSTransition in={step === c.authSteps.signup}
                                   unmountOnExit
                                   timeout={400}
                                   classNames={'fade'}>
                      <BoardingCard emailCardRef={emailCardRef}
                                    stripe={stripe}
                                    isLoading={isAuthLoading}
                                    validCouponDeets={validCouponDeets}
                                    paymentMethodId={paymentMethodId}
                                    targetClassId={paymentMethodId ? 100 : -1}
                                    setHideAuthButtonControls={this.setHideAuthButtonControls.bind(this)}
                                    setLoading={this.setAuthLoading.bind(this)}
                                    setValidationErrors={this.setAuthErrors.bind(this)}
                                    doStartOverButtonClick={this.doStartOverButtonClick.bind(this)}
                                    onRef={(ref) => { this.setState({boardingCardRef : ref}) }}/>
                    </CSSTransition>
                    <CSSTransition in={step === c.authSteps.forgotPassword}
                                   unmountOnExit
                                   timeout={400}
                                   classNames={'fade'}>
                      <ForgotPasswordCard isLoading={isAuthLoading}
                                          emailCardRef={emailCardRef}
                                          setHideAuthButtonControls={this.setHideAuthButtonControls.bind(this)}
                                          doStartOverButtonClick={this.doStartOverButtonClick.bind(this)}
                                          setLoading={this.setAuthLoading.bind(this)}
                                          setValidationErrors={this.setAuthErrors.bind(this)}
                                          onRef={(ref) => { this.setState({forgotPwdCardRef : ref}) }}/>
                    </CSSTransition>
                    <CSSTransition in={step === c.authSteps.tfa}
                                   unmountOnExit
                                   timeout={400}
                                   classNames={'fade'}>
                      <TFACard isLoading={isAuthLoading}
                               setLoading={this.setAuthLoading.bind(this)}
                               setValidationErrors={this.setAuthErrors.bind(this)}
                               onRef={(ref) => { this.setState({tfaCardRef : ref}) }}/>
                    </CSSTransition>
                    <CSSTransition in={step === c.authSteps.tfaPwdReset}
                                   unmountOnExit
                                   timeout={400}
                                   classNames={'fade'}>
                      <TFAPwdResetCard isLoading={isAuthLoading}
                                       setLoading={this.setAuthLoading.bind(this)}
                                       setValidationErrors={this.setAuthErrors.bind(this)}
                                       onRef={(ref) => { this.setState({tfaPwdResetCardRef : ref}) }}/>
                    </CSSTransition>
                    <AuthControls isLoading={isAuthLoading}
                                  hideControls={hideAuthButtonControls}
                                  newPasswordLinkClick={this.newPasswordLinkClick.bind(this)}
                                  promoCodeLinkClick={this.addRemovePromoCodeClick.bind(this)}
                                  validCouponDeets={validCouponDeets}
                                  hideStartOverButton={step === c.authSteps.password}
                                  authValidationErrors={authValidationErrors}
                                  nextBtnClick={this.onNextBtnClick.bind(this)}
                                  startOverBtnClick={this.doStartOverButtonClick.bind(this)} />
                                  
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
        {/*<footer className={'sticky-footer'}>*/}
        {/*  <div className={'container'}>*/}
        {/*    <div className={'row'}>*/}
        {/*      <div className={'col'}>*/}
        {/*        <div className={'text-center'}>*/}
        {/*          <CSSTransition in={appLoad} timeout={400} unmountOnExit classNames={'fade'}>*/}
        {/*            <a className={'auth-link'}*/}
        {/*               style={{fontSize: '14px'}}*/}
        {/*               href={c.links.mainNoRedirect}*/}
        {/*               target={'_blank'}>{t("About Verifyle")}</a>*/}
        {/*          </CSSTransition>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</footer>*/}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    step : state.auth.step,
    email : state.auth.email,
    token : state.auth.token,
    institution : state.auth.institution,
    emailFieldIsVisible : state.auth.emailFieldIsVisible,
    preventEmailDisabled : state.auth.preventEmailDisabled,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNextStep: step => dispatch(authActions.setNextStep(step)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(Auth));
