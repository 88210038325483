import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class TutorialCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {

  }

  render() {
    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
        <div className={'alert alert-warning'}>
          Whoops, we forgot to make a tutorial!
        </div>
      </div>
    )
  }
}

TutorialCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(TutorialCtrl)));
