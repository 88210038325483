import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import DMPanelHeader from "./DMPanelHeader";
import colors from "../../../util/colors";
import UploadHelper from "../components/UploadHelper";
import uploadActions from "../../../actions/upload-actions";
import sharedActions from "../../../actions/shared-actions";
import AttachedDocsPanel from "../docs/AttachedDocsPanel";
import Scroll from "react-scroll";
import UpgradeDialog from "../../modals/UpgradeDialog";
import modalActions from "../../../actions/modal-actions";
import DMSignatureRequest from "../../../models/DMSignatureRequest";
import SignatureRequest from "../../../models/SignatureRequest";
import {getMessageForError} from "../../../util/errors";
import PendingMsgCache from "../../../helpers/pending-msg-cache";
import utils from "../../../util/util";
import msgHelper from "../../../helpers/msg-helper";
import ChatPanelFooter from "./ChatPanelFooter";
import workspaceActions from "../../../actions/workspace-actions";
import ChatPanelMessages from "./ChatPanelMessages";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class DMPanel extends PureComponent {
  
  constructor(props) {
    super(props);
    
    this.chatFileDrop = this.chatFileDrop.bind(this);
    this.onThreadDocClick = this.onThreadDocClick.bind(this);
    this.refreshMessages = this.refreshMessages.bind(this);
    this.onMsgPanelRef = this.onMsgPanelRef.bind(this);
    this.onFooterPanelRef = this.onFooterPanelRef.bind(this);
    this.onDocPanelRef = this.onDocPanelRef.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    
    this.state = {
      chatMessagesRef: null,
      docsPanelRef: null,
      footerRef: null,
      sendingMsg: false,
      contactInfo: null
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }
    
    utils.waitForCondition(() => {
      return !!this.state.footerRef && !!this.props.dm;
    })
      .then(() => {
        this.updateContactInfo();
        let cached = PendingMsgCache.fetchDm(this.props.dm.guest_uid);
        this.state.footerRef.initPendingMessage(cached.msg, cached.docs);
      })
  }
  
  componentWillUnmount() {
    setTimeout(() => {
      if(this.props.dm) {
        let previousData = this.state.footerRef.getPendingData();
        PendingMsgCache.cacheDm(this.props.dm.guest_uid, previousData.msg, previousData.docs);
      }
    })
    
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.dm && (prevProps.dm !== this.props.dm)){
  
      //Kind of ugly, but the reference can change, but the ids are the same.
      //We only want to run this if they're changing the panel.
      if(!prevProps.dm || prevProps.dm.guest_uid !== this.props.dm.guest_uid) {
        if (prevProps.dm) {
          //save previous pending data, and restore the upcoming data
          let previousData = this.state.footerRef.getPendingData();
          PendingMsgCache.cacheDm(prevProps.dm.guest_uid, previousData.msg, previousData.docs);
        }
        let cached = PendingMsgCache.fetchDm(this.props.dm.guest_uid);
        this.state.footerRef.initPendingMessage(cached.msg, cached.docs);
        
        setTimeout(() => {
          this.state.chatMessagesRef.scrollToBottomWhenReady();
        })
  
        this.updateContactInfo();
      }
    }
  }
  
  mergeAttachDocsWithCurrentMsg(docs){
    let files = [];
    _.each(docs, (doc) => {
      let f = _.extend({}, doc);
      f.name = f.doc_label;
      f.sizeString = ''; // we don't have this for copy docs.
      f.is_doc_copy = true;
      f.uniqueId = _.uniqueId('vf-doc-attach-');
      files.push(f);
    })
    
    this.state.footerRef.updatePendingDocs(files);
  }
  
  updateContactInfo(){
    sapi.Contacts.get(this.props.dm.guest_uid)
      .then((res) => {
        this.setState({contactInfo : res.data})
      })
      .catch((err) => {
        log.error('error fetching contact info', err);
        this.setState({contactInfo : null})
      })
  }
  
  onMsgPanelRef(panelRef){
    this.setState({chatMessagesRef: panelRef})
  }
  
  onFooterPanelRef(panelRef){
    this.setState({footerRef: panelRef})
  }
  
  onDocPanelRef(panelRef){
    this.setState({docsPanelRef: panelRef})
  }
  
  refreshMessages() {
    let { dm, workspace} = this.props;
    
    return Promise.all([
      this.props.refreshMessages(),
      this.props.updateDirectMessages(),
      this.props.updateNotifications(),
      dm ? this.props.updateDMPreviews([dm.guest_uid]) : Promise.resolve(true),
      workspace ?  this.props.refreshDocs(workspace.forum_id, workspace.host_uid) : Promise.resolve(true)
    ])
  }
  
  sendMessageWithDocs(pendingMsg, pendingFiles){
    let {dm, workspace, t} = this.props;
  
    let docAttachFiles = [];
    let sigRequestFiles = [];
    let nonSigRequestFiles = [];
    _.each(pendingFiles, (f) => {
      if(f.is_doc_copy){
        docAttachFiles.push(f);
      }
      else {
        if (f.signature_requested) {
          sigRequestFiles.push(f);
        }
        else {
          nonSigRequestFiles.push(f);
        }
      }
    })
  
    let last_forum_id = workspace ? workspace.forum_id : null;
    let msgSent = false;
    if(nonSigRequestFiles.length > 0){
      let transaction_id = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      msgSent = true;
      this.props.queueDMDocTransaction(transaction_id, dm.guest_uid, upload_id, nonSigRequestFiles, pendingMsg);
      this.props.queueDMUpload(transaction_id, upload_id, dm.guest_uid, false, nonSigRequestFiles, pendingMsg, last_forum_id);
    }
    if(sigRequestFiles.length > 0){
      let transaction_id = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueDMDocTransaction(transaction_id, dm.guest_uid, upload_id, sigRequestFiles, msgSent ? null : pendingMsg);
      this.props.queueDMUpload(transaction_id, upload_id, dm.guest_uid, true, sigRequestFiles, msgSent ? null : pendingMsg, last_forum_id);
    }
    if(docAttachFiles.length > 0){
      let docAttachTransaction = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueDMDocAttachTransaction(docAttachTransaction, dm.guest_uid, docAttachFiles, msgSent ? null : pendingMsg);
      this.props.queueDMDocAttach(docAttachTransaction, upload_id, dm.guest_uid, docAttachFiles, msgSent ? null : pendingMsg, last_forum_id);
      msgSent = true;
    }
    
    this.state.footerRef.clear();
    
    return this.refreshMessages()
      .then(() => {
        PendingMsgCache.clearDM(dm.guest_uid);
        setTimeout(() => {
          this.state.chatMessagesRef.scrollToBottom(250);
        })
      })
      .catch((err) => {
        log.log('error during message refresh', err);
        this.state.footerRef.setNotSending();
        this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
      })
  }
  
  sendMessage(pendingMsg, pendingFiles) {
    let {dm, workspace, t} = this.props;
  
    if(!dm){
      console.warn('sendMessage called with no DM present');
      return null;
    }
    
    pendingMsg = msgHelper.trimWhitespace(pendingMsg);
    
    if(pendingFiles.length > 0){
      this.sendMessageWithDocs(pendingMsg, pendingFiles);
      return;
    }
    
    let transaction_id = _.uniqueId('vf-transaction-id');
  
    let last_forum_id = workspace ? workspace.forum_id : null;
    this.props.queueDMMsgTransaction(transaction_id, dm.guest_uid, pendingMsg);
    this.setState({sendingMsg: true})
    sapi.DM.sendMessage(dm.guest_uid, pendingMsg, last_forum_id)
      .then((res) => {
        log.log('send message res', res);
  
        this.props.dequeueDMMsgTransaction(transaction_id);
        this.state.footerRef.clear();
        
        return this.refreshMessages()
      })
      .then(() => {
        PendingMsgCache.clearDM(dm.guest_uid);
        setTimeout(() => {
          this.state.chatMessagesRef.scrollToBottom(250);
        })
      })
      .catch((err) => {
        log.log('error during message send', err);
        this.props.dequeueDMMsgTransaction(transaction_id);
        this.state.footerRef.setNotSending();
        this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
      })
      .finally(() => {
        this.setState({sendingMsg: false})
      })
  }
  
  chatFileDrop(files) {
    this.state.footerRef.docFileDrop(files);
  }
  
  onDocAttach(docs){
    this.state.footerRef.addCopyDocs(docs);
  }
  
  onThreadDocClick(doc){
    this.state.docsPanelRef.selectDocAndNavigate(doc);
  }
  
  selectDocAndNavigate(doc, thread){
    this.state.docsPanelRef.selectDocAndNavigate(doc, thread);
  }
  
  onAttachDocToThread(res){
    this.props.onAttachDocToThread(res);
  }
  
  editSignatureRequest(msg, doc) {
    log.log('edit signature request click', msg, doc);
    
    if(this.warnNonProUsersAboutSigning()) {
      return;
    }
    
    let {dm, t} = this.props;
    let sigRequest = new DMSignatureRequest(
      dm.guest_uid,
      dm.forum_id,
      dm.host_uid,
      doc.doc_id,
      msg.mesg_id,
      doc
    )
    
    Promise.all([
        sapi.Docs.info(dm.forum_id, dm.host_uid, doc.doc_id),
        sapi.DM.getSignatureRequest(doc.sign_request_id, dm.guest_uid)
      ])
      .then((res) => {
        log.log('signature request load', res);
        sigRequest.setSigner(doc.signer_uid, dm);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(res[1].data);
        sigRequest.terms = res[1].data.terms;
        sigRequest.smsNumber = _.get(res[1], 'data.sign_data.smsNumber');
        
        this.props.editSignatureRequest(sigRequest, (res) => {
          log.log('pdf signature request window closed', res);
          
          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }
  
  fulfillSignatureRequest(msg, doc){
    let {dm, accountInfoGuest, t} = this.props;
    let sigRequest = new DMSignatureRequest(
      dm.guest_uid,
      dm.forum_id,
      dm.host_uid,
      doc.doc_id,
      msg.mesg_id,
      doc
    )
    
    Promise.all([
        sapi.Docs.info(dm.forum_id, dm.host_uid, doc.doc_id),
        sapi.DM.getSignatureRequest(doc.sign_request_id, dm.guest_uid)
      ])
      .then((res) => {
        log.log('signature request load', res);
        sigRequest.setSigner(doc.signer_uid, accountInfoGuest);
        sigRequest.setRequestor(dm);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(res[1].data);
        sigRequest.terms = res[1].data.terms;
        sigRequest.smsNumber = _.get(res[1], 'data.sign_data.smsNumber');
        
        this.props.showFulfillSignatureRequest(sigRequest, (res) => {
          log.log('pdf signature request window closed', res);
  
          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }
  
  completeSignatureRequest(msg, doc){
    log.log('dm panel constructor????', msg, doc)
    if(this.warnNonProUsersAboutSigning()) {
      return;
    }
    
    let { dm, t } = this.props;
  
    log.log('complete sig request', msg, doc);
    let sigRequest = new DMSignatureRequest(
      dm.guest_uid,
      dm.forum_id,
      dm.host_uid,
      doc.doc_id,
      msg.mesg_id,
      doc
    )
    
    this.props.showDMRequestSignatureWindow(
      dm.guest_uid,
      msg.mesg_id,
      doc.doc_id,
      doc.label,
      [dm],
      null,
      null,
      (res) => {
        log.log('request signature form closed', res);
        if (res) {
          
          //No further data has been saved at this point, the signature request is still incomplete.
          sigRequest.smsNumber = res.smsNumber;
          sigRequest.terms = res.terms;
          Promise.all([
              sapi.Docs.info(dm.forum_id, dm.host_uid, sigRequest.doc_id),
            ])
            .then((loadRes) => {
              sigRequest.setSigner(res.signer_uid, dm);
              sigRequest.setDocInfo(loadRes[0].data);
              
              this.props.completeSignatureRequest(sigRequest, (res) => {
                log.log('pdf signature request window closed', res);
  
                this.refreshMessages();
              })
            })
            .catch((err) => {
              log.error('error loading signature request data', err);
              this.props.showAlert(t('Error loading signature request data'), getMessageForError(err, t));
            })
        }
      }
    )
  }
  
  warnNonProUsersAboutSigning(){
    let { accountInfo, t } = this.props;
    
    if(accountInfo.class_id < 100){
      this.props.showAlert(t('Unable to Sign Document'), t('To manage signature requests on this Document, you must be a Verifyle Pro user.'));
      return true;
    }
    
    return false;
  }
  
  fulfillDMSignatureRequest(mesg_id, doc_id, signatureRequest, sign_request_id){
    let {dm, accountInfoGuest, t} = this.props;
    let sigRequest = new DMSignatureRequest(
      dm.guest_uid,
      dm.forum_id,
      dm.host_uid,
      doc_id,
      mesg_id,
      {
        sign_status : SignatureRequest.SIGN_STATUS.REQUESTED,
        sign_request_id
      }
    )
    
    Promise.all([
        sapi.Docs.info(dm.forum_id, dm.host_uid, doc_id)
      ])
      .then((res) => {
        log.log('signature request load', res);
        sigRequest.setSigner(accountInfoGuest.guest_uid, accountInfoGuest);
        sigRequest.setRequestor(dm);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(signatureRequest);
        sigRequest.terms = signatureRequest.terms;
        sigRequest.smsNumber = signatureRequest.sign_data.smsNumber;
        
        this.props.showFulfillSignatureRequest(sigRequest, (res) => {
          log.log('pdf signature request window closed', res);
  
          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }
  
  doBeforePrint(){
    return this.state.chatMessagesRef.doPrintPreview()
  }
  
  onPrintError(err){
    log.error("error printing chat", err);
    this.state.chatMessagesRef.cleanupPrintPreview();
  }
  
  doAfterPrintPreview(){
    this.state.chatMessagesRef.cleanupPrintPreview();
  }
  
  getPrintPreviewContents(){
    return this.state.chatMessagesRef.printPreviewRef.printRef.current
  }
  
  onMesgHistoryClick(msg){
    let { dm } = this.props;
    
    this.props.showDMMessageHistory(dm, msg)
  }
  
  onMesgEditClick(msg) {
    let { dm } = this.props;
    
    this.props.showEditDMMessage(dm, msg, (res) => {
      if(res){
        this.refreshMessages();
      }
    })
  }
  
  findDocInfo(doc_id){
    let { docs } = this.props;
    
    let found = _.find(docs, (d) => {
      return d.doc_id === doc_id;
    })
    
    return found;
  }
  
  render() {
    let {dm, messageBlocks, dmDocs, isInTabView, docs, workspace} = this.props;
    let { contactInfo} = this.state;
    
    let docNotifyCount = 0;
    _.each(dmDocs, (doc) => {
      if(doc.notify_flag) docNotifyCount++
    })
    
    return (
      <UploadHelper onDrop={this.chatFileDrop}
                    generateImagePreviews={true}
                    allowMultiple={true}
                    disableClick={true}>
        <div className={`center-col ${isInTabView ? 'tab-view' : ''} d-flex flex-column`}>
  
          <div>
            <DMPanelHeader dm={dm}
                           contactInfo={contactInfo}
                           onAfterPrintPreview={this.doAfterPrintPreview.bind(this)}
                           onBeforePrintPreview={this.doBeforePrint.bind(this)}
                           onPrintPreviewError={this.onPrintError.bind(this)}
                           getPrintPreviewContents={this.getPrintPreviewContents.bind(this)}/>
          </div>
  
          <div className="flex-grow-1" style={styles.messagePanelFlex}>
            <div className="row mx-0 height-100">
              <div className="col-7 height-100 pl-2 pr-0">
                <div className="d-flex flex-column" style={styles.messagePanelFlex}>
                  <div className="flex-grow-1" style={styles.messageContents}>
                    <ChatPanelMessages dm={dm}
                                       docClick={this.onThreadDocClick}
                                       messageBlocks={messageBlocks}
                                       mesg_edit_flag={true}
                                       refreshMessages={this.refreshMessages}
                                       onMesgEditClick={this.onMesgEditClick.bind(this)}
                                       onMesgHistoryClick={this.onMesgHistoryClick.bind(this)}
                                       findDocInfo={this.findDocInfo.bind(this)}
                                       onRef={this.onMsgPanelRef}
                                       fulfillSignatureRequestClick={this.fulfillSignatureRequest.bind(this)}
                                       editSignatureRequestClick={this.editSignatureRequest.bind(this)}
                                       completeSignatureRequestClick={this.completeSignatureRequest.bind(this)}/>
                  </div>
                  <div>
                    <ChatPanelFooter onRef={this.onFooterPanelRef}
                                     sendMessage={this.sendMessage} />
                  </div>
                </div>
              </div>
              <div className="col-5 height-100 pl-2 pr-2 pb-2">
                <div id="attached-docs-col" style={styles.docsPanelContents}>
                  <AttachedDocsPanel docs={dmDocs}
                                     dm={dm}
                                     workspace={workspace}
                                     workspaceDocs={docs}
                                     onAttachDocToThread={this.onAttachDocToThread.bind(this)}
                                     onRef={this.onDocPanelRef}
                                     docNotifyCount={docNotifyCount || 0}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UploadHelper>
    )
  }
}

const styles = {
  messagePanelFlex: {
    height: '100%',
    overflow: 'hidden',
    padding: '0px',
    backgroundColor: colors.DARK,
    borderBottomLeftRadius: '5px'
  },
  messageContents: {
    backgroundColor: colors.LIGHT,
    padding: '0px 5px',
    borderRadius: '5px',
    height: '0%', //I don't know why this works, but any other value screws things up in safari
  },
  docsPanelContents: {
    backgroundColor: colors.LIGHT,
    borderRadius: '5px',
    height: '100%',
    overflowY: 'auto'
  }
}

DMPanel.propTypes = {
  dm: PropTypes.object,
  messageBlocks : PropTypes.array,
  refreshMessages : PropTypes.func.isRequired,
  dmDocs : PropTypes.array,
  isInTabView : PropTypes.bool.isRequired,
  onRef : PropTypes.func.isRequired,
  onAttachDocToThread : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    workspace : state.workspace.workspace,
    docs : state.workspace.docs,
    accountInfoGuest : state.shared.accountInfoGuest,
    accountInfo : state.shared.accountInfo,
    accountSignatures : state.shared.signatures,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    queueDMUpload: (transaction_id, upload_id, guest_uid, signature_requested, files, mesg, last_forum_id) => dispatch(uploadActions.queueDMUpload(transaction_id, upload_id, guest_uid, signature_requested, files, mesg, last_forum_id)),
    queueDMDocAttach:(transaction_id, upload_id, guest_uid, files, mesg) => dispatch(uploadActions.queueDMDocAttach(transaction_id, upload_id, guest_uid, files, mesg)),
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),
    
    refreshDocs : (forum_id, host_uid) => dispatch(workspaceActions.refreshDocs(forum_id, host_uid))
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DMPanel));
