import c from '../util/const';
import _ from 'lodash';
import log from "../util/log";

let initialState = {
  activeUpload: null,
  uploadQueue: [],
  uploadProgress: null
}

const upload = (state = initialState, action) => {
  switch (action.type) {
    
    case c.actions.upload.setActiveUpload:
      return {
        ...state,
        activeUpload: action.transaction
      };
    
    case c.actions.upload.updateQueue:
      return {
        ...state,
        uploadQueue : action.queue
      }
      
    case c.actions.upload.addToUploadQueue:
      return {
        ...state,
        uploadQueue : _.concat(state.uploadQueue, action.transaction)
      };
    
    case c.actions.upload.updateProgress:
      return {
        ...state,
        uploadProgress : action.progress
      }
      
    case c.actions.upload.cleanup:
      return {...initialState}
      
    default:
      return state;
  }
}

export default upload;
