
const getErrorLookup = (t) => {
  return {
    "APP_REQ_INVALID"   : t("One or more of your entries is invalid."),
    "APP_REQ_MISSING"   : t("One or more of the required entries is missing."),
    "APP_LOGIN_FAIL"    : t("The combination of username and password you entered is invalid."),
    "APP_SESS_INVALID"  : t("Sorry, you have been logged out of this session. Please log back in."),
    "APP_NOT_AUTHZ"     : t("Sorry, but it looks like your Host capabilities might have expired. You'll have to log back in."),
    "APP_CONFIRM_FAIL"  : t("That combination of username and code is invalid. Please try again."),
    "APP_CONFIRM_EXP"   : t("The confirmation code you entered has expired."),
    "APP_PASS_MISMATCH" : t("The password you entered is incorrect."),
    "APP_FILE_TOOLARGE" : t("The file is too large to upload."),
    "APP_ACCT_QUOTA"    : t("Performing this action would exceed the storage limit. Space has to be made to proceed."),
    "APP_ACCT_EXISTS"   : t("An account with that email address already exists.  Please enter a different email address."),
    "APP_ACCT_UNAVAIL"  : t("This account is temporarily unavailable."),
    "APP_ACCT_GONE"     : t("The Host account has been deleted, exiting the Workspace..."),
    "APP_STALE_DATA"    : t("Some information has changed since this page loaded."),
    "APP_NOT_FOUND"     : t("That item appears to have been removed."),
    "APP_CC_NOAUTH"     : t("There was a problem with your payment.  Please contact us at support@verifyle.com."),
    "APP_CC_DECLINED"   : t("The credit card was declined.  Please try again with a different credit card."),
    "APP_CC_ERROR"      : t("Please check that all information is accurate and try again."),
    "APP_CC_CARD_NUM"   : t("Please check the credit card number and try again."),
    "APP_CC_EXP_DATE"   : t("Please check the card expiration date and try again."),
    "APP_CC_CARD_CODE"  : t("Please check the verification code on the back of your card and try again."),
    "APP_CC_ADDRESS"    : t("Please check the card billing address and try again."),
    "APP_GUEST_DUP"     : t("This person is already in your Contact list."),
    "APP_GUEST_MAX"     : t("You've reached the maximum number of Contacts allowed. Please remove a Contact first."),
    "APP_GUEST_SELF"    : t("You cannot add yourself as a Contact."),
    "APP_GUEST_RSVP"    : t("This person is already a confirmed user."),
    "APP_PERM_CONFLICT" : t("The replacement target has existing permissions."),
    "APP_FORUM_DUP"     : t("There is already a Workspace with that name."),
    "APP_FORUM_MAX"     : t("You've reached the maximum number of Workspaces allowed. Please delete a Workspace first."),
    "APP_FORUM_DUP_COL" : t("There is already an item with that name."),
    "APP_FORUM_MAX_COL" : t("You've reached the maximum number of items allowed in this column. Please delete an item first."),
    "APP_CHAT_MESG_MAX" : t("The maximum number of messages has been reached. This can be continued in a new thread."),
    "APP_PARTIAL_FAIL"  : t("Some records were not updated.  Please try again."),
    "APP_UNKNOWN_ERROR" : t("An error has occurred.  Please try again."),
    "APP_SERVER_ERROR"  : t("An error has occurred.  Please try again."),
    "APP_TFA_FAIL"      : t("The authenticator code you entered is incorrect."),
    "APP_TFA_EXP"       : t("That code is now invalid.  Please wait for your authenticator to give you a new number and try again."),
    "APP_PARTNER_USED"  : t("This member number is already in use.  Please try again."),
    "APP_PARTNER_INVALID" : t("This member number is invalid.  Please try again."),
    "APP_FILE_TOOMANY" : t("You've selected too many files to attach.  Please unselect some and try again.")
  }
}

export const getErrorMessage = (errName, t) => {
  let errors = getErrorLookup(t);
  
  if(errors[errName]){
    return errors[errName]
  }
  else{
    return t("There was an unexpected error handling your request.");
  }
}

//This FAKE_T this is a little funny.  Maybe I'm just being stubborn, but there's no reason for this
//method to take t as an argument, because it doesn't need the context.  However, the lookup requires context
//so that it can translate the error messages.  I COULD duplicate some list of error message keys somewhere, and
//use that to look up known errors.  That sucks because then I need to maintain two places.
//The other easier option is to fake the translate function, and just call the lookup like normal because we don't care
//about the language returned by the result.
//Worth it just to keep this function free of needing t as input?  Maybe.  Feel free to change your mind on this.
const FAKE_T = (str) => { return str; }
export const isKnownVFError = (err) => {
  let errors = getErrorLookup(FAKE_T)
  return err && err.name && err.error && errors[err.name];
}

export const getMessageForError = (err, t) => {
  
  if(err && err.name && err.error){
    return getErrorMessage(err.name, t);
  }
  else if(typeof err.status === 'undefined'){
    return t('We seem to be having trouble connecting.  Please check your network settings and try again.')
  }
  return null;
}
