import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import EmptyState from "../../components/EmptyState";
import accountActions from "../../../../actions/account-actions";
import Account from "../../../pages/Account";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class BlockListCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {

  }

  unblockUser(user){
    let { updateUserBlockList } = this.props;

    log.log('unblock user', user);

    sapi.NotifyBlock.unblockGuest(user.guest_uid)
      .then((res) => {
        log.log('unblock res', res);

        return updateUserBlockList();
      })
      .catch((err) => {
        log.log('error while unblocking user', err);
      })
  }

  render() {
    let { userBlockList, t } = this.props;

    if(userBlockList.length === 0){
      return (
        <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>
          <EmptyState>
            <div className={'text-center'}>
              <p>
                {t('No users are blocked')}
              </p>
            </div>
          </EmptyState>
        </div>
      )
    }

    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3 '}>

        <table className="table">
          <thead>
          <tr>
            <th scope="col">{t("Name")}</th>
            <th scope="col">{t("Email")}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          {userBlockList.map((user) =>
          <tr key={user.guest_uid}>
            <td scope="row"><p>{user.first_name} {user.last_name}</p></td>
            <td><p>{user.email_address}</p></td>
            <td><Button onClick={this.unblockUser.bind(this, user)} className={'btn btn-link btn-sm'}>{t("Unblock User")}</Button></td>
          </tr>
          )}
          </tbody>
        </table>

      </div>
    )
  }
}

BlockListCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    userBlockList : state.account.userBlockList
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserBlockList : () => dispatch(accountActions.updateUserBlockList())
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(BlockListCtrl)));
