import appActions from "../../../actions/app-actions";
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import {withRouter} from "react-router-dom";
import React, { Component } from 'react';
import {connect} from 'react-redux';
import log from "../../../util/log";
import companyLogo from "../../../resources/logo-w-text-blue.png";
import Image from "../elements/Image";
import c from "../../../util/const";
import {withVFTranslation} from "../../../util/withVFTranslation";

class EnvironmentalConfigurationErrorPage extends React.Component {
  reloadPage(){
    window.location.reload();
  }
  
  render() {
    let { applicationError, t } = this.props;
    
    return (
      <div className="container">
        <div className="row">
          <div className="col text-center" style={{marginTop : '5vh'}}>
            <Image className={'mt-2'} src={companyLogo} alt={'Logo'} imgHeight={70}/>
          </div>
        </div>
        <div className="row">
          <div className="col text-center" style={{marginTop : '10vh'}}>
            <h1>
              <i style={{fontSize : '60px'}} className="icon ion-android-warning" />
            </h1>
            {applicationError.isLocalStorageProblem &&
            <>
              <h3>
                {t("It looks like there's a problem with your browser's local storage, which is needed for our application to run correctly.")}
              </h3>
              <p>
                {t("An easy thing to try would be to use a different browser.  If that's not possible, try clearing or resetting your local storage.  If you're having problems with this, please contact us at")}
                <a href={c.links.mailToSupport} style={{textDecoration: 'underline'}} className="pl-1">{t("support@verifyle.com")}</a>
              </p>
            </>
            }
            {applicationError.isSessionStorageProblem &&
            <>
              <h3>
                {t("It looks like there's a problem with your browser's session storage, which is needed for our application to run correctly.")}
              </h3>
              <p>
                {t("An easy thing to try would be to use a different browser.  If that's not possible, try clearing or resetting your session storage.  If you're having problems with this, please contact us at")}
                <a href={c.links.mailToSupport} style={{textDecoration: 'underline'}} className="pl-1">{t("support@verifyle.com")}</a>
              </p>
            </>
            }
          </div>
        </div>
        <div className="row">
          <div className="col text-center" style={{
            marginTop: '50px'
          }}>
            <div className="d-inline-block text-center" style={{marginTop: '50px'}}>
              <button className="btn btn-secondary"
                      onClick={this.reloadPage.bind(this)}>
                {t("Reload Page")}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    applicationError : state.app.applicationError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EnvironmentalConfigurationErrorPage)));
