import storageHelper from "./storage-helper";

const vfSessionStorage = {
  get (key) {
    if(!sessionStorage){
      console.error('session storage does not exist');
      return null;
    }
    
    return sessionStorage.getItem(key);
  },
  
  set (key, val) {
    if(!sessionStorage){
      console.error('session storage does not exist');
      return null;
    }
    
    return sessionStorage.setItem(key, val);
  },
  
  remove (key){
    if(!sessionStorage){
      console.error('session storage does not exist');
      return null;
    }
    
    return sessionStorage.removeItem(key);
  },
  
  clear () {
    if(!sessionStorage){
      console.error('session storage does not exist');
      return null;
    }
    
    return sessionStorage.clear();
  },
  
  testStorage(){
    return storageHelper.storageAvailable('sessionStorage');
  },
}

export default vfSessionStorage;
