import React from 'react';
import { useTranslation } from 'react-i18next'
import hoistStatics from 'hoist-non-react-statics';

let getDisplayName = (Component) => {
  return (
    Component.displayName ||
    Component.name ||
    (typeof Component === 'string' && Component.length > 0 ? Component : 'Unknown')
  );
}

export function withVFTranslation(ns, options = {}) {
  return function Extend(WrappedComponent) {
    function I18nextWithTranslation({ forwardedRef, ...rest }) {
      const [t, i18n] = useTranslation(ns, rest);
      
      const passDownProps = {
        ...rest,
        t,
        i18n,
      };
      if (options.withRef && forwardedRef) {
        passDownProps.ref = forwardedRef;
      } else if (!options.withRef && forwardedRef) {
        passDownProps.forwardedRef = forwardedRef;
      }
      return React.createElement(WrappedComponent, passDownProps);
    }
    
    I18nextWithTranslation.displayName = `withI18nextTranslation(${getDisplayName(
      WrappedComponent,
    )})`;
    
    I18nextWithTranslation.WrappedComponent = WrappedComponent;
    
    const forwardRef = (props, ref) =>
      React.createElement(I18nextWithTranslation, Object.assign({}, props, { forwardedRef: ref }));
    
    hoistStatics(I18nextWithTranslation, WrappedComponent);
    
    return options.withRef ? React.forwardRef(forwardRef) : I18nextWithTranslation;
  };
}
