import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import UploadHelper from "../components/UploadHelper";
import GuestRow from "./GuestRow";
import Measure from "react-measure";
import log from "../../../util/log";
import PlaceholderLoaders from "../util/PlaceholderLoaders";
import MemberRow from "./MemberRow";
import InfiniteLoader from "react-window-infinite-loader";
import _ from 'lodash'
import Loading from "../util/Loading";

class MemberItem extends PureComponent {
  render() {
    const {data, index, style} = this.props;
    const {
      contacts,
      onRemoveGuest,
      guestClick,
      updateItemHeight,
      isItemLoaded
    } = data;
    
    const guest = contacts[index];
  
    if (!isItemLoaded(index)) {
      return (
        <div style={style}>
          <div className="p-3">
            <Loading centered size={'sm'}/>
          </div>
        </div>
      )
    }
  
    return (
      <div style={style}>
        <MemberRow key={guest.guest_uid}
                   row={guest}
                   onRemoveGuest={onRemoveGuest}
                   onItemClick={guestClick}/>
      </div>
    )
  }
}

MemberItem.ROW_HEIGHT = 64;

class MemberList extends PureComponent {
  
  constructor(props) {
    super(props);
    
    this.getItemSize = this.getItemSize.bind(this);
    this.updateItemHeight = this.updateItemHeight.bind(this);
    
    this.listRef = React.createRef();
    this.scrollableContainerRef = React.createRef();
    
    this.state = {};
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  scrollToIndex(index) {
    let top = 0;
    for (let i = 0; i < index; i++) {
      top += this.state['item-index-' + i] ? this.state['item-index-' + i] : MemberItem.ROW_HEIGHT
    }
    
    if (this.scrollableContainerRef && this.scrollableContainerRef.current && this.scrollableContainerRef.current.scrollTo) {
      this.scrollableContainerRef.current.scrollTo({
        left: 0,
        top: top,
        behavior: 'smooth',
      });
    }
  }
  
  updateItemHeight(index, totalHeight) {
    if (this.state['item-index-' + index] !== totalHeight) {
      this.setState({['item-index-' + index]: totalHeight},
        () => {
          this.listRef.current.resetAfterIndex(index);
        })
    }
  }
  
  getItemSize(index) {
    let height = this.state['item-index-' + index];
    if (!height) {
      height = MemberItem.ROW_HEIGHT;
    }
    return height;
  }
  
  render() {
    const {
      contacts,
      onRemoveGuest,
      guestClick,
      hasMoreItems,
      isLoadingMoreMembers
    } = this.props;
    
    if (!contacts) {
      return PlaceholderLoaders.renderContactInfoPlaceholderRows(10);
    }
  
    const itemCount = hasMoreItems ? contacts.length + 1 : contacts.length;
  
    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isLoadingMoreMembers ? _.noop : this.props.onLoadMoreItems;
  
    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = index => !hasMoreItems || index < contacts.length;
    
    return (
      <AutoSizer>
        {({height, width}) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({onItemsRendered, ref}) => (
              <List
                ref={ref}
                outerRef={this.scrollableContainerRef}
                height={height}
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                itemData={{
                  contacts,
                  onRemoveGuest,
                  guestClick,
                  updateItemHeight: this.updateItemHeight,
                  isItemLoaded
                }}
                itemSize={MemberItem.ROW_HEIGHT}
                width={width}
              >{MemberItem}</List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    )
  }
}

MemberList.propTypes = {
  contacts: PropTypes.array,
  onRemoveGuest: PropTypes.func.isRequired,
  guestClick: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
  onLoadMoreItems: PropTypes.func.isRequired,
  hasMoreItems : PropTypes.bool.isRequired,
  isLoadingMoreMembers : PropTypes.bool.isRequired
}

export default MemberList;
