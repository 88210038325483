import c from '../util/const';
import sapi from "../util/sapi";

import Promise from 'bluebird';

import _ from 'lodash';

const downloadActions = {

  updatePendingQueue(queue){
    return {
      type : c.actions.download.updatePendingQueue,
      queue
    }
  },

  addToCurrentQueue(downloads){
    return {
      type : c.actions.download.addToCurrentQueue,
      downloads
    }
  },
  
  doDownload(headers, url){
    let downloads = [];
    downloads.push({
      id : _.uniqueId('vf-download-'),
      headers,
      url
    })
    
    return {
      type : c.actions.download.doDownload,
      downloads
    }
  },

  cleanup (){
    return {
      type : c.actions.download.cleanup
    }
  }
}

export default downloadActions;
