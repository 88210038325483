import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import {getMessageForError} from "../../../../util/errors"
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import appActions from "../../../../actions/app-actions";
import Account from "../../../pages/Account";
import vfLocalStorage from "../../../../util/local-storage";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class DeleteAccountCtrl extends Component {

  constructor(props){
    super(props);

    this.state = {
      pwd : '',
      isWorking : false,
      validation_errors : []
    }
  }

  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  init() {
    this.setState({
      pwd : '',
      isWorking: false,
      validation_errors : []
    })
  }

  save(){
    if(!this.doValidation()){
      return;
    }

    this.setState({isWorking: true});

    let { pwd } = this.state;
    let { history, t } = this.props;

    sapi.AccountDelete.deleteAccount(pwd)
      .then((res) => {
        log.log('account delete res', res);

        this.props.cleanup();
        vfLocalStorage.clear();
        history.push('/');
      })
      .catch((err) => {
        log.log('error deleting account', err);
        this.setState({
          validation_errors : [ getMessageForError(err, t) ]
        })
      })
      .finally(() => {
        this.setState({isWorking: false})
      })
  }

  doValidation(){
    let { pwd } = this.state;
    let { t } = this.props;
    
    let err = [];
    if(!pwd || pwd.length === 0){
      err.push(t("Please enter your password"));
    }

    this.setState({validation_errors : err})
    return err.length === 0;
  }

  cancel(){
    let { doClose } = this.props;

    doClose();
  }

  render() {
    let { pwd, isWorking, validation_errors } = this.state;
    let { t } = this.props;
    
    return (
      <div style={Account.styles.rowContents} className={'mt-3 mb-3'}>

        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <span className="text-danger">{t("WARNING!")}</span> {t("This is a permanent and immediate action. Once deleted, your account cannot be recovered.")}
              {t("You will no longer have access to Verifyle or any information stored by Verifyle.")}
              {t("This includes any Workspaces you have created and Workspaces in which you are a Guest.")}
              {t("If you delete a Pro account, you will not receive a refund. Enter your password to complete this action.")}
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Enter Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     value={pwd}
                     onChange={(evt) => this.setState({pwd: evt.target.value})}
                     placeholder={t('Password')} />
            </div>
          </div>
        </div>
        {validation_errors.length > 0 &&
        <div className={'row'}>
          <div className={'col'}>
            <ValidationErrors errors={validation_errors}/>
          </div>
        </div>
        }
        <div className={'row'}>
          <div className={'col'}>
            <div className={'text-right'}>
              {isWorking &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isWorking} className={'btn btn-secondary'} onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={isWorking} className={'btn btn-danger ml-2'} onClick={this.save.bind(this)}>{t("Delete Account")}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

DeleteAccountCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    cleanup : () => dispatch(appActions.cleanup())
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteAccountCtrl)));
