import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import Promise from "bluebird";
import accountActions from "../../../../actions/account-actions";
import modalActions from "../../../../actions/modal-actions";
import Image from "../../elements/Image";
import _ from 'lodash';
import api from "../../../../util/api";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class UpgradeCtrl extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      promo_code: '',
      valid_code_deets: null,
      coupon_code_err: null,
      isSaving: false,
    }
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  init() {
    let {accountInfo} = this.props;
    
    this.setState({
      promo_code: '',
      valid_code_deets: null,
      coupon_code_err: null,
      isSaving: false,
    })
  }
  
  getUpgradeText() {
    let {userContract, t} = this.props;
    
    return (
      <div>
        <div className={'row pb-2'}>
          <div className={'col-6'}>
          
          </div>
          <div className={'col-3 text-center'}>
            {t("You")}
          </div>
          <div className={'col-3 text-center'}>
            {t("Pro You")}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 text-right'}>
            {t("Digital Signing")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {t("N/A")}
          </div>
          <div className={'col-3 text-center green-color'}>
            {t("Unlimited")}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 text-right'}>
            {t("Account Logo")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {t("N/A")}
          </div>
          <div className={'col-3 text-center green-color'}>
            {t("Yes")}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 text-right'}>
            {t("Hosted Workspaces")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {userContract.current['forum_max_total']}
          </div>
          <div className={'col-3 text-center green-color'}>
            {userContract.upgrade['forum_max_total']}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 text-right'}>
            {t("Documents per Workspace")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {userContract.current['forum_max_doc']}
          </div>
          <div className={'col-3 text-center green-color'}>
            {userContract.upgrade['forum_max_doc']}
          </div>
        </div>
        <div className={'row pb-2'}>
          <div className={'col-6 text-right'}>
            {t("Storage Space")}
          </div>
          <div className={'col-3 text-center light-grey-color'}>
            {+(userContract.current['max_storage'] / 1073741824).toFixed(2)}GB
          </div>
          <div className={'col-3 text-center green-color'}>
            {+(userContract.upgrade['max_storage'] / 1073741824).toFixed(2)}GB
          </div>
        </div>
      </div>
    )
  }
  
  validateCouponData(data) {
    let { t } = this.props;
    if (!data.valid) {
      return t('This Promo code is no longer valid.');
    }
    
    if (data.amount_off && data.amount_off > 0) {
      return null;
    }
    else if (data.percent_off && data.percent_off > 0) {
      return null;
    }
    else {
      log.error('Unhandled coupon configuration', data);
      return t('This Promo code is not valid.');
    }
  }
  
  getDisplayPrice(price) {
    var split = ('' + price).split('.')
    if (split.length > 1) {
      var cents = +split[1];
      if (cents === 0) {
        return +split[0];
      }
      else {
        return price.toFixed(2);
      }
    }
    else {
      return +price;
    }
  }
  
  applyPromoCode() {
    let {userContract, t} = this.props;
    let {promo_code} = this.state;
    
    api.Stripe.validateCoupon(promo_code)
      .then((res) => {
        log.log('validate coupon res', res);
        
        let coupon = res.data;
        let msg = this.validateCouponData(coupon);
        if (msg) {
          this.setState({
            coupon_code_err: msg
          })
          return;
        }
        else {
          this.setState({
            coupon_code_err: null
          })
        }
        
        //Then it's valid.
        let couponDeets = null;
        var monthDuration = coupon.duration === t('once') ? 1 : coupon.duration_in_months;
        if (coupon.amount_off && coupon.amount_off > 0) {
          
          //FYI - amount_off comes back in cents
          let price = this.getDisplayPrice((userContract.upgrade.term_cost - (coupon.amount_off / 100)));
          couponDeets = {
            name: coupon.name,
            price: price,
            monthly_amount: (price === 0 ? t('Free') : '$' + price + t('/month')),
            month_duration: monthDuration === 1 ? t('1 month') : monthDuration + t(' months'),
            normal_monthly: '$' + this.getDisplayPrice(userContract.upgrade.term_cost),
            coupon: coupon
          };
        }
        else if (coupon.percent_off && coupon.percent_off > 0) {
          
          let price = this.getDisplayPrice((userContract.upgrade.term_cost - (userContract.upgrade.term_cost * (coupon.percent_off / 100))));
          couponDeets = {
            name: coupon.name,
            price: price,
            monthly_amount: (price === 0 ? t('Free') : '$' + price + t('/month')),
            month_duration: monthDuration === 1 ? t('1 month') : monthDuration + t(' months'),
            normal_monthly: '$' + this.getDisplayPrice(userContract.upgrade.term_cost),
            coupon: coupon
          }
        }
        
        this.setState({
          valid_code_deets: couponDeets
        })
      })
      .catch((err) => {
        log.log('error validating coupon', err);
        
        this.setState({
          coupon_code_err: t('This Promo code is not valid.')
        })
      })
  }
  
  clearCoupon() {
    this.setState({
      valid_code_deets: null,
      promo_code: '',
      coupon_code_err: ''
    })
  }
  
  getCouponControls() {
    let { t } = this.props;
    let {valid_code_deets} = this.state;
    
    return (
      <div style={UpgradeCtrl.styles.couponBox}>
        <Button className={'btn btn-dark no-focus'} style={UpgradeCtrl.styles.clearButton}
                onClick={this.clearCoupon.bind(this)}>
          <i className="icon ion-close"/>
        </Button>
        <div className={'text-center'}>
          {/*This padding is to prevent overlapping text under the close button*/}
          <h4 style={{paddingRight: '20px'}}>
            {valid_code_deets.name} {t("Promo Applied")}
          </h4>
          <h5 className={'green-color'}>
            {valid_code_deets.monthly_amount} {t("for")} {valid_code_deets.month_duration}
          </h5>
          <p>
            {valid_code_deets.normal_monthly}/{t("month after that")}
          </p>
        </div>
      </div>
    )
  }
  
  showStripeCheckout(){
    let {
      stripeClass100,
      upgradePlans
    } = this.props;
  
    let {
      valid_code_deets
    } = this.state;
  
    this.props.showStripeCheckout(
      stripeClass100.statement_descriptor,
      (valid_code_deets ? valid_code_deets.price : upgradePlans.class_info.term_cost) * 100,
      (res) => {
        log.log('stripe checkout res', res);
        if (res) {
          let {valid_code_deets} = this.state;
          let couponId = _.get(valid_code_deets, 'coupon.id');
          this.props.onSuccessfulPaymentMethod(res, upgradePlans.class_info.class_id, couponId)
        }
      })
  }
  
  render() {
    let {
      accountInfo,
      userContract,
      stripeClass100,
      onUpgradeToken,
      upgradeMessage,
      t
    } = this.props;
    
    let {
      isSaving,
      coupon_code_err,
      promo_code,
      valid_code_deets
    } = this.state;
    
    let msg = t("Go Pro now to take your Verifyle account to the next level with unlimited signing, custom branding, and more capacity.");
    if(upgradeMessage && upgradeMessage.length > 0){
      msg = upgradeMessage;
    }
    
    return (
      <div className={'mt-3 mb-3'}>
        
        <div className={'card-body'}>
          <div className={'row'}>
            <div className={'col'}>
              {msg}
            </div>
          </div>
          
          {this.getUpgradeText()}
          
          <div className={'row mt-3'}>
            {!valid_code_deets &&
            <div className={'col'}>
              <div className={'form-inline justify-content-end'}>
                <label className={'mr-2'}>{t("Promo Code")}:</label>
                <input className="form-control mr-2 text-uppercase"
                       type={'text'}
                       value={promo_code}
                       onChange={(evt) => this.setState({promo_code: evt.target.value.toUpperCase()})}/>
                <Button disabled={promo_code.length === 0}
                        onClick={this.applyPromoCode.bind(this)}
                        className={'btn btn-secondary'}>{t("Apply")}</Button>
              </div>
            </div>
            }
            {valid_code_deets &&
            <div className={'col'}>
              {this.getCouponControls()}
            </div>
            }
            <div className={'col text-right'}>
              <Button onClick={this.showStripeCheckout.bind(this)}
                      className={'btn btn-primary mr-2'}>
                {t("Upgrade to Pro for")} ${valid_code_deets ? valid_code_deets.price : userContract.upgrade.term_cost}/{t("month")}
              </Button>
            </div>
          </div>
        </div>
        
        {coupon_code_err &&
        <div className={'row'}>
          <div className={'col mx-3'}>
            <ValidationErrors errors={[coupon_code_err]}/>
          </div>
        </div>
        }
      </div>
    )
  }
}

UpgradeCtrl.styles = {
  couponBox: {
    padding: '10px',
    border: 'dashed 2px grey',
    textAlign: 'center',
  },
  clearButton: {
    position: 'absolute',
    top: '-15px',
    right: '0px',
    border: '10px solid',
    zIndex: '100',
    borderRadius: '50%'
  }
}

UpgradeCtrl.propTypes = {
  onRef: PropTypes.func,
  onSuccessfulPaymentMethod: PropTypes.func.isRequired,
  upgradeMessage : PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    stripeData: state.shared.stripeData,
    userContract: state.shared.userContract,
    stripeClass100: state.shared.stripeClass100,
    upgradePlans : state.shared.upgradePlans
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(UpgradeCtrl)));
