import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

class ExpandableRow extends PureComponent {
  render() {
    let {children, isActive} = this.props;
    
    return (
      <AnimateHeight duration={400} height={isActive ? 'auto' : 0}>
        {children}
      </AnimateHeight>
    )
  }
}

ExpandableRow.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired
}

export default ExpandableRow;
