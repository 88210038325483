import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../partials/elements/Button';
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import log from "../../util/log";
import UserBadge from "../partials/badges/UserBadge";
import colors from "../../util/colors";
import {getMessageForError} from "../../util/errors";
import Checkbox from "../partials/elements/Checkbox";
import Loading from "../partials/util/Loading";
import searchHelper from "../../helpers/search-helper";
import sharedActions from "../../actions/shared-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class AddGuestWindow extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      checkedGuestIds : [],
      availableGuests : [],
      filteredGuests : [],
      searchString : '',
      loading : false
    }
  }
  
  componentDidMount() {
    let { t } = this.props;
    this.setState({loading : true});
    this.refreshAvailableGuests()
      .catch((err) => {
        log.error('error loading available guests', err);
        this.props.showAlert(t('Error loading guests'), t("There was a problem loading available guests.  Please try again"), () => {
          this.closeModal();
        })
      })
      .finally(() => {
        this.setState({loading : false})
      })
  }
  
  refreshAvailableGuests(){
    let { workspace } = this.props;
    return sapi.Workspace.availableGuests(workspace.forum_id)
      .then((res) => {
        this.setState({
          availableGuests : res.data
        }, () => {
          this.refreshSearchFilter();
        });
      })
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  onSearchUpdate(evt){
    this.setState({searchString : evt.target.value}, () => { this.refreshSearchFilter() });
  }
  
  onSearchClear(){
    this.setState({searchString : ''}, () => { this.refreshSearchFilter() });
  }
  
  refreshSearchFilter() {
    let {searchString, availableGuests} = this.state;
    let filteredGuests = [];
    _.each(availableGuests, (guest) => {
      let searchIndex = [guest.first_name, guest.last_name, guest.email_address];
      if(searchHelper.findInTokens(searchString, searchIndex)){
        filteredGuests.push(guest);
      }
    })
    
    filteredGuests = _.uniqBy(filteredGuests, 'guest_uid');
    this.setState({filteredGuests})
  }
  
  submit(){
    let { workspace, t } = this.props;
    let { checkedGuestIds } = this.state;
    sapi.Guests.add(workspace.forum_id, checkedGuestIds)
      .then((res) => {
        log.log('guest add res', res);
        this.closeModal(res);
      })
      .catch((err) => {
        log.error('error adding guests', err);
        this.props.showAlert(t("Error adding guests"), getMessageForError(err, t));
      })
    
  }
  
  newContactClick(){
    let { searchString } = this.state;
    
    this.props.showAddContactWindow(searchString, false, (res) => {
      if(res){
        Promise.all([
            this.refreshAvailableGuests(),
            this.props.updateContacts(),
            this.props.updateDirectMessages()
          ])
          .then(() => {
            //auto-select guest
            if(res && res.guest_uid){
              this.checkGuest(res.guest_uid, true);
            }
          })
      }
    })
  }
  
  checkGuest(guest_uid, val){
    let update = _.concat([], this.state.checkedGuestIds)
    if(!val){
      _.remove(update, (checked_guest_uid) => { return checked_guest_uid === guest_uid})
    }
    else{
      update.push(guest_uid);
    }
    this.setState({checkedGuestIds : update});
  }
  
  guestCheckChange(guest_uid, evt){
    this.checkGuest(guest_uid, evt.target.checked);
  }
  
  render() {
    let { t } = this.props;
    let {searchString, filteredGuests, checkedGuestIds, availableGuests, loading} = this.state;
    
    return (
      <div className="modal-content">
  
        <div className="modal-header">
          <h5 className="modal-title">{t("Add Guests")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        {loading &&
        <div className="modal-content">
          <div className="modal-body my-5 py-5">
            <Loading centered size={'sm'}/>
          </div>
        </div>
        }
        {!loading &&
        <div className="modal-body">
          <div className="mb-3">
            <h4 className={'m-0'}>
              {t("Contacts")}
              <Button onClick={this.newContactClick.bind(this)}
                      className={'btn btn-lg btn-icon ion-ios-plus-outline tab-add-btn no-focus'}/>
            </h4>
            <div className="input-group position-relative">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="icon ion-ios-search-strong" />
                </div>
              </div>
              <input className="form-control"
                     type="search"
                     value={searchString}
                     onChange={this.onSearchUpdate.bind(this)}
                     placeholder={t('Search Contacts')}/>
              {searchString.length > 0 &&
              <div style={styles.customLabelClearBtn}
                   onClick={this.onSearchClear.bind(this)}>
                <i className="icon ion-close dark-color"/>
              </div>
              }
            </div>
          </div>
          <div style={{
            minHeight : '300px'
          }}>
            {_.map(filteredGuests, (guest) => {
              let userChecked = checkedGuestIds.indexOf(guest.guest_uid) >= 0;
              return (
                <div key={guest.guest_uid} className="d-flex mb-3">
                  <div style={styles.userColumn} className="mr-3">
                    <UserBadge guest={guest} />
                  </div>
                  <div className="flex-grow-1" style={styles.userColumn}>
                    <h6 className="mb-0"
                        style={userChecked ? styles.selectedUserHeader : styles.unselectedUser}>
                      {guest.first_name} {guest.last_name}
                    </h6>
                    <p className="mb-0"
                       style={userChecked ? styles.selectedUserEmail : styles.unselectedUser}>
                      {guest.email_address}
                    </p>
                  </div>
                  <div style={styles.userColumn} className="ml-3 mr-2">
                    <div className="d-inline-block">
                      <Checkbox isChecked={userChecked}
                                rootCls="big-round-checkbox form-control-lg"
                                label={' '}
                                onChange={this.guestCheckChange.bind(this, guest.guest_uid)}
                                labelCls={'primary-color'} />
                    </div>
                  </div>
                </div>
              )
            })}
            {searchString.length > 0 && filteredGuests.length === 0 &&
            <h4 className="font-weight-light text-center py-5">
              {t("No matches found.")}
            </h4>
            }
            {(availableGuests.length === 0 || filteredGuests.length === 0) &&
            <div className="text-center pt-2">
              <button className="btn btn-lg btn-primary" onClick={this.newContactClick.bind(this)}>
                {t("Add New Contact")}
              </button>
            </div>
            }
          </div>
        </div>
        }
        <div className="modal-footer">
          <Button className="btn btn-secondary" onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className="btn btn-primary"
                  disabled={checkedGuestIds.length === 0}
                  onClick={this.submit.bind(this)}>
            {checkedGuestIds.length > 0 ? t("Add ") + checkedGuestIds.length : t('Add')}
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  userColumn: {
    minHeight : '50px'
  },
  selectedUserHeader : {
    fontWeight : 'bold'
  },
  selectedUserEmail : {
    fontWeight: '100'
  },
  unselectedUser : {
    color : colors.SECONDARY_TEXT,
    fontWeight: '100'
  },
  customLabelClearBtn : {
    position : 'absolute',
    right : '10px',
    top : '6px',
    lineHeight : '24px',
    color : colors.DARK,
    zIndex : 100,
    cursor : 'pointer'
  }
}

const mapStateToProps = (state) => {
  return {
    workspace : state.workspace.workspace
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch)
  };
};

AddGuestWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddGuestWindow));
