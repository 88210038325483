import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import ChatPanelHeader from "./ChatPanelHeader";
import ChatPanelMessages from "./ChatPanelMessages";
import ChatPanelFooter from "./ChatPanelFooter";
import colors from "../../../util/colors";
import UploadHelper from "../components/UploadHelper";
import uploadActions from "../../../actions/upload-actions";
import workspaceActions from "../../../actions/workspace-actions";
import sharedActions from "../../../actions/shared-actions";
import AttachedDocsPanel from "../docs/AttachedDocsPanel";
import Scroll from "react-scroll";
import UpgradeDialog from "../../modals/UpgradeDialog";
import modalActions from "../../../actions/modal-actions";
import {withRouter} from "react-router-dom";
import SignatureRequest from "../../../models/SignatureRequest";
import {getMessageForError} from "../../../util/errors";
import PendingMsgCache from "../../../helpers/pending-msg-cache";
import utils from "../../../util/util";
import msgHelper from "../../../helpers/msg-helper";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class ChatPanel extends PureComponent {
  
  constructor(props) {
    super(props);
    
    this.state = {
      chatMessagesRef: null,
      footerRef : null,
      docsPanelRef: null,
      sendingMsg: false
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this);
    }
  
    utils.waitForCondition(() => {
        return !!this.state.footerRef;
      })
      .then(() => {
        if(this.props.thread) {
          let cached = PendingMsgCache.fetchThread(this.props.thread.chat_id);
          this.state.footerRef.initPendingMessage(cached.msg, cached.docs);
        }
      })
  }
  
  componentWillUnmount() {
    if(this.props.thread) {
      let previousData = this.state.footerRef.getPendingData();
      PendingMsgCache.cacheThread(this.props.thread.chat_id, previousData.msg, previousData.docs);
    }
    
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.thread && prevProps.thread !== this.props.thread){
  
      //Kind of ugly, but the reference can change, but the ids are the same.
      //We only want to run this if they're changing the panel.
      if(!prevProps.thread || prevProps.thread.chat_id !== this.props.thread.chat_id) {
        if (prevProps.thread) {
          //save previous pending data, and restore the upcoming data
          let previousData = this.state.footerRef.getPendingData();
          PendingMsgCache.cacheThread(prevProps.thread.chat_id, previousData.msg, previousData.docs);
        }
        let cached = PendingMsgCache.fetchThread(this.props.thread.chat_id);
        this.state.footerRef.initPendingMessage(cached.msg, cached.docs);
  
        setTimeout(() => {
          this.state.chatMessagesRef.scrollToBottomWhenReady();
        })
      }
    }
  }
  
  mergeAttachDocsWithCurrentMsg(docs){
    let files = [];
    _.each(docs, (doc) => {
      let f = _.extend({}, doc);
      f.name = f.doc_label;
      f.sizeString = ''; // we don't have this for copy docs.
      f.is_doc_copy = true;
      f.uniqueId = _.uniqueId('vf-doc-attach-');
      files.push(f);
    })
    
    this.state.footerRef.updatePendingDocs(files);
  }
  
  refreshMessages() {
    let {workspace, thread, refreshThreadMessages} = this.props;
    
    return Promise.all([
      refreshThreadMessages(workspace.forum_id, workspace.host_uid, thread.chat_id),
      this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true),
      this.props.updateNotifications(),
      this.props.refreshDocs(workspace.forum_id, workspace.host_uid)
    ])
  }
  
  sendMessageWithDocs(pendingMsg, pendingFiles){
    let {workspace, thread, t} = this.props;
    
    let docAttachFiles = [];
    let sigRequestFiles = [];
    let nonSigRequestFiles = [];
    _.each(pendingFiles, (f) => {
      if(f.is_doc_copy){
        docAttachFiles.push(f);
      }
      else{
        if(f.signature_requested){
          sigRequestFiles.push(f);
        }
        else{
          nonSigRequestFiles.push(f);
        }
      }
    })
    
    let msgSent = false;
    if(nonSigRequestFiles.length > 0){
      let transaction_id = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueThreadDocTransaction(transaction_id, workspace.forum_id, workspace.host_uid, thread.chat_id, upload_id, nonSigRequestFiles, pendingMsg);
      this.props.queueChatUpload(transaction_id, upload_id, workspace.forum_id, workspace.host_uid, thread.chat_id, false, nonSigRequestFiles, pendingMsg);
      msgSent = true;
    }
    if(sigRequestFiles.length > 0){
      let transaction_id = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueThreadDocTransaction(transaction_id, workspace.forum_id, workspace.host_uid, thread.chat_id, upload_id, sigRequestFiles, msgSent ? null : pendingMsg);
      this.props.queueChatUpload(transaction_id, upload_id, workspace.forum_id, workspace.host_uid, thread.chat_id, true, sigRequestFiles, msgSent ? null : pendingMsg);
      msgSent = true;
    }
    if(docAttachFiles.length > 0){
      let docAttachTransaction = _.uniqueId('vf-transaction-id');
      let upload_id = _.uniqueId('vf-upload-id-');
      this.props.queueThreadDocAttachTransaction(docAttachTransaction, workspace.forum_id, workspace.host_uid, thread.chat_id, docAttachFiles, msgSent ? null : pendingMsg);
      this.props.queueThreadDocAttach(docAttachTransaction, upload_id, workspace.forum_id, workspace.host_uid, thread.chat_id, docAttachFiles, msgSent ? null : pendingMsg)
      msgSent = true;
    }
  
    this.state.footerRef.clear();
    return this.refreshMessages()
      .then(() => {
        PendingMsgCache.clearThread(thread.chat_id)
        setTimeout(() => {
          this.state.chatMessagesRef.scrollToBottom(250);
        })
      })
      .catch((err) => {
        log.log('error during message refresh', err);
        this.state.footerRef.setNotSending();
        this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
      })
  }
  
  sendMessage(pendingMsg, pendingFiles) {
    let {workspace, thread, t} = this.props;
    
    pendingMsg = msgHelper.trimWhitespace(pendingMsg);
    
    if(pendingFiles.length > 0){
      this.sendMessageWithDocs(pendingMsg, pendingFiles);
      return;
    }
    
    let transaction_id = _.uniqueId('vf-transaction-id');
    
    this.props.queueThreadMsgTransaction(transaction_id, workspace.forum_id, workspace.host_uid, thread.chat_id, pendingMsg);
    this.setState({sendingMsg: true})
    sapi.Threads.sendMessage(workspace.forum_id, workspace.host_uid, thread.chat_id, pendingMsg)
      .then((res) => {
        log.log('send message res', res);
        
        this.props.dequeueThreadMsgTransaction(transaction_id);
        this.state.footerRef.clear();
        
        return this.refreshMessages()
      })
      .then(() => {
        PendingMsgCache.clearThread(thread.chat_id)
        setTimeout(() => {
          this.state.chatMessagesRef.scrollToBottom(250);
        })
      })
      .catch((err) => {
        log.log('error during message send', err);
        this.props.dequeueThreadMsgTransaction(transaction_id);
        this.state.footerRef.setNotSending();
        this.props.showAlert(t('Error Sending Message'), getMessageForError(err, t))
      })
      .finally(() => {
        this.setState({sendingMsg: false})
      })
  }
  
  editSignatureRequest(msg, doc) {
    log.log('edit signature request click', msg, doc);
    
    let {workspace, threadParticipantLookup, thread, t} = this.props;
    let sigRequest = new SignatureRequest(
      workspace.forum_id,
      workspace.host_uid,
      doc.doc_id,
      thread.chat_id,
      msg.mesg_id,
      doc
    )
    
    Promise.all([
        sapi.Docs.info(workspace.forum_id, workspace.host_uid, doc.doc_id),
        sapi.Workspace.getSignatureRequest(doc.sign_request_id, workspace.host_uid)
      ])
      .then((res) => {
        let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === doc.signer_uid});
        log.log('signature request load', res);
        sigRequest.setSigner(doc.signer_uid, guest);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(res[1].data);
        sigRequest.terms = res[1].data.terms;
        sigRequest.smsNumber = _.get(res[1], 'data.sign_data.smsNumber');
        
        this.props.editSignatureRequest(sigRequest, (res) => {
          log.log('pdf signature request window closed', res);
          
          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }
  
  fulfillSignatureRequest(msg, doc){
    let {workspace, threadParticipantLookup, thread, t} = this.props;
    let sigRequest = new SignatureRequest(
      workspace.forum_id,
      workspace.host_uid,
      doc.doc_id,
      thread.chat_id,
      msg.mesg_id,
      doc
    )
    
    Promise.all([
        sapi.Docs.info(workspace.forum_id, workspace.host_uid, doc.doc_id),
        sapi.Workspace.getSignatureRequest(doc.sign_request_id, workspace.host_uid)
      ])
      .then((res) => {
        let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === doc.signer_uid});
        let host = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === workspace.host_uid});
        log.log('signature request load', res, guest, host);
        sigRequest.setSigner(doc.signer_uid, guest);
        sigRequest.setRequestor(host);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(res[1].data);
        sigRequest.terms = res[1].data.terms;
        sigRequest.smsNumber = _.get(res[1], 'data.sign_data.smsNumber');
        
        this.props.showFulfillSignatureRequest(sigRequest, (res) => {
          log.log('pdf signature request window closed', res);
          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }
  
  completeSignatureRequest(msg, docStatus){
    let { workspace, threadParticipantLookup, thread, accountInfoGuest, t } = this.props;
    
    let sigRequest = new SignatureRequest(
      workspace.forum_id,
      workspace.host_uid,
      docStatus.doc_id,
      thread.chat_id,
      msg.mesg_id,
      docStatus
    );
    
    let participants = _.filter(threadParticipantLookup[thread.chat_id], (user) => {
      return user.guest_uid !== accountInfoGuest.guest_uid;
    })
    
    if(participants.length === 0){
      this.props.showAlert(t('No Guests in Thread'), t("There are no Guests in this Thread.  To request a signature, please add a Guest first."));
      return;
    }
    
    this.props.showRequestSignatureWindow(
      workspace.forum_id,
      thread.chat_id,
      msg.mesg_id,
      docStatus.doc_id,
      docStatus.label,
      participants,
      null,
      null,
      (res) => {
        log.log('request signature form closed', res);
        if(res){
          
          //No further data has been saved at this point, the signature request is still incomplete.
          sigRequest.smsNumber = res.smsNumber;
          sigRequest.terms = res.terms;
          Promise.all([
              sapi.Docs.info(sigRequest.forum_id, sigRequest.host_uid, sigRequest.doc_id),
            ])
            .then((loadRes) => {
              let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === res.signer_uid});
              sigRequest.setSigner(res.signer_uid, guest);
              sigRequest.setDocInfo(loadRes[0].data);
              
              this.props.completeSignatureRequest(sigRequest, (res) => {
                log.log('pdf signature request window closed', res);
  
                this.refreshMessages();
              })
            })
            .catch((err) => {
              log.error('error loading signature request data', err);
              this.props.showAlert(t('Error loading signature request data'), getMessageForError(err, t));
            })
        }
      }
    )
  }
  
  fulfillThreadSignatureRequest(mesg_id, doc_id, signatureRequest, sign_request_id){
    let {workspace, threadParticipantLookup, thread, accountInfoGuest, accountSignatures, t} = this.props;
    let sigRequest = new SignatureRequest(
      workspace.forum_id,
      workspace.host_uid,
      doc_id,
      thread.chat_id,
      mesg_id,
      {
        sign_status : SignatureRequest.SIGN_STATUS.REQUESTED,
        sign_request_id
      }
    )
    
    Promise.all([
        sapi.Docs.info(workspace.forum_id, workspace.host_uid, doc_id)
      ])
      .then((res) => {
        let host = _.find(threadParticipantLookup[thread.chat_id], (guest) => { return guest.guest_uid === workspace.host_uid});
        log.log('signature request load', res, host);
        sigRequest.setSigner(accountInfoGuest.guest_uid, accountInfoGuest);
        sigRequest.setRequestor(host);
        sigRequest.setDocInfo(res[0].data);
        sigRequest.setSignatureRequest(signatureRequest);
        sigRequest.terms = signatureRequest.terms;
        sigRequest.smsNumber = signatureRequest.sign_data.smsNumber;
        
        this.props.showFulfillSignatureRequest(sigRequest, (res) => {
          log.log('pdf signature request window closed', res);
          this.refreshMessages();
        })
      })
      .catch((err) => {
        log.error('error loading signature request', err);
        this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
      })
  }
  
  chatFileDrop(files) {
    this.state.footerRef.docFileDrop(files);
  }
  
  onDocAttach(docs){
    this.state.footerRef.addCopyDocs(docs);
  }
  
  onThreadDocClick(doc){
    this.state.docsPanelRef.selectDocAndNavigate(doc);
  }
  
  selectDocAndNavigate(doc, thread){
    this.state.docsPanelRef.selectDocAndNavigate(doc, thread);
  }
  
  onAttachDocToThread(res){
    this.props.onAttachDocToThread(res);
  }
  
  doBeforePrint(){
    return this.state.chatMessagesRef.doPrintPreview()
  }
  
  onPrintError(err){
    log.error("error printing chat", err);
    this.state.chatMessagesRef.cleanupPrintPreview();
  }
  
  doAfterPrintPreview(){
    this.state.chatMessagesRef.cleanupPrintPreview();
  }
  
  getPrintPreviewContents(){
    return this.state.chatMessagesRef.printPreviewRef.printRef.current
  }
  
  findDocInfo(doc_id){
    let { docs } = this.props;
    
    let found = null;
    _.each(docs, (chatBlock) => {
      _.each(chatBlock.docs, (doc) => {
        if(doc.doc_id === doc_id){
          found = doc;
          return false;
        }
      })
      if(found){
        return false;
      }
    })
    
    return found;
  }
  
  onMesgHistoryClick(msg) {
    let { thread } = this.props;
    
    this.props.showThreadMessageHistory(thread, msg)
  }
  
  onMesgEditClick (msg) {
    let { thread } = this.props;
    
    this.props.showEditThreadMessage(thread, msg, (res) => {
      if(res){
        this.refreshMessages();
      }
    })
  }
  
  render() {
    let {
      workspace,
      thread,
      threadParticipantLookup,
      docParticipantLookup,
      docs,
      threadDocs,
      activeThreadMessageBlocks,
      activeThreadMesgEditFlag
    } = this.props;
  
    let docNotifyCount = 0;
    _.each(threadDocs, (doc) => {
      if(doc.notify_flag) docNotifyCount++
    })
    
    let threadGuests = threadParticipantLookup ? (threadParticipantLookup[thread.chat_id] || []) : [];
    
    return (
      <UploadHelper onDrop={this.chatFileDrop.bind(this)}
                    generateImagePreviews={true}
                    allowMultiple={true}
                    disableClick={true}>
        <div className="center-col d-flex flex-column">
      
          <div>
            <ChatPanelHeader thread={thread}
                             onAfterPrintPreview={this.doAfterPrintPreview.bind(this)}
                             onBeforePrintPreview={this.doBeforePrint.bind(this)}
                             onPrintPreviewError={this.onPrintError.bind(this)}
                             getPrintPreviewContents={this.getPrintPreviewContents.bind(this)}
                             guests={threadGuests}/>
          </div>
      
          <div className="flex-grow-1" style={styles.messagePanelFlex}>
            <div className="row mx-0 height-100">
              <div className="col-7 height-100 pl-2 pr-0">
                <div className="d-flex flex-column" style={styles.messagePanelFlex}>
                  <div className="flex-grow-1" style={styles.messageContents}>
                    <ChatPanelMessages thread={thread}
                                       mesg_edit_flag={activeThreadMesgEditFlag}
                                       messageBlocks={activeThreadMessageBlocks}
                                       refreshMessages={this.refreshMessages.bind(this)}
                                       docClick={this.onThreadDocClick.bind(this)}
                                       onRef={(ref) => {
                                         this.setState({chatMessagesRef: ref})
                                       }}
                                       onMesgEditClick={this.onMesgEditClick.bind(this)}
                                       onMesgHistoryClick={this.onMesgHistoryClick.bind(this)}
                                       findDocInfo={this.findDocInfo.bind(this)}
                                       fulfillSignatureRequestClick={this.fulfillSignatureRequest.bind(this)}
                                       editSignatureRequestClick={this.editSignatureRequest.bind(this)}
                                       completeSignatureRequestClick={this.completeSignatureRequest.bind(this)} />
                  </div>
                  <div>
                    <ChatPanelFooter thread={thread}
                                     onRef={(ref) => this.setState({footerRef : ref})}
                                     sendMessage={this.sendMessage.bind(this)}
                                     signingIsAllowed={!workspace.host_uid} />
                  </div>
                </div>
              </div>
              <div className="col-5 height-100 pl-2 pr-2 pb-2">
                <div id="attached-docs-col" className="scrollable-col" style={styles.docsPanelContents}>
                  <AttachedDocsPanel docs={threadDocs}
                                     workspace={workspace}
                                     thread={thread}
                                     onRef={(ref) => {
                                       this.setState({docsPanelRef: ref})
                                     }}
                                     onAttachDocToThread={this.onAttachDocToThread.bind(this)}
                                     participantLookup={docParticipantLookup}
                                     workspaceDocs={docs}
                                     docNotifyCount={docNotifyCount || 0}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UploadHelper>
    )
  }
}

const styles = {
  messagePanelFlex: {
    height: '100%',
    overflow: 'hidden',
    padding: '0px',
    backgroundColor: colors.PRIMARY,
    borderBottomLeftRadius: '5px'
  },
  messageContents: {
    backgroundColor: colors.LIGHT,
    padding: '0px 5px',
    borderRadius: '5px',
    height: '0%', //I don't know why this works, but any other value screws things up in safari
  },
  docsPanelContents: {
    backgroundColor: colors.LIGHT,
    borderRadius: '5px',
    height: '100%',
    overflowY: 'auto'
  }
}

ChatPanel.propTypes = {
  thread: PropTypes.object,
  threadDocs: PropTypes.array,
  onRef : PropTypes.func.isRequired,
  onAttachDocToThread : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    docs : state.workspace.docs,
    threadParticipantLookup : state.workspace.threadParticipantLookup,
    docParticipantLookup : state.workspace.docParticipantLookup,
    accountInfoGuest : state.shared.accountInfoGuest,
    accountSignatures : state.shared.signatures,
    activeThreadMessageBlocks : state.workspace.activeThreadMessageBlocks,
    activeThreadMesgEditFlag : state.workspace.activeThreadMesgEditFlag,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    queueChatUpload: (transaction_id, upload_id, forum_id, host_uid, chat_id, signature_requested, files, mesg) => dispatch(uploadActions.queueChatUpload(transaction_id, upload_id, forum_id, host_uid, chat_id, signature_requested, files, mesg)),
    queueThreadDocAttach: (transaction_id, upload_id, forum_id, host_uid, chat_id, files, mesg) => dispatch(uploadActions.queueThreadDocAttach(transaction_id, upload_id, forum_id, host_uid, chat_id, files, mesg)),
    ...sharedActions.mapToDispatch(dispatch),
    ...workspaceActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatPanel)));
