import c from '../util/const';
import sapi from "../util/sapi";

import Promise from 'bluebird';
import homeActions from "./home-actions";
import workspaceActions from "./workspace-actions";
import moment from 'moment';
import _ from 'lodash';
import log from "../util/log";

const sharedActions = {

  startup() {
    let me = this;

    return (dispatch) => {
  
      return Promise.all([
          dispatch(me.updateContacts()),
          dispatch(me.updateAccountInfo()),
        ])
        .then(() => {
  
          dispatch({
            type : c.actions.shared.setStartupFirstCallsLoaded,
            hasLoaded : true
          })
          log.log('first startup calls finished')
          
          return Promise.all([
            dispatch(me.updateWorkspaces()),
            dispatch(me.updateNotifications()),
            dispatch(me.updateSignatures()),
            dispatch(me.updateDirectMessages()),
            dispatch(me.updateUserContractInfo()),
            dispatch(me.updateStripeData()),
            dispatch(me.updateStripePlan100()),
            dispatch(me.updateLogo()),
            dispatch(me.updateAvailablePlans()),
          ])
        })
        .then((res) => {
          dispatch({
            type: c.actions.shared.setLoaded,
            hasLoaded: true
          })
        })
    }
  },

  cleanup() {
    return {
      type : c.actions.shared.cleanup,
    }
  },
  
  updateUserContractInfo() {
    return (dispatch) => {
      return sapi.UserContract.post()
        .then((data) => {
          dispatch({
            type: c.actions.shared.updateUserContract,
            contract: data.data
          })
        })
    }
  },
  
  updateLogo(){
    return (dispatch) => {
      return sapi.Workspace.logo()
        .then((res) => {
  
          let logoRes = res.data.logo;
          //Weird, but the backend returns a single pixel response when there's no logo.
          if(logoRes === c.BLANK_LOGO){
            dispatch({
              type : c.actions.shared.updateLogo,
              data : null
            })
          }
          else{
            dispatch({
              type : c.actions.shared.updateLogo,
              data : logoRes
            })
          }
        })
        .catch((err) => {
          log.log('error fetching logo', err);
          dispatch({
            type : c.actions.shared.updateLogo,
            data : null
          })
        })
    }
  },
  
  monitorContactImportQueue(callUpdateListWhenDone) {
    return (dispatch, getState) => {
      return sapi.Contacts.queue()
        .then((res) => {
          
          let queuedContacts = [];
          let queueRes = res.data;
          if (queueRes.status && queueRes.status.undone > 0) {
            log.log('queue triggered', queueRes);
            
            _.each(queueRes.queue, (queuedItem) => {
              let existingContact = _.find(getState().shared.contacts, (c) => { return c.email_address === queuedItem.email; })
              if(!existingContact) {
                queuedContacts.push({
                  email_alert_flag: true,
                  guest_uid: _.uniqueId('fake-guest-uid'), //just so we have something to use as a key.
                  first_name: queuedItem.first_name,
                  last_name: queuedItem.last_name,
                  email_address: queuedItem.email,
                  active_flag: true,
                  is_pending: queuedItem.status === 'undone',
                })
              }
            })
            
            let existingContacts = getState().shared.contacts;
            let contactRes = _.concat(existingContacts, queuedContacts);
            dispatch({
              type: c.actions.shared.updateContacts,
              contacts: contactRes
            })
            
            setTimeout(() => {
              log.log('running new queue pass');
              dispatch(this.updateDirectMessages());
              dispatch(this.monitorContactImportQueue(true));
            }, 5000);
          }
          else if (callUpdateListWhenDone) {
            dispatch(this.updateContacts());
          }
        })
    }
  },
  
  updateContacts() {
    return (dispatch) => {
      
      return Promise.all([
          sapi.Contacts.list()
        ])
        .then((res) => {
          
          let contactRes = _.concat(res[0].data);
          dispatch({
            type: c.actions.shared.updateContacts,
            contacts: contactRes
          })
          
          //Longer process that we don't want to wait for.  We want to check if there are more
          //queued contacts any time we check this list, it updates things in the background.
          dispatch(this.monitorContactImportQueue(false));
        })
    }
  },
  
  updateSignatures(){
    return (dispatch) => {
      return sapi.AccountInfo.getSignatures()
        .then((res) => {
          dispatch({
            type : c.actions.shared.updateAccountSignatures,
            signatures : res.data
          })
        })
        .catch((err) => {
          log.log('no signatures in account', err);
          dispatch({
            type : c.actions.shared.updateAccountSignatures,
            signatures : null
          })
        })
    }
  },
  
  updateWorkspaces() {
    return (dispatch) => {
      return sapi.Workspace.get()
        .then(data => {
          dispatch(homeActions.updateWorkspaceLists(data.data))
          dispatch({
            type: c.actions.shared.updateWorkspaces,
            workspaces: data.data
          });
          return data.data;
        })
    }
  },
  
  updateDMPreviews(guest_uids = []){
    return (dispatch, getState) => {
      return sapi.DM.previewList(guest_uids)
        .then((res) => {
          dispatch({
            type: c.actions.shared.updateDirectMessagePreviews,
            directMessagePreviews : res.data
          })
        })
    }
  },
  
  updateDirectMessages() {
    return (dispatch, getState) => {
      let dmList = null;
      return sapi.DM.list('updated_date', true)
        .then((res) => {
        
          dmList = res.data;
          dispatch({
            type: c.actions.shared.updateDirectMessages,
            directMessages: dmList
          })
          
          if(getState().workspace.activeDM){
            let foundDM = null;
            _.each(dmList, (dm) => {
              if(dm.guest_uid === getState().workspace.activeDM.guest_uid){
                foundDM = dm;
              }
            })
          
            if(foundDM){
              return dispatch({
                type: c.actions.workspace.setActiveDM,
                dm : foundDM
              });
            }
          }
          if(getState().home.activeDM){
            let foundDM = null;
            _.each(dmList, (dm) => {
              if(dm.guest_uid === getState().home.activeDM.guest_uid){
                foundDM = dm;
              }
            })
    
            if(foundDM){
              return dispatch({
                type: c.actions.home.setActiveDM,
                dm : foundDM
              });
            }
          }
        })
    }
  },
  
  updateAvailablePlans(){
    return (dispatch) => {
      return sapi.Stripe.availablePlans()
        .then((res) => {
          dispatch({
            type : c.actions.shared.updateAvailablePlans,
            upgradePlans : res.data
          })
        })
    }
  },
  
  updateAccountInfo() {
    return (dispatch) => {
      return sapi.AccountInfo.get()
        .then(data => {
          let accountInfo = data.data;
          dispatch({
            type: c.actions.shared.updateAccountInfo,
            accountInfo,
            //just a helper, to normalize your guest info against normal guest calls.
            accountInfoGuest : {
              first_name : accountInfo.first_name,
              last_name : accountInfo.last_name,
              guest_uid : accountInfo.uid,
              email_address : accountInfo.login,
              email : accountInfo.login
            }
          });
          return data.data;
        })
    }
  },
  
  updateNotifications() {
    return (dispatch, getState) => {
      
      let { accountInfo } = getState().shared;
      if(accountInfo && accountInfo.auto_notify_flag){
        return Promise.resolve(true);
      }
      
      return sapi.Notify.count()
        .then(data => {
          dispatch({
            type: c.actions.shared.updateNotificationCount,
            notifyCount: data.data.notification_count
          });
          return data.data;
        })
    }
  },
  
  updateStripeData() {
    return (dispatch) => {
      return sapi.Stripe.list()
        .then((data) => {
          dispatch({
            type: c.actions.shared.updateStripeInfo,
            data : data.data
          })
        })
    }
  },
  
  queueDMMsgTransaction(transaction_id, guest_uid, mesg){
    return {
      type : c.actions.shared.queueThreadTransaction,
      threadTransactionQueue : [{
        transaction_id,
        guest_uid,
        mesg,
        type : this.TRANSACTION_TYPES.DM_MESSAGE,
        queue_time: moment()
      }]
    }
  },
  
  dequeueDMMsgTransaction(transaction_id){
  
    return {
      type : c.actions.shared.dequeueThreadTransaction,
      transaction_id
    }
  },
  
  queueDMDocTransaction(transaction_id, guest_uid, upload_id, files, mesg){
    return {
      type : c.actions.shared.queueThreadTransaction,
      threadTransactionQueue : [{
        transaction_id,
        guest_uid,
        upload_id,
        files,
        mesg,
        type : this.TRANSACTION_TYPES.DM_DOC,
        queue_time: moment(),
      }]
    }
  },
  
  dequeueDMDocTransaction(transaction_id){
  
    return {
      type : c.actions.shared.dequeueThreadTransaction,
      transaction_id
    }
  },
  
  queueDMDocAttachTransaction(transaction_id, guest_uid, files, mesg){
    return {
      type : c.actions.shared.queueThreadTransaction,
      threadTransactionQueue : [{
        transaction_id,
        guest_uid,
        mesg,
        files,
        type : this.TRANSACTION_TYPES.DOC_ATTACH,
        queue_time: moment()
      }]
    }
  },
  
  dequeueDMDocAttachTransaction(transaction_id){
    return {
      type : c.actions.shared.dequeueThreadTransaction,
      transaction_id
    }
  },
  
  queueThreadDocAttachTransaction(transaction_id, forum_id, host_uid, chat_id, files, mesg){
    return {
      type : c.actions.shared.queueThreadTransaction,
      threadTransactionQueue : [{
        transaction_id,
        forum_id,
        host_uid,
        chat_id,
        mesg,
        files,
        type : this.TRANSACTION_TYPES.DOC_ATTACH,
        queue_time: moment()
      }]
    }
  },
  
  dequeueThreadDocAttachTransaction(transaction_id){
  
    return {
      type : c.actions.shared.dequeueThreadTransaction,
      transaction_id
    }
  },
  
  queueThreadMsgTransaction(transaction_id, forum_id, host_uid, chat_id, mesg){
    return {
      type : c.actions.shared.queueThreadTransaction,
      threadTransactionQueue : [{
        transaction_id,
        forum_id,
        host_uid,
        chat_id,
        mesg,
        type : this.TRANSACTION_TYPES.MESSAGE,
        queue_time: moment()
      }]
    }
  },
  
  dequeueThreadMsgTransaction(transaction_id){
    return {
      type : c.actions.shared.dequeueThreadTransaction,
      transaction_id
    }
  },
  
  queueThreadDocTransaction(transaction_id, forum_id, host_uid, chat_id, upload_id, files, mesg){
    return {
      type : c.actions.shared.queueThreadTransaction,
      threadTransactionQueue : [{
        transaction_id,
        forum_id,
        host_uid,
        chat_id,
        upload_id,
        files,
        mesg,
        type : this.TRANSACTION_TYPES.DOC,
        queue_time: moment(),
      }]
    }
  },
  
  dequeueThreadDocTransaction(transaction_id){
  
    return {
      type : c.actions.shared.dequeueThreadTransaction,
      transaction_id
    }
  },
  
  updateStripePlan100() {
    return (dispatch) => {
      return sapi.Stripe.class_100()
        .then(data => {
          dispatch({
            type: c.actions.shared.updateStripePlan100,
            stripeClass100: data.data
          });
          return true;
        })
    }
  },
  
  mapToDispatch(dispatch) {
    return {
      queueThreadMsgTransaction : (transaction_id, forum_id, host_uid, chat_id, mesg) => dispatch(this.queueThreadMsgTransaction(transaction_id, forum_id, host_uid, chat_id, mesg)),
      queueThreadDocTransaction : (transaction_id, forum_id, host_uid, chat_id, upload_id, files, mesg) => dispatch(this.queueThreadDocTransaction(transaction_id, forum_id, host_uid, chat_id, upload_id, files, mesg)),
      dequeueThreadDocTransaction : (transaction_id) => dispatch(this.dequeueThreadDocTransaction(transaction_id)),
      dequeueThreadMsgTransaction : (transaction_id) => dispatch(this.dequeueThreadMsgTransaction(transaction_id)),
      queueThreadDocAttachTransaction : (transaction_id, forum_id, host_uid, chat_id, files, mesg) => dispatch(this.queueThreadDocAttachTransaction(transaction_id, forum_id, host_uid, chat_id, files, mesg)),
      dequeueThreadDocAttachTransaction : (tranaction_id) => dispatch(this.dequeueThreadDocAttachTransaction(tranaction_id)),
      queueDMDocAttachTransaction: (transaction_id, guest_uid, files, mesg) => dispatch(this.queueDMDocAttachTransaction(transaction_id, guest_uid, files, mesg)),
      dequeueDMDocAttachTransaction : (transaction_id) => dispatch(this.dequeueDMDocAttachTransaction(transaction_id)),
      queueDMDocTransaction: (transaction_id, guest_uid, upload_id, files, mesg) => dispatch(this.queueDMDocTransaction(transaction_id, guest_uid, upload_id, files, mesg)),
      dequeueDMDocTransaction : (transaction_id) => dispatch(this.dequeueDMDocTransaction(transaction_id)),
      queueDMMsgTransaction:(transaction_id, guest_uid, mesg) => dispatch(this.queueDMMsgTransaction(transaction_id, guest_uid, mesg)),
      dequeueDMMsgTransaction:(transaction_id) => dispatch(this.dequeueDMMsgTransaction(transaction_id)),
  
      updateAvailablePlans: () => dispatch(this.updateAvailablePlans()),
      updateNotifications: () => dispatch(this.updateNotifications()),
      updateAccountInfo : () => dispatch(this.updateAccountInfo()),
      updateDirectMessages: () => dispatch(this.updateDirectMessages()),
      updateDMPreviews: (guest_uids) => dispatch(this.updateDMPreviews(guest_uids)),
      updateWorkspaces : () => dispatch(this.updateWorkspaces()),
      updateSignatures : () => dispatch(this.updateSignatures()),
      updateContacts : () => dispatch(this.updateContacts())
    }
  },
}

sharedActions.TRANSACTION_TYPES = {
  MESSAGE : 'msg',
  DOC_ATTACH: 'doc_attach',
  DOC : 'doc',
  DM_MESSAGE : 'dm_msg',
  DM_DOC : 'dm_doc'
}

export default sharedActions;
