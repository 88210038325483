import c from '../../util/const';
import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import log from "../../util/log";
import sapi from '../../util/sapi';
import Loading from "../partials/util/Loading";

import workspaceActions from '../../actions/workspace-actions';

import Promise from 'bluebird';
import Image from "../partials/elements/Image";
import filters from "../../helpers/filters";
import DocInfo from "../partials/doc-preview/DocInfo";
import ImgPreview from "../partials/doc-preview/ImgPreview";
import PdfPreview from "../partials/pdf-preview/PdfPreview";
import TextPreview from "../partials/doc-preview/TextPreview";
import SvgPreview from "../partials/doc-preview/SvgPreview";
import classnames from 'classnames'
import AnimateHeight from "react-animate-height";
import utils from "../../util/util";
import modalActions from "../../actions/modal-actions";
import {getMessageForError} from "../../util/errors";
import SignatureRequest from "../../models/SignatureRequest";
import DMSignatureRequest from "../../models/DMSignatureRequest";
import _ from "lodash";
import pdfPreviewActions from "../../actions/pdf-preview-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class PreviewWindow extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      loading : true,
      doc_info : null,
      displayMode : null,
    }
  }

  setWindowMode(mode){
    this.setState({
      displayMode : mode
    })
  }

  getPreviewMode(label){
    let mode = null;
    if (utils.isPDFPreview(label)) {
      mode = PreviewWindow.WINDOW_MODES.PDF_PREVIEW;
    } else if (utils.isImgPreview(label)) {
      mode = PreviewWindow.WINDOW_MODES.IMG_PREVIEW;
    } else if (utils.isTextPreview(label)) {
      mode = PreviewWindow.WINDOW_MODES.TEXT_PREVIEW;
    } else if (utils.isSvgPreview(label)) {
      mode = PreviewWindow.WINDOW_MODES.SVG_PREVIEW;
    }
  
    return mode;
  }
  
  componentDidMount() {
    let { t } = this.props;
    let { doc_id, forum_id, host_uid } = this.props.modalProps;

    this.setState({loading: true})
    Promise.all([
        sapi.Docs.info(forum_id, host_uid, doc_id)
      ])
      .then((res) => {
        this.setState({
          doc_info: res[0].data,
          displayMode : this.getPreviewMode(res[0].data.label)
        })
      })
      .catch((err) => {
        log.log('error loading doc info', err);
        this.props.showAlert(t("Unable to load document"), getMessageForError(err, t), () => {
          this.props.close();
        })
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }
  
  doCloseFromPreview(){
    this.props.close(!!this.props.pdfState.signatureRequestData);
  }
  
  buildSignatureRequest(){
    let { thread, dm, forum_id, host_uid, doc_id } = this.props.modalProps;
    let promise = null;
    if(thread){
      promise = sapi.Threads.messages(forum_id, host_uid, thread.chat_id)
        .then((res) => {
          let foundMesg = null;
          let foundDocStatus = null;
          _.each(res.data.mesg_data, (mesg) => {
            if(mesg.docs){
              _.each(mesg.docs, (doc) => {
                if(doc.doc_id === doc_id){
                  foundMesg = mesg;
                  foundDocStatus = doc;
                  return false;
                }
              })
            }
            if(foundMesg){
              return false;
            }
          })
          return {
            foundMesg,
            foundDocStatus
          };
        })
        .then((mesg) => {
          let { foundMesg, foundDocStatus } = mesg;
          
          return new SignatureRequest(
            forum_id,
            host_uid,
            doc_id,
            thread.chat_id,
            foundMesg.mesg_id,
            foundDocStatus ? foundDocStatus : {sign_status : SignatureRequest.SIGN_STATUS.INCOMPLETE} //Hack, we don't have doc status in this scenario
          );
        })
    }
    else if(dm){
      promise = sapi.DM.messages(dm.guest_uid)
        .then((res) => {
          let foundMesg = null;
          let foundDoc = null;
          _.each(res.data, (mesg) => {
            if(mesg.docs){
              _.each(mesg.docs, (doc) => {
                if(doc.doc_id === doc_id){
                  foundMesg = mesg;
                  foundDoc = doc;
                  return false;
                }
              })
            }
            if(foundMesg){
              return false;
            }
          })
          return {
            foundMesg,
            foundDoc
          };
        })
        .then((mesg) => {
          let { foundMesg, foundDoc } = mesg;
          return new DMSignatureRequest(
            dm.guest_uid,
            dm.forum_id,
            dm.host_uid,
            doc_id,
            foundMesg.mesg_id,
            foundDoc ? foundDoc : {sign_status : SignatureRequest.SIGN_STATUS.INCOMPLETE} //Hack, we don't have doc status in this scenario
          )
        })
    }
    else{
      throw new Error('Unable to initiate signature request.  Needed data not present');
    }
  
    return promise;
  }
  
  initializeThreadSignatureRequest(sigRequest) {
    let { threadParticipantLookup, accountInfo, t } = this.props;
    let { thread, dm, forum_id, host_uid, doc_id } = this.props.modalProps;
    let {doc_info} = this.state;
    
    let participants = _.filter(threadParticipantLookup[thread.chat_id], (user) => {
      return user.guest_uid !== accountInfo.uid;
    })
  
    if(participants.length === 0){
      this.props.showAlert(t('No Guests in Thread'), t("There are no Guests in this Thread.  To request a signature, please add a Guest first."));
      return;
    }
  
    log.log('initialize thread sig request', sigRequest);
    if(sigRequest.status.sign_status === SignatureRequest.SIGN_STATUS.INCOMPLETE ||
      sigRequest.status.sign_status === SignatureRequest.SIGN_STATUS.NONE){
      this.props.showRequestSignatureWindow(
        forum_id,
        thread.chat_id,
        sigRequest.mesg_id,
        doc_id,
        doc_info.label,
        participants,
        null,
        null,
        (res) => {
          if (res) {
            sigRequest.smsNumber = res.smsNumber;
            sigRequest.terms = res.terms;
            let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => {
              return guest.guest_uid === res.signer_uid
            });
            sigRequest.setSigner(res.signer_uid, guest);
            sigRequest.setDocInfo(doc_info);
        
            this.props.pdfActions.loadSignatureRequestData(false, sigRequest);
          }
        }
      )
    }
    else if(sigRequest.status.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED){
      sapi.Workspace.getSignatureRequest(sigRequest.status.sign_request_id, host_uid)
        .then((res) => {
          let guest = _.find(threadParticipantLookup[thread.chat_id], (guest) => {
            return guest.guest_uid === res.data.signer_uid
          });
          log.log('signature request load', res);
          sigRequest.setSigner(res.data.signer_uid, guest);
          sigRequest.setDocInfo(doc_info);
          sigRequest.setSignatureRequest(res.data);
          sigRequest.terms = res.data.terms;
          sigRequest.smsNumber = _.get(res, 'data.sign_data.smsNumber');
          
          this.props.pdfActions.loadSignatureRequestData(true, sigRequest);
        })
        .catch((err) => {
          log.error('error loading signature request', err);
          this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
        })
    }
    else{
      this.props.showAlert(t('Unable to initiate Signature Request'), t("There is already an active signature request on this document.  You cannot start another one."));
    }
  }
  
  initializeDMSignatureRequest(sigRequest){
    let { t } = this.props;
    let { dm, doc_id } = this.props.modalProps;
    let {doc_info} = this.state;
  
    log.log('initialize thread sig request', sigRequest, sigRequest.status.sign_status, SignatureRequest.SIGN_STATUS.INCOMPLETE, SignatureRequest.SIGN_STATUS.NONE);
    if(sigRequest.status.sign_status === SignatureRequest.SIGN_STATUS.INCOMPLETE ||
      sigRequest.status.sign_status === SignatureRequest.SIGN_STATUS.NONE){
      this.props.showDMRequestSignatureWindow(
        dm.guest_uid,
        sigRequest.mesg_id,
        doc_id,
        doc_info.label,
        [dm],
        null,
        null,
        (res) => {
          if (res) {
            sigRequest.smsNumber = res.smsNumber;
            sigRequest.terms = res.terms;
            sigRequest.setSigner(res.signer_uid, dm);
            sigRequest.setDocInfo(doc_info);
            this.props.pdfActions.loadSignatureRequestData(false, sigRequest);
          }
        }
      )
    }
    else if(sigRequest.status.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED){
      sapi.DM.getSignatureRequest(sigRequest.status.sign_request_id, dm.guest_uid)
        .then((res) => {
          log.log('signature request load', res);
          sigRequest.setSigner(res.data.signer_uid, dm);
          sigRequest.setDocInfo(doc_info);
          sigRequest.setSignatureRequest(res.data);
          sigRequest.terms = res.data.terms;
          sigRequest.smsNumber = _.get(res, 'data.sign_data.smsNumber');
  
          this.props.pdfActions.loadSignatureRequestData(true, sigRequest);
        })
        .catch((err) => {
          log.error('error loading signature request', err);
          this.props.showAlert(t('Error loading signature request'), getMessageForError(err, t));
        })
    }
    else{
      this.props.showAlert(t('Unable to initiate Signature Request'), t("There is already an active signature request on this document.  You cannot start another one."));
    }
  }
  
  onInitiateSignatureRequestClick(){
    let { t } = this.props;
    let { thread, host_uid } = this.props.modalProps;
    
    this.buildSignatureRequest()
      .then((sigRequest) => {
        
        let doit = () => {
          if(thread) {
            this.initializeThreadSignatureRequest(sigRequest);
          }
          else{
            this.initializeDMSignatureRequest(sigRequest);
          }
        }
        
        if(sigRequest.status.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED) {
          this.props.showConfirm(t('Signature Request already Exists'),
            t("A Signature Request already exists for this document. Do you want to edit it?"),
            (res) => {
              if (res) {
                doit();
              }
            })
        }
        else{
          doit();
        }
      })
      .catch((err) => {
        log.error('error building signature request', err);
        this.props.showAlert(t('Unable to initiate Signature Request'), t("There was a problem initiating your signature request.  Please try again."));
      })
  }
  
  render() {
    let {
      loading,
      displayMode,
    } = this.state;
    let { t } = this.props;
    
    let content = null;
    if (loading) {
      content = <div className="modal-content">
        <div className="modal-body">
          <Loading centered size={'sm'}/>
        </div>
      </div>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.INFO) {
      content = <DocInfo {...this.props.modalProps}
                         setWindowMode={this.setWindowMode.bind(this)}
                         doc_info={this.state.doc_info}
                         close={this.props.close}/>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.IMG_PREVIEW) {
      content = <ImgPreview {...this.props.modalProps}
                            setWindowMode={this.setWindowMode.bind(this)}
                            doc_info={this.state.doc_info}
                            close={this.props.close}/>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.PDF_PREVIEW) {
      content = <PdfPreview {...this.props.modalProps}
                            shownInSignatureRequestContext={false}
                            onInitiateSignatureRequest={this.onInitiateSignatureRequestClick.bind(this)}
                            setWindowMode={this.setWindowMode.bind(this)}
                            doc_info={this.state.doc_info}
                            close={this.doCloseFromPreview.bind(this)}/>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.TEXT_PREVIEW) {
      content = <TextPreview {...this.props.modalProps}
                             setWindowMode={this.setWindowMode.bind(this)}
                             doc_info={this.state.doc_info}
                             close={this.props.close}/>
    }
    else if (displayMode === PreviewWindow.WINDOW_MODES.SVG_PREVIEW) {
      content = <SvgPreview {...this.props.modalProps}
                            setWindowMode={this.setWindowMode.bind(this)}
                            doc_info={this.state.doc_info}
                            close={this.props.close}/>
    }
    return (
      content
    )
  }
}

const mapStateToProps = (state) => {
  return {
    threadParticipantLookup : state.workspace.threadParticipantLookup,
    accountInfo : state.shared.accountInfo,
    pdfState : {...state.pdfPreview}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    pdfActions : {...pdfPreviewActions.mapToDispatch(dispatch)},
  };
};

PreviewWindow.MODAL_XL = true;

PreviewWindow.WINDOW_MODES = {
  INFO : 'info',
  IMG_PREVIEW : 'img.preview',
  PDF_PREVIEW : 'pdf.preview',
  TEXT_PREVIEW : 'text.preview',
  SVG_PREVIEW : 'svg.preview'
}

PreviewWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PreviewWindow));
