import c from '../../../util/const'
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import filters from "../../../helpers/filters";
import Button from "../elements/Button";
import sapi from "../../../util/sapi";
import Image from "../elements/Image";
import Promise from "bluebird";
import PreviewWindow from "../../modals/PreviewWindow";
import utils from "../../../util/util";
import downloadActions from "../../../actions/download-actions";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class DocInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      thumbnail: null,
      isPreviewable: utils.isPreviewable(props.doc_info.label)
    }
  }
  
  componentDidMount() {
    
    this.setState({loading: true})
    this.fetchThumbnail()
      .then((res) => {
        this.setState({
          thumbnail: res
        })
      })
      .finally(() => {
        this.setState({loading: false})
      })
  }
  
  downloadDoc() {
    this.props.doDownload(this.getDownloadHeaders(), sapi.Docs.url('download'))
  }
  
  getThumbnail() {
    let { t } = this.props;
    let {thumbnail} = this.state;
    
    if (thumbnail) {
      return (
        <div className={'text-center'}>
          <Image src={thumbnail}
                 imgHeight={150}
                 alt={t('Thumbnail')}/>
        </div>
      )
    }
  }
  
  getDownloadHeaders() {
    let {doc_id, forum_id, host_uid} = this.props;
    
    let params = {
      doc_id,
      forum_id
    }
    
    if (host_uid) {
      params.host_uid = host_uid;
    }
    
    params[c.api.X_TOKEN] = sapi.getToken();
    
    return params;
  }
  
  fetchThumbnail() {
    let {doc_id, forum_id, host_uid} = this.props;
    
    return new Promise((resolve, reject) => {
      sapi.Docs.thumbnail(forum_id, host_uid, doc_id)
        .then((res) => {
          resolve(res.data.thumbnail);
        })
        .catch((err) => {
          //no error
          resolve(null);
        })
    })
  }
  
  closeModal() {
    this.props.close();
  }
  
  setWindowMode(mode) {
    let {setWindowMode} = this.props;
    
    setWindowMode(mode);
  }
  
  previewClick() {
    let {doc_info} = this.props;
    
    let mode = null;
    if (utils.isPDFPreview(doc_info.label)) {
      mode = PreviewWindow.WINDOW_MODES.PDF_PREVIEW;
    } else if (utils.isImgPreview(doc_info.label)) {
      mode = PreviewWindow.WINDOW_MODES.IMG_PREVIEW;
    } else if (utils.isTextPreview(doc_info.label)) {
      mode = PreviewWindow.WINDOW_MODES.TEXT_PREVIEW;
    } else if (utils.isSvgPreview(doc_info.label)) {
      mode = PreviewWindow.WINDOW_MODES.SVG_PREVIEW;
    }
    
    if (mode) {
      this.setWindowMode(mode);
    }
  }
  
  render() {
    let {doc_info, t} = this.props;
    let {isPreviewable} = this.state;
    
    return (
      <Fragment>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{doc_info.label}</h5>
            <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
              <i className="icon ion-ios-close-empty" />
            </button>
          </div>
          <div className="modal-body">
            <div>
              {this.getThumbnail()}
            </div>
            <div>
              <div className={'form-group'}>
                <label>{t("Name")}</label>
                <div className={'form-control'}>
                  {doc_info.label}
                </div>
              </div>
              <div className={'form-group'}>
                <label>{t("Size")}</label>
                <div className={'form-control'}>
                  {filters.getFileSizeString(doc_info.storage_used)}
                </div>
              </div>
              <div className={'form-group'}>
                <label>{t("Upload Time")}</label>
                <div className={'form-control'}>
                  {filters.getFriendlyDate(t, doc_info.created_date)}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {isPreviewable &&
            <Button className={'btn btn-primary'} onClick={this.previewClick.bind(this)}>{t("Preview")}</Button>
            }
            <Button className={'btn btn-primary'} onClick={this.downloadDoc.bind(this)}>{t("Download")}</Button>
            <Button className={'btn btn-primary'} onClick={this.closeModal.bind(this)}>{t("OK")}</Button>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    doDownload:(headers, url) => dispatch(downloadActions.doDownload(headers, url))
  };
};

DocInfo.propTypes = {
  close: PropTypes.func.isRequired,
  setWindowMode: PropTypes.func.isRequired,
  doc_info: PropTypes.object.isRequired,
  forum_id: PropTypes.string.isRequired,
  host_uid: PropTypes.string,
  doc_id: PropTypes.string.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DocInfo));
