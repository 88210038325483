import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../partials/elements/Button';
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import log from "../../util/log";
import UserBadge from "../partials/badges/UserBadge";
import colors from "../../util/colors";
import {getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import {last} from "pdf-lib";
import AddContact from "../partials/contact-add/AddContact";
import CSVReview from "../partials/contact-add/CSVReview";
import CSVUpload from "../partials/contact-add/CSVUpload";

class AddContactWindow extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      mode : AddContactWindow.WINDOW_MODES.ADD_CONTACT,
      windowData : null
    }
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }

  setWindowMode(mode, windowData){
    this.setState({
      mode,
      windowData
    })
  
    this.props.setLargeSize(mode === AddContactWindow.WINDOW_MODES.CSV_REVIEW);
  }
  
  render() {
    let {mode, windowData} = this.state;
  
    if (mode === AddContactWindow.WINDOW_MODES.ADD_CONTACT) {
      return (
        <AddContact setWindowMode={this.setWindowMode.bind(this)}
                    {...this.props}
                    {...windowData} />
      )
    }
    else if (mode === AddContactWindow.WINDOW_MODES.CSV_UPLOAD) {
      return (
        <CSVUpload setWindowMode={this.setWindowMode.bind(this)}
                   {...this.props}
                   {...windowData} />
      )
    }
    else if (mode === AddContactWindow.WINDOW_MODES.CSV_REVIEW) {
      return (
        <CSVReview setWindowMode={this.setWindowMode.bind(this)}
                   {...this.props}
                   {...windowData} />
      )
    }
  }
}

AddContactWindow.WINDOW_MODES = {
  ADD_CONTACT : 'add_contact',
  CSV_UPLOAD : 'csv_upload',
  CSV_REVIEW : 'csv_review'
}

const styles = {

}

const mapStateToProps = (state) => {
  return {
  
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

AddContactWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired,
  setLargeSize : PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AddContactWindow);
