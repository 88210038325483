import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import PropTypes from 'prop-types';
import colors from "../../util/colors";
import Button from '../partials/elements/Button';
import UserBadge from "../partials/badges/UserBadge";
import Loading from "../partials/util/Loading";
import sapi from "../../util/sapi";
import log from "../../util/log";
import modalActions from "../../actions/modal-actions";
import {getMessageForError} from "../../util/errors";
import classnames from "classnames";
import Popover from "react-tiny-popover";
import utils from "../../util/util";
import ColorGenerator from "../../helpers/color-generator";
import {last} from "pdf-lib";
import he from "he";
import accountActions from "../../actions/account-actions";
import Promise from 'bluebird'
import c from "../../util/const";
import appActions from "../../actions/app-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class ContactInfoWindow extends Component {
  
  isSavingContact = false
  
  constructor(props) {
    super(props);
    
    this.state = {
      loading : true,
      contactInfo : null,
      isEditing : false,
      showingMenu : false,
      validationErr : [],
      email : '',
      first_name : '',
      last_name : '',
      title : '',
      company : '',
      phone : '',
      address : '',
      note : '',
      changesMade : false
    }
  }
  
  componentDidMount() {
    let { t } = this.props;
    this.setState({loading : true})
    this.refreshContactInfo()
      .then(() => {
        this.handleQsActionIfNeeded();
      })
      .catch((err) => {
        log.error('error getting contact', err);
        this.props.showAlert(t("Error loading contact info"), getMessageForError(err, t), () => {
          this.closeModal(false)
        })
      })
      .finally(() => {
        this.setState({loading : false})
      })
  }
  
  handleQsActionIfNeeded(){
    let { qsActionNeeded, qs } = this.props;
    let { contactInfo } = this.state;
    
    if(qsActionNeeded && qs[c.querystring.goto_block_user] && contactInfo.email_alert_flag){
      this.props.setQsActionNeeded(false);
      this.blockContact();
    }
  }
  
  refreshContactInfo(){
    let { guest_uid } = this.props.modalProps;
    return sapi.Contacts.get(guest_uid)
      .then((res) => {
        
        if(res.data.address){
          res.data.address = he.decode(res.data.address);
        }
        if(res.data.note){
          res.data.note = he.decode(res.data.note);
        }
        
        this.setState({
          contactInfo : res.data,
          email : res.data.email,
          first_name : res.data.first_name || '',
          last_name : res.data.last_name || '',
          title : res.data.title || '',
          company : res.data.company || '',
          phone : res.data.phone || '',
          address : res.data.address || '',
          note : res.data.note || '',
        })
      })
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  showMenu() {
    this.setState({
      showingMenu : true
    })
  }
  
  hidePopover(){
    this.setState({
      showingMenu : false
    })
  }
  
  editContactClickMenu(){
    if(!this.state.showingMenu){
      return;
    }
    
    this.editContactClick();
  }
  
  editContactClick(){
    this.hidePopover();
    this.setState({isEditing : true})
  }
  
  blockContactMenu(){
    if(!this.state.showingMenu){
      return;
    }
    
    this.blockContact();
  }
  
  blockContact(){
    let { t } = this.props;
    let { guest_uid } = this.props.modalProps;
    let { contactInfo } = this.state;
    
    this.hidePopover();
    
    this.props.showContactConfirm(
      t('Blocking Contact'),
      contactInfo,
      t("Are you sure you want to block all email alerts from this user? The user will not know they've been blocked."),
      t("Block"),
      t("Cancel"),
      (res) => {
        if(res){
          sapi.NotifyBlock.blockGuest(guest_uid)
            .then((res) => {
              return Promise.all([
                this.refreshContactInfo(),
                this.props.updateUserBlockList()
              ])
            })
            .catch((err) => {
              log.error('error blocking guest', err);
              this.props.showAlert(t("Error blocking contact"), getMessageForError(err, t))
            })
            .finally(() => {
              this.setState({changesMade:true})
            })
        }
      }
    )
  }
  
  unblockContact(){
    if(!this.state.showingMenu){
      return;
    }
    
    let { t } = this.props;
    let { guest_uid } = this.props.modalProps;
    this.hidePopover();
    
    sapi.NotifyBlock.unblockGuest(guest_uid)
      .then((res) => {
        return Promise.all([
          this.refreshContactInfo(),
          this.props.updateUserBlockList()
        ])
      })
      .catch((err) => {
        log.error('error blocking guest', err);
        this.props.showAlert(t("Error unblocking contact"), getMessageForError(err, t))
      })
      .finally(() => {
        this.setState({changesMade:true})
      })
  }
  
  deleteContact(){
    if(!this.state.showingMenu){
      return;
    }
    
    let { t } = this.props;
    let { guest_uid } = this.props.modalProps;
    let { contactInfo } = this.state;
  
    this.hidePopover();
    
    this.props.showContactConfirm(
      t('Deleting Contact'),
      contactInfo,
      t("WARNING: Deleting here means deleting everywhere. If ") +
      t("this user is a Guest in any of your Workspaces, they will be ") +
      t("removed from those Workspaces. You will lose any access ") +
      t("you have to Workspaces hosted by this contact. And any ") +
      t("Private Messages with this contact will be lost."),
      t("Delete"),
      t("Cancel"),
      (res) => {
        if(res){
          sapi.Contacts.delete(guest_uid)
            .then((res) => {
              this.closeModal(res);
            })
            .catch((err) => {
              log.error("Error deleting contact", err);
              this.props.showAlert(t("Error deleting contact"), getMessageForError(err, t))
            })
        }
      }
    )
  }
  
  cancelSaveContact(){
    let { t } = this.props;
    let { contactInfo } = this.state;
    this.props.showConfirm(t("Are you sure?"), t("Are you sure you want to discard your changes?"), (res) => {
      if(res){
        this.setState({
          ...contactInfo,
          isEditing : false
        })
      }
    })
  }
  
  sendInvitationEmail() {
    let { t } = this.props;
    let {contactInfo} = this.state;
    this.props.showConfirm(t('Are you sure?'),
      t("Are you sure you want Verifyle to send a new invitation email to ") + contactInfo.first_name + " " +  contactInfo.last_name + t("?"),
      (res) => {
        if(res){
          sapi.Contacts.sendInvitationLink(contactInfo.guest_uid)
            .then((res) => {
              log.log('invitation sent', res);
              this.props.showAlert(t('Success'), t('Invitation email sent.'));
            })
            .catch((err) => {
              log.error('error sending invitation link', err);
              this.props.showAlert(t('Failed to send invitation'), getMessageForError(err, t));
            })
        }
      })
  }
  
  saveContact(){
    if(this.isSavingContact){
      return;
    }
    
    this.isSavingContact = true;
    let { t } = this.props;
    let {
      contactInfo,
      email,
      first_name,
      last_name,
      title,
      company,
      phone,
      address,
      note
    } = this.state;
  
    //Don't save email address unless it has changed, and the guest is pending.  bug 2367
    let saveEmail = null;
    if(contactInfo.email !== email && contactInfo.status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING){
      saveEmail = email;
    }
    
    sapi.Contacts.update(
      contactInfo.guest_uid,
      saveEmail,
      first_name,
      last_name,
      title,
      company,
      phone,
      address,
      note
    )
      .then((res) => {
        this.setState({isEditing : false, changesMade : true})
        return this.refreshContactInfo()
      })
      .catch((err) => {
        log.error('error updating contact', err);
        this.props.showAlert(t("Unable to save contact"), getMessageForError(err, t));
      })
      .finally(() => {
        this.isSavingContact = false;
      })
  }
  
  renderStatus(status){
    let { t } = this.props;
    
    if(status === ContactInfoWindow.CONFIRM_STATUS.GUEST_CONFIRM){
      return (
        <p className="font-italic secondary-text-color">
          {t("- Confirmed -")}
        </p>
      )
    }
    else if(status === ContactInfoWindow.CONFIRM_STATUS.GUEST_DELETED){
      return (
        <p className="font-italic secondary-text-color">
          {t("- Deleted -")}
        </p>
      )
    }
    else if(status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING){
      return (
        <div className="mb-2">
          <p className="font-italic secondary-text-color mb-0">
            {t("- Pending -")}
          </p>
          <div>
            <button className="btn btn-link primary-color"
                    onClick={this.sendInvitationEmail.bind(this)}>
              {t("Send Invitation Email")}
            </button>
          </div>
        </div>
      )
    }
  }
  
  getPopoverContent() {
    let {guest_uid, t} = this.props;
    let { contactInfo} = this.state;
    
    return (
      <div>
        <div className="click-block" onClick={this.hidePopover.bind(this)} />
        <ul className="popover-content list-group" style={styles.popoverWrap}>
          
          <a style={{...styles.menuItemTop, ...{color : colors.LIGHT, backgroundColor : ColorGenerator.generateColorFromId(guest_uid)}}}
             className="list-group-item list-group-item-action">
            <span>
              <i style={styles.menuIcons} className="icon ion-person mr-3"/>
              {contactInfo.first_name} {contactInfo.last_name}
            </span>
          </a>
  
          <a onClick={this.editContactClickMenu.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <div className="d-inline-block mr-5">
              <i style={styles.menuIcons} className="icon ion-edit mr-3"/>
              {t("Add/Edit Contact Info")}
            </div>
          </a>
  
          {contactInfo.email_alert_flag &&
          <a onClick={this.blockContactMenu.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <div className="d-inline-block mr-5">
              <i style={styles.menuIcons} className="icon ion-android-notifications-off mr-3"/>
              {t("Block Contact")}
            </div>
          </a>
          }
  
          {!contactInfo.email_alert_flag &&
          <a onClick={this.unblockContact.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <div className="d-inline-block mr-5">
              <i style={styles.menuIcons} className="icon ion-android-notifications mr-3"/>
              {t("Unblock Contact")}
            </div>
          </a>
          }
          
          <a onClick={this.deleteContact.bind(this)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action has-pointer assertive-color">
            <span>
              <i style={styles.menuIcons} className="icon ion-close mr-3"/>
              {t("Delete Contact")}
            </span>
          </a>
          
        </ul>
      </div>
    )
  }
  
  renderEditing(){
    let { t } = this.props;
    let {
      contactInfo,
      email,
      first_name,
      last_name,
      title,
      company,
      phone,
      address,
      note
    } = this.state;
    
    return (
      <div className="text-center mt-5" style={{ minHeight : '300px' }}>
        <UserBadge large={true} guest={contactInfo}/>
        <p className="mt-3">
          {contactInfo.email}
        </p>
        <div className="text-left">
  
          {contactInfo.status === ContactInfoWindow.CONFIRM_STATUS.GUEST_PENDING &&
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Email")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'email'}
                     value={email}
                     onChange={(evt) => this.setState({email: evt.target.value})}
                     placeholder={t('Email')}/>
            </div>
          </div>
          }
          
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("First Name")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'text'}
                     value={first_name}
                     onChange={(evt) => this.setState({first_name: evt.target.value})}
                     placeholder={t('First Name')} />
            </div>
          </div>
  
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Last Name")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'text'}
                     value={last_name}
                     onChange={(evt) => this.setState({last_name: evt.target.value})}
                     placeholder={t('Last Name')} />
            </div>
          </div>
  
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Title")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'text'}
                     value={title}
                     onChange={(evt) => this.setState({title: evt.target.value})}
                     placeholder={t('Title')} />
            </div>
          </div>
  
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Company")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'text'}
                     value={company}
                     onChange={(evt) => this.setState({company: evt.target.value})}
                     placeholder={t('Company')} />
            </div>
          </div>
  
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Phone")}</label>
            <div className="col-sm-9">
              <input className="form-control"
                     type={'text'}
                     value={phone}
                     onChange={(evt) => this.setState({phone: evt.target.value})}
                     placeholder={t('Phone')} />
            </div>
          </div>
  
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Address")}</label>
            <div className="col-sm-9">
              <textarea className="form-control no-resize"
                     rows={3}
                     value={address}
                     onChange={(evt) => this.setState({address: evt.target.value})}
                     placeholder={t('Address')} />
            </div>
          </div>
  
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">{t("Notes")}</label>
            <div className="col-sm-9">
              <textarea className="form-control no-resize"
                     rows={3}
                     value={note}
                     onChange={(evt) => this.setState({note: evt.target.value})}
                     placeholder={t('Notes')} />
            </div>
          </div>
          
        </div>
      </div>
    )
  }
  
  renderView(){
    let { t } = this.props;
    let { contactInfo } = this.state;
    return (
      <div className="text-center mt-5" style={{ minHeight : '300px' }}>
        <UserBadge large={true} guest={contactInfo}/>
        <h3 className="mt-3">
          {contactInfo.first_name} {contactInfo.last_name}
          <a onClick={this.editContactClick.bind(this)} className="btn btn-link pl-3">
            <i className="icon ion-edit"/>
          </a>
        </h3>
    
        {contactInfo.title &&
        <h4>
          {contactInfo.title}
        </h4>
        }
        {contactInfo.company &&
        <h4 className="font-weight-bold">
          {contactInfo.company}
        </h4>
        }
        {contactInfo.email &&
        <p>
          {contactInfo.email}
        </p>
        }
        {contactInfo.phone &&
        <p>
          {contactInfo.phone}
        </p>
        }
        {contactInfo.address &&
        <p>
          {contactInfo.address}
        </p>
        }
        {contactInfo.note &&
        <p>
          {contactInfo.note}
        </p>
        }
    
        {contactInfo.status &&
        this.renderStatus(contactInfo.status)
        }
        {!contactInfo.email_alert_flag && contactInfo.status !== ContactInfoWindow.CONFIRM_STATUS.GUEST_DELETED &&
        <div style={styles.blockedBadge} className="d-inline-block">
          {t("BLOCKED")}
        </div>
        }
  
      </div>
    )
  }
  
  render() {
    let { t } = this.props;
    let { loading, isEditing, showingMenu, changesMade } = this.state;
  
    if (loading) {
      return (
        <div className="modal-content">
          <div className="modal-body">
            <Loading centered size={'sm'}/>
          </div>
        </div>
      )
    }
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" onClick={this.closeModal.bind(this, changesMade)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
          {!isEditing &&
          <Popover
            isOpen={showingMenu}
            position={'bottom'}
            disableReposition
            onClickOutside={this.hidePopover.bind(this)}
            content={() => this.getPopoverContent()}>
            <div style={styles.topRightButton}
                 onClick={this.showMenu.bind(this)}>
              <i className="icon ion-gear-b" />
            </div>
          </Popover>
          }
        </div>
        <div className="modal-body position-relative">
          {!isEditing &&
            this.renderView()
          }
          {isEditing &&
          this.renderEditing()
          }
        </div>
        {isEditing && <div className="modal-footer">
          <Button className={'btn btn-secondary mr-2'}
                  onClick={this.cancelSaveContact.bind(this, changesMade)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'}
                  disabled={this.isSavingContact}
                  onClick={this.saveContact.bind(this, changesMade)}>{t("Save")}</Button>
        </div>
        }
      </div>
    )
  }
}

ContactInfoWindow.CONFIRM_STATUS = {
  GUEST_PENDING : 'GUEST_PENDING',
  GUEST_CONFIRM : 'GUEST_CONFIRM',
  GUEST_DELETED : 'GUEST_DELETED'
}

const styles = {
  topLeftButton : {
    position : 'absolute',
    top: '15px',
    left : '30px',
    fontSize : '60px',
    cursor: 'pointer',
    opacity: 1
  },
  topRightButton : {
    position : 'absolute',
    top: '5px',
    right : '16px',
    fontSize : '30px',
    zIndex : 1000,
    cursor: 'pointer'
  },
  blockedBadge : {
    backgroundColor : colors.ASSERTIVE,
    color : colors.LIGHT,
    borderRadius: '3px',
    padding : '3px 5px'
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    qsActionNeeded : state.app.qsActionNeeded,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserBlockList: () => dispatch(accountActions.updateUserBlockList()),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    ...modalActions.mapToDispatch(dispatch)
  }
};

ContactInfoWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactInfoWindow));
