import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import { getErrorMessage} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import _ from "lodash";
import Checkbox from "../partials/elements/Checkbox";
import log from "../../util/log";
import colors from "../../util/colors";
import sapi from "../../util/sapi";
import c from "../../util/const";
import Promise from "bluebird";
import modalActions from "../../actions/modal-actions";
import downloadActions from "../../actions/download-actions";
import Loading from "../partials/util/Loading";
import {withVFTranslation} from "../../util/withVFTranslation";

class DownloadMultipleWindow extends Component {
  
  mounted = false;
  
  constructor(props) {
    super(props);
    
    let checkedDocs = [];
    _.each(props.flatDocList, (doc) => {
      if(_.find(props.modalProps.selectedDocIds, (id) => {return doc.doc_id === id})){
        checkedDocs.push(doc);
      }
    })
    
    this.state = {
      flatDocs : props.flatDocList,
      loading : false,
      validationErr: null,
      selectAllChecked : checkedDocs.length === props.flatDocList.length,
      checkedDocs,
      downloadReadyDocId : null,
      downloadReadyForumId : null,
      downloadQueueId : null,
      isZipping : false,
      zippingSuccessful : false
    }
  }
  
  componentDidMount() {
    this.mounted = true;
  }
  
  componentWillUnmount() {
    this.mounted = false;
  }
  
  closeModal() {
    this.props.close();
  }
  
  availableDocCheckChange(doc, evt){
    let { checkedDocs, flatDocs } = this.state;
    log.log('available doc check change', doc, evt);
    
    let update = null;
    if(evt.target.checked){
      update = _.concat(checkedDocs, doc);
    }
    else{
      _.remove(checkedDocs, (thisDoc) => {
        return thisDoc.doc_id === doc.doc_id;
      });
      update = _.concat([], checkedDocs);
    }
  
    this.setState({
      checkedDocs : update,
      selectAllChecked : checkedDocs.length === flatDocs.length
    })
  }
  
  selectAllCheckChange(evt){
    let { flatDocs } = this.state;
    
    if(evt.target.checked){
      this.setState({
        checkedDocs : _.concat([], flatDocs),
        selectAllChecked : true
      })
    }
    else{
      this.setState({
        checkedDocs : [],
        selectAllChecked : false
      })
    }
  }
  
  getDownloadHeaders(){
    let { downloadReadyForumId, downloadReadyDocId, checkedDocs } = this.state;
    if(downloadReadyForumId && downloadReadyDocId){
      let params = {
        forum_id : downloadReadyForumId,
        doc_id : downloadReadyDocId
      }
      params[c.api.X_TOKEN] = sapi.getToken();
      return params;
    }
    else if(checkedDocs.length === 1){
      let {forum_id, host_uid} = this.props.modalProps;
      let params = {
        forum_id,
        doc_id : checkedDocs[0].doc_id
      }
      if (host_uid) {
        params.host_uid = host_uid;
      }
      params[c.api.X_TOKEN] = sapi.getToken();
  
      return params;
    }
    return {};
  }
  
  selectClick(){
    let { checkedDocs } = this.state;
    
    if(checkedDocs.length === 1) {
      //Just forces us to success view.  Handling single file vs zip file is handled in the download handler.
      this.setState({
        zippingSuccessful : true
      })
    }
    else{
      this.zipAndDownload();
    }
  }
  
  waitForZipToFinish(queue_id){
    let { t } = this.props;
    
    return sapi.Workspace.downloadZipStatus(queue_id)
      .then((statusRes) => {
        if(!this.mounted){
          return;
        }
        
        if(statusRes.data.queue_status === 'done'){
          this.setState({
            downloadReadyDocId : statusRes.data.doc_id,
            downloadReadyForumId : statusRes.data.forum_id,
            zippingSuccessful : true,
            downloadQueueId : null,
            isZipping : false
          })
        }
        else if(statusRes.queue_status === 'error'){
          this.setState({
            validationErr : t('There was an error packaging your Documents.  Please try again.'),
            downloadReadyDocId : null,
            downloadReadyForumId : null,
            downloadQueueId : null,
            zippingSuccessful : false,
            isZipping : false
          })
        }
        else{
          //retry
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(this.waitForZipToFinish(queue_id))
            }, 2000)
          })
        }
      })
  }
  
  zipAndDownload(){
    let { t } = this.props;
    let {forum_id, host_uid} = this.props.modalProps;
    let { checkedDocs } = this.state;
    sapi.Workspace.downloadZip(forum_id, host_uid, _.map(checkedDocs, 'doc_id'))
      .then((res) => {
        log.log('zip queue res', res);
        this.setState({
          downloadQueueId : res.data.queue_id,
          isZipping : true
        })
        return this.waitForZipToFinish(res.data.queue_id);
      })
      .catch((err) => {
        if(!this.mounted){
          return;
        }
        
        log.error('error zipping files', err);
        this.props.showAlert(t('Error Downloading Files'), getErrorMessage(err, t));
        this.setState({
          downloadQueueId : null,
          isZipping : false
        })
      })
  }
  
  downloadClick(){
    this.props.doDownload(this.getDownloadHeaders(), sapi.Docs.url('download'));
    this.closeModal(true);
  }
  
  getSuccessView(){
    let { t } = this.props;
    let { checkedDocs } = this.state;
    
    if(checkedDocs.length === 1){
      return (
        <>
          <h3 className="text-center green-color mb-3">
            <i className="icon ion-checkmark-circled green-color mr-2" />
            {t("Success!")}
          </h3>
          <p className="text-center mb-5">
            {t("Your Document is ready for download.")}
          </p>
          <div className="text-center mb-5">
            <button className="btn btn-lg btn-primary" onClick={this.downloadClick.bind(this)}>
              {t("Download File")}
            </button>
          </div>
        </>
      )
    }
    else{
      return (
        <>
          <h3 className="text-center green-color mb-3">
            <i className="icon ion-checkmark-circled green-color mr-2" />
            {t("Success!")}
          </h3>
          <p className="text-center mb-5">
            {t("Your Documents have been packaged and are ready for download.")}
          </p>
          <div className="text-center mb-5">
            <button className="btn btn-lg btn-primary" onClick={this.downloadClick.bind(this)}>
              {t("Download Zip File")}
            </button>
          </div>
        </>
      )
    }
  }
  
  getProgressView(){
    let { t } = this.props;
    return (
      <>
        <div>
          <h3 className="text-center mb-3">
            {t("Preparing Download...")}
          </h3>
          <p className="text-center mb-5">
            {t("We're packaging your Documents into a single zip file for download.")}
          </p>
        </div>
        <div className="mb-5">
          <Loading centered={true}
                   size={'sm'}/>
        </div>
      </>
    )
  }
  
  getSelectionView(){
    let { t } = this.props;
    let { checkedDocs, selectAllChecked } = this.state;
    let { flatDocs } = this.state;
    
    return (
      <>
        <div style={styles.selectAllWrap}>
          <div className="d-flex my-2">
            <div>
              <i className="icon ion-document-text mr-2 invisible" style={{fontSize: '28px', lineHeight: '26px'}} />
            </div>
            <div className="flex-grow-1" style={styles.userColumn}>
              <h6 className="mb-0 mt-1"
                  style={styles.selectedUserHeader}>
                {t("Select All")}
              </h6>
            </div>
            <div style={styles.userColumn} className="ml-3 mr-2">
              <div className="d-inline-block">
                <Checkbox isChecked={selectAllChecked}
                          rootCls="big-round-checkbox form-control-lg"
                          label={' '}
                          onChange={this.selectAllCheckChange.bind(this)}
                          labelCls={'primary-color'} />
              </div>
            </div>
          </div>
        </div>
        <div>
          {flatDocs.map((item) => {
            let found = _.find(checkedDocs, (doc) => {return doc.doc_id === item.doc_id});
            return (
              <div key={item.doc_id} className="d-flex my-2">
                <div>
                  <i className="icon ion-document-text mr-2" style={{fontSize: '28px', lineHeight: '26px'}} />
                </div>
                <div className="flex-grow-1" style={styles.userColumn}>
                  <h6 className="mb-0 mt-1"
                      style={{...(found ? styles.selectedUserHeader : styles.unselectedUser), ...{
                          wordBreak : 'break-all'
                        }}}>
                    {item.label}
                  </h6>
                </div>
                <div style={styles.userColumn} className="ml-3 mr-2">
                  <div className="d-inline-block">
                    <Checkbox isChecked={!!found}
                              rootCls="big-round-checkbox form-control-lg"
                              label={' '}
                              onChange={this.availableDocCheckChange.bind(this, item)}
                              labelCls={'primary-color'} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }
  
  render() {
    let { validationErr, checkedDocs, isZipping, zippingSuccessful} = this.state;
    let { t } = this.props;
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Download Documents")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          {!zippingSuccessful && !isZipping && this.getSelectionView()}
          {!zippingSuccessful && isZipping && this.getProgressView()}
          {zippingSuccessful && !isZipping && this.getSuccessView()}
        </div>
        {!zippingSuccessful && !isZipping &&
        <div className="modal-footer">
          {validationErr && <ValidationErrors errors={[validationErr]}/>}
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Cancel")}</Button>
          <Button disabled={checkedDocs.length === 0}
                  className={'btn btn-primary'}
                  onClick={this.selectClick.bind(this)}>{t("Select")}</Button>
        </div>
        }
      </div>
    )
  }
}

const styles = {
  selectAllWrap : {
    borderBottom : `1px solid ${colors.STABLE}`
  },
  userColumn: {
    minHeight : '50px'
  },
  selectedUserHeader : {
    fontWeight : 'bold'
  },
  selectedUserEmail : {
    fontWeight: '100'
  },
  unselectedUser : {
    color : colors.SECONDARY_TEXT,
    fontWeight: '100'
  },
}

const mapStateToProps = (state) => {
  return {
  
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url))
  };
};

DownloadMultipleWindow.propTypes = {
  close: PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DownloadMultipleWindow));
