import c from '../util/const';
import sapi from "../util/sapi";
import log from "../util/log";

import Promise from 'bluebird';
import _ from "lodash";
import msgHelper from "../helpers/msg-helper";

const homeActions = {

  updateWorkspaceLists(workspaces){
    let unarchivedWorkspaces = [];
    let archivedWorkspaces = [];
    _.each(workspaces, (ws) => {
      if(ws.hide_flag){
        archivedWorkspaces.push(ws);
      }
      else{
        unarchivedWorkspaces.push(ws);
      }
    })
  
    return {
      type: c.actions.home.updateWorkspaceLists,
      archivedWorkspaces,
      unarchivedWorkspaces
    };
  },
  
  refreshActiveDMMessages(){
    return (dispatch, getState) => {
      
      let { activeDM } = getState().home;
      if(!activeDM){
        return Promise.resolve(true);
      }
      
      dispatch(this.refreshDMMessages(activeDM))
    }
  },
  
  clearActiveDM(){
    return (dispatch) => {
      dispatch({
        type: c.actions.home.updateActiveDMMessages,
        messages: [],
        messageBlocks: [],
        docs: []
      })
      return dispatch({
        type: c.actions.home.setActiveDM,
        dm : null
      })
    }
  },
  
  refreshDMMessages(dm) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        
        let finishedDocs = false;
        let finishedMessages = false;
        
        if (dm.guest_uid) {
          sapi.DM.docList(dm.guest_uid)
            .then((res) => {
              let docs = [];
              _.each(res.data, (block) => {
                _.each(block.docs, (doc) => {
                  doc.$chatId = block.chat_id;
                  docs.push(doc);
                })
              })
              return docs;
            })
            .then((res) => {
              dispatch({
                type: c.actions.home.updateActiveDMMessages,
                docs: res ? res : []
              })
              finishedDocs = true;
              if(finishedDocs && finishedMessages){
                resolve(true);
              }
            })
        }
        else{
          finishedDocs = true;
        }
        
        sapi.DM.messages(dm.guest_uid)
          .then((res) => {
            
            let {accountInfo, threadTransactionQueue} = getState().shared;
            let pendingMsgs = msgHelper.buildPendingMessageList(dm.guest_uid, accountInfo, threadTransactionQueue);
            
            dispatch({
              type: c.actions.home.updateActiveDMMessages,
              messages: res.data,
              messageBlocks: msgHelper.getMessageBlocks(_.concat(pendingMsgs, res.data)),
            })
            finishedMessages = true;
            if(finishedDocs && finishedMessages){
              resolve(true);
            }
          })
      })
    }
  },
  
  setActiveDM(dm){
    return (dispatch) => {
      return dispatch(this.refreshDMMessages(dm))
        .then((res) => {
          dispatch({
            type: c.actions.home.setActiveDM,
            dm
          })
        })
    }
  },
  
  cleanup(){
    return {
      type : c.actions.home.cleanup
    }
  }
}

export default homeActions;
