import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import filters from "../../../helpers/filters";
import classnames from 'classnames'
import Popover from "react-tiny-popover";
import ColorGenerator from "../../../helpers/color-generator";
import sapi from "../../../util/sapi";
import {connect} from "react-redux";
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import {getMessageForError} from "../../../util/errors";
import colors from "../../../util/colors";
import UserBadge from "../badges/UserBadge";
import ExpandableRow from "../components/ExpandableRow";
import Promise from "bluebird";
import Image from "../elements/Image";
import DownloadHelper from "../components/DownloadHelper";
import log from "../../../util/log";
import _ from "lodash";
import Button from "../elements/Button";
import utils from "../../../util/util";
import c from "../../../util/const";
import sharedActions from "../../../actions/shared-actions";
import {Element } from 'react-scroll'
import homeActions from "../../../actions/home-actions";
import downloadActions from "../../../actions/download-actions";
import { isMobile } from 'react-device-detect';
import DocExtensionPlaceholder from "../elements/DocExtensionPlaceholder";
import { DragSource } from 'react-dnd'
import {withVFTranslation} from "../../../util/withVFTranslation";

class DocRow extends PureComponent {

  constructor(props){
    super(props)
  
    this.hidePopover = this.hidePopover.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.getPopoverContent = this.getPopoverContent.bind(this);
    this.itemClick = this.itemClick.bind(this);
    this.itemClickMenu = this.itemClickMenu.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.downloadDoc = this.downloadDoc.bind(this);
    this.downloadDocMenu = this.downloadDocMenu.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.showPreviewMenu = this.showPreviewMenu.bind(this);
    this.showSigningMetadata = this.showSigningMetadata.bind(this);
    this.showSigningMetadataMenu = this.showSigningMetadataMenu.bind(this);
    this.setNotifyFlagMenu = this.setNotifyFlagMenu.bind(this);
    
    
    this.state = {
      thumbnail : null,
      hasFetchedThumbnail : false,
      isPreviewable: utils.isPreviewable(props.row.label),
      metadata : null,
      hovering : false,
      showMenu : false
    }
  }
  
  componentDidMount() {
    if(this.props.isActive && !this.state.thumbnail){
      this.fetchThumbnail()
        .then((res) => {
          this.setState({
            thumbnail: res,
            hasFetchedThumbnail : true
          })
        })
    }
    if(this.props.isActive){
      this.fetchMetadata();
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    
    let prevForumId = _.get(prevProps, 'workspace.forum_id');
    let thisForumId = _.get(this.props, 'workspace.forum_id');
    
    if(prevForumId !== thisForumId && thisForumId && this.props.isActive){
      this.updateExpandedRowData();
    }
    else {
      prevForumId = _.get(prevProps, 'dm.forum_id');
      thisForumId = _.get(this.props, 'dm.forum_id');
      if (prevForumId !== thisForumId && thisForumId && this.props.isActive) {
        this.updateExpandedRowData();
      }
    }
    
    if(!prevProps.isActive && this.props.isActive){
      log.log('doc row did update isActive flag', prevProps, this.props);
      this.updateExpandedRowData();
    }
  }
  
  updateExpandedRowData(){
    this.fetchMetadata();
    this.fetchThumbnail()
      .then((res) => {
        this.setState({
          thumbnail: res,
          hasFetchedThumbnail: true
        })
      })
  }
  
  itemClick(evt){
    let {onItemClick, row} = this.props;

    this.hidePopover(evt)
    
    onItemClick(row);
    
    if(row.notify_flag){
      this.setNotifyFlag(false, evt);
    }
  }
  
  itemClickMenu(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.itemClick(evt);
  }
  
  onMouseEnter(){
    if(this.state.hovering){
      return;
    }
    
    this.setState({
      hovering : true
    })
  }
  
  onMouseLeave(){
    this.setState({hovering : false})
  }
  
  showMenu(evt){
    evt.preventDefault();
    evt.stopPropagation();
    
    this.setState({showMenu : true})
  }
  
  hidePopover(evt){
    evt.preventDefault();
    evt.stopPropagation();
    
    this.setState({showMenu : false})
  }
  
  getDownloadHeaders() {
    let {workspace, dm, row, isOutsideCurrentContext} = this.props;
    
    let params = {
      doc_id : row.doc_id,
    }
  
    if(dm && !isOutsideCurrentContext){
      if (dm && dm.forum_id) {
        params.forum_id = dm.forum_id
      }
      if (dm.host_uid) {
        params.host_uid = dm.host_uid;
      }
    }
    else{
      if (workspace && workspace.forum_id) {
        params.forum_id = workspace.forum_id
      }
      if (workspace.host_uid) {
        params.host_uid = workspace.host_uid;
      }
    }
    
    params[c.api.X_TOKEN] = sapi.getToken();
    
    return params;
  }
  
  fetchMetadata(){
    let {row, workspace, dm, isOutsideCurrentContext} = this.props;
    
    let forum_id = null;
    let host_uid = null;
    let doc_id = row.doc_id;
    if(dm && !isOutsideCurrentContext){
      forum_id = dm.forum_id;
      host_uid = dm.host_uid;
    }
    else{
      forum_id = workspace.forum_id;
      host_uid = workspace.host_uid;
    }
    
    return sapi.Docs.metadata(forum_id, host_uid, doc_id)
      .then((res) => {
        log.log('doc metadata', res);
        let val = _.get(res, 'data');
        if(val && !_.isEmpty(val)){
          this.setState({metadata : res.data})
        }
        else {
          this.setState({metadata : null})
        }
      })
      .catch((err) => {
        log.log('error getting doc metadata', err);
        this.setState({metadata : null})
      })
  }
  
  fetchThumbnail() {
    let {row, workspace, dm, isOutsideCurrentContext} = this.props;
    
    return new Promise((resolve, reject) => {
  
      if(dm && !isOutsideCurrentContext){
        sapi.Docs.thumbnail(dm.forum_id, dm.host_uid, row.doc_id)
          .then((res) => {
            resolve(res.data.thumbnail);
          })
          .catch((err) => {
            //no error
            resolve(null);
          })
      }
      else{
        sapi.Docs.thumbnail(workspace.forum_id, workspace.host_uid, row.doc_id)
          .then((res) => {
            resolve(res.data.thumbnail);
          })
          .catch((err) => {
            //no error
            resolve(null);
          })
      }
    })
  }
  
  renderThumbnail() {
    let {thumbnail, hasFetchedThumbnail} = this.state;
    let {connectDragSource, connectDragPreview, t } = this.props;

    //Just FYI there's some delicate stuff in here around Edge handling.  bugs 2563, 2564

    //If we haven't fetch the thumb yet, render a blank space to give us a sec to show
    // while we fetch.
    if(!hasFetchedThumbnail){
      return (
        <div style={{minHeight: '60px'}}></div>
      )
    }

    if (thumbnail) {
      let image = <span>
        <Image src={thumbnail}
               className={"no-pointer"}
               imgHeight={100}
               alt={t('Thumbnail')}/>
      </span>
      connectDragPreview(image)
      let dragDropContents = connectDragSource(
        <div className="has-grabber d-inline-block">
          {image}
        </div>
      )
      return (
        <div className={'text-center pb-3'}>
          {dragDropContents}
        </div>
      )
    }
    else{
      let dragDropContents = connectDragSource(
        <div className="has-grabber d-inline-block drag-drop-placeholder-wrap">
          <DocExtensionPlaceholder size="lg"
                                   filename={this.props.row.label} />
        </div>
      )
      return (
        <div className={'margin-auto pb-3'}>
          {dragDropContents}
        </div>
      )
    }
  }
  
  downloadDocMenu(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.downloadDoc(evt);
  }
  
  downloadDoc(evt){
    this.hidePopover(evt);
    this.props.doDownload(this.getDownloadHeaders(), sapi.Docs.url('download'));
  }
  
  showDocumentHistory(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
    
    let { workspace, dm, row, isOutsideCurrentContext } = this.props;
    
    let params = {};
    if(dm && !isOutsideCurrentContext){
      params.guest_uid = dm.guest_uid;
    }
    else{
      params.forum_id = workspace.forum_id;
      params.host_uid = workspace.host_uid;
    }
    params.doc_id = row.doc_id;
    
    this.props.showDocViewHistory(params, () => {
      log.log('history window closed');
    })
  }
  
  showRename(evt){
    if(!this.state.showMenu){
      return;
    }
    
    let { workspace, row, refreshDocs, t } = this.props;
    
    this.hidePopover(evt);
    
    let doRename = (label) => {
      return sapi.Docs.rename(workspace.forum_id, row.doc_id, label);
    }
    
    this.props.showRename(t('Rename Document'), row.label, doRename, (res) => {
      if(res){
        refreshDocs(workspace.forum_id, workspace.host_uid);
      }
    })
  }
  
  setNotifyFlagMenu(notify_flag, evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.setNotifyFlag(notify_flag, evt);
  }
  
  setNotifyFlag(notify_flag, evt){
    let { row, workspace, showAlert, refreshDocs, isOutsideCurrentContext, dm, t } = this.props;
    
    this.hidePopover(evt);
    
    if(dm && !isOutsideCurrentContext){
      sapi.Docs.mark(dm.forum_id, dm.host_uid, row.doc_id, notify_flag)
        .then((res) => {
          this.props.refreshActiveDMMessages();
          this.props.refreshActiveHomeDMMessages();
        })
        .catch((err) => {
          showAlert(t('Error Updating Document'), getMessageForError(err, t))
        })
    }
    else{
      sapi.Docs.mark(workspace.forum_id, workspace.host_uid, row.doc_id, notify_flag)
        .then((res) => {
          refreshDocs(workspace.forum_id, workspace.host_uid);
        })
        .catch((err) => {
          showAlert(t('Error Updating Document'), getMessageForError(err, t))
        })
    }
  }
  
  showPreviewMenu(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.showPreview(evt);
  }
  
  showPreview(evt){
    
    this.hidePopover(evt);
    let {
      workspace,
      row,
      thread,
      dm,
      isOutsideCurrentContext
    } = this.props;
    
    if(thread){
      this.props.showDocInfoThread(workspace.forum_id, workspace.host_uid, row.doc_id, thread, (res) => {
        log.log('preview window closed', res);
        if(res){
          this.props.setActiveThread(workspace.forum_id, workspace.host_uid, thread);
          this.props.refreshThreadMessages(workspace.forum_id, workspace.host_uid, thread.chat_id);
        }
      })
    }
    else if(dm){
      let forum_id = null;
      let host_uid = null;
      if(isOutsideCurrentContext){
        forum_id = workspace.forum_id;
        host_uid = workspace.host_uid;
      }
      else{
        forum_id = dm.forum_id;
        host_uid = dm.host_uid;
      }
      
      this.props.showDocInfoDM(forum_id, host_uid, row.doc_id, dm, (res) => {
        log.log('preview window closed', res);
        if(res){
          this.props.refreshActiveDMMessages();
          this.props.refreshActiveHomeDMMessages();
        }
      })
    }
    else{
      this.props.showDocInfo(workspace.forum_id, workspace.host_uid, row.doc_id, (res) => {
        log.log('preview window closed', res);
      })
    }
  }
  
  showSigningMetadata(evt){
    this.hidePopover(evt);
    let {
      workspace,
      dm,
      thread,
      isOutsideCurrentContext
    } = this.props;
  
    let forum_id = null;
    let host_uid = null;
    if(isOutsideCurrentContext || thread){
      forum_id = workspace.forum_id;
      host_uid = workspace.host_uid;
    }
    else if(dm){
      forum_id = dm.forum_id;
      host_uid = dm.host_uid;
    }
  
    this.props.showSigningMetadataWindow(
      forum_id,
      host_uid,
      (dm ? dm.guest_uid : null),
      this.state.metadata,
      (res) => {
        log.log('metadata window closed', res)
      })
  }
  
  showSigningMetadataMenu(evt){
    if(!this.state.showMenu){
      return;
    }
  
    this.showSigningMetadata(evt);
  }
  
  removeDocFromWorkspace(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
  
    let { row, workspace, t } = this.props;
    this.props.showConfirm(
      t("Delete Document"),
      t("Are you sure you want to delete the Document, ") + row.label + t("?"),
      (res) => {
        if(res){
          sapi.Docs.delete(workspace.forum_id, row.doc_id)
            .then((res) => {
              this.props.updateNotifications();
              this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true);
              this.props.refreshDocs(workspace.forum_id, workspace.host_uid);
            })
            .catch((err) => {
              log.log('error deleting doc', err);
              this.props.showAlert(t('Error Deleting Document'), getMessageForError(err, t))
            })
        }
      }, t('Delete'))
  }
  
  showDownloadMultiple(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
    
    let { row, workspace, dm } = this.props;
    if(dm){
      let flatDocs = [];
      if(this.props.activeDMDocs){
        _.each(this.props.activeDMDocs, (doc) => {
          flatDocs.push(doc);
        })
      }
      else if(this.props.activeHomeDMDocs){
        _.each(this.props.activeHomeDMDocs, (doc) => {
          flatDocs.push(doc);
        })
      }
      this.props.showDownloadMultiple(dm.forum_id, dm.host_uid, [row.doc_id], flatDocs, () => {
        log.log('show download multiple closed')
      })
    }
    else{
      let flatDocs = [];
      _.each(this.props.docs, (docBlock) => {
        _.each(docBlock.docs, (doc) => {
          flatDocs.push(doc);
        })
      })
      flatDocs = _.uniqBy(flatDocs, 'doc_id');
      this.props.showDownloadMultiple(workspace.forum_id, workspace.host_uid, [row.doc_id], flatDocs, () => {
        log.log('show download multiple closed')
      })
    }
  }
  
  attachToThreadClick(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
    
    let {
      row,
      workspace,
      dm,
      isOutsideCurrentContext,
      accountInfo
    } = this.props;
  
    //NOTE: yes, it's a little weird that we set src_host_uid if it doesn't exist on the workspace/dm.
    //The attach call requires it as of 4/23/20
    //There's tricky logic here that doesn't quite match the if/else.
    //If this doc is:
    //1. in a DM on the home page - workspace not set.  isOutsideContext not possible.  Source is dm
    //2. in a DM in a workspace - workspace is set.  dm is set.  isOutsideContext is set.  Source is workspace (because of outside context)
    //3. in a DM in a workspace - workspace is set.  dm is set.  is OutsideContext is NOT set.  Source is dm.
    //4. in a thread in a workspace - workspace is set.  dm not set.  isOutsideContext could change, but does not matter here.  Source is workspace.
    let src_forum_id = null;
    let src_host_uid = null;
    let initial_selection_forum_id = null;
    let initial_selection_guest_uid = null;
    if(isOutsideCurrentContext){
      src_forum_id = workspace.forum_id;
      src_host_uid = workspace.host_uid || accountInfo.uid;
      initial_selection_forum_id = src_forum_id;
    }
    else if(dm){
      src_forum_id = dm.forum_id;
      src_host_uid = dm.host_uid || accountInfo.uid;
      initial_selection_guest_uid = src_forum_id;
    }
    else if(workspace){
      src_forum_id = workspace.forum_id;
      src_host_uid = workspace.host_uid || accountInfo.uid;
      initial_selection_forum_id = src_forum_id;
    }
    else{
      console.error('what context is this?');
      return;
    }
    
    this.props.showAttachDocToThreadWindow(initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, row, (res) => {
      log.log('show attach doc window', res);
      if(res){
        this.props.onAttachToThread(res);
      }
    })
  }
  
  getPopoverContent(){
    let { row, workspace, dm, isOutsideCurrentContext, t } = this.props;
    let { isPreviewable } = this.state;
    
    if(dm && !isOutsideCurrentContext){
      return (
        <div>
          <div className="click-block" onClick={this.hidePopover} />
          <ul className="popover-content list-group">
            <a onClick={this.itemClickMenu.bind(this)}
               style={styles.menuHeader}
               className="list-group-item list-group-item-action">
              <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-document-text"/>
              {row.label}
            </a>
        
            <a onClick={this.downloadDocMenu}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-android-download"/>
              {t("Download Document")}
            </a>
            {isPreviewable &&
            <a onClick={this.showPreviewMenu}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-eye"/>
              {t("Preview Document")}
            </a>
            }
  
            <a onClick={this.showDocumentHistory.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-list-outline"/>
              {t("View Access History")}
            </a>
  
            <a onClick={this.attachToThreadClick.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-paperclip"/>
              {t("Send to...")}
            </a>
            
            <a onClick={this.showDownloadMultiple.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-download-outline"/>
              {t("Download Multiple Documents")}
            </a>
        
            {!row.notify_flag &&
            <a onClick={this.setNotifyFlagMenu.bind(this, true)}
               style={!workspace ? styles.menuItemBottom : styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
              {t("Mark as Unread")}
            </a>
            }
            {row.notify_flag &&
            <a onClick={this.setNotifyFlagMenu.bind(this, false)}
               style={!workspace ? styles.menuItemBottom : styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
              {t("Mark as Read")}
            </a>
            }
          </ul>
        </div>
      )
    }
  
    return (
      <div>
        <div className="click-block" onClick={this.hidePopover} />
        <ul className="popover-content list-group">
          <a onClick={this.itemClickMenu}
             style={styles.menuHeader}
             className="list-group-item list-group-item-action">
            <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-document-text"/>
            {row.label}
          </a>
          
          {!workspace.host_uid &&
            <a onClick={this.showRename.bind(this)}
               style={styles.menuItem}
               className="list-group-item list-group-item-action has-pointer">
              <i style={styles.menuIcons} className="icon ion-compose"/>
              {t("Rename Document")}
            </a>
          }

          {workspace && !workspace.host_uid &&
          <a onClick={this.showDocumentHistory.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-list-outline"/>
            {t("View Access History")}
          </a>
          }
  
          {workspace &&
          <a onClick={this.attachToThreadClick.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-paperclip"/>
            {t("Send to...")}
          </a>
          }
          
          <a onClick={this.downloadDocMenu}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-android-download"/>
            {t("Download Document")}
          </a>
          {isPreviewable &&
          <a onClick={this.showPreviewMenu}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-eye"/>
            {t("Preview Document")}
          </a>
          }
          {!dm &&
          <a onClick={this.showDownloadMultiple.bind(this)}
             style={(!workspace || workspace.host_uid) ? styles.menuItemBottom : styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-download-outline"/>
            {t("Download Multiple Documents")}
          </a>
          }

          {!row.notify_flag &&
          <a onClick={this.setNotifyFlagMenu.bind(this, true)}
             style={!workspace ? styles.menuItemBottom : styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
            {t("Mark as Unread")}
          </a>
          }
          {row.notify_flag &&
          <a onClick={this.setNotifyFlagMenu.bind(this, false)}
             style={!workspace ? styles.menuItemBottom : styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
            {t("Mark as Read")}
          </a>
          }

          {workspace && !workspace.host_uid &&
          <a onClick={this.removeDocFromWorkspace.bind(this)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action has-pointer assertive-color">
            <i style={styles.menuIcons} className="icon ion-close"/>
            {t("Delete Document")}
          </a>
          }
        </ul>
      </div>
    )
  }
  
  render() {
    let { row, thread, isOutsideCurrentContext, guests, isActive, t } = this.props;
    let { isPreviewable, metadata, hovering, showMenu } = this.state;
    
    return (
      <div id={`doc-${row.doc_id}`}>
        <div onClick={this.itemClick}
             onMouseEnter={this.onMouseEnter}
             onMouseLeave={this.onMouseLeave}
             className={classnames("d-flex flex-row doc-row pt-1 pb-2 item-row-wrap doc-row-wrap", {'active': isActive})}>
          <div style={styles.iconRowStyle}>
            <p>
              <i className={'icon ion-record ' + (row.notify_flag ? ' primary-color' : ' transparent-color')}/>
            </p>
          </div>
          <div className="flex-grow-1">
            <p className="mb-0 doc-label">
              {row.label}
              <Popover
                isOpen={showMenu}
                position={['left', 'bottom']}
                onClickOutside={this.hidePopover}
                content={this.getPopoverContent}>
                  <span style={{lineHeight: '18px'}}
                        className="pl-2 d-inline-block"
                        onClick={this.showMenu}>
                    <i style={styles.gearIcon} className={classnames("icon item-menu-icon ion-gear-b", {'invisible' : !isMobile && !showMenu && !hovering})}/>
                  </span>
              </Popover>
            </p>
            <p style={styles.updateDate} className="doc-preview mb-0 secondary-text-color">
              {t("Last Updated:")} {filters.getFriendlyDate(t, row.updated_date)} - {filters.getFileSizeString(row.storage_used)}
            </p>
            {isOutsideCurrentContext && thread &&
            <p style={styles.updateDate} className="doc-preview mb-0 secondary-text-color">
              <i className="icon ion-chatbox" /> {thread.label}
            </p>
            }
            {guests.map((guest) => {
              return (
                <span key={guest.guest_uid} className="mr-1">
                  <UserBadge guest={guest} small={true} />
                </span>
              )
            })}
          </div>
          <div style={styles.iconStyle}>
            <i className={'icon light-grey-color ' + (isActive ? ' ion-chevron-down' : ' ion-chevron-right')}/>
          </div>
        </div>
        <ExpandableRow isActive={isActive}>
          <div className="row-hover-bg">
            {isActive &&
            <Fragment>
              <div className="text-center p-3">
                {this.renderThumbnail()}
                <Button className={'btn btn-primary mr-2'} onClick={this.downloadDoc}>
                  <i className="icon ion-android-download pr-2"/>
                  {t("Download")}
                </Button>
                {isPreviewable &&
                <Button className={'btn btn-primary'} onClick={this.showPreview}>
                  <i className="icon ion-eye pr-2"/>
                  {t("Preview")}
                </Button>
                }
                <div className="my-2 text-center">
                  {metadata &&
                  <Button className={'btn btn-link'} onClick={this.showSigningMetadata}>
                    {t("View signing info")}
                  </Button>
                  }
                </div>
              </div>
            </Fragment>
            }
          </div>
  
        </ExpandableRow>
      </div>
    )
  }
}

const styles = {
  updateDate : {
  
  },
  
  iconStyle : {
    height: '48px',
    lineHeight: '48px',
    minWidth: '30px',
    textAlign: 'center'
  },
  
  //duplicated in WorkspaceRow...consolidate this.
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT,
    backgroundColor : colors.SECONDARY_TEXT
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  iconRowStyle : {
    minWidth: '30px',
    textAlign: 'center'
  },
}

DocRow.propTypes = {
  thread : PropTypes.object,
  dm : PropTypes.object,
  workspace : PropTypes.object,
  row: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  isActive : PropTypes.bool.isRequired,
  guests : PropTypes.array.isRequired,
  isOutsideCurrentContext : PropTypes.bool.isRequired,
  onAttachToThread : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    activeThreadMessages: state.workspace.activeThreadMessages,
    wsActiveDMMessages: state.workspace.activeDMMessages,
    homeActiveDMMessages : state.home.activeDMMessages,
    
    //only needed for doc attach.  refactor?
    contacts : state.shared.contacts,
    threads: state.workspace.threads,
    accountInfo: state.shared.accountInfo,
    
    //these are only needed for download multiple window.  maybe we can refactor this?
    docs : state.workspace.docs,
    activeDMDocs: state.workspace.activeDMDocs,
    activeHomeDMDocs: state.home.activeDMDocs,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    ...workspaceActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),
    refreshActiveHomeDMMessages: () => dispatch(homeActions.refreshActiveDMMessages()),
    doDownload: (headers, url) => dispatch(downloadActions.doDownload(headers, url))
  };
};

const cardSource = {
  beginDrag(props) {
    // Return the data describing the dragged item
    log.log('begin drag', props);
  
    let src_forum_id = null;
    let src_host_uid = null;
    if(props.isOutsideCurrentContext){
      src_forum_id = props.workspace.forum_id;
      src_host_uid = props.workspace.host_uid || props.accountInfo.uid;
    }
    else if(props.dm){
      src_forum_id = props.dm.forum_id;
      src_host_uid = props.dm.host_uid || props.accountInfo.uid;
    }
    else if(props.workspace){
      src_forum_id = props.workspace.forum_id;
      src_host_uid = props.workspace.host_uid || props.accountInfo.uid;
    }
    else{
      console.error('what context is this?');
      return;
    }
    
    const item = {
      row: props.row,
      src_forum_id,
      src_host_uid
    }
    return item
  },
  
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return
    }
    
    // When dropped on a compatible target, do something
    // const item = monitor.getItem()
    // const dropResult = monitor.getDropResult()
    //
    // log.log('endDrag', item, dropResult);
  }
}

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  }
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(DragSource(c.DRAG_DROP_TYPES.THREAD_DOC, cardSource, collect)(DocRow)));
