import React, {Component} from 'react';

class Button extends Component {
  
  render() {
    let {children, ...otherProps} = this.props;
    
    return <button {...otherProps}>{children}</button>
  }
}

export default Button;