import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import filters from "../../../helpers/filters";
import uploadActions from "../../../actions/upload-actions";
import modalActions from "../../../actions/modal-actions";
import {connect} from "react-redux";
import log from "../../../util/log";
import ProgressBar from "../elements/ProgressBar";
import colors from "../../../util/colors";
import Button from "../elements/Button";
import SignatureRequest from "../../../models/SignatureRequest";
import _ from "lodash";
import UserBadge from "../badges/UserBadge";
import DocSigningStatus from "./DocSigningStatus";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../../util/withVFTranslation";

class ThreadDoc extends PureComponent {
  
  constructor(props){
    super(props);
    
    this.onDocClick = this.onDocClick.bind(this);
  }
  
  onDocClick(){
    let { doc } = this.props;
    if(doc.isPending){
      return;
    }
    
    this.props.docClick(doc);
  }
  
  render() {
    let { doc, docInfo, t } = this.props;
    
    let label = null;
    let sizeString = null;
    if(doc.isPending){
      label = doc.filename;
      sizeString = doc.sizeString;
    }
    else if(docInfo){
      label = docInfo.label;
      sizeString = filters.getFileSizeString(docInfo.storage_used);
    }
    else{
      label = doc.label;
    }
    
    let isDeleted = !label && !doc.isPending;
    
    return (
      <span key={doc.doc_id} className="d-inline-block" style={styles.docWrap}>
        <span>
          {label &&
          <span style={styles.docTopRow} className="mb-0">
            <span className={`${doc.isPending ? 'secondary-text-color' : 'primary-color has-pointer'}`}
                  onClick={this.onDocClick}
                  style={styles.docTitle}>
              <i style={{
                fontSize: '30px',
                verticalAlign: 'middle',
                marginRight: '10px',
              }} className="icon ion-document-text" />
              {label}
            </span>
            <span style={styles.docSize}>
              {sizeString}
            </span>
          </span>
          }
          {isDeleted &&
          <span style={styles.docTopRow} className="mb-0">
            <span style={styles.docTitleDeleted}>({t("document deleted")})</span>
          </span>
          }
          {!doc.isPending && !isDeleted &&
          <DocSigningStatus doc={doc}
                            msg={this.props.msg}
                            findInParticipants={this.props.findInParticipants}
                            completeRequestClick={this.props.completeRequestClick}
                            editRequestClick={this.props.editRequestClick}
                            fulfillSignatureRequestClick={this.props.fulfillSignatureRequestClick}
                            workspace={this.props.workspace}
                            dm={this.props.dm}
          />
          }
        </span>
      </span>
    )
  }
}

const styles = {
  docWrap : {
    marginTop: '5px',
    marginBottom : '5px'
  },
  docTopRow : {
    marginRight : '10px',
    lineHeight : '16px',
    maxWidth : '100%'
  },
  docTitle : {
    fontSize : '14px',
    lineHeight: '24px',
    fontWeight : 'bold',
    marginRight : '10px',
    color : colors.DARK,
    wordBreak: 'break-all'
  },
  docTitleDeleted : {
    fontSize : '12px',
    lineHeight: '20px',
    marginRight : '10px',
    color : colors.SECONDARY_TEXT
  },
  docSize : {
    fontSize: '12px',
    color : colors.SECONDARY_TEXT
  }
}

ThreadDoc.propTypes = {
  msg : PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  docInfo : PropTypes.object,
  completeRequestClick: PropTypes.func.isRequired,
  editRequestClick : PropTypes.func.isRequired,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,
  docClick : PropTypes.func.isRequired,
  findInParticipants : PropTypes.func,
  dm : PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    accountInfo : state.shared.accountInfo,
    accountInfoGuest : state.shared.accountInfoGuest,
    activeUpload : state.upload.activeUpload,
    uploadProgress : state.upload.uploadProgress
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ThreadDoc));
