import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from '../../partials/elements/Button';
import sapi from "../../../util/sapi";
import modalActions from "../../../actions/modal-actions";
import log from "../../../util/log";
import UserBadge from "../../partials/badges/UserBadge";
import colors from "../../../util/colors";
import {getErrorMessage, getMessageForError} from "../../../util/errors";
import ValidationErrors from "../../partials/components/ValidationErrors";

import {last} from "pdf-lib";
import AddContactWindow from "../../modals/AddContactWindow";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../../util/withVFTranslation";
import utils from "../../../util/util";
import {validateEmail} from "../../../util/validation";

class AddContact extends Component {
  
  constructor(props) {
    super(props);
    
    this.firstInputRef = React.createRef();
    
    this.state = {
      first_name : '',
      last_name : '',
      email_address : '',
      validationErr : []
    }
  }
  
  componentDidMount() {
    let { searchString } = this.props.modalProps;
    
    if(searchString){
      let split = searchString.split(' ');
      let consumedTokens = [];
      let foundEmail = '';
      _.each(split, (token, index) => {
        if(token.indexOf("@") > 0){
          consumedTokens.push(index);
          foundEmail = token;
          return false;
        }
      })
      
      let foundFirstName = '';
      _.each(split, (token, index) => {
        if(consumedTokens.indexOf(index) < 0) {
          if (token.indexOf("@") < 0) {
            consumedTokens.push(index);
            foundFirstName = token;
            return false;
          }
        }
      })
      
      let foundLastName = '';
      _.each(split, (token, index) => {
        if(consumedTokens.indexOf(index) < 0) {
          consumedTokens.push(index);
          foundLastName += token + ' ';
        }
      })
      foundLastName = _.trim(foundLastName, ' ');
      this.setState({
        first_name : foundFirstName,
        last_name : foundLastName,
        email_address : foundEmail
      })
    }
    
    setTimeout(() => {
      this.firstInputRef.current.focus();
    })
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  doValidation(){
    let err = [];
    let { t } = this.props;
    
    if(!this.state.first_name){
      err.push(t('Please enter your first name'));
    }
    
    if(!this.state.last_name){
      err.push(t('Please enter your last name'));
    }
  
    if(!this.state.email_address || !validateEmail(this.state.email_address)){
      err.push(t("Please check your contact's email address."));
    }
    
    this.setState({validationErr : err})
    return err.length === 0;
  }
  
  submit(){
    if(!this.doValidation()){
      return;
    }
    
    let { adminInfo, t } = this.props;
    let { first_name, last_name, email_address } = this.state;
    
    let contact = {
      first_name : _.trim(first_name),
      last_name : _.trim(last_name),
      email : _.trim(email_address)
    }
    
    let upgradeClassId = utils.pickMemberAddClassId(adminInfo);
    sapi.Member.add(adminInfo.inst_id, upgradeClassId, [contact])
      .then((res) => {
        log.log('add contact res', res);
        
        //This api call handles lists of contacts, so the response returns a list of errors.
        //There will only be on when called from here, but we need to look through the error list
        //differently because it's not the usual error response.
        let success = true;
        if(res && res.data){
          _.each(res.data, (contactAddRes) => {
            if(contactAddRes.error){
              success = false;
              //Any error other than APP_GUEST_DUP, we should warn about, because the guest probably didn't get added.
              this.props.showAlert(t('Error adding member'), getErrorMessage(contactAddRes.error, t));
            }
          })
        }
        else{
          success = false;
          this.props.showAlert(t('Error adding member'), t("There was a problem adding your member.  Please try again."));
        }
        
        if(success) {
          this.closeModal(res.data[0]);
        }
      })
      .catch((err) => {
        this.props.showAlert(t('Error adding member'), getMessageForError(err, t));
      })
  }
  
  importCSVClick(){
    this.props.setWindowMode(AddContactWindow.WINDOW_MODES.CSV_UPLOAD);
  }
  
  render() {
    let {allowBulkImport, t} = this.props;
    let {validationErr, first_name, last_name, email_address} = this.state;
    
    return (
      <div className="modal-content">
        
        <div className="modal-header">
          <h5 className="modal-title">{t("Add Member")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, null)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("First Name:")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={first_name}
                       ref={this.firstInputRef}
                       placeholder={t("Enter your member's first name")}
                       onChange={(evt) => this.setState({first_name: evt.target.value})}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Last Name:")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={last_name}
                       placeholder={t("Enter your member's last name")}
                       onChange={(evt) => this.setState({last_name: evt.target.value})}/>
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <div className="form-group">
                <label>{t("Email Address:")}</label>
                <input className={'form-control'}
                       type={'text'}
                       value={email_address}
                       placeholder={t("Enter your member's email address")}
                       onChange={(evt) => this.setState({email_address: evt.target.value})}/>
              </div>
            </div>
          </div>
          {validationErr.length > 0 && <ValidationErrors errors={[validationErr]} />}
          {allowBulkImport &&
            <div className="row">
              <div className="col text-center">
                <a className="btn btn-link primary-color btn-lg hover-underline" onClick={this.importCSVClick.bind(this)}>
                  {t("Import members from CSV file")}
                </a>
              </div>
            </div>
          }
        </div>
        <div className="modal-footer">
          <Button className="btn btn-secondary" onClick={this.closeModal.bind(this, null)}>{t("Cancel")}</Button>
          <Button className="btn btn-primary"
                  disabled={!first_name || !last_name || !email_address}
                  onClick={this.submit.bind(this)}>{t("Add")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {

}

const mapStateToProps = (state) => {
  return {
  
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

AddContact.propTypes = {
  setWindowMode : PropTypes.func.isRequired,
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddContact));
