import React, {Component} from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { isMobile, isIE, browserVersion } from 'react-device-detect';
import log from "../../../util/log";

class DownloadHelper extends Component {

  constructor(props){
    super(props);
    this.downloadForm = React.createRef();
    this.state = {
      formId : _.uniqueId("download_form_"),
      iframeId : _.uniqueId("download_iframe_"),
    }
  }
  
  componentDidMount() {
    let { onRef } = this.props;
    
    if(onRef){
      onRef(this);
    }
  }
  
  componentWillUnmount() {
    let { onRef } = this.props;
    
    if(onRef){
      onRef(null);
    }
  }
  
  doDownload(){
    if(!this.downloadForm){
      throw new Error('download form does not exist');
    }
    this.downloadForm.current.submit();
  }
  
  iFrameError(evt, el){
    log.log('iframe error?', evt, el);
  }
  
  render() {
    let { headers, url } = this.props;
    let { formId, iframeId } = this.state;

    //This was in the original ui project, I just re-implemented.
    //Notes from ui below:
    //Just FYI, mobile browsers do weird things with hidden iframe downloads the way we do it
    //on desktop browsers.  We need to set the form target as blank so that downloads open in
    //a new page instead.  bug 771.
    let downloadTarget = iframeId;
    if(isMobile){
      downloadTarget = '_blank';
    }
    else if( isIE && +browserVersion === 11){
      //Apparently ie 11 has some problems with iframe targets now?
      //bug 2199
      downloadTarget = '';
    }

    return (
      <div>
        <iframe name={iframeId}
                id={iframeId}
                className={'d-none'}
                onError={this.iFrameError.bind(this)}>
        </iframe>
        <form ref={this.downloadForm}
              name={formId}
              id={formId}
              action={url}
              acceptCharset={'UTF-8'}
              method={'POST'}
              encType={'multipart/form-data'}
              target={downloadTarget}>
          {_.keys(headers).map((name, index) =>
            <input type={'hidden'}
                   name={name}
                   key={index}
                   value={headers[name]}/>
          )}
        </form>
      </div>
    )
  }
}

DownloadHelper.propTypes = {
  headers : PropTypes.object.isRequired,
  url : PropTypes.string.isRequired,
  onRef : PropTypes.func.isRequired
}

export default DownloadHelper;
