import appActions from "../../../actions/app-actions";
import authActions from "../../../actions/auth-actions";
import modalActions from "../../../actions/modal-actions";
import {withRouter} from "react-router-dom";
import React, { Component } from 'react';
import {connect} from 'react-redux';
import log from "../../../util/log";
import ErrorPage from "./ErrorPage";
import ConnectionErrorPage from "./ConnectionErrorPage";
import sapi from "../../../util/sapi";
import EnvironmentalConfigurationErrorPage from "./EnvironmentalConfigurationErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  
    if(sapi.shouldUIErrorTriggerApplicationError(error)) {
      this.props.setApplicationError(error);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.applicationError && this.props.applicationError){
      this.setState({ hasError: true });
    }
  }
  
  isEnvironmentalError(err){
    return !!err.isEnvironmentalError;
  }
  
  render() {
    if (this.state.hasError) {
      if(sapi.isConnectionError(this.props.applicationError)){
        return <ConnectionErrorPage />;
      }
      else if(this.isEnvironmentalError(this.props.applicationError)){
        return <EnvironmentalConfigurationErrorPage />
      }
      else{
        return <ErrorPage />;
      }
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    applicationError : state.app.applicationError
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApplicationError: (error) => dispatch(appActions.setApplicationError(error))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary));
