import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import filters from "../../../helpers/filters";
import classnames from 'classnames'
import utils from "../../../util/util";
import _ from 'lodash';
import he from "he";
import UserBadge from "../badges/UserBadge";
import sapi from "../../../util/sapi";
import colors from "../../../util/colors";
import {getMessageForError} from "../../../util/errors";
import ColorGenerator from "../../../helpers/color-generator";
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import {connect} from "react-redux";
import Popover from "react-tiny-popover";
import ManageObjectPermissionsWindow from "../../modals/ManageObjectPermissionsWindow";
import sharedActions from "../../../actions/shared-actions";
import log from "../../../util/log";
import Truncate from "react-truncate";
import { isMobile } from 'react-device-detect';
import ReactToPrint from "react-to-print";
import {DropTarget} from "react-dnd";
import c from "../../../util/const";
import UploadHelper from "../components/UploadHelper";
import DocAttach from "../../../models/DocAttach";
import {withVFTranslation} from "../../../util/withVFTranslation";

class ThreadRow extends PureComponent {
  
  constructor(props){
    super(props);
  
    this.hidePopover = this.hidePopover.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.getPopoverContent = this.getPopoverContent.bind(this);
    this.itemClick = this.itemClick.bind(this);
    this.itemClickMenu = this.itemClickMenu.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    
    this.state = {
      hovering : false,
      showMenu : false
    }
  }
  
  onMouseEnter(){
    this.setState({
      hovering : true
    })
  }
  
  onMouseLeave(){
    this.setState({hovering : false})
  }
  
  itemClick(evt){
    let {onItemClick, row} = this.props;

    this.hidePopover(evt);
    onItemClick(row);
  }
  
  itemClickMenu(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.itemClick(evt);
  }
  
  showRename(evt){
    if(!this.state.showMenu){
      return;
    }
    
    let { workspace, row, refreshThreads, activeThread, t } = this.props;
    
    this.hidePopover(evt);
    
    let doRename = (label) => {
      return sapi.Threads.rename(workspace.forum_id, row.chat_id, label);
    }
    
    this.props.showRename(t('Rename Thread'), row.label, doRename, (res) => {
      if(res){
        refreshThreads(workspace.forum_id, workspace.host_uid, true);
      }
    })
  }
  
  setNotifyFlag(notify_flag, evt){
    if(!this.state.showMenu){
      return;
    }
    
    let { row, workspace, showAlert, refreshThreads, activeThread, t } = this.props;
    
    this.hidePopover(evt);
    
    sapi.Threads.mark(workspace.forum_id, workspace.host_uid, row.chat_id, notify_flag)
      .then((res) => {
        refreshThreads(workspace.forum_id, workspace.host_uid, true);
      })
      .catch((err) => {
        showAlert(t('Error Updating Thread'), getMessageForError(err, t))
      })
  }
  
  managePermissionsMenu(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.managePermissions(evt);
  }
  
  managePermissions(evt){
    this.hidePopover(evt);
    
    let { row, workspace } = this.props;
    this.props.showManageObjectPermissionsWindow(row.chat_id, row.label, row, ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD, workspace, (res) => {
      if(res){
        this.props.updateNotifications();
        this.props.refreshGuests(workspace.forum_id, workspace.host_uid);
        this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true);
        this.props.refreshDocs(workspace.forum_id, workspace.host_uid);
        this.props.updateDirectMessages();
      }
    })
  }
  
  showMenu(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    
    this.setState({showMenu : true})
  }
  
  hidePopover(evt){
    if(evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    
    this.setState({showMenu : false})
  }
  
  removeThreadFromWorkspace(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
    
    let { row, workspace, t } = this.props;
    this.props.showConfirm(
      t("Remove Thread"),
      t("Are you sure you want to delete the Thread, ") + row.label + t("?"),
      (res) => {
        if(res){
          sapi.Threads.delete(workspace.forum_id, row.chat_id)
            .then((res) => {
              this.props.updateNotifications();
              this.props.onDeleteThread(row);
              this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true);
            })
            .catch((err) => {
              log.log('error deleting thread', err);
              this.props.showAlert(t('Error Deleting Thread'), getMessageForError(err, t))
            })
        }
      })
  }
  
  showAddExistingDoc(evt){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover(evt);
    
    let { workspace, row } = this.props;
    this.props.showAddDocWindow(workspace.forum_id, null, row.chat_id, (res) => {
      log.log('add doc window', res);
      if(res){
        this.props.onDocAttach(res);
      }
    });
  }
  
  beforePrintPreview(){
    if(!this.state.showMenu){
      return;
    }
    
    this.hidePopover();
    
    return this.props.onBeforePrintPreview(this.props.row.chat_id);
  }
  
  getPopoverContent(){
    let { row, workspace, isActive, t } = this.props;
    
    return (
      <div>
        <div className="click-block" onClick={this.hidePopover} />
        <ul className="popover-content list-group">
          <a onClick={this.itemClickMenu}
             style={styles.menuHeader}
             className="list-group-item list-group-item-action">
            <i style={{...styles.menuIcons, ...styles.menuHeaderIcon}} className="icon ion-chatbox"/>
            {row.label}
          </a>
          
          <a onClick={this.itemClickMenu}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-eye"/>
            {t("View Thread")}
          </a>
          {!workspace.host_uid &&
          <a onClick={this.showRename.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-compose"/>
            {t("Rename Thread")}
          </a>
          }
          
          {!workspace.host_uid &&
          <a onClick={this.managePermissionsMenu.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-android-share-alt"/>
            {t("Manage Permissions")}
          </a>
          }
          
          <a onClick={this.showAddExistingDoc.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-paperclip"/>
            {t("Attach from...")}
          </a>
          
          {!row.notify_flag &&
          <a onClick={this.setNotifyFlag.bind(this, true)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-checkmark-outline"/>
            {t("Mark as Unread")}
          </a>
          }
          {row.notify_flag &&
          <a onClick={this.setNotifyFlag.bind(this, false)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer">
            <i style={styles.menuIcons} className="icon ion-ios-circle-outline"/>
            {t("Mark as Read")}
          </a>
          }
  
          <ReactToPrint onBeforeGetContent={this.beforePrintPreview.bind(this)}
                        onPrintError={this.props.onPrintPreviewError.bind(this)}
                        onAfterPrint={this.props.onAfterPrintPreview.bind(this)}
                        removeAfterPrint={false}
                        content={this.props.getPrintPreviewContents.bind(this)}
                        trigger={() => {
                          return (
                            <a style={styles.menuItem}
                               className="list-group-item list-group-item-action has-pointer">
                              <i style={styles.menuIcons} className="icon ion-printer"/>
                              {t("Print Thread")}
                            </a>
                          )
                        }}/>
          
          {workspace && !workspace.host_uid &&
          <a onClick={this.removeThreadFromWorkspace.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action has-pointer assertive-color">
            <i style={styles.menuIcons} className="icon ion-close"/>
            {t("Remove Thread from Workspace")}
          </a>
          }
        </ul>
      </div>
    )
  }
  
  renderDragDropOverlays(){
    let { isOverCurrent, canDrop, t } = this.props;
    
    let contents = null;
    if(!isOverCurrent && !canDrop){
      contents = <div></div>
    }
    // else if(canDrop && !isOverCurrent){
    //   contents = <div style={{...UploadHelper.styles.overlayStyle, ...UploadHelper.styles.canDragDrop}}>You can drag that file here!</div>
    // }
    else if(isOverCurrent){
      contents = <div style={{...UploadHelper.styles.overlayStyle, ...UploadHelper.styles.dragDrop}}>{t("Drop files here!")}</div>
    }
    
    return contents;
  }
  
  render() {
    let { row, isActive, guests, connectDropTarget, t } = this.props;
    let { showMenu, hovering } = this.state;
  
    let preview = _.get(this.props, 'row.preview');
    let hasPreview = !!preview;
    let previewTime = null;
    let previewMesg = null;
    let hasPreviewDocs = null;
    if (hasPreview) {
      previewTime = utils.getMomentDate(preview.mesg_time);
      previewMesg = he.decode(preview.mesg);
      hasPreviewDocs = preview.mesg_docs > 0;
    }
    
    return connectDropTarget(
      <div onClick={this.itemClick}
           onMouseEnter={this.onMouseEnter}
           onMouseLeave={this.onMouseLeave}
           id={`thread-row-${row.chat_id}`}
           className={classnames('d-flex flex-row thread-row pl-2 pb-2 pt-1', {'active' : isActive})}>
        {this.renderDragDropOverlays()}
        <div style={styles.iconRowStyle}>
          <p>
            <i className={classnames('icon ion-record', {'transparent-color' : !row.notify_flag}, {'primary-color' : row.notify_flag && !isActive}, {'light-color' : row.notify_flag && isActive})}/>
          </p>
        </div>
        <div className="d-flex flex-row item-row-wrap thread-row-wrap mr-2">
          <div className="flex-grow-1">
            <p className="mb-0 thread-label">
              {row.label}
              <Popover
                isOpen={showMenu}
                position={['right', 'bottom', 'top']}
                onClickOutside={this.hidePopover}
                content={this.getPopoverContent()}>
                  <span style={{lineHeight: '18px'}}
                        className="pl-2 d-inline-block"
                        onClick={this.showMenu}>
                    <i style={styles.gearIcon} className={classnames(`icon item-menu-icon ion-gear-b ${isActive ? 'active' : ''}`, {'invisible' : !isMobile && !showMenu && !hovering})}/>
                  </span>
              </Popover>
            </p>
            {hasPreview &&
            <p className={`thread-preview mb-0 ${isActive ? 'light-color' : 'secondary-text-color'}`}>
              {hasPreviewDocs &&
              <i style={styles.attachmentIcon} className="icon ion-paperclip"/>
              }
              <Truncate lines={2} ellipsis={t('...')}>
                {previewTime.format('MMM D YYYY')}{previewMesg.length > 0 ? ' - ' + previewMesg : ''}
              </Truncate>
            </p>
            }
            {!hasPreview &&
            <p className={`thread-preview mb-0 font-italic ${isActive ? 'light-color' : 'secondary-text-color'}`}>
              <Truncate lines={2} ellipsis={t('...')}>
                {t("No messages yet")}
              </Truncate>
            </p>
            }
            <div>
              <Fragment>
              {guests.length === 0 &&
              <p style={{marginTop: '3px'}} className={`thread-preview mb-0 ${isActive ? 'light-color' : 'private-green-color'}`}>
                <span style={styles.blockedBadge} className="d-inline-block mr-1">
                    {t("PRIVATE")}
                </span>
                <span className={`font-italic ${isActive ? 'hover-underline' : ''}`} onClick={isActive ? this.managePermissions.bind(this) : _.noop}>{t("No Guests have access yet.")}</span>
              </p>
              }
              {guests.map((guest) => {
                return (
                  <span key={guest.guest_uid} className="mr-1">
                    <UserBadge guest={guest} small={true} />
                  </span>
                )
              })}
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  iconStyle : {
    height: '48px',
    lineHeight: '48px',
    minWidth: '30px',
    textAlign: 'center'
  },
  attachmentIcon : {
    marginRight: '5px',
    verticalAlign : 'baseline',
    fontSize : '16px'
  },
  
  //duplicated in WorkspaceRow...consolidate this.
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT,
    backgroundColor : colors.SECONDARY_TEXT
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  iconRowStyle : {
    minWidth: '30px',
    textAlign: 'center'
  },
  blockedBadge : {
    backgroundColor : colors.PRIVATE_GREEN,
    color : colors.LIGHT,
    borderRadius: '3px',
    padding : '3px 5px'
  }
}

ThreadRow.propTypes = {
  row: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onDeleteThread : PropTypes.func.isRequired,
  isActive : PropTypes.bool.isRequired,
  guests : PropTypes.array.isRequired,
  onDocAttach : PropTypes.func.isRequired,
  
  onBeforePrintPreview: PropTypes.func.isRequired,
  onPrintPreviewError: PropTypes.func.isRequired,
  onAfterPrintPreview:PropTypes.func.isRequired,
  getPrintPreviewContents:PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    activeThread: state.workspace.activeThread,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...workspaceActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch)
  };
};


const dropTargetSpec = {
  canDrop(props, monitor) {
    // You can disallow drop based on props or item
    //const item = monitor.getItem()
    return true;
  },
  
  drop(props, monitor, component) {
    // Obtain the dragged item
    const item = monitor.getItem()
    
    log.log('threadRow onDocAttach', item.row, props.row);
    
    let docs = [{
      ...item.row,
      forum_id : item.src_forum_id,
      host_uid : item.src_host_uid,
    }]
    let result = DocAttach.buildThreadAttachResult(props.workspace.forum_id, props.row.chat_id, docs)
  
    props.onDocAttach(result);
    
    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return props.row;
  }
}

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  }
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(
  DropTarget(
    c.DRAG_DROP_TYPES.THREAD_DOC,
    dropTargetSpec,
    collect
  )(ThreadRow)));
