import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import _ from 'lodash'
import Button from '../partials/elements/Button';
import UserBadge from "../partials/badges/UserBadge";
import utils from "../../util/util";
import colors from "../../util/colors";
import {getMessageForError} from "../../util/errors";
import log from "../../util/log";
import sapi from "../../util/sapi";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class MesgEditWindow extends Component {
  
  constructor(props) {
    super(props);
  
    this.inputRef = React.createRef();
    
    this.state = {
      mesg : props.msg.mesg,
      detachDocIds : {}
    }
  }
  
  componentDidMount() {
    setTimeout(() => {
      this.inputRef.current.focus();
      
      setTimeout(() => {
        //log.log('input', this.inputRef.current.selectionStart , this.inputRef.current.selectionEnd)
        this.inputRef.current.selectionStart = this.state.mesg.length;
      })
    })
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  onDocDeleteClick(doc){
    log.log('doc delete', doc);
    
    let update = _.extend({}, this.state.detachDocIds);
    if(update[doc.doc_id]){
      update[doc.doc_id] = false;
    }
    else{
      update[doc.doc_id] = true;
    }
    this.setState({
      detachDocIds : update
    })
  }
  
  saveChanges(){
    let { workspace, dm, thread, t } = this.props;
    let { msg } = this.props.modalProps;
    let { mesg, detachDocIds } = this.state;
    
    let docIdsToDetach = [];
    _.each(_.keys(detachDocIds), (doc_id) => {
      if(detachDocIds[doc_id]){
        docIdsToDetach.push(doc_id);
      }
    })
    
    if(thread){
      sapi.Threads.editMessage(workspace.forum_id, workspace.host_uid, thread.chat_id, msg.mesg_id, mesg, docIdsToDetach)
        .then((res) => {
          log.log('edit message res', res);
          this.closeModal(res);
        })
        .catch((err) => {
          log.error('error saving message', err);
          this.props.showAlert(t('Error saving message'), getMessageForError(err, t))
        })
    }
    else if(dm){
      sapi.DM.editMessage(dm.guest_uid, msg.mesg_id, mesg, docIdsToDetach)
        .then((res) => {
          log.log('edit message res', res);
          this.closeModal(res);
        })
        .catch((err) => {
          log.error('error saving message', err);
          this.props.showAlert(t('Error saving message'), getMessageForError(err, t))
        })
    }
  }
  
  hasDetachedDocIds(){
    log.log('hasDetached doc ids', this.state.detachDocIds)
    let foundDetached = false;
    _.each(_.keys(this.state.detachDocIds), (doc_id) => {
      if(this.state.detachDocIds[doc_id]){
        foundDetached = true;
      }
    })
    return foundDetached;
  }
  
  renderDoc(doc){
    let { t } = this.props;
    let { detachDocIds } = this.state;
    
    if(doc.isPending){
      return null;
    }
    
    let renderDocControls = !!doc.label;
    
    if(!renderDocControls){
      return (
        <div key={doc.doc_id}>
          <div style={styles.docDeletedWrap}>
            {t("(Document Deleted)")}
          </div>
        </div>
      )
    }
    
    let detaching = detachDocIds[doc.doc_id];
    
    return (
      <div key={doc.doc_id}>
        <div style={{...styles.docControlWrap, ...{
            border : `1px solid ${detaching ? colors.SECONDARY_TEXT : colors.PRIMARY}`,
            color : detaching ? colors.SECONDARY_TEXT : colors.PRIMARY
          }}}>
          <i className="icon ion-document-text d-inline-block align-baseline"
             style={{
               fontSize: '26px',
               width: '21px',
               marginLeft: '8px',
             }}/>
          {!detaching &&
          <span className="msg-edit-doc d-inline-block auto-ellipsis align-text-bottom">
            {doc.label}
          </span>
          }
          {detaching &&
          <span className="msg-edit-doc d-inline-block auto-ellipsis align-text-bottom secondary-text-color">
            {t("Document will be detached")}
          </span>
          }
          <i onClick={this.onDocDeleteClick.bind(this, doc)}
             className={`icon ${detaching ? 'ion-ios-refresh-empty' : 'ion-ios-close'} d-inline-block has-pointer align-baseline msg-edit-doc-delete`}
             style={{
               color : colors.PRIMARY,
               fontSize: '26px',
               width: '21px',
               marginRight: '8px',
               marginLeft: '5px'
             }}/>
        </div>
      </div>
    )
  }
  
  render() {
    let {modalProps, accountInfoGuest, detachDocIds, t} = this.props;
    let { msg } = modalProps;
    let { mesg } = this.state;
  
    let time = utils.getMomentDate(msg.mesg_date).format('h:mm A');
  
    let rowStyle = {backgroundColor : colors.THREAD_COLOR_YOU};
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Edit Message")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
        
          <div className="d-flex">
            <div className="mr-2">
              <UserBadge guest={accountInfoGuest}
                         overrideColor={colors.PRIMARY}/>
            </div>
            <div className="flex-grow-1 msg-edit-block" style={{...styles.messageBlock, ...rowStyle}}>
              <p className="d-inline-block mb-0" style={{fontWeight: '600'}}>
                {accountInfoGuest.first_name} {accountInfoGuest.last_name}
                <span style={styles.blockTime}>
                  [{time}]
                </span>
              </p>
              <div>
                <textarea value={mesg}
                          rows={5}
                          ref={this.inputRef}
                          onChange={(evt) => this.setState({mesg : evt.target.value})}
                          className="no-resize mesg-edit-textarea form-control" />
              </div>
              <div>
                {_.map(msg.docs, (doc) => {
                  return this.renderDoc(doc);
                })}
              </div>
            </div>
          </div>
  
          <p className="mb-0 text-muted pt-1" style={{paddingLeft: '58px', lineHeight: '16px'}}>
            <small>
              {t("Note:  Message editing is not destructive.  Previous versions of messages and detached documents are still available to thread participants in message history.")}
            </small>
          </p>
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'}
                  disabled={mesg === msg.mesg && !this.hasDetachedDocIds()}
                  onClick={this.saveChanges.bind(this, true)}>{t("Save")}</Button>
        </div>
      </div>
    )
  }
}

const styles = {
  messageBlock : {
    padding: '10px',
    textAlign: 'left',
    borderRadius : '5px'
  },
  blockTime : {
    fontSize : '10px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    paddingLeft : '5px'
  },
  docControlWrap: {
    borderRadius: '20px',
    padding: '6px 3px 5px 3px',
    lineHeight: '26px',
    marginBottom: '8px',
    display: 'inline-block',
    maxWidth: '100%'
  },
  docDeletedWrap: {
    color: colors.SECONDARY_TEXT,
    padding: '6px 3px 5px 3px',
    lineHeight: '26px',
    marginBottom: '8px',
    display: 'inline-block',
    maxWidth: '100%'
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
    workspace : state.workspace.workspace
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};

MesgEditWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(MesgEditWindow));
