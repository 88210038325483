import Promise from "bluebird";
import log from "./log";
import _ from "lodash";
import { getErrorMessage, getMessageForError, isKnownVFError} from "./errors";

const stripeHelper = {
  getMessageForStripeOrVfError(err, t){
  
    //stripe error:
    // code: "payment_intent_authentication_failure"
    // doc_url: "https://stripe.com/docs/error-codes/payment-intent-authentication-failure"
    // message: "We are unable to authenticate your payment method. Please choose a different payment method and try again."
    // payment_intent: {id: "pi_1Ha6wpFJy4l0f3VY55nKUyoW", object: "payment_intent", amount: 900, canceled_at: null, cancellation_reason: null, …}
    // payment_method: {id: "pm_1Ha6woFJy4l0f3VYu2cbYY8u", object: "payment_method", billing_details: {…}, card: {…}, created: 1602193610, …}
    // type: "invalid_request_error"
  
    //This logic might be a little unnecessary.  I wanted to make sure we preserved the existing logic as closely
    //as possible, which means first checking for a vf error first, and then checking for a stripe error.
    //if an error is in neither place, then just return getMessageForError's default.
    let errorMessage = "";
    if(isKnownVFError(err)){
      errorMessage = getMessageForError(err, t);
    }
    else{
      let stripeErrMessage = _.get(err, 'message', null);
      if(stripeErrMessage){
        errorMessage = stripeErrMessage;
      }
      else{
        errorMessage = getMessageForError(err, t);
      }
    }
    return errorMessage;
  },
  
  handleStripe3DAuth(stripe, client_secret, payment_method_id, t){
    return new Promise((resolve, reject) => {
      stripe.confirmCardPayment(client_secret, {
          payment_method : payment_method_id
        })
        .then((res) => {
          log.log('confirmCardPayment', res);
          const {error, paymentIntent} = res;
          
          if(error && !_.isEmpty(error)){
            reject(error);
          }
          else if(!paymentIntent || !paymentIntent.id){
            reject(getErrorMessage("APP_UNKNOWN_ERROR", t))
          }
          else{
            // setupIntent:
            //   cancellation_reason: null
            //   client_secret: "seti_1HXASoFJy4l0f3VYMYLMDL39_secret_I7PH8A7kGapTusa1AcosDaJXL09ZxVg"
            //   created: 1601492142
            //   description: null
            //   id: "seti_1HXASoFJy4l0f3VYMYLMDL39"
            //   last_setup_error: null
            //   livemode: false
            //   next_action: null
            //   object: "setup_intent"
            //   payment_method: "pm_1HXASmFJy4l0f3VYN0yP3e7q"
            //   payment_method_types: ["card"]
            //   status: "succeeded"
            //   usage: "off_session"
            resolve({
              payment_method_id : _.get(paymentIntent, 'payment_method'),
              pi_id : _.get(paymentIntent, 'id')
            })
          }
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
}

export default stripeHelper;
