import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';

class ValidationErrors extends PureComponent {
  
  render() {
    let { errors } = this.props;
    
    return (
      <Fragment>
        {errors && errors.length > 0 &&
        <div className="alert alert-danger" role="alert">
          {errors.map((err, i) =>
            <div key={i}>
              {err}
            </div>
          )}
        </div>
        }
      </Fragment>
    )
  }
}

ValidationErrors.propTypes = {
  errors: PropTypes.array.isRequired,
}

export default ValidationErrors;
