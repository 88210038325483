import React, {Component} from 'react';

class EmptyState extends Component {
  
  render() {
    let {children} = this.props;
    
    return <div>{children}</div>
  }
}

export default EmptyState;