import c from '../util/const';
import enums from '../util/enums'
import PdfPreview from "../components/partials/pdf-preview/PdfPreview";
import _ from 'lodash';
import log from "../util/log";
import PdfWritingSvc from "../helpers/pdf-writing-svc";

let initialState = {
  //data from the server
  rawPdf: null,
  
  //pdf js stuff
  pdf: null,
  pages: null,
  pdfLoadErr : null,
  
  //pdf-lib stuff
  pdfWriter : null,
  pdfWriterLoadErr : null,
  
  //global window status
  // scale: 1,
  windowSigningState: enums.WINDOW_SIGNING_STATUS.NONE,
  
  //vf parameters
  forum_id : null,
  host_uid: null,
  doc_id: null,
  chat_id: null,
  dm : null,
  thread : null,
  doc_info : null,
  
  
  //v2 stuff
  signatureRequestData: null,
  signatureRequestOverlays: [],
  signatureRequestOverlayLookup: {},
  isEditingSignatureRequest : false,
  isFulfillingSignatureRequest : false,
  confirmedSignatureCount : 0,
  
  //v1 signing only
  signatureIds: [],
  activeOverlays: [],
  invalidOverlays: [],
  hasCommittedSignatures: false,
  hasValidV1Signing : false,
  pdfChangesMade : false,
}

const pdfPreview = (state = initialState, action) => {
  switch (action.type) {
    
    case c.actions.pdf.initialize:
      log.log('initialize called in reducer')
      return {
        ...state,
        forum_id : action.forum_id,
        host_uid: action.host_uid,
        doc_id: action.doc_id,
        chat_id: action.chat_id,
        pdfWriter : new PdfWritingSvc()
      }
    
    case c.actions.pdf.setRawPdf:
      return {
        ...state,
        rawPdf: action.rawPdf
      }
  
    case c.actions.pdf.setPdfJsModel:
      return {
        ...state,
        pdf: action.pdf
      }
  
    case c.actions.pdf.setPdfJsPages:
      return {
        ...state,
        pages: action.pages
      }
  
    case c.actions.pdf.setPdfJsLoadErr:
      return {
        ...state,
        pdfLoadErr: action.pdfLoadErr
      }
  
    case c.actions.pdf.setPdfWriterLoadErr:
      return {
        ...state,
        pdfWriterLoadErr: action.pdfWriterLoadErr
      }
      
    case c.actions.pdf.loadSignatureRequestData:
      return {
        ...state,
        isEditingSignatureRequest : action.isEditingSignatureRequest,
        isFulfillingSignatureRequest : action.isFulfillingSignatureRequest,
        signatureRequestData : action.signatureRequestData
      }
      
    case c.actions.pdf.updateSignatureRequest:
      
      let signatureRequestDataUpdate = _.extend({}, state.signatureRequestData);
      signatureRequestDataUpdate.smsNumber = action.smsNumber;
      signatureRequestDataUpdate.terms = action.terms;
      
      return {
        ...state,
        signatureRequestData : signatureRequestDataUpdate
      }
      
    case c.actions.pdf.updateSignatureRequestOverlays:
      return {
        ...state,
        signatureRequestOverlays : action.signatureRequestOverlays,
        signatureRequestOverlayLookup : action.signatureRequestOverlayLookup,
        confirmSignatureCount : action.confirmSignatureCount
      }
      
    case c.actions.pdf.setEditingSignatureRequest:
      return {
        ...state,
        isEditingSignatureRequest: action.isEditingSignatureRequest
      }
  
    case c.actions.pdf.updateV1SignatureOverlays:
      log.log('updateV1 sig overlays', action);
      return {
        ...state,
        signatureIds : action.signatureIds
      }
  
    case c.actions.pdf.updateV1ActiveOverlays:
      return {
        ...state,
        activeOverlays : action.activeOverlays
      }
  
    case c.actions.pdf.updateV1InactiveOverlays:
      return {
        ...state,
        invalidOverlays : action.invalidOverlays
      }
  
    case c.actions.pdf.updateV1HasCommittedSignatures:
      return {
        ...state,
        hasCommittedSignatures : action.hasCommittedSignatures
      }
  
    case c.actions.pdf.updateV1HasValidSigning:
      return {
        ...state,
        hasValidV1Signing : action.hasValidV1Signing
      }
  
    case c.actions.pdf.updateV1PdfChangesMade:
      return {
        ...state,
        pdfChangesMade : action.pdfChangesMade
      }
      
    case c.actions.pdf.resetV1SigningState:
      return {
        ...state,
        signatureIds: [],
        activeOverlays: [],
        invalidOverlays: [],
        hasCommittedSignatures: false,
        hasValidV1Signing : false,
        pdfChangesMade : false,
      }
      
    case c.actions.pdf.setWindowSigningMode:
      return {
        ...state,
        windowSigningState : action.mode
      }
      
    case c.actions.pdf.cleanup:
      return {...initialState}
      
    default:
      return state;
  }
}

export default pdfPreview;
