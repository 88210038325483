import request from 'superagent'
import sapi from "../util/sapi";

import _ from 'lodash';
import Promise from 'bluebird';

class PdfFontLoader {
  
  loadedFonts = {};
  fontsHaveBeenLoaded = false;
  
  fontPathLookup = {
    'La Belle Aurore' : "/fonts/la_belle_aurore/LaBelleAurore.ttf"
  }
  
  fontsAreLoaded() {
    return this.fontsHaveBeenLoaded;
  }
  
  loadFonts() {
    let base = process.env.PUBLIC_URL;
    let promises = [];
    _.each(_.keys(this.fontPathLookup), (key) => {
      promises.push(this.loadFont(key, base + this.fontPathLookup[key]))
    })
    
    return Promise.all(promises)
      .then((res) => {
        this.fontsHaveBeenLoaded = true;
      })
  }
  
  loadFont(font, url) {
    return request
      .get(url)
      .responseType('arraybuffer')
      .then((res) => {
        return new Uint8Array(res.body);
      })
      .then((fontRes) => {
        this.loadedFonts[font] = fontRes;
      })
  }
  
  getFontData(font) {
    return this.loadedFonts[font];
  }
}

export default PdfFontLoader;
