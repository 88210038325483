import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";

import SignatureRequest from '../../../models/SignatureRequest'
import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import UserBadge from "../badges/UserBadge";
import {withRouter} from "react-router-dom";
import utils from "../../../util/util";
import colors from "../../../util/colors";
import Autolinker from "autolinker";
import modalActions from "../../../actions/modal-actions";
import c from "../../../util/const";
import PendingDocRow from "../docs/PendingDocRow";
import ThreadDoc from "./ThreadDoc";
import UploadingThreadDoc from "../docs/UploadingThreadDoc";
import workspaceActions from "../../../actions/workspace-actions";
import {getMessageForError} from "../../../util/errors";
import ProgressBar from "../elements/ProgressBar";
import { isMobile } from 'react-device-detect';
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class MessageBlock extends PureComponent {
  
  constructor(props){
    super(props);
    
    this.onDocClick = this.onDocClick.bind(this);
    
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    
    this.state = {
      hoveringMesgId : null
    }
  }
  
  getLink(token){
    let t = Autolinker.link( token.text, {
      replaceFn : ( match ) => {
      
        let attrs = {
          'rel' : 'nofollow',
          'target': '_blank',
          'href':  match.getAnchorHref()
        };
      
        let tag = new Autolinker.HtmlTag( {
          tagName : 'a',
          attrs   : attrs,
          innerHtml : match.getMatchedText()
        } );
      
        return tag;
      }
    } );
  
    return (
      <span key={token.id}
            style={styles.token}
            dangerouslySetInnerHTML={{__html: t}}/>
    )
  }
  
  onMouseEnter = (msg) => () => {
    if(!this.state.hoveringMesgId || msg && msg.mesg_id !== this.state.hoveringMesgId){
      this.setState({hoveringMesgId : msg.mesg_id})
    }
  }
  
  onMouseLeave(){
    this.setState({hoveringMesgId : null})
  }
  
  renderMessage(msg){
    let {accountInfoGuest, block, mesg_edit_flag, uploadProgress, activeUpload, t } = this.props;
    let { hoveringMesgId} = this.state;
    let isCurrentUser = accountInfoGuest.guest_uid === block.guest_uid;
    
    return (
      <div key={msg.mesg_id}
           onMouseEnter={this.onMouseEnter(msg)}
           onMouseLeave={this.onMouseLeave}>
        <span style={styles.message}>
          {msg.tokens.map((token) => {
            if(token.useHtml){
              return this.getLink(token);
            }
            else{
              return (
                <span key={token.id} style={styles.token}>
                  {token.text}
                </span>
              )
            }
          })}
          {msg.edited_flag && msg.mesg.length > 0 &&
          <span className="pl-1" style={styles.editLink}>
            (<a className="hover-underline" onClick={() => this.props.onMesgHistoryClick(msg)}>{t("edited")}</a>)
          </span>
          }
          {msg.edited_flag && msg.mesg.length === 0 &&
          <span className="pl-1" style={styles.editLink}>
            (<a className="hover-underline"  onClick={() => this.props.onMesgHistoryClick(msg)}>{t("removed")}</a>)
          </span>
          }
        </span>
        {msg.isPending && uploadProgress && activeUpload && activeUpload.transaction_id === msg.transaction_id &&
          <div>
            <ProgressBar percentProgress={uploadProgress.percent}/>
          </div>
        }
        {msg.docs && msg.docs.length > 0 &&
        <>
          <div />
          <span style={styles.message}>
          {msg.docs.map((doc) => {
            return this.renderDoc(msg, doc)
          })}
        </span>
        </>
        }
        <span className={`${(mesg_edit_flag && isCurrentUser && (isMobile || hoveringMesgId === msg.mesg_id)) ? '' : 'invisible'}`} style={styles.editIcon}>
          <i onClick={() => this.props.onMesgEditClick(msg)} className="icon ion-edit align-middle mesg-edit-icon"/>
        </span>
      </div>
    
    )
  }
  
  onDocClick(doc){
    this.props.docClick(doc);
  }
  
  renderDoc(msg, doc) {
    let docInfo = this.props.findDocInfo(doc.doc_id);
    return (
      <Fragment key={doc.doc_id}>
        <ThreadDoc doc={doc}
                   msg={msg}
                   docInfo={docInfo}
                   docClick={this.onDocClick}
                   findInParticipants={this.props.findGuestInActiveThreadParticipants}
                   dm={this.props.dm}
                   fulfillSignatureRequestClick={this.props.fulfillSignatureRequestClick}
                   editRequestClick={this.props.editSignatureRequestClick}
                   completeRequestClick={this.props.completeSignatureRequestClick} />
      </Fragment>
    )
  }
  
  render() {
    let { guest, block, accountInfoGuest } = this.props;
    
    let time = utils.getMomentDate(block.blockList[0].mesg_date).format('h:mm A');
    let isCurrentUser = accountInfoGuest.guest_uid === block.guest_uid;
    
    let userName = null;
    let rowStyle = null;
    if(isCurrentUser){
      userName = accountInfoGuest.first_name + ' ' + accountInfoGuest.last_name;
      rowStyle = {backgroundColor : colors.THREAD_COLOR_YOU}
    }
    else{
      userName = guest.first_name + ' ' + guest.last_name;
      rowStyle = {backgroundColor : colors.THREAD_COLOR_THEM}
    }
    
    return (
      <div className="d-flex" style={styles.row} ref={this.props.innerRef || null}>
        <Fragment>
          <div style={{...styles.iconWrapper, ...styles.leftIcon}}>
            {isCurrentUser && <UserBadge guest={accountInfoGuest} overrideColor={colors.PRIMARY} /> }
          </div>
          <div className="flex-grow-1 message-block-text" style={{...styles.messageBlock, ...rowStyle}}>
            <div style={{paddingBottom: '3px'}}>
              <div className="font-weight-bold d-inline-block">
                {userName}
              </div>
              <div className="d-inline-block" style={styles.blockTime}>
                [{time}]
              </div>
            </div>
            <div>
              {block.blockList.map((msg) => {
                return this.renderMessage(msg);
              })}
            </div>
          </div>
          <div style={{...styles.iconWrapper, ...styles.rightIcon}}>
            {!isCurrentUser && <UserBadge guest={guest} /> }
          </div>
        </Fragment>
      </div>
    )
  }
}

const styles = {
  row : {
    marginTop : '5px',
    marginBottom : '10px'
  },
  message: {
    marginBottom: '0px',
    maxWidth : '100%',
    userSelect : 'auto',
  },
  token: {
    maxWidth: '100%',
    whiteSpace : 'pre-wrap',
    overflow : 'hidden',
    // display : 'inline-block',
    wordWrap : 'break-word',
    userSelect : 'auto',
    pointerEvents: 'all',
    cursor: 'text'
  },
  messageBlock : {
    padding: '10px',
    textAlign: 'left',
    borderRadius : '5px'
  },
  leftIcon : {
    marginRight : '5px'
  },
  rightIcon : {
    marginLeft : '5px'
  },
  iconWrapper : {
    minWidth: `${UserBadge.MEDIUM_SIZE_WIDTH}px`,
    minHeight: `${UserBadge.MEDIUM_SIZE_WIDTH}px`,
  },
  blockTime : {
    fontSize : '10px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    paddingLeft : '5px'
  },
  linkButton : {
    whiteSpace : 'pre-line',
    wordWrap : 'break-word',
    textAlign : 'left',
    maxWidth: '100%',
    overflow: 'hidden',
    marginTop : '5px',
    marginBottom : '5px',
    marginRight : '5px'
  },
  editIcon : {
    fontSize : '13px',
    color : colors.DARK,
    verticalAlign : 'middle',
    paddingLeft : '5px'
  },
  editDeleteLink : {
    color : colors.SECONDARY_TEXT
  },
  editLink : {
    fontSize : '12px',
    color : colors.SECONDARY_TEXT,
    verticalAlign : 'middle',
    cursor: 'pointer'
  }
}

MessageBlock.propTypes = {
  dm : PropTypes.object,
  guest : PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  docClick : PropTypes.func.isRequired,
  mesg_edit_flag: PropTypes.bool,
  innerRef : PropTypes.func,
  
  onMesgHistoryClick : PropTypes.func,
  onMesgEditClick : PropTypes.func,
  findDocInfo : PropTypes.func.isRequired,
  
  editSignatureRequestClick : PropTypes.func.isRequired,
  completeSignatureRequestClick : PropTypes.func.isRequired,
  fulfillSignatureRequestClick : PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    accountInfoGuest : state.shared.accountInfoGuest,
    activeUpload : state.upload.activeUpload,
    uploadProgress : state.upload.uploadProgress,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    findGuestInActiveThreadParticipants : (guest_uid) => dispatch(workspaceActions.findGuestInActiveThreadParticipants(guest_uid))
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MessageBlock)));
