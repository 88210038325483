import React, {Component} from 'react';
import PropTypes from 'prop-types';

import browser from '../../../util/browser';

import dots from '../../../resources/three-dots.svg';

import ls from '../../../util/local-storage'
import c from '../../../util/const';
import log from "../../../util/log";
import _ from 'lodash';
import classNames from 'classnames';
import api from "../../../util/api";
import sapi from "../../../util/sapi";
import config from "../../../util/config";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withVFTranslation} from "../../../util/withVFTranslation";

class EndpointPicker extends Component {

    constructor(props){
        super(props);

        let savedEndpoint = ls.get(c.localstorage.endpoint);
        log.log('saved endpoint', savedEndpoint)
        this.state = {
            endpoint : savedEndpoint || config.prodEndpoint
        }
    }

    onSelectChange(evt){
        log.log('select change', evt.target.value);
        this.setState({endpoint : evt.target.value})

        ls.set(c.localstorage.endpoint, evt.target.value);
        api.setEndpoint(evt.target.value);
        sapi.setEndpoint(evt.target.value);
    }

    render() {
        let { t } = this.props;
        let { endpoint } = this.state;

        return (
            <div style={styles.wrapper}>
                <div className="form-inline">
                    <label className="light-color" style={styles.label}>{t("Endpoint")}</label>
                    <select className="form-control" value={endpoint} onChange={this.onSelectChange.bind(this)}>
                        { config.endpoints.map((item) => {
                            return <option key={item} value={item}>{item}</option>
                        })}
                    </select>
                </div>
            </div>
        )
    }
}

const styles = {
    wrapper : {
        position: 'absolute',
        top : '20px',
        right : '20px'
    },
    label : {
        paddingRight: '10px',
    }
}

export default withVFTranslation()(EndpointPicker);
