import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

class ProgressBar extends Component {
  
  render() {
    let value = _.get(this.props, 'percentProgress', 0).toFixed(0);
    
    return (
      <div className="progress">
        <div className="progress-bar progress-bar-striped bg-success"
             role="progressbar"
             style={{width: `${value}%`}}
             aria-valuenow={value}
             aria-valuemin="0"
             aria-valuemax="100"/>
      </div>
    )
  }
}

const styles = {

}

ProgressBar.propTypes = {
  percentProgress: PropTypes.number
}

export default ProgressBar
