import c from '../util/const';
import log from "../util/log";
import _ from "lodash";

let initialState = {
  workspace: null,
  threads: null,
  threadNotifyCount : 0,
  docs: null,
  docNotifyCount : 0,
  guests: null,
  activeThread: null,
  activeDoc : null,
  activeDocAssociatedThread: null,
  threadParticipantLookup: null,
  docParticipantLookup: null,
  activeDM : null,
  activeDMMessages: null,
  activeDMMessageBlocks: null,
  activeDMDocs: null,
  activeThreadMessages: null,
  activeThreadMessageBlocks: null,
  activeThreadDataDate: null,
  activeThreadDocs : null,
  activeThreadMesgEditFlag : false,
  logo: null
}

const workspace = (state = initialState, action) => {
  switch (action.type) {

    case c.actions.workspace.updateWorkspace:
      return {
        ...state,
        workspace: {...action.workspace}
      };

    case c.actions.workspace.setActiveThread:
      return {
        ...state,
        activeThread: action.thread
      }
  
    case c.actions.workspace.setActiveDM:
      return {
        ...state,
        activeDM: action.dm
      }
  
    case c.actions.workspace.setActiveDoc:
      return {
        ...state,
        activeDoc: action.doc
      }
      
    case c.actions.workspace.setActiveDocAssociatedThread:
      return {
        ...state,
        activeDocAssociatedThread : action.associatedThread
      }

    case c.actions.workspace.updateLogo:
      return {
        ...state,
        logo: action.data
      }

    case c.actions.workspace.updateDocs:
  
      let docNotifyCount = 0;
      _.each(action.docs, (chatBlock) => {
        _.each(chatBlock.docs, (doc) => {
          if(doc.notify_flag){
            docNotifyCount++;
          }
        })
      })
      
      return {
        ...state,
        docs: action.docs,
        docNotifyCount
      };

    case c.actions.workspace.updateGuests:
      return {
        ...state,
        guests: action.guests
      };

    case c.actions.workspace.updateThreads:
  
      let threadNotifyCount = 0;
      _.each(action.threads, (thread) => {
        if(thread.notify_flag){
          threadNotifyCount++;
        }
      })
      
      return {
        ...state,
        threads: action.threads,
        threadNotifyCount
      };
  
    case c.actions.workspace.updateThreadMessages:
      return {
        ...state,
  
        // ...(action.messages ? {activeThreadMessages: action.messages} : {}),
        // ...(action.messageBlocks ? {activeThreadMessageBlocks: action.messageBlocks} : {}),
        // ...(action.data_date ? {activeThreadDataDate: action.data_date} : {}),
        // ...(action.docs ? {activeThreadDocs: action.docs} : {}),
        // ...(action.mesg_edit_flag ? {activeThreadMesgEditFlag: action.mesg_edit_flag} : {}),
        
        activeThreadMessages: action.messages,
        activeThreadMessageBlocks: action.messageBlocks,
        activeThreadDataDate : action.data_date,
        activeThreadDocs : action.docs,
        activeThreadMesgEditFlag : action.mesg_edit_flag
      };
  
    case c.actions.workspace.updateActiveDMMessages:
      return {
        ...state,
        ...(action.messages ? {activeDMMessages: action.messages} : {}),
        ...(action.messageBlocks ? {activeDMMessageBlocks: action.messageBlocks} : {}),
        ...(action.docs ? {activeDMDocs: action.docs} : {}),
      };
  
    case c.actions.workspace.updateThreadParticipantLookup:
      return {
        ...state,
        threadParticipantLookup: action.lookup
      };
  
    case c.actions.workspace.updateDocParticipantLookup:
      return {
        ...state,
        docParticipantLookup: action.lookup
      };

    case c.actions.workspace.cleanup:
      return {...initialState}

    default:
      return state;
  }
}

export default workspace;
