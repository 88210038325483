import React, {Component} from 'react';
import PropTypes from 'prop-types'

import _ from 'lodash';
import classNames from 'classnames';

class Checkbox extends Component {
  
  constructor(props){
    super(props);
    
    this.state = {
      generatedId: null
    };
  }
  
  componentDidMount() {
    this.setState({generatedId : _.uniqueId('vf-checkbox-')})
  }
  
  onCheckChange(e){
    let { onChange } = this.props;
    
    if(onChange){
      onChange(e);
    }
  }
  
  render() {
    let {isChecked, label, rootCls, labelCls, inputCls} = this.props;
    let { generatedId } = this.state;
    
    return (
      <div className={classNames(rootCls, "custom-control custom-checkbox")}>
        <input type="checkbox"
               id={generatedId}
               checked={isChecked}
               onChange={this.onCheckChange.bind(this)}
               className={classNames(inputCls, "custom-control-input")}/>
        <label className={classNames(labelCls, "custom-control-label")} htmlFor={generatedId}>
          {label}
        </label>
      </div>
    )
  }
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  rootCls: PropTypes.string,
  labelCls: PropTypes.string,
  inputCls: PropTypes.string
}

export default Checkbox;
