import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import Promise from "bluebird";
import modalActions from "../../../../actions/modal-actions";
import _ from 'lodash';
import Account from "../../../pages/Account";

class FixSubscriptionCtrl extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {}
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  showStripeCheckout(){
    let { upgradePrice } = this.props;
    
    this.props.showStripeCheckout(
      '',
      upgradePrice * 100,
      (res) => {
      log.log('stripe checkout res', res);
      if(res){
        this.props.onSuccessfulPaymentMethod(res)
      }
    })
  }
  
  render() {
    let {
      accountInfo,
      stripeClass100,
      upgradeMessage,
      upgradePrice,
      btnText
    } = this.props;
    
    return (
      <div style={{
        ...Account.styles.rowContents,
        width: '30rem' //This weird style hack is for ie (of course)
      }} className={'mt-3 mb-3'}>
        
        <div className={'card-body'}>
          <div className={'row'}>
            <div className={'col'}>
              <div className={'alert alert-warning'}>
                {upgradeMessage}
              </div>
            </div>
          </div>
          
          <div className={'row mt-3'}>
            <div className={'col text-right'}>
  
              <Button onClick={this.showStripeCheckout.bind(this)}
                      className={'btn btn-primary mr-2'}>
                {btnText}
              </Button>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

FixSubscriptionCtrl.propTypes = {
  onRef: PropTypes.func,
  onSuccessfulPaymentMethod: PropTypes.func.isRequired,
  upgradeMessage : PropTypes.string.isRequired,
  btnText : PropTypes.string.isRequired,
  upgradePrice : PropTypes.number.isRequired
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo,
    stripeClass100: state.shared.stripeClass100,
    upgradePlans : state.shared.upgradePlans
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FixSubscriptionCtrl));
