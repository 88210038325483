import React, {Component} from 'react';
import PropTypes from 'prop-types'

class Image extends Component {
  
  constructor(props){
    super(props);
    
    this.state = { loaded: false };
  }
  
  onImgLoad() {
    this.setState({
      loaded : true
    })
  }
  
  render() {
    let { loaded } = this.state;
    let {src, alt, imgWidth, imgHeight, className, style} = this.props;
    
    let localStyles = {
      transition: 'opacity .3s ease-in-out'
    };
    //was having problems with this and safari.  img.onLoad() was not always getting called there.
    // if(loaded){
    //   localStyles.opacity = 1;
    // }
    // else{
    //   localStyles.opacity = 0;
    // }
  
    localStyles.maxWidth = '100%';
    localStyles.maxHeight = '100%';
    
    return (
      <img src={src}
           style={{...localStyles, ...style}}
           alt={alt}
           onLoad={this.onImgLoad.bind(this)}
           width={imgWidth || null}
           height={imgHeight || null}
           className={className}
      />
    )
  }
}

Image.propTypes = {
  src : PropTypes.string.isRequired,
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  alt: PropTypes.string.isRequired,
  style: PropTypes.object
}

export default Image;
