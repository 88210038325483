import c from '../util/const';
import SearchWindow from "../components/modals/SearchWindow";
import workspace from "../reducers/workspace";
import {getMessageForError} from "../util/errors";
import _ from 'lodash'
import log from "../util/log";

const modalActions = {
  
  show(type, props) {
  
    return (dispatch, getState) => {
      let modalCount = getState().modal.modals.length;
      if(modalCount === 0){
        document.body.classList.add('modal-open');
      }
      
      dispatch({
        type: c.actions.modal.show,
        modalType: type,
        modalProps : props
      })
    }
  },

  updateSize(id, isLarge){
    return (dispatch) => {
      dispatch({
        type: c.actions.modal.updateSize,
        id,
        isLarge
      })
    }
  },
  
  showConfirm(title, message, callback, okBtn, cancelBtn){
    return this.show(c.dialog.confirm, {
      callback,
      title,
      message,
      okBtn,
      cancelBtn
    })
  },

  showNewItem(title, labelText, placeholderText, addNewFn, callback){
    return this.show(c.modal.newItem, {
      title,
      labelText,
      placeholderText,
      addNewFn,
      callback
    })
  },
  
  showNewWorkspace(title, labelText, placeholderText, callback){
    return this.show(c.modal.newWorkspace, {
      title,
      labelText,
      placeholderText,
      callback
    })
  },
  
  showAgreeToTerms(terms, callback){
    return this.show(c.dialog.agree_to_terms, {
      callback,
      terms
    })
  },
  
  showSMSVerification(signatureRequest, callback){
    return this.show(c.modal.smsVerificationDialog, {
      callback,
      signatureRequest
    })
  },
  
  showAddSignaturesDialog(callback){
    return this.show(c.dialog.add_signatures, {
      callback
    })
  },
  
  showSearchContacts(searchString, contacts, callback){
    return this.show(c.modal.search, {
      context : SearchWindow.SEARCH_CONTEXT.CONTACTS,
      searchString,
      contacts,
      callback
    })
  },
  
  showSearchHome(searchString, workspaces, callback){
    return this.show(c.modal.search, {
      context : SearchWindow.SEARCH_CONTEXT.HOME,
      searchString,
      workspaces,
      callback
    })
  },
  
  showSearchWorkspace(searchString, workspace, workspaces, guests, threads, docs, callback){
    return this.show(c.modal.search, {
      context : SearchWindow.SEARCH_CONTEXT.WORKSPACE,
      searchString,
      workspace,
      workspaces,
      guests,
      threads,
      docs,
      callback
    })
  },
  
  showRename(title, oldName, renameFn, callback){
    return this.show(c.modal.renameItem, {
      title,
      oldName,
      renameFn,
      callback
    })
  },

  showAuthInfo(title, contents, callback){
    return this.show(c.modal.authInfo, {
      title,
      callback,
      contents
    })
  },

  showAlert(title, message, callback){
    return this.show(c.dialog.alert, {
      callback,
      title,
      message
    })
  },
  
  showPdfSubmit(title, suggestedFilename, thread, dm, callback){
    return this.show(c.dialog.pdf_submit, {
      callback,
      title,
      suggestedFilename,
      thread,
      dm
    })
  },
  
  showSubmitSignedDoc(title, suggestedFilename, showMesg, callback){
    return this.show(c.dialog.submit_signed_doc, {
      callback,
      title,
      suggestedFilename,
      showMesg
    })
  },

  showDocInfo(forum_id, host_uid, doc_id, callback){
    return this.show(c.modal.preview, {
      forum_id,
      host_uid,
      doc_id,
      callback
    })
  },
  
  showDocInfoDM(forum_id, host_uid, doc_id, dm, callback){
    return this.show(c.modal.preview, {
      forum_id,
      host_uid,
      doc_id,
      dm,
      callback
    })
  },
  
  showDocInfoThread(forum_id, host_uid, doc_id, thread, callback){
    return this.show(c.modal.preview, {
      forum_id,
      host_uid,
      doc_id,
      chat_id : thread.chat_id,
      thread,
      callback
    })
  },

  showManageObjectPermissionsWindow(object_id, label, object, object_type, workspace, callback){
    return this.show(c.modal.manageObjectPermissionsWindow, {
      object_id,
      object_type,
      object,
      label,
      workspace,
      callback
    })
  },
  
  showAddGuestWindow(callback){
    return this.show(c.modal.addGuestWindow, {
      callback
    })
  },
  
  showAddContactWindow(adminInfo, allowBulkImport, callback){
    return this.show(c.modal.addContactWindow, {
      adminInfo,
      allowBulkImport,
      callback
    })
  },
  
  showNotifications(callback){
    return this.show(c.modal.notifications, {
      callback
    })
  },

  showAddDocWindow(forum_id, dest_guest_uid, dest_chat_id, callback){
    return this.show(c.modal.addDoc, {
      forum_id,
      dest_guest_uid,
      dest_chat_id,
      callback
    })
  },
  
  showDownloadMultiple(forum_id, host_uid, selectedDocIds, flatDocList, callback){
    return this.show(c.modal.downloadMultiple, {
      forum_id,
      host_uid,
      selectedDocIds,
      flatDocList,
      callback
    })
  },

  showFulfillSignatureRequest(signatureRequestData, callback){
    return this.show(c.modal.pdfSignatureRequest, {
      signatureRequestData,
      doesSignatureRequestExist : true,
      callback
    })
  },
  
  editSignatureRequest(signatureRequestData, callback){
    return this.show(c.modal.pdfSignatureRequest, {
      signatureRequestData,
      doesSignatureRequestExist : true,
      callback
    })
  },
  
  completeSignatureRequest(signatureRequestData, callback){
    return this.show(c.modal.pdfSignatureRequest, {
      signatureRequestData,
      doesSignatureRequestExist : false,
      callback
    })
  },
  
  showEditSignatureRequestTerms(terms, smsNumber, callback){
    return this.show(c.modal.requestSignature, {
      isEditMode : true,
      terms,
      smsNumber,
      callback
    })
  },
  
  showRequestSignatureWindow(forum_id, chat_id, mesg_id, doc_id, filename, suggestedGuests, selectedGuest, terms, callback){
    return this.show(c.modal.requestSignature, {
      forum_id,
      chat_id,
      mesg_id,
      doc_id,
      filename,
      suggestedGuests,
      selectedGuest,
      terms,
      callback
    })
  },
  
  showDMRequestSignatureWindow(guest_uid, mesg_id, doc_id, filename, suggestedGuests, selectedGuest, terms, callback){
    return this.show(c.modal.requestSignature, {
      guest_uid,
      mesg_id,
      is_dm : true,
      doc_id,
      filename,
      suggestedGuests,
      selectedGuest,
      terms,
      callback
    })
  },
  
  showContactConfirm(headerText, contactInfo, bodyText, okBtn, cancelBtn, callback){
    return this.show(c.dialog.contactConfirm, {
      headerText,
      contactInfo,
      bodyText,
      okBtn,
      cancelBtn,
      callback
    })
  },
  
  showMemberInfoWindow(member, adminInfo, callback){
    return this.show(c.modal.memberInfoWindow, {
      adminInfo,
      member,
      callback
    })
  },
  
  showContactInfoWindow(guest_uid, callback){
    return this.show(c.modal.contactInfoWindow, {
      guest_uid,
      callback
    })
  },
  
  close(id) {
    return (dispatch, getState) => {
      let modalCount = getState().modal.modals.length;
      if(modalCount === 1){
        document.body.classList.remove('modal-open');
      }
      
      dispatch( {
        type: c.actions.modal.close,
        id
      })
    }
  },
  
  showGeneratedPasswordWindow(callback){
    return this.show(c.modal.generatedPasswordDialog, {callback});
  },
  
  showDocViewHistory(params, callback) {
    return this.show(c.modal.docViewHistory, {
      forum_id: params.forum_id || null,
      host_uid: params.host_uid || null,
      guest_uid: params.guest_uid || null,
      doc_id : params.doc_id,
      callback
    })
  },
  
  showEditThreadMessage(thread, msg, callback){
    return this.show(c.modal.mesgEditWindow, {
      thread,
      msg,
      callback
    })
  },
  
  showEditDMMessage(dm, msg, callback){
    return this.show(c.modal.mesgEditWindow, {
      dm,
      msg,
      callback
    })
  },
  
  showThreadMessageHistory(thread, msg, callback){
    return this.show(c.modal.mesgHistoryWindow, {
      thread,
      msg,
      callback
    })
  },
  
  showDMMessageHistory(dm, msg, callback){
    return this.show(c.modal.mesgHistoryWindow, {
      dm,
      msg,
      callback
    })
  },
  
  showPromoCodeWindow(callback){
    return this.show(c.modal.addPromoCodeDialog, {
      callback
    })
  },
  
  showTutorialWindow(callback){
    return this.show(c.modal.tutorialWindow, {
      callback
    })
  },
  
  showAttachDocToThreadWindow(initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, doc, callback){
    return this.show(c.modal.attachDocToThreadWindow, {
      initial_selection_forum_id,
      initial_selection_guest_uid,
      src_forum_id,
      src_host_uid,
      doc,
      callback
    })
  },
  
  showSigningMetadataWindow(forum_id, host_uid, dm_guest_uid, metadata, callback){
    return this.show(c.modal.signingMetadata, {
      forum_id,
      host_uid,
      dm_guest_uid,
      metadata,
      callback
    })
  },
  
  showContactUsWindow(callback){
    return this.show(c.modal.contactUsWindow, {
      callback
    })
  },
  
  showHelpResourceWindow(callback){
    return this.show(c.modal.helpResourceWindow, {
      callback
    })
  },
  
  showUpgradeDialog(upgradeType, callback){
    return this.show(c.modal.upgradeDialogNew, {
      callback,
      upgradeType
    })
  },
  
  showUpgradeDialogOrError(upgradeType, serverErr, t, callback){
    return (dispatch, getState) => {
      let contract = getState().shared.userContract;
    
      log.log('modal action upgrade check', getState().shared);
      if(contract && contract.upgrade && !_.isEmpty(contract.upgrade)){
        dispatch(this.show(c.modal.upgradeDialogNew, {
          upgradeType,
          callback
        }))
      }
      else{
        dispatch(this.showAlert(t('Limit Reached'), getMessageForError(serverErr, t), callback))
      }
    }
  },
  
  showStripeCheckout(description, termCostCents, callback){
    return (dispatch, getState) => {
      dispatch(this.show(c.modal.stripeCheckoutDialog, {
        description,
        termCostCents,
        callback
      }))
    }
  },
  
  showChangePasswordDialog(callback){
    return this.show(c.modal.changePassword, {
      callback
    })
  },
  
  cleanup(){
    return {
      type : c.actions.modal.cleanup
    }
  },
  
  mapToDispatch(dispatch) {
    return {
      updateSize: (id, isLarge) => dispatch(this.updateSize(id, isLarge)),
      showStripeCheckout: (description, termCostCents, callback) => dispatch(this.showStripeCheckout(description, termCostCents, callback)),
      showTutorialWindow: () => dispatch(this.showTutorialWindow()),
      showContactUsWindow: (cb) => dispatch(this.showContactUsWindow(cb)),
      showHelpResourceWindow: (cb) => dispatch(this.showHelpResourceWindow(cb)),
      showPromoCodeWindow: (cb) => dispatch(this.showPromoCodeWindow(cb)),
      showChangePasswordDialog: (cb) => dispatch(this.showChangePasswordDialog(cb)),
      showNewItem: (title, labelText, placeholderText, addNewFn, callback) => dispatch(this.showNewItem(title, labelText, placeholderText, addNewFn, callback)),
      showAttachDocToThreadWindow: (initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, doc, callback) => dispatch(this.showAttachDocToThreadWindow(initial_selection_forum_id, initial_selection_guest_uid, src_forum_id, src_host_uid, doc, callback)),
      showNewWorkspace: (title, labelText, placeholderText, callback) => dispatch(this.showNewWorkspace(title, labelText, placeholderText, callback)),
      showAddDocWindow: (forum_id, dest_guest_uid, dest_chat_id, callback) => dispatch(this.showAddDocWindow(forum_id, dest_guest_uid, dest_chat_id, callback)),
      showAddGuestWindow: (callback) => dispatch(this.showAddGuestWindow(callback)),
      showContactConfirm: (headerText, contactInfo, bodyText, okBtn, cancelBtn, callback) => dispatch(this.showContactConfirm(headerText, contactInfo, bodyText, okBtn, cancelBtn, callback)),
      showUpgradeDialogOrError: (upgradeType, serverErr, t, callback) => dispatch(this.showUpgradeDialogOrError(upgradeType, serverErr, t, callback)),
      showUpgradeDialog : (upgradeType, callback) => dispatch(this.showUpgradeDialog(upgradeType, callback)),
      showAlert: (title, msg, cb) => dispatch(this.showAlert(title, msg, cb)),
      showConfirm:(title, msg, cb, okBtn, cancelBtn) => dispatch(this.showConfirm(title, msg, cb, okBtn, cancelBtn)),
      showAgreeToTerms:(terms, cb) => dispatch(this.showAgreeToTerms(terms, cb)),
      showSMSVerification:(signatureRequest, cb) => dispatch(this.showSMSVerification(signatureRequest, cb)),
      showAddSignatures:(cb) => dispatch(this.showAddSignaturesDialog(cb)),
      showDocInfo: (forum_id, host_uid, doc_id, callback) => dispatch(this.showDocInfo(forum_id, host_uid, doc_id, callback)),
      showDocInfoDM: (forum_id, host_uid, doc_id, dm, callback) => dispatch(this.showDocInfoDM(forum_id, host_uid, doc_id, dm, callback)),
      showDocInfoThread: (forum_id, host_uid, doc_id, thread, callback) => dispatch(this.showDocInfoThread(forum_id, host_uid, doc_id, thread, callback)),
      showRename : (title, oldName, renameFn, callback) => dispatch(this.showRename(title, oldName, renameFn, callback)),
      showContactInfoWindow : (guest_uid, callback) => dispatch(this.showContactInfoWindow(guest_uid, callback)),
      showMemberInfoWindow : (member, adminInfo, callback) => dispatch(this.showMemberInfoWindow(member, adminInfo, callback)),
      showAddContactWindow : (adminInfo, allowBulkImport, callback) => dispatch(this.showAddContactWindow(adminInfo, allowBulkImport, callback)),
      showNotifications: (callback) => dispatch(this.showNotifications(callback)),
      showEditThreadMessage: (thread, msg, callback) => dispatch(this.showEditThreadMessage(thread, msg, callback)),
      showEditDMMessage: (dm, msg, callback) => dispatch(this.showEditDMMessage(dm, msg, callback)),
      showThreadMessageHistory: (thread, msg, callback) => dispatch(this.showThreadMessageHistory(thread, msg, callback)),
      showDMMessageHistory: (dm, msg, callback) => dispatch(this.showDMMessageHistory(dm, msg, callback)),
      showSearchContacts: (searchString, contacts, callback) => dispatch(this.showSearchContacts(searchString, contacts, callback)),
      showSearchHome: (searchString, workspaces, callback) => dispatch(this.showSearchHome(searchString, workspaces, callback)),
      showSearchWorkspace: (searchString, workspace, workspaces, guests, threads, docs, callback) => dispatch(this.showSearchWorkspace(searchString, workspace, workspaces, guests, threads, docs, callback)),
      showGeneratedPasswordWindow:(callback) => dispatch(this.showGeneratedPasswordWindow(callback)),
      showDocViewHistory:( params, callback) => dispatch(this.showDocViewHistory(params, callback)),
      showDownloadMultiple: (forum_id, host_uid, selectedDocIds, flatDocList, callback) => dispatch(this.showDownloadMultiple(forum_id, host_uid, selectedDocIds, flatDocList, callback)),
      showManageObjectPermissionsWindow: (object_id, label, object, object_type, workspace, callback) => dispatch(this.showManageObjectPermissionsWindow(object_id, label, object, object_type, workspace, callback)),
      showPdfSubmit: (title, suggestedFilename, thread, dm, cb) => dispatch(this.showPdfSubmit(title, suggestedFilename, thread, dm, cb)),
      showSubmitSignedDoc: (title, suggestedFilename, showMesg, cb) => dispatch(this.showSubmitSignedDoc(title, suggestedFilename, showMesg, cb)),
      showRequestSignatureWindow: (forum_id, chat_id, mesg_id, doc_id, filename, suggestedGuests, selectedGuest, terms, callback) => dispatch(this.showRequestSignatureWindow(forum_id, chat_id, mesg_id, doc_id, filename, suggestedGuests, selectedGuest, terms, callback)),
      editSignatureRequest : (signatureRequestData, callback)  => dispatch(this.editSignatureRequest(signatureRequestData, callback)),
      showEditSignatureRequestTerms : (terms, smsNumber, callback) => dispatch(this.showEditSignatureRequestTerms(terms, smsNumber, callback)),
      completeSignatureRequest : (signatureRequestData, callback)  => dispatch(this.completeSignatureRequest(signatureRequestData, callback)),
      showFulfillSignatureRequest : (signatureRequestData, callback)  => dispatch(this.showFulfillSignatureRequest(signatureRequestData, callback)),
      showSigningMetadataWindow : (forum_id, host_uid, dm_guest_uid, metadata, callback) => dispatch(this.showSigningMetadataWindow(forum_id, host_uid, dm_guest_uid, metadata, callback)),
      showDMRequestSignatureWindow: (guest_uid, mesg_id, doc_id, filename, suggestedGuests, selectedGuest, terms, callback) => dispatch(this.showDMRequestSignatureWindow(guest_uid, mesg_id, doc_id, filename, suggestedGuests, selectedGuest, terms, callback)),
    }
  },
}

export default modalActions;
