import c from '../util/const';
import sapi from "../util/sapi";

import Promise from 'bluebird';
import log from '../util/log';
import _ from 'lodash';
import {fontConstants} from "../util/font-constants";
import colors from "../util/colors";
import PdfPage from "../components/partials/pdf-preview/PdfPage";
import utils from "../util/util";

const utilityActions = {
  
  setWatermarkCanvasRef(ref){
    return {
      type : c.actions.utility.setWatermarkCanvasRef,
      ref
    }
  },
  
  resetWatermarkCanvas(watermarkCanvasRef){
    return new Promise((resolve, reject) => {
      
      watermarkCanvasRef.width  = utilityActions.WATERMARK_CANVAS_WIDTH;
      watermarkCanvasRef.height  = utilityActions.WATERMARK_CANVAS_HEIGHT;
  
      watermarkCanvasRef.style.width = watermarkCanvasRef.width + 'px';
      watermarkCanvasRef.style.height = watermarkCanvasRef.height + 'px';
      
      utils.waitForCondition(() => {
        return watermarkCanvasRef.height <= utilityActions.WATERMARK_CANVAS_HEIGHT && watermarkCanvasRef.width <= utilityActions.WATERMARK_CANVAS_WIDTH
      })
        .then(() => {
          resolve(true);
        })
      
    })
  },
  
  generateWatermarkImg(watermarkText) {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        this.resetWatermarkCanvas(getState().utility.watermarkCanvasRef)
          .then(() => {
            let watermarkCanvasRef = getState().utility.watermarkCanvasRef;
            let text = watermarkText;
            let sigFont = fontConstants.COURIER.familyName;
            let ctx = watermarkCanvasRef.getContext('2d');
            let outputScale = PdfPage.getOutputScale(ctx);
            ctx.setTransform(outputScale.sx,0,0,outputScale.sy,0,0);
            ctx.font = `12px ${sigFont}`;
            let measuredText = ctx.measureText(text);
            log.log('measure text', measuredText, outputScale);
  
            watermarkCanvasRef.width  = measuredText.width + 4;
            watermarkCanvasRef.height  = utilityActions.WATERMARK_CANVAS_HEIGHT;
  
            watermarkCanvasRef.style.width = watermarkCanvasRef.width + 'px';
            watermarkCanvasRef.style.height = watermarkCanvasRef.height + 'px';
  
            watermarkCanvasRef.width *= outputScale.sx;
            watermarkCanvasRef.height *= outputScale.sy;
  
            setTimeout(() => {
              let ctx = watermarkCanvasRef.getContext('2d');
              ctx.setTransform(outputScale.sx,0,0,outputScale.sy,0,0);
              ctx.clearRect(0, 0, watermarkCanvasRef.width, watermarkCanvasRef.height);
    
              ctx.fillStyle = colors.LIGHT;
              ctx.fillRect(0, 0, watermarkCanvasRef.width, watermarkCanvasRef.height);
    
              ctx.fillStyle = colors.DARK;
              ctx.font = `12px ${sigFont}`;
              ctx.fillText(text, 2, 20);
    
              log.log('returning image dimensions', watermarkCanvasRef.width, watermarkCanvasRef.height);
    
              resolve({
                width : watermarkCanvasRef.width / outputScale.sx,
                height : watermarkCanvasRef.height / outputScale.sy,
                data : watermarkCanvasRef.toDataURL('image/png', 1.0)
              });
            })
          })
        
      })
    }
  },
  
  cleanup (){
    return {
      type : c.actions.utility.cleanup
    }
  }
}

utilityActions.WATERMARK_CANVAS_WIDTH = 275;
utilityActions.WATERMARK_CANVAS_HEIGHT = 30;

export default utilityActions;
