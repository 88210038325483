import React, {Component} from 'react';
import {connect} from "react-redux";
import log from "../../util/log";
import PropTypes from 'prop-types';
import sapi from "../../util/sapi";
import _ from 'lodash';
import Button from '../partials/elements/Button';
import colors from "../../util/colors";
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement, } from '@stripe/react-stripe-js';
import Promise from "bluebird";
import ValidationErrors from "../partials/components/ValidationErrors";
import filters from "../../helpers/filters";
import { getErrorMessage, getMessageForError} from "../../util/errors";
import sharedActions from "../../actions/shared-actions";
import accountActions from "../../actions/account-actions";
import {BiCheck} from "react-icons/all";
import UpgradeDialog from "./UpgradeDialog";
import modalActions from "../../actions/modal-actions";
import utils from "../../util/util";
import api from "../../util/api";
import stripeHelper from "../../util/stripe-helper";
import Loading from "../partials/util/Loading";
import {withVFTranslation} from "../../util/withVFTranslation";

class UpgradeDialogNew extends Component {
  
  BASIC_PLAN_ID = 'basic-plan';
  VF_PRO_PLAN_ID = 'vf-pro-plan';
  
  _isMounted = false;
  
  constructor(props) {
    super(props);
    
    log.log('UpgradeDialogNew load', props);
    
    this.state = {
      loading : false,
      stripePromise : null,
      validationErr : [],
      currentPlanId : null,
      selectedPlanId : null,
      isCancelling : false,
      
      isHandlingSubmitButtonClick : false,
      
      waitForClassInfoToBeReady : false,
      
      isFetchingThings : false,
      limitMessage : null,
      userContract : _.extend({}, props.userContract), //Load this into state, so that when we update we don't re-render things.
      
      promo_code: '',
      valid_code_deets: null,
      coupon_code_err: null,
    }
  }
  
  componentDidMount() {
    this._isMounted = true;
    this.setState({
      stripePromise : loadStripe(this.props.upgradePlans.pub_key)
    })
    
    this.loadStripeClass1();
    
    let {
      upgradeType
    } = this.props;
    let {
      userContract
    } = this.state;
    
    log.log('upgrade dialog mounted', userContract);
    
    if(upgradeType){
      this.setState({
        limitMessage : this.getUpgradeMessage()
      })
    }
    
    if(userContract.current.class_id === 1){
      //Easy case, a non-pro updating to a pro account.
      this.setState({
        currentPlanId : this.BASIC_PLAN_ID,
        selectedPlanId : this.VF_PRO_PLAN_ID
      })
    }
    else if(userContract.current.class_id > 100){
      //then we need to deal with inst stuff.  This could be an upgrade or a cancel.
      let hasUpgrade = userContract.upgrade && !_.isEmpty(userContract.upgrade);
      if(hasUpgrade){
        //then you're coming in as a non-paying pro.  Probabaly a part of an inst.
        //We want to omit the 'currentPlan' case, and just show the upgrade path.
        this.setState({
          selectedPlanId : this.VF_PRO_PLAN_ID
        })
      }
      else{
        //then you're canceling from a pro plan back to the basic plan
        this.setState({
          currentPlanId : this.VF_PRO_PLAN_ID,
          selectedPlanId : this.BASIC_PLAN_ID,
          isCancelling : true
        })
      }
    }
    else{
      //Cancel subscription case.  No inst stuff, just straight cancel from 100 to 1
      this.setState({
        currentPlanId : this.VF_PRO_PLAN_ID,
        selectedPlanId : this.BASIC_PLAN_ID,
        isCancelling : true
      })
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
    
    this.updateAccountData();
  }
  
  loadStripeClass1(){
    this.setState({isFetchingThings : true})
    sapi.Stripe.class_1()
      .then(data => {
        this.setState({
          isFetchingThings : false,
          stripeClass1: data.data
        })
      })
  }
  
  getUpgradeMessage(){
    let { upgradeType, t } = this.props;
    let {
      userContract
    } = this.state;
    let contractUpgrade = _.get(userContract, 'upgrade', {});
    
    if(upgradeType === UpgradeDialog.UPGRADE_TYPES.WORKSPACE){
      return t("You've reached your limit on Workspaces.  To increase your limits, upgrade to the Professional Plan for $") + contractUpgrade.term_cost + t(" per month.")
    }
    else if(upgradeType === UpgradeDialog.UPGRADE_TYPES.DOCS){
      return t("You've reached your limit on Documents.  To increase your limits, upgrade to the Professional Plan for $") + contractUpgrade.term_cost + t(" per month.")
    }
    else if(upgradeType === UpgradeDialog.UPGRADE_TYPES.STORAGE){
      return t("You've reached your limit on storage.  To increase your limits, upgrade to the Professional Plan for $") + contractUpgrade.term_cost + t(" per month.")
    }
    else if(upgradeType === UpgradeDialog.UPGRADE_TYPES.SIGNING){
      return t("Only Verifyle Pros can request signatures.  To enable signing, upgrade to the Professional Plan for $") + contractUpgrade.term_cost + t(" per month.")
    }
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    log.log('stripe checkout close modal', res);
    close(res);
  }
  
  updateAccountData() {
    return Promise.all([
      this.props.updateAccountInfo(),
      this.props.updateUserContractInfo(),
      this.props.updateStripePlan100(),
      this.props.updateLogo(),
      this.props.updateAvailablePlans(),
      this.props.updateStripeData()
    ])
  }
  
  pollAvailablePlansUntilReadyForSubscribing(){
    if(!this._isMounted){
      return;
    }
    
    this.setState({
      waitForClassInfoToBeReady : true
    })
    let pollAvailablePlans = () => {
      return new Promise((resolve, reject) => {
        this.props.updateAvailablePlans()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err);
          })
      })
    }
  
    return pollAvailablePlans()
      .then(() => {
        let { upgradePlans } = this.props;
        let classInfo = _.get(upgradePlans, 'class_info');
        if(!classInfo || _.isEmpty(classInfo)){
          return utils.waitFor(500)
            .then(() => {
              return this.pollAvailablePlansUntilReadyForSubscribing();
            })
        }
        else{
          this.setState({waitForClassInfoToBeReady : false})
          return true;
        }
      })
  }
  
  cancelSubscription() {
    let {
      userContract
    } = this.state;
    let {
      showConfirm,
      stripeData,
      t
    } = this.props;
  
    this.setState({loading:true})
    sapi.Stripe.unsubscribe()
      .then((res) => {
        log.log('cancel res', res);
        return this.updateAccountData();
      })
      .then(() => {
        this.closeModal(true);
      })
      .catch((err) => {
        log.log('error canceling', err);
        this.props.showAlert(t("Error cancelling subscription"), getMessageForError(err, t));
      })
      .finally(() => {
        this.setState({loading:false})
      })
    
    // showConfirm('Are you sure?',
    //   'Upon cancelling you will immediately lose your Verifyle Pro capabilities and will be subject to reduced capacity limits.  Click OK to cancel now.',
    //   (res) => {
    //     if (res) {
    //
    //     }
    //   })
  }
  
  onSubscribeSuccess(res){
    log.log('subscribe success', res);
    return this.updateAccountData()
      .then(() => {
        this.closeModal(res);
      })
  }
  
  onSubscribeFailure(err){
    let { t } = this.props;
    log.log('subscribe error', err);
    
    this.props.showAlert(t("Error processing card"), stripeHelper.getMessageForStripeOrVfError(err, t));
  
    return this.updateAccountData()
      .then((res) => {
        
        //In payment failure cases, we need to refresh stripe/2/avail, and detect
        //The case where class_info has been nulled.  If it has, we need to wait for it to repopulate.
        //Until this time, the backend is not ready to subscribe.
        //This is really just a problem for stripe 3d auth failure cases.  In that case
        //we know about the failure before the backend does, which means we need to wait for it.
        //bug 2704
        let { upgradePlans } = this.props;
        let classInfo = _.get(upgradePlans, 'class_info');
        log.log('after update account data', upgradePlans, classInfo);
        if(!classInfo || _.isEmpty(classInfo)){
          log.log('doing polling thing')
          this.pollAvailablePlansUntilReadyForSubscribing();
        }
      })
  }
  
  onSuccessfulPaymentMethod(paymentMethodId, toClassId, couponId, stripe){
    log.log('onSuccessfulPaymentMethod', arguments);
    let { t } = this.props;
    
    sapi.Stripe.subscribeV2(paymentMethodId, toClassId, couponId)
      .then((res) => {
        log.log('subscribe res', res);
        return this.onSubscribeSuccess(res);
      })
      .then(() => {
        this.setState({
          loading:false,
          isHandlingSubmitButtonClick : false
        })
      })
      .catch((err) => {
        let stripeClientSecret = _.get(err, 'body.data.client_secret');
        if(err && err.name === 'APP_PARTIAL_FAIL' && stripeClientSecret){
          log.warn('got partial fail', err);
          stripeHelper.handleStripe3DAuth(stripe, stripeClientSecret, paymentMethodId, t)
            .then((res) => {
              //upgrade is finished as soon as 3d auth returns.
              return this.onSubscribeSuccess(res);
            })
            .catch((err) => {
              this.onSubscribeFailure(err);
            })
            .finally(() => {
              this.setState({
                loading:false,
                isHandlingSubmitButtonClick : false
              })
            })
        }
        else{
          this.setState({
            loading:false,
            isHandlingSubmitButtonClick : false
          })
          this.onSubscribeFailure(err);
        }
      })
  }
  
  onSubmitButtonClick(stripe, elements, evt){
    evt.preventDefault();
    
    let { t } = this.props;
    if(this.state.isHandlingSubmitButtonClick){
      log.log('prevent stripe submit');
      return;
    }
    
    if(this.state.waitForClassInfoToBeReady){
      this.props.showAlert(t("Just a moment"), t("Sorry, we're updating our records. Please wait a moment and try again."));
      return;
    }
  
    this.setState({isHandlingSubmitButtonClick : true});
    
    let { postal } = this.state;
    stripe.createPaymentMethod({
      type: 'card',
      
      //This is a weird parameter, and is NOT documented in the stripe docs.
      //You don't need to pass the rest of the card elements into the card.
      //You just give stripe the first element and it will apparently detect the rest.  Weird guys.
      //https://github.com/stripe/react-stripe-elements/issues/482
      // card: elements.getElement(CardNumberElement),
      card: elements.getElement(CardElement),
      
      //This is an expected stripe field
      //We could pass more if we wanted.
      //https://stripe.com/docs/js/payment_methods/create_payment_method
      // billing_details: {
      //   address: {
      //     postal_code: postal,
      //   },
      // },
    })
      .then((res) => {
        const {error, paymentMethod} = res;
        log.log('payment method res', error, paymentMethod);
        
        if(error && !_.isEmpty(error)){
          // code: "invalid_number"
          // message: "Your card number is invalid."
          // type: "validation_error"
          this.setState({
            validationErr : [error.message],
            isHandlingSubmitButtonClick : false
          })
        }
        else if(!paymentMethod || !paymentMethod.id){
          //I'm not sure this is a real case.  I guarded against it because the docs did.
          this.setState({
            validationErr : [getErrorMessage("APP_UNKNOWN_ERROR", t)],
            isHandlingSubmitButtonClick : false
          })
        }
        else{
          this.setState({
            validationErr : [],
            loading:true
          });
          let { upgradePlans } = this.props;
          let {valid_code_deets} = this.state;
          let couponId = _.get(valid_code_deets, 'coupon.id');
          this.onSuccessfulPaymentMethod(paymentMethod.id, upgradePlans.class_info.class_id, couponId, stripe)
        }
      })
    
  }
  
  renderPlanPanel(){
    let {
      currentPlanId,
      selectedPlanId,
      isCancelling,
      userContract,
      stripeClass1,
      isFetchingThings
    } = this.state;
    let {
      stripeClass100,
      t
    } = this.props;
    
    if(isFetchingThings){
      return null;
    }
    
    //log.log('renderPlanPanel', userContract, stripeClass1, stripeClass100);
    let contractCurrent = null;
    let contractTarget = null;
    let currentLimitBlock = _.get(userContract, 'current', {});
    let upgradeLimitBlock = _.get(userContract, 'upgrade', {});
    
    if(isCancelling){
      if(currentLimitBlock.class_id === 100){
        contractCurrent = stripeClass100.verifyle;
        contractTarget = stripeClass1.verifyle;
      }
      else if(currentLimitBlock.class_id > 100){
        contractCurrent = currentLimitBlock;
        contractTarget = stripeClass1.verifyle;
      }
    }
    else{
      contractCurrent = currentLimitBlock;
      contractTarget = upgradeLimitBlock;
    }
    
    let plans = [];
    let currentPlan = null;
    
    let proPoints = [
      t("Unrivaled digital security"),
      t("Host up to ") + stripeClass100.verifyle['forum_max_total'] + t(" Workspaces"),
      t("Unlimited File Upload Size"),
      +(stripeClass100.verifyle['max_storage'] / 1073741824).toFixed(2) + t("GB of encrypted storage"),
      t("Customize your account with your logo"),
      t("Unlimited document signing"),
    ];
    let basicPoints = null;
    if(currentPlanId){
      basicPoints = [
        t("Unrivaled digital security"),
        t("Host up to ") + stripeClass1.verifyle['forum_max_total'] + t(" Workspaces"),
        t("Unlimited File Upload Size"),
        +(stripeClass1.verifyle['max_storage'] / 1073741824).toFixed(2) + t("GB of encrypted storage")
      ]
    }
    
    //This is mostly for the inst plan cases.
    //If they have class_id > 100, then this is a unpaid pro.
    //They have no downgrade option, just the option of upgrading to pro.
    if(currentPlanId){
      currentPlan = {
        id : currentPlanId,
        title : currentPlanId === this.BASIC_PLAN_ID ? t('Verifyle Basic') : t('Verifyle Pro'),
        termCost : contractCurrent['term_cost'],
        term : currentPlanId === this.BASIC_PLAN_ID ? t('forever') : t('month'),
        points : currentPlanId === this.BASIC_PLAN_ID ? basicPoints : proPoints
      }
    }
    let targetPlan = {
      id : selectedPlanId,
      title : selectedPlanId === this.BASIC_PLAN_ID ? t('Verifyle Basic') : t('Verifyle Pro'),
      termCost : contractTarget['term_cost'],
      term : selectedPlanId === this.BASIC_PLAN_ID ? t('forever') : t('month'),
      points : selectedPlanId === this.BASIC_PLAN_ID ? basicPoints : proPoints
    }
    
    if(isCancelling){
      plans.push(targetPlan);
      plans.push(currentPlan);
    }
    else{
      if(currentPlan) {
        plans.push(currentPlan);
      }
      plans.push(targetPlan);
    }
    
    return (
      <div className="pb-3">
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-center">
              {_.map(plans, (plan) => {
                return (
                  <div key={plan.id}>
                    {this.renderPlan(plan)}
                  </div>
                )
              })}
            </div>
          </div>
          
        </div>
      </div>
    )
  }
  
  renderPlan(plan){
    let {
      currentPlanId,
      selectedPlanId
    } = this.state;
    let { t } = this.props;
    
    let isCurrentPlan = plan.id === currentPlanId;
    let isSelectedPlan = plan.id === selectedPlanId;
    
    return (
      <>
        <div className="card d-inline-block mx-2"
             style={{
               width: '20rem',
               height: '26rem',
               borderRadius: '40px',
               border: isCurrentPlan ? `2px solid ${colors.LIGHT_GREY}` : `3px solid ${colors.PRIMARY}`,
               color : isCurrentPlan ? colors.SECONDARY_TEXT : colors.PRIMARY,
               textAlign: 'center'
             }}>
          <div className="px-3 pt-2">
            <h2 className="mb-0">
              ${plan.termCost}
              <span className="small pl-2">
                /{plan.term}
              </span>
            </h2>
          </div>
          <div className="px-3 mb-1">
            <h3>
              {plan.title}
            </h3>
          </div>
          <div className="text-left ml-4 px-2"
               style={{height: '13rem'}}>
            <ul className="list-group list-group-flush"
                style={{
                  fontSize: '14px'
                }}>
              {_.map(plan.points, (p) => {
                return (
                  <li key={p}
                      style={{
                        lineHeight: '30px'
                      }}>
                    {p}
                  </li>
                )
              })}
            </ul>
          </div>
          <div>
  
            {isCurrentPlan &&
            <div className="text-center h-100 container">
              <div className="light-grey-bg mx-auto"
                   style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     zIndex: 10
                   }}/>
                   
              <div className="row align-items-center h-100" style={{
                marginTop: '-80px',
                lineHeight: '80px',
                zIndex: 11
              }}>
                <div className="col mx-auto px-0">
                  <div className="light-grey-bg d-inline-block font-italic light-color w-100 font-weight-bold"
                       style={{lineHeight: '30px', height: '30px'}}>
                    {t("Current Plan")}
                  </div>
                </div>
              </div>
            </div>
            }
  
            {isSelectedPlan &&
            <div className="text-center position-absolute w-100">
              <div className="primary-bg mx-auto"
                   style={{
                     borderRadius: '50%',
                     width: '80px',
                     height: '80px',
                     zIndex: 10
                   }}/>
  
              <div className="row align-items-center h-100" style={{
                marginTop: '-80px',
                lineHeight: '80px',
                zIndex: 11
              }}>
                <div className="col mx-auto px-0">
                  <div className="d-inline-block light-color align-middle"
                       style={{lineHeight: '80px', height: '80px'}}>
                      <i className="ion-checkmark-round"
                         style={{
                           fontSize: '30px'
                         }}/>
                  </div>
                </div>
              </div>
            </div>
            }
            
          </div>
        </div>
      </>
    )
  }
  
  renderPaymentPanel(){
    let {
      valid_code_deets,
      userContract
    } = this.state;
    let { t } = this.props;
    
    let contractUpgrade = _.get(userContract, 'upgrade', {});
    let termCostCents = +(contractUpgrade['term_cost']) * 100;
    
    if(valid_code_deets){
      termCostCents = valid_code_deets.price * 100;
    }
    
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col p-0">
      
              <div className="card border-0">
                <div style={{minHeight: '100px'}}>
                  <p className="card-title font-weight-bold">
                    {t("Verifyle Pro")}
                    <span className="mb-2 font-weight-normal text-muted pl-2">
                      {filters.getCurrency(termCostCents)} {t("/ month")}
                    </span>
                  </p>
  
                  <CardElement onChange={() => this.setState({validationErr: []})}
                               options={{
                                 iconStyle: 'solid',
                                 style: {
                                   base: {
                                     fontSize: '16px',
                                     color: colors.DARK,
                                     '::placeholder': {
                                       color: colors.SECONDARY_TEXT,
                                     },
                                   },
                                   invalid: {
                                     color: colors.ASSERTIVE,
                                   },
                                 },
                               }}/>
          
                  {this.state.validationErr.length > 0 &&
                  <div className="my-2">
                    <ValidationErrors errors={this.state.validationErr}/>
                  </div>
                  }
                </div>
              </div>
    
            </div>
          </div>
        </div>
      </>
    )
  }
  
  validateCouponData(data) {
    let { t } = this.props;
    if (!data.valid) {
      return t('This Promo code is no longer valid.');
    }
    
    if (data.amount_off && data.amount_off > 0) {
      return null;
    }
    else if (data.percent_off && data.percent_off > 0) {
      return null;
    }
    else {
      log.error('Unhandled coupon configuration', data);
      return t('This Promo code is not valid.');
    }
  }
  
  getDisplayPrice(price) {
    var split = ('' + price).split('.')
    if (split.length > 1) {
      var cents = +split[1];
      if (cents === 0) {
        return +split[0];
      }
      else {
        return price.toFixed(2);
      }
    }
    else {
      return +price;
    }
  }
  
  applyPromoCode() {
    let { t } = this.props;
    let {promo_code, userContract} = this.state;
  
    let contractUpgrade = _.get(userContract, 'upgrade', {});
    
    api.Stripe.validateCoupon(promo_code)
      .then((res) => {
        log.log('validate coupon res', res);
        
        let coupon = res.data;
        let msg = this.validateCouponData(coupon);
        if (msg) {
          this.setState({
            coupon_code_err: msg
          })
          return;
        }
        else {
          this.setState({
            coupon_code_err: null
          })
        }
        
        //Then it's valid.
        let couponDeets = null;
        var monthDuration = coupon.duration === 'once' ? 1 : coupon.duration_in_months;
        if (coupon.amount_off && coupon.amount_off > 0) {
          
          //FYI - amount_off comes back in cents
          let price = this.getDisplayPrice((contractUpgrade.term_cost - (coupon.amount_off / 100)));
          couponDeets = {
            name: coupon.name,
            price: price,
            monthly_amount: (price === 0 ? t('Free') : '$' + price + t('/month')),
            month_duration: monthDuration === 1 ? t('1 month') : monthDuration + t(' months'),
            normal_monthly: '$' + this.getDisplayPrice(contractUpgrade.term_cost),
            coupon: coupon
          };
        }
        else if (coupon.percent_off && coupon.percent_off > 0) {
          
          let price = this.getDisplayPrice((contractUpgrade.term_cost - (contractUpgrade.term_cost * (coupon.percent_off / 100))));
          couponDeets = {
            name: coupon.name,
            price: price,
            monthly_amount: (price === 0 ? t('Free') : t('$') + price + t('/month')),
            month_duration: monthDuration === 1 ? t('1 month') : monthDuration + t(' months'),
            normal_monthly: '$' + this.getDisplayPrice(contractUpgrade.term_cost),
            coupon: coupon
          }
        }
        
        this.setState({
          valid_code_deets: couponDeets
        })
      })
      .catch((err) => {
        log.log('error validating coupon', err);
        
        this.setState({
          coupon_code_err: t('This Promo code is not valid.')
        })
      })
  }
  
  clearCoupon() {
    this.setState({
      valid_code_deets: null,
      promo_code: '',
      coupon_code_err: ''
    })
  }
  
  getCouponControls() {
    let { t } = this.props;
    let {valid_code_deets} = this.state;
    
    return (
      <div className="mb-1" style={styles.couponBox}>
        <Button className={'btn btn-dark no-focus'}
                type={'button'}
                style={styles.clearButton}
                onClick={this.clearCoupon.bind(this)}>
          <i className="icon ion-close"/>
        </Button>
        <div className={'text-center'}>
          {/*This padding is to prevent overlapping text under the close button*/}
          <h4 style={{paddingRight: '20px'}}>
            {valid_code_deets.name} {t("Promo Applied")}
          </h4>
          <h5 className={'green-color'}>
            {valid_code_deets.monthly_amount} {t("for")} {valid_code_deets.month_duration}
          </h5>
          <p className="mb-1">
            {valid_code_deets.normal_monthly}{t("/month after that")}
          </p>
        </div>
      </div>
    )
  }
  
  renderPromoCodePanel(){
    let { t } = this.props;
    let {
      coupon_code_err,
      promo_code,
      valid_code_deets
    } = this.state;
    
    return (
      <>
        {!valid_code_deets &&
        <div>
          <label className="form-label font-weight-bold">{t("Promo Code")}</label>
          <div className="form-inline">
            <input className="form-control text-uppercase mr-1"
                   type={'text'}
                   value={promo_code}
                   onChange={(evt) => this.setState({promo_code: evt.target.value.toUpperCase()})}/>
            <Button disabled={promo_code.length === 0}
                    onClick={this.applyPromoCode.bind(this)}
                    type={'button'}
                    className={'btn btn-secondary'}>{t("Apply")}</Button>
          </div>
        </div>
        }
        {valid_code_deets &&
        <div>
          {this.getCouponControls()}
        </div>
        }
  
        {coupon_code_err &&
        <div className="my-2">
          <ValidationErrors errors={[coupon_code_err]}/>
        </div>
        }
      </>
    )
  }
  
  renderLimitMessage(){
    let {
      limitMessage
    } = this.state;
    
    if(!limitMessage){
      return null;
    }
    
    return (
      <div className="row">
        <div className="col">
          <div className={'alert alert-warning'}>
        
            <div className="d-flex">
              <div className="mr-2">
                <i className="icon ion-android-warning align-middle"
                   style={{ fontSize: '20px' }}/>
              </div>
              <div>
                {limitMessage}
              </div>
            </div>
      
          </div>
        </div>
      </div>
    )
  }
  
  render() {
    let { t } = this.props;
    let {
      loading,
      isCancelling,
      isHandlingSubmitButtonClick,
      limitMessage
    } = this.state;
    
    return (
      <div className="modal-content">
        <Elements stripe={this.state.stripePromise}>
          <ElementsConsumer>
            {({stripe, elements}) => {
              return (
                <>
                  <form onSubmit={this.onSubmitButtonClick.bind(this, stripe, elements)}>
                    <div className="modal-header">
                      <h5 className="modal-title">{t("Subscription Management")}</h5>
                      <button type="button" className="close" onClick={this.closeModal.bind(this, false)}
                              aria-label={t("Close")}>
                        <i className="icon ion-ios-close-empty"/>
                      </button>
                    </div>
                    <div className="modal-body">
  
                      {this.renderLimitMessage()}
                      
                      <div className="row">
                        <div className="col">
                          {this.renderPlanPanel()}
                        </div>
                      </div>
  
                      {!isCancelling &&
                      <div className="card text-center" style={{minHeight: '160px'}}>
                        <h4 className="card-title">
                          {t("Payment Information")}
                        </h4>
                        <div className="container">
                          <div className="row">
                            <div className="col text-left">
                              {this.renderPromoCodePanel()}
                            </div>
                            <div className="col text-left">
                              {this.renderPaymentPanel()}
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                    <div className="modal-footer">
                      {loading &&
                      <Loading inline={true}
                               className={'mr-2'}
                               size={'sm'}/>
                      }
                      <Button className={'btn btn-secondary'}
                              type={'button'}
                              disabled={loading}
                              onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
                      {!isCancelling &&
                      <Button className={'btn btn-primary'}
                              disabled={isHandlingSubmitButtonClick || loading}
                              type="submit">
                        {t("Go Pro!")}
                      </Button>
                      }
                      {isCancelling &&
                      <Button className={'btn btn-danger'}
                              onClick={this.cancelSubscription.bind(this)}
                              disabled={loading}
                              type="button">
                        {t("Cancel Subscription")}
                      </Button>
                      }
                    </div>
                  </form>
                </>
              )
            }}
          </ElementsConsumer>
        </Elements>
      </div>
    )
  }
}

const styles = {
  couponBox: {
    padding: '10px',
    border: 'dashed 2px grey',
    textAlign: 'center',
  },
  clearButton: {
    position: 'absolute',
    top: '-15px',
    right: '0px',
    border: '10px solid',
    zIndex: '100',
    borderRadius: '50%'
  }
}

const mapStateToProps = (state) => {
  return {
    userContract : state.shared.userContract,
    upgradePlans : state.shared.upgradePlans,
    stripeClass100 : state.shared.stripeClass100,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    updateStripeData : () => dispatch(sharedActions.updateStripeData()),
    updateUserContractInfo : () => dispatch(sharedActions.updateUserContractInfo()),
    updateStripePlan100 : () => dispatch(sharedActions.updateStripePlan100()),
    updateLogo : () => dispatch(sharedActions.updateLogo()),
    updateAvailablePlans : () => dispatch(sharedActions.updateAvailablePlans()),
    ...modalActions.mapToDispatch(dispatch)
  };
};

UpgradeDialogNew.MODAL_LARGE = true;

UpgradeDialogNew.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(UpgradeDialogNew));
