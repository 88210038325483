import React, {Component, PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Waypoint} from "react-waypoint";
import Button from "../elements/Button";

import classNames from 'classnames';

import log from '../../../util/log';

import _ from 'lodash';
import Scroll from "react-scroll";
import Popover from "react-tiny-popover";

const DEFAULT_HEADER_HEIGHT = 48;

class ScrollingTabView extends PureComponent {
  
  constructor(props){
    super(props);
  
    this.hidePopover = this.hidePopover.bind(this);
    
    this.state = {
      itemsLockedToTop: [],
      showingSort : false
    }
  }
  
  onHeaderTopEnter(index, evt) {
    // log.log('onHeaderTopenter', index, evt);
    if(evt.previousPosition === Waypoint.above){
      let { itemsLockedToTop } = this.state;
  
      let copy = _.concat([], itemsLockedToTop);
      _.remove(copy, (item) => {
        return item === index;
      })
      this.setState({
        itemsLockedToTop : copy
      })
    }
  }
  
  onHeaderTopLeave(index, evt) {
    // log.log('onHeaderTopLeave', index, evt);
    if(evt.currentPosition === Waypoint.above){
      let { itemsLockedToTop } = this.state;
    
      this.setState({
        itemsLockedToTop : _.concat([], itemsLockedToTop, index)
      })
    }
  }
  
  getHeaderHeight(){
    if(this.props.customTabHeight){
      return this.props.customTabHeight;
    }
    return DEFAULT_HEADER_HEIGHT;
  }
  
  hidePopover(evt){
    this.setState({
      showingSort : false
    })
  }
  
  getSortContent(tab){
    
    return (
      <div style={{
        wordBreak: 'keep-all',
        whiteSpace: 'nowrap'
      }}>
        <div className="click-block" onClick={this.hidePopover} />
        <div onClick={this.hidePopover}>
          {tab.getSortingPopoverContent()}
        </div>
      </div>
    )
  }
  
  renderTabs(isLocked) {
    let {tabs, headerCls} = this.props;
    
    let selectedTab = null;
    _.each(tabs, (tab) => {
      if(tab.isSelected){
        selectedTab = tab;
      }
    })
    
    return (
      <div className={classNames("tabs position-relative", {[headerCls] : headerCls})}>
        {_.map(tabs, (tab) => {
          return (
            <div key={tab.id} className={'tab ' + (tab.isSelected ? 'selected' : '')}>
              {tab.tabRenderFn(tab, isLocked)}
        </div>
          )
        })}
      </div>
    )
  }
  
  render() {
    let { tabs } = this.props;
    let { itemsLockedToTop, showingSort } = this.state;
    
    let height = this.getHeaderHeight();
    let headerStyle = null;
    let topIndex = _.indexOf(itemsLockedToTop, 0);
    if(topIndex >= 0){
      headerStyle = {
        height: height,
        top: `${(height * topIndex)}px`,
      }
    }
    let selectedTab = _.find(tabs, (tab) => { return tab.isSelected });
    return (
        <div id={this.props.id}
             className={'scrollable-col'}>
          <Popover
            isOpen={showingSort}
            position={['bottom']}
            onClickOutside={this.hidePopover}
            content={() => this.getSortContent(selectedTab)}>
            <div className="d-inline-block position-absolute accordion-sort-btn" style={{
              right : '15px',
              top: '14.5px',
              zIndex: 100,
              borderRadius: '8px',
              padding : '1px 10px',
              fontSize: '14px'
            }}>
            <span onClick={() => this.setState({showingSort : true})}>
              <span className="font-weight-bold">Sort: </span>
              {selectedTab.getSortLabel()}
              <i className="icon ion-android-arrow-dropdown ml-3" />
            </span>
            </div>
          </Popover>
          <div className="height-100">
            {headerStyle &&
            <div className={classNames('tab-view-header locked')}
                 style={{...styles.headerStyleBase, ...headerStyle}}>
        
              {this.renderTabs(true)}
            </div>
            }
      
            <Waypoint onEnter={this.onHeaderTopEnter.bind(this, 0)}
                      onLeave={this.onHeaderTopLeave.bind(this, 0)}/>
      
            <div className={classNames('tab-view-header')} style={{height: height}}>
              {this.renderTabs(false)}
            </div>
      
            {selectedTab && selectedTab.listRenderFn()}
          </div>
        </div>
    )
  }
}

const styles = {
  headerStyleBase : {
    position: 'absolute',
    left: '0',
    right: '0'
  }
}

ScrollingTabView.propTypes = {
  id : PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
    id: PropTypes.string.isRequired,
    tabRenderFn: PropTypes.func,
    isSelected : PropTypes.bool,
    listRenderFn: PropTypes.func.isRequired,
    getSortingPopoverContent: PropTypes.func.isRequired,
    getSortLabel : PropTypes.func.isRequired
    })
  ).isRequired,
  headerCls:PropTypes.string,
  //The code does not currently support headers with different sizes.
  customTabHeight:PropTypes.number,
  scrollOffset: PropTypes.number
}

export default ScrollingTabView;
