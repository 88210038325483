import React, {Component, Fragment, PureComponent} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Select from 'react-select';
import Draggable from "react-draggable";
import log from '../../../util/log';
import colors from "../../../util/colors";
import config from "../../../util/config";
import Button from "../elements/Button";
import classnames from 'classnames';
import _ from 'lodash'
import SignatureRequest from "../../../models/SignatureRequest";
import {fontConstants} from "../../../util/font-constants";
import utils from "../../../util/util";
import {withVFTranslation} from "../../../util/withVFTranslation";

class PdfSignatureRequestOverlay extends PureComponent {
  
  INITIAL_STATE = {
    isDragging : false
  }
  
  constructor(props){
    super(props);
    
    this.otherInputRef = React.createRef();
    
    this.state = _.extend({}, this.INITIAL_STATE);
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this.props.overlay.id, this)
    }
  }
  
  componentWillUnmount() {
    if(this.props.onRef) {
      this.props.onRef(this.props.overlay.id, null)
    }
  }
  
  getSignatureRequestOptions(){
    let { t } = this.props;
    return [
      { display : t('Signature'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE, cls : fontConstants.LA_BELLE_AURORE.class},
      { display : t('Initials'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.INITIALS, cls : fontConstants.HELVETICA.class},
      { display : t('Date'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE, cls : fontConstants.COURIER.class},
      { display : t('Custom'), val : SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER, cls : fontConstants.TIMES_NEW_ROMAN.class},
    ]
  }
  
  deleteButtonClick(){
    this.props.deleteSignature();
  }
  
  onRequestOptionChanged(evt){
    this.props.onSignatureTypeChange(evt.target.value);
    
    if(evt.target.value === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER){
      utils.waitForCondition(() => {
        return this.props.overlay.signatureType === evt.target.value
      }, 250)
        .then(() => {
          this.otherInputRef.current.focus();
        })
    }
  }
  
  onRequestCustomLabelChange(evt){
    this.props.onSignatureCustomLabelChange(evt.target.value);
  }
  
  onCustomLabelClear(){
    this.props.onSignatureTypeChange(SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE);
    this.props.onSignatureCustomLabelChange('');
  }
  
  getScaledMoveCoordinates(position){
    //Move events come in scaled, however we manage relative scaling within render.
    //This means that we need to reverse scale the move coordinates because we'll re-scale it back during render.
    //This might seem like extra work, and it is, but
    
    let { overlay, viewScale } = this.props;
    return {
      x :  PdfSignatureRequestOverlay.calculateOverlayRelativeScale(position.x, viewScale, overlay.scale),
      y :  PdfSignatureRequestOverlay.calculateOverlayRelativeScale(position.y, viewScale, overlay.scale)
    }
  }
  
  handleDragStart(e, position){
    this.setState({ isDragging : true })
    this.props.onSignatureMove(this.getScaledMoveCoordinates(position));
  }
  
  handleDrag(e, position){
    this.props.onSignatureMove(this.getScaledMoveCoordinates(position));
  }
  
  handleDragStop(e, position){
    this.setState({ isDragging : false })
    this.props.onSignatureMove(this.getScaledMoveCoordinates(position));
  }
  
  static calculateOverlayRelativeScale(val, overlayScale, viewScale){
    return val * (viewScale / overlayScale);
  }
  
  scaleRelativeToOverlay(val){
    let { overlay, viewScale } = this.props;
    return PdfSignatureRequestOverlay.calculateOverlayRelativeScale(val, overlay.scale, viewScale);
  }
  
  render(){
    let { overlay, allowChanges } = this.props;
    
    let scaledOverlayWidth = this.scaleRelativeToOverlay(overlay.width);
    let scaledOverlayHeight = this.scaleRelativeToOverlay(overlay.height);
    
    return (
      <Draggable
        handle=".handle"
        disabled={!allowChanges}
        bounds={'parent'}
        position={{
          x : this.scaleRelativeToOverlay(overlay.coords.x),
          y : this.scaleRelativeToOverlay(overlay.coords.y)
        }}
        onStart={this.handleDragStart.bind(this)}
        onDrag={this.handleDrag.bind(this)}
        onStop={this.handleDragStop.bind(this)}>
        <div style={{
          position : 'absolute',
          zIndex : 1000000,
        }}>
          <div className="d-flex">
            <div>
              <button className="btn btn-icon handle"
                      style={{
                        paddingTop: '0px',
                        paddingLeft: this.scaleRelativeToOverlay(12) + 'px',
                        paddingRight: this.scaleRelativeToOverlay(12) + 'px',
                        fontSize: `${this.scaleRelativeToOverlay(32)}px`,
                        color: colors.SIGNING_REQUEST,
                        boxShadow: 'none',
                        display: 'block',
                        height: this.scaleRelativeToOverlay(30) + 'px',
                      }}>
                <i className="icon icon-top ion-bookmark signature-request-color has-grabber"/>
              </button>
            </div>
            {overlay.signatureType !== SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER &&
            <select className="form-control flex-grow-1 signature-request-input py-0"
                    style={{
                      paddingLeft: '0px',
                      width: scaledOverlayWidth,
                      height: scaledOverlayHeight,
                      fontSize : `${this.scaleRelativeToOverlay(14)}px`,
                      lineHeight: `${scaledOverlayHeight}px`}}
                    value={overlay.signatureType}
                    disabled={!allowChanges}
                    onChange={this.onRequestOptionChanged.bind(this)}>
              { this.getSignatureRequestOptions().map((option) => {
                return (
                  <option className={option.cls}
                          key={option.val}
                          value={option.val}>
                    {option.display}
                  </option>
                )
              })}
            </select>
            }
            {overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER &&
              <div className="position-relative">
                <input className="form-control flex-grow-1 signature-request-input helvetica"
                       style={{
                         padding : '0px 22px 0px 5px',
                         width: scaledOverlayWidth,
                         height: scaledOverlayHeight,
                         lineHeight: `${scaledOverlayHeight}px`,
                         fontSize : `${this.scaleRelativeToOverlay(14)}px`,
                       }}
                       value={overlay.signatureCustomLabel}
                       ref={this.otherInputRef}
                       onChange={this.onRequestCustomLabelChange.bind(this)}
                       disabled={!allowChanges} />
                {allowChanges &&
                <div onClick={this.onCustomLabelClear.bind(this)}
                     style={{
                       lineHeight: `${scaledOverlayHeight}px`,
                       position: 'absolute',
                       right: '6px',
                       top: '0px',
                       color: colors.DARK
                     }}>
                  <i className="icon ion-close dark-color"/>
                </div>
                }
              </div>
            }
            <div>
              <button disabled={!allowChanges}
                      className="btn btn-icon"
                      style={{
                        paddingTop: '0px',
                        paddingLeft: this.scaleRelativeToOverlay(12) + 'px',
                        paddingRight: this.scaleRelativeToOverlay(12) + 'px',
                        fontSize: `${this.scaleRelativeToOverlay(26)}px`,
                        color: colors.SIGNING_REQUEST,
                        boxShadow: 'none',
                        display: 'block',
                        height: this.scaleRelativeToOverlay(30) + 'px',
                      }}
                      onClick={this.deleteButtonClick.bind(this)}>
                <i className="icon icon-top ion-close-circled signature-request-color"/>
              </button>
            </div>
          </div>
        </div>
      </Draggable>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

//This is calculated at scale = 1.  I need this from outside the component in order to calculate
//Proper overlay positions with the draggable handle.
//Just a heads up that I just measured this in the browser and hardcoded the size.  It's probably
//possible to calculate this and pass it around, but that involves references to rendered components which is harder to deal with in code.
PdfSignatureRequestOverlay.LEFT_BUTTON_WIDTH = 38;

PdfSignatureRequestOverlay.propTypes = {
  viewScale : PropTypes.number.isRequired,
  overlay : PropTypes.object.isRequired,
  onSignatureMove : PropTypes.func.isRequired,
  onSignatureTypeChange : PropTypes.func.isRequired,
  onSignatureCustomLabelChange : PropTypes.func.isRequired,
  deleteSignature : PropTypes.func.isRequired,
  allowChanges : PropTypes.bool.isRequired,
  isFulfillingSignatureRequest : PropTypes.bool.isRequired,
  onRef : PropTypes.func,
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfSignatureRequestOverlay));
