import React, {PureComponent, Fragment} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import UserBadge from "../badges/UserBadge";
import colors from "../../../util/colors";
import log from "../../../util/log";
import Button from "../elements/Button";
import Popover from "react-tiny-popover";
import appActions from "../../../actions/app-actions";
import modalActions from "../../../actions/modal-actions";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class AccountHeader extends PureComponent {
  
  constructor(props) {
    super(props);
    
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    
    this.state = {
      menuIsOpen: false,
      isHovering : false
    }
  }
  
  goToAccount() {
    let {history} = this.props;
    
    this.hidePopover();
    history.push('/account');
  }
  
  hidePopover() {
    this.setState({
      menuIsOpen: false
    })
  }
  
  showPopover() {
    this.setState({
      menuIsOpen: true
    })
  }
  
  helpClick(){
    this.hidePopover();
    this.props.showHelpResourceWindow();
  }
  
  logout() {
    let {history, logout} = this.props;
  
    this.hidePopover();
    logout();
    history.push('/');
  }
  
  upgradeMenuClick(){
    this.hidePopover();
    this.props.showUpgradeDialog()
  }
  
  getPopoverContent() {
  
    let {accountInfo, t} = this.props;
    let showUpgrade = accountInfo && accountInfo.class_id === 1;
    return (
      <div>
        <div className="click-block" onClick={this.hidePopover.bind(this)} />
        <ul className="account-header-popover popover-content list-group" style={styles.popoverWrap}>
  
          {showUpgrade &&
          <a onClick={this.upgradeMenuClick.bind(this)}
             style={styles.menuItemTop}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item primary-color">
            <span>
              <i style={styles.menuIcons} className="icon ion-ios-star-outline"/>
              {t("Upgrade to Pro!")}
            </span>
          </a>
          }
          
          <a onClick={this.helpClick.bind(this)}
             style={showUpgrade ? styles.menuItem : styles.menuItemTop}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
            <span>
              <i style={styles.menuIcons} className="icon ion-ios-help-outline"/>
              {t("Help")}
            </span>
          </a>
          
          <a onClick={this.goToAccount.bind(this)}
             style={styles.menuItem}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
            <span>
              <i style={styles.menuIcons} className="icon ion-gear-b"/>
              {t("Account Settings")}
            </span>
          </a>
          
          <a onClick={this.logout.bind(this)}
             style={styles.menuItemBottom}
             className="list-group-item list-group-item-action d-flex justify-content-between align-items-center has-pointer settings-menu-item">
            <span>
              <i style={styles.menuIcons} className="icon ion-log-out"/>
              {t("Logout")}
            </span>
          </a>
        
        
        </ul>
      </div>
    )
  }
  
  onMouseEnter(){
    if(this.state.isHovering){
      return;
    }
    
    this.setState({isHovering : true});
  }
  
  onMouseLeave(){
    this.setState({isHovering : false});
  }
  
  render() {
    let {accountInfo} = this.props;
    let {menuIsOpen, isHovering} = this.state;
    
    if (!accountInfo) {
      return <div/>
    }
    
    return (
      <Fragment>
        
        <Popover
          isOpen={menuIsOpen}
          position={['bottom', 'right']}
          onClickOutside={this.hidePopover.bind(this)}
          content={() => this.getPopoverContent()}>
          
          <div className="d-flex account-badge has-pointer"
               onMouseEnter={this.onMouseEnter}
               onMouseLeave={this.onMouseLeave}
               onClick={this.showPopover.bind(this)}>
            <div className={'text-right pr-2'}>
              <UserBadge guest={accountInfo} overrideColor={isHovering ? colors.DARK : colors.SECONDARY_TEXT}/>
            </div>
            <div className={'text-left pl-0 pt-3'} style={styles.userInfoWrap}>
              <div className={`${isHovering ? 'dark-color' : 'secondary-text-color'} account-name`} style={styles.userInfoTitle}>
                {accountInfo.first_name} {accountInfo.last_name}
              </div>
              <div className={`${isHovering ? 'dark-color' : 'secondary-text-color'}`} style={styles.userInfoEmail}>
                {accountInfo.login}
              </div>
            </div>
          </div>
        
        </Popover>
      </Fragment>
    )
  }
}

const styles = {
  userInfoWrap: {
    fontSize: '14px'
  },
  userInfoTitle: {
    lineHeight: '14px'
  },
  userInfoEmail: {
    lineHeight: '14px',
    marginTop: '5px'
  },
  menuIcon: {
    minWidth: '20px',
    fontSize: '24px'
  },
  menuItemTop: {
    padding: '6px 15px',
    borderBottomColor: colors.TRANSPARENT,
  },
  menuItem: {
    padding: '6px 15px',
    borderBottomColor: colors.TRANSPARENT,
    borderTopColor: colors.TRANSPARENT,
  },
  menuItemBottom: {
    padding: '7px 15px',
    borderTopColor: colors.TRANSPARENT,
  },
  menuIcons: {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign: 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  popoverWrap : {
  
  }
}

const mapStateToProps = (state) => {
  return {
    accountInfo: state.shared.accountInfo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout : () => dispatch(appActions.logout()),
    ...modalActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountHeader)));
