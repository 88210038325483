import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import querystring from 'query-string';

import config from '../util/config';

import _ from 'lodash';

import c from '../util/const';
import log from '../util/log';
import vfLocalStorage from '../util/local-storage';

import api from '../util/api';
import sapi from '../util/sapi';
import authHelper from '../helpers/auth-helper';

import appActions from '../actions/app-actions';
import authActions from '../actions/auth-actions';

import routing from '../routing';
import ModalRoot from './modals/ModalRoot';
import Loading from './partials/util/Loading';
import redirectHelper from "../util/redirect-helper";
import UploadManager from "../containers/UploadManager";

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import browser from '../util/browser';
import vfSessionStorage from "../util/session-storage";
import modalActions from "../actions/modal-actions";
import DownloadManager from "../containers/DownloadManager";
import {fontsNeedingPreload} from "../util/font-constants";
import utilityActions from "../actions/utility-actions";
import cookieHelper from "../helpers/cookie-helper";
import {withVFTranslation} from "../util/withVFTranslation";

class App extends Component {

  showingVersionPrompt = false;
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showingSessionWarning : false
    };

    ReactGA.initialize(c.analytics.gaTrackingId, { debug: config.debug });
    ReactPixel.init(c.analytics.pixelId);
  }
  
  componentDidMount() {
    this.initEndpoint();
    this.startup();
  
    sapi.registerApiHandlers(
      this.onInvalidSessionCaught.bind(this),
      this.onConnectionError.bind(this)
    )
    
    log.log('Current Browser', JSON.stringify(browser.getBrowser()));

    if(browser.isIE11()){
      document.body.classList.add('browser-is-ie11');
    }
  
    document.body.classList.add(`browser-${browser.getBrowser().name}`);

    window.addEventListener("focus", this.onBrowserTabFocus.bind(this))
    window.addEventListener("blur", this.onBrowserTabBlur.bind(this))
  
    this.showVersionDialogIfNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onBrowserTabFocus.bind(this))
    window.removeEventListener("blur", this.onBrowserTabBlur.bind(this))
  }
  
  onBrowserTabFocus(){
    log.log('browser tab active')
    this.props.setBrowserTabActive(true);
    
    this.showVersionDialogIfNeeded();
  }
  
  onBrowserTabBlur(){
    log.log('browser tab blur');
    this.props.setBrowserTabActive(false);
  }
  
  showVersionDialogIfNeeded(){
    
    if(this.showingVersionPrompt){
      log.log('already showing prompt.  skipping');
      return;
    }
    
    let { t } = this.props;
    api.Version.list()
      .then((res) => {
        log.log('got version res', res);
        
        let foundVersion = _.find(res.data, (v) => v.platform === 'web');
        if(foundVersion){
          if(foundVersion.version_id > config.versions.web){
            this.showingVersionPrompt = true;
            this.props.showConfirm(t('New Version Available'), t("There is a new version of this page available.  Please reload to update."), (res) => {
              if(res){
                window.location.reload();
              }
              this.showingVersionPrompt = false;
            })
          }
        }
        
      })
      .catch((err) => {
        log.log('error fetching version', err);
      })
  }
  
  initEndpoint(){
    if(config.debug){
      let savedEndpoint = vfLocalStorage.get(c.localstorage.endpoint);
      api.setEndpoint(savedEndpoint || config.prodEndpoint);
      sapi.setEndpoint(savedEndpoint || config.prodEndpoint);
    }
    else{
      api.setEndpoint(config.prodEndpoint);
      sapi.setEndpoint(config.prodEndpoint);
    }
  }

  onConnectionError(err){
    
    if(!sapi.isConnectionError(err)){
      console.warn('non-connection error in the wrong error handler', err);
    }
    
    this.props.setApplicationError(err);
  }
  
  onInvalidSessionCaught(err) {
    log.error('onInvalid Session caught', err);
    
    if(this.state.showingSessionWarning){
      return;
    }
    let { t } = this.props;
    this.props.resetAuthenticatedAppState();
    this.setState({showingSessionWarning : true});
    this.props.history.replace("/");
    setTimeout(() => {
      this.props.cleanupModals();
      this.props.showAlert(t('Session Invalid'), t('We need to start a new secure session for you.  Please log in to continue.'), () => {
        setTimeout(() => {
          this.setState({showingSessionWarning : false})
        })
      })
    })
  }
  
  performEnvironmentalChecks(){
    
    if(!vfLocalStorage.testStorage()){
      //report localstorage problem
      this.props.setApplicationError({
        isEnvironmentalError : true,
        isLocalStorageProblem : true
      })
      return false;
    }
    
    if(!vfSessionStorage.testStorage()){
      //report session storage problem
      this.props.setApplicationError({
        isEnvironmentalError : true,
        isSessionStorageProblem : true
      })
      return false;
    }
    
    return true;
  }
  
  startup() {
    if(!this.performEnvironmentalChecks()){
      return;
    }
    
    let {location} = this.props;
    let parsed = querystring.parse(location.search);
    //This is a little tricky, but the kinds of deeplinking needed around authentication and such
    //gets parsed and handled from the qs directly.  If there is querystring data stored in sessionstorage
    //we just save it as the querystring in app, but log in like normal.
    //This is part of handling querystrings for users that are not authenticated yet.
    if(parsed && !_.isEmpty(parsed) && this.validateQs(parsed)) {
      log.log('parsed querystring', parsed);
      this.initializeQs(parsed);
    }
    else{
      let qs = null;
      let sessQs = vfSessionStorage.get(c.sessionstorage.qs);
      if(sessQs) {
        qs = JSON.parse(sessQs);
      }
      if(qs){
        log.log('setting qs from session storage', qs);
        this.props.setQueryString(qs);
        this.props.setQsActionNeeded(true);
      }
    }
    
      if(_.isEmpty(parsed)){
        this.loginFromSavedAuth()
      }
      else{
        this.processQueryString(parsed);
      }
  }
  
  validateQs(qs){
    if(qs.forum_id && qs.thread_id){
      return true;
    }
    else if(qs.forum_id && qs.doc_id){
      return true;
    }
    else if(qs[c.querystring.goto_reset]){
      return true;
    }
    else if(qs[c.querystring.goto_confirm]){
      return true;
    }
    else if(qs[c.querystring.goto_dnd]){
      return true;
    }
    else if(qs[c.querystring.goto_block_user] && qs.guest_uid){
      return true;
    }
    else if(qs[c.querystring.sign_request_id]){
      return true;
    }
    else if(qs[c.querystring.guest_uid]){
      return true;
    }
    else{
      return false;
    }
  }
  
  initializeQs(qs){
    //This is a weird special case.  If there's an email in the query string, we want to save it, but not
    //store it in the session.
    //It's possible there is an authenticated user, but not the one from the query string.
    //If that happens, we'll write the notification query string args to session storage and log them out.
    //Then when they log back in, we check local storage for the args, and navigate them there.
    //Possibly important to note that if the current user logged in as someone else, who happen to also
    //have a reference to the forum and chat/doc id in the query string, we'll navigate there, even if it's not the intended recipient after this first redirect.
    let qsClone = _.extend({}, qs);
    let savedEmail = null;
    if(qsClone.email){
      savedEmail = qsClone.email;
      delete qsClone.email;
    }
  
    //Save to session storage.  If the user gets bounced back out to login, we want to
    //save this so we can navigate them when they come back in.
    vfSessionStorage.set(c.sessionstorage.qs, JSON.stringify(qsClone));
    this.props.setQueryString(qsClone);
    this.props.setQsActionNeeded(true);
    this.props.setQsEmail(savedEmail)
    
    //If there's a user in the query string, clear localStorage and set the next login email to the one in teh qs.
    //So when they get bounced out, they correct email address will be set.  bug 934
    
    let isConfirm = qs[c.querystring.goto_confirm] && (qs[c.querystring.goto_confirm] + "").toUpperCase() === "YES";
    
    if(!isConfirm) {
      let lsEmail = vfLocalStorage.get(c.localstorage.email);
      if(lsEmail){
        lsEmail = lsEmail.toLowerCase();
      }
      if (savedEmail && savedEmail.toLowerCase() !== lsEmail) {
        vfLocalStorage.clearExceptEndpoint();
        vfLocalStorage.set(c.localstorage.email, savedEmail);
      }
    }
  }
  
  updateInstitution(inst_id){
    let { setInstitution } = this.props;

    if(inst_id){
      return api.Institution.post(inst_id)
        .then((res) => {
          setInstitution(res.data);
        })
        .catch((err) => {
          //nothing to do.  The user can edit this in the querystring, so don't worry about failures.
          log.log(`no institution found for ${inst_id}`, err);
        })
    }
    else{
      return Promise.resolve(true);
    }
  }

  handleLogoutQs(qs){
    this.props.logout()
      .finally(() => {
  
        //Looks a little weird, but we want to respect this qs param if it's there, and we have to do it
        //after logout() because it clears the authentication store.
        let instPromise = new Promise((resolve, reject) => {
          if(qs[c.querystring.inst_id]){
            this.updateInstitution(qs[c.querystring.inst_id])
              .finally(() => {
                resolve(true);
              })
          }
          else{
            resolve(true);
          }
        })
  
        instPromise
          .then(() => {
            this.props.history.replace("/");
            setTimeout(() => {
              this.setState({ loading: false })
            })
          })
      })
  }
  
  processQueryString(qs) {
    let { setEmail, setNextStep, setConfirmationCode, setPreventEmailDisabled, setLoginWithPayment, history } = this.props;
    
    if(qs[c.querystring.logout] && (qs[c.querystring.logout] + "").toUpperCase() === "YES"){
      this.handleLogoutQs(qs);
      return;
    }
    
    let qsEmail = qs[c.querystring.email];
    let lsEmail = vfLocalStorage.get(c.localstorage.email);

    //If we have different values in qs vs ls, just clear localstorage.
    if(qsEmail && lsEmail && qsEmail.toLowerCase() !== lsEmail.toLowerCase()){
      vfLocalStorage.clearExceptEndpoint()
    }

    let instPromise = new Promise((resolve, reject) => {
      if(qs[c.querystring.inst_id]){
        this.updateInstitution(qs[c.querystring.inst_id])
          .finally(() => {
            resolve(true);
          })
      }
      else{
        resolve(true);
      }
    })
    
    instPromise
      .then(() => {
        
        let loginLikeNormal = false;
        if (qs[c.querystring.goto_confirm] && (qs[c.querystring.goto_confirm] + "").toUpperCase() === "YES") {
          //If they clicked a confirm link, but their email is already in ls, it means they've been here before.
          //do normal login
          if(qsEmail && lsEmail && qsEmail === lsEmail){
            loginLikeNormal = true;
          }
          else{
            setEmail(qsEmail);
            setConfirmationCode(qs[c.querystring.confirmation_code]);
            this.setState({loading: false},
              () => {
                history.replace("/confirm");
              })
          }
        }
        else if (qs[c.querystring.goto_reset] && (qs[c.querystring.goto_reset] + "").toUpperCase() === "YES") {
          setEmail(qsEmail);
          setConfirmationCode(qs[c.querystring.confirmation_code])
          this.setState({loading: false},
            () => {
              history.replace("/confirm");
            })
        }
        else if (qs[c.querystring.goto_reg] && (qs[c.querystring.goto_reg] + "").toUpperCase() === "YES") {
          if(qsEmail) {
            setEmail(qsEmail);
          }
          setNextStep(c.authSteps.signup);
          this.setState({ loading: false })
    
          ReactGA.ga('send','event','Sign Up','create account','load');
          ReactPixel.track('Lead', {'content_category':'Sign Up','content_name':'create account'})
        }
        else if (qs[c.querystring.goto_proreg] && (qs[c.querystring.goto_proreg] + "").toUpperCase() === "YES") {
          if(qsEmail) {
            setEmail(qsEmail);
          }
          setLoginWithPayment(true);
          setNextStep(c.authSteps.email);
          this.setState({ loading: false })
  
          ReactGA.ga('send','event','Sign Up','create pro account','load');
          ReactPixel.track('Lead', {'content_category':'Sign Up','content_name':'create pro account'})
        }
        else{
          loginLikeNormal = true;
        }
  
        if(loginLikeNormal){
          if(qsEmail){
            setEmail(qsEmail);
          }
          else if(lsEmail){
            setEmail(lsEmail);
          }
          this.loginFromSavedAuth();
        }
      })
  }
  
  loginFromSavedAuth() {
    let { history, setAuthFromLocalStorage, qs } = this.props;

    if(authHelper.hasSavedAuthentication()){
      this.props.resetAuthenticatedAppState();
      this.setState({ loading: true })
      authHelper.testSavedAuthentication()
        .then((res) => {
          log.log('auth success', res);
          cookieHelper.clearTrackingCookies();
          setAuthFromLocalStorage();
          this.setState({ loading: false })
          redirectHelper.redirectToApp(history, qs, vfLocalStorage.get(c.localstorage.vip));
        })
        .catch((err) => {
          log.log('no auth', err);
          this.setState({ loading: false });
          history.replace("/");
        })
    }
    else{
      this.setState({ loading: false });
      history.replace("/");
    }
  }

  renderUtilities(){
    
    return (
      <>
        <canvas height={utilityActions.WATERMARK_CANVAS_HEIGHT}
                width={utilityActions.WATERMARK_CANVAS_WIDTH}
                className="d-none"
                ref={(ref) => this.props.setWatermarkCanvasRef(ref)}/>
      </>
    )
  }
  
  render() {
    let { loading } = this.state;

    let content = null;
    if(!loading){
      content = routing.getRoutes();
    }
    else{
      content = <div />
    }

    return (
      <div x-ms-format-detection="none">
        <UploadManager/>
        <DownloadManager/>
        <ModalRoot/>
        {this.renderUtilities()}
        {content}
        {_.map(fontsNeedingPreload, (font) => {
          return (
            <div key={font.name} className={`font-pre-load ${font.class}`}>.</div>
          )
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    qsEmail : state.app.qsEmail,
    qsActionNeeded : state.app.qsActionNeeded
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cleanupModals: () => dispatch(modalActions.cleanup()),
    logout: () => dispatch(appActions.logout()),
    setBrowserTabActive: (isActive) => dispatch(appActions.setBrowserTabActive(isActive)),
    setQueryString: (qs) => dispatch(appActions.setQueryString(qs)),
    setQsEmail: (email) => dispatch(appActions.setQsEmail(email)),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    setPreventEmailDisabled : preventDisabled => dispatch(authActions.setPreventEmailDisabled(preventDisabled)),
    setConfirmationCode: code => dispatch(authActions.setConfirmationCode(code)),
    setNextStep: step => dispatch(authActions.setNextStep(step)),
    setAuthFromLocalStorage: () => dispatch(authActions.setAuthFromLocalStorage()),
    setInstitution: (institution) => dispatch(authActions.setInstitution(institution)),
    setWatermarkCanvasRef: (ref) => dispatch(utilityActions.setWatermarkCanvasRef(ref)),
    setApplicationError : (error) => dispatch(appActions.setApplicationError(error)),
    resetAuthenticatedAppState : () => dispatch(appActions.resetAuthenticatedAppState()),
    setLoginWithPayment : (setLoginWithPayment) => dispatch(authActions.setLoginWithPayment(setLoginWithPayment)),
    ...modalActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
