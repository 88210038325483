import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
// import registerServiceWorker from './registerServiceWorker';

import * as Sentry from "@sentry/react";
import './scss/import.scss';
import 'emoji-mart/css/emoji-mart.css'
import 'react-phone-number-input/style.css'
import config from "./util/config";
import log from "./util/log";
import './i18n';

Sentry.init({
  dsn: "https://0c6b66b3aaa74912a6e15d4bc4edb18f@o384426.ingest.sentry.io/5630906",
  environment : config.debug ? "development": "production",
  beforeBreadcrumb(breadcrumb, hint) {
    if(breadcrumb && breadcrumb.message){
      //Ignore errors looking like this.  Not useful, and generates lots of extra data.
      if(breadcrumb.message.indexOf("Non-Error promise rejection captured") >= 0){
        return null;
      }
      if(breadcrumb.message.indexOf("a promise was created in a handler but was not returned from it") >= 0){
        return null;
      }
    }
    
    if(breadcrumb.category === "console"){
      //There is extra data available, but we trim it to keep personal info out
      //as well as just to keep the amount of data down
      //Without this, we can run into bug 2692
      delete breadcrumb.data.extra;
      delete breadcrumb.data.arguments;
      if(breadcrumb.message){
        //clip to first 100 chars
        breadcrumb.message = breadcrumb.message.substring(0, 100);
      }
    }
    //log.log('sendingBreadcrumb', breadcrumb);
    return breadcrumb;
  },
});

ReactDOM.render(<Root />, document.getElementById('root'));

//Kevin removed the service worker 11/15/18
//There's good reasons to have it, but equally good reasons to not.
//Long discussion here:
//https://github.com/facebook/create-react-app/issues/2398
//registerServiceWorker();
