const colors = {
  PRIMARY : '#3672B3',
  PRIMARY_DARK_TEXT : '#005fa9',
  LIGHT : '#FFF',
  DARK : '#444',
  STABLE : '#F8F8F8',
  LIGHT_GREY : '#B0AEAB',
  LIGHTER_GREY: '#C3C3C3',
  SECONDARY_TEXT : '#777777',
  GREEN : '#0F9D58',
  PRIVATE_GREEN : '#66CC33',
  NOTIFICATION : '#FF0000',
  TRANSPARENT : 'transparent',
  SIGNING : '#B226FB',
  SIGNING_HOVER : '#921CD0',
  SIGNING_INVALID : '#DB3A4D',
  BLACK : '#000',
  THREAD_COLOR_YOU :'#E6EBFA',
  THREAD_COLOR_THEM: '#EBEBEB',
  SIGNING_REQUEST: '#FD6700',
  ASSERTIVE : '#ef473a'
}

export default colors;
