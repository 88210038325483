import React, {PureComponent, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import sapi from "../../../util/sapi";
import log from "../../../util/log";

import moment from 'moment/moment';
import Promise from 'bluebird';
import _ from 'lodash';
import Loading from "../util/Loading";
import Button from "../elements/Button";
import classNames from 'classnames';
import Popover from "react-tiny-popover";
import colors from "../../../util/colors";
import {getMessageForError} from "../../../util/errors";
import modalActions from "../../../actions/modal-actions";
import workspaceActions from "../../../actions/workspace-actions";
import ThreadBadge from "../badges/ThreadBadge";
import ReactToPrint from "react-to-print";
import ManageObjectPermissionsWindow from "../../modals/ManageObjectPermissionsWindow";
import ColorGenerator from "../../../helpers/color-generator";
import sharedActions from "../../../actions/shared-actions";
import filters from "../../../helpers/filters";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../../util/withVFTranslation";

class ChatPanelHeader extends PureComponent {
  
  constructor(props) {
    super(props);
    
    this.state = {
      openGuestTooltip : null
    }
  }
  
  getGuestEmail(guest_uid) {
    let {guests, accountInfo} = this.props;
  
    if(accountInfo.uid === guest_uid){
      //This is kind of nasty...add yourself as a fake guest.
      return accountInfo.login;
    }
    
    let found = _.find(guests, (g) => {
      return g.guest_uid === guest_uid;
    })
    
    return found && found.email_address;
  }
  
  setNotifyFlag(notify_flag){
    let { workspace, thread, showAlert, t } = this.props;
    sapi.Threads.mark(workspace.forum_id, workspace.host_uid, thread.chat_id, notify_flag)
      .then((res) => {
        this.updateActiveWorkspace();
      })
      .catch((err) => {
        showAlert(t('Error Updating Workspace'), getMessageForError(err, t))
      })
  }
  
  showRename(){
    let { workspace, thread, t } = this.props;
    let doRename = (label) => {
      return sapi.Threads.rename(workspace.forum_id, thread.chat_id, label);
    }
  
    this.props.showRename(t('Rename Thread'), thread.label, doRename, (res) => {
      if(res){
        this.updateActiveWorkspace();
      }
    })
  }
  
  updateActiveWorkspace(){
    let {workspace, thread} = this.props;
    
    this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true);
  }
  
  managePermissions(){
    let { thread, workspace } = this.props;
    this.props.showManageObjectPermissionsWindow(thread.chat_id, thread.label, thread, ManageObjectPermissionsWindow.OBJECT_TYPES.THREAD, workspace, (res) => {
      if(res){
        this.props.updateNotifications();
        this.props.refreshGuests(workspace.forum_id, workspace.host_uid);
        this.props.refreshThreads(workspace.forum_id, workspace.host_uid, true);
        this.props.refreshDocs(workspace.forum_id, workspace.host_uid);
      }
    })
  }
  
  getGuestInfoContent(guest) {
    let { t } = this.props;
    return (
      <Fragment>
        <div style={styles.tooltipWrap}>
          <p className="mb-1">
            {guest.first_name} {guest.last_name}
          </p>
          <p className="mb-1">
            {this.getGuestEmail(guest.guest_uid)}
          </p>
          {guest.read_date &&
          <p className="mb-1">
            {filters.momentFilter(guest.read_date, 'dddd, MMMM Do YYYY, h:mm:ss a')}
          </p>
          }
          {!guest.read_date &&
          <p className="mb-1 font-italic">
            {t("Not yet viewed")}
          </p>
          }
        </div>
      </Fragment>
    )
  }
  
  beforePrintPreview(){
    return this.props.onBeforePrintPreview();
  }
  
  getLockedTooltip(){
    let { workspace, t } = this.props;
    if(workspace.host_uid){
      return t('Message editing is not permitted by your host.');
    }
    else{
      return t('You have disabled message editing.  You can enable message editing in your account settings.');
    }
  }
  
  render() {
    let {workspace, thread, guests, activeThreadMesgEditFlag, t} = this.props;
    let { openGuestTooltip} = this.state;
    
    return (
      <Fragment>
        <div className="primary-bg light-color p-2" style={styles.wrapper}>
          <div className={'d-flex'}>
            <div style={styles.contentCenter} className="flex-grow-1" >
              <div className="d-flex">
                <h5 className="flex-grow-1 mb-0">
                  <i style={{fontSize: '22px'}} className="icon ion-chatbox light-color mr-2" />
                  {thread.label}
                </h5>
                {!activeThreadMesgEditFlag &&
                <h5 className="mb-0" title={this.getLockedTooltip()}>
                  <i className="icon color-light ion-locked"/>
                </h5>
                }
              </div>
              <div className="d-flex">
                <div style={{lineHeight: '20px'}} className={classNames('flex-grow-1 d-block')}>
                  <span className={'font-weight-bold'}>{t("To")}: </span>
                  {guests.length === 0 &&
                    <span className="font-italic has-pointer hover-underline" onClick={this.managePermissions.bind(this)} style={styles.expandBtn}>
                      {t("No Guests have access yet.")}
                    </span>
                  }
                  {guests.map((guest, i) => {
    
                    let guestTooltipIsOpen = guest.guest_uid === openGuestTooltip;
                    return (
                      <Popover
                        key={guest.guest_uid}
                        isOpen={guestTooltipIsOpen}
                        position={'bottom'}
                        disableReposition
                        onClickOutside={() => this.setState({openGuestTooltip: null})}
                        content={() => this.getGuestInfoContent(guest)}>
                        <Fragment>
                            <span className="has-pointer hover-underline"
                                  onClick={() => guestTooltipIsOpen ?
                                    this.setState({openGuestTooltip: null}) :
                                    this.setState({openGuestTooltip: guest.guest_uid})}>
                              {`${guest.first_name} ${guest.last_name}`}
                            </span>
                          {i < guests.length - 1 &&
                          <span>, </span>
                          }
                        </Fragment>
                      </Popover>
                    )
                  })}
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </Fragment>
    )
  }
}

const styles = {
  wrapper : {
    borderTopLeftRadius : '5px',
  },
  tooltipWrap: {
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: colors.DARK,
    color: colors.LIGHT,
    fontSize: '12px'
  },
  popoverButton : {
    border : 'none',
    margin : '3px',
    fontSize: '24px',
    lineHeight : '24px',
    padding : '0px 12px'
  },
  menuHeader : {
    padding : '10px 15px',
    lineHeight : '20px',
    zIndex : 2,
    color : colors.LIGHT,
    backgroundColor : colors.SECONDARY_TEXT
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
  contentCenter : {
    overflow : 'hidden',
    fontSize : '14px'
  },
  expandBtn : {
    fontSize : '14px',
    lineHeight: '20px'
  },
  controlWrapper : {
    textAlign: 'right',
    maxWidth: '100px'
  }
}

ChatPanelHeader.propTypes = {
  thread: PropTypes.object,
  guests: PropTypes.array.isRequired,
  onBeforePrintPreview: PropTypes.func.isRequired,
  onPrintPreviewError: PropTypes.func.isRequired,
  onAfterPrintPreview:PropTypes.func.isRequired,
  getPrintPreviewContents:PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace.workspace,
    accountInfo : state.shared.accountInfo,
    activeThreadMesgEditFlag : state.workspace.activeThreadMesgEditFlag
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    ...workspaceActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChatPanelHeader));
