import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import {getErrorMessage, getMessageForError} from "../../util/errors";
import ValidationErrors from "../partials/components/ValidationErrors";
import modalActions from "../../actions/modal-actions";
import sapi from "../../util/sapi";
import log from "../../util/log";
import sharedActions from "../../actions/shared-actions";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Popover from "react-tiny-popover";
import colors from "../../util/colors";
import {withVFTranslation} from "../../util/withVFTranslation";

class GeneratedPasswordDialog extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      generatedPwd : '',
      changingPassword : false,
      validationErr : [],
      newPassword : '',
      cfmPassword : '',
      showPopover : false
    }
  }
  
  componentDidMount() {
    let { t } = this.props;
    sapi.Password.getPasswordReset()
      .then((res) => {
        this.setState({
          generatedPwd : res.data.password
        })
      })
      .catch((err) => {
        log.error('error loading password reset', err);
        this.props.showAlert(t('There was a problem generating a password for you'), getMessageForError(err, t), () => {
          this.closeModal();
        })
      })
  }
  
  closeModal() {
    this.props.close();
  }
  
  setChangePassword(changingPassword){
    this.setState({changingPassword})
  }
  
  doChangePassword(){
    if(!this.doValidate()){
      return;
    }
    let { t } = this.props;
    let { newPassword, generatedPwd} = this.state;
    sapi.Password.reset(generatedPwd, newPassword)
      .then((res) => {
        return this.props.updateAccountInfo();
      })
      .then(() => {
        this.closeModal();
      })
      .catch((err) => {
        log.error('error changing password', err);
        this.setState({
          validationErr : [ getMessageForError(err, t) ]
        })
      })
  }
  
  doValidate() {
    let { t } = this.props;
    let { newPassword, cfmPassword } = this.state;
    
    let err = [];
    if(!newPassword && newPassword.length === 0){
      err.push(t('Please enter your new password'));
    }
    if(!cfmPassword && cfmPassword.length === 0){
      err.push(t('Please confirm your new password'));
    }
    
    if(newPassword && cfmPassword && newPassword !== cfmPassword){
      err.push(t('Your passwords do not match'));
    }
  
    if(newPassword && cfmPassword && newPassword.length < 6){
      err.push(t('Your new password must be at least 6 characters.'));
    }
  
    this.setState({
      validationErr : err
    })
    return err.length === 0;
  }
  
  afterCopyToClipboard(){
    this.setState({showPopover : true}, () => {
     setTimeout(() => {
       this.setState({showPopover : false})
     }, 2000)
    })
  }
  
  renderShowPassword(){
    let { t } = this.props;
    let {generatedPwd, showPopover} = this.state;
    return (
      <div className="modal-body" style={{padding : '2rem'}}>
        <p>
          {t("We've randomly generated a strong and unique password for you. Please copy the following password and store it somewhere safe, or change it now.")}
        </p>
        <div className="text-center mt-2">
          <h3>
            {t("Your Password:")}
          </h3>
        </div>
        <div className="text-center">
          <Popover
            isOpen={showPopover}
            position={['right', 'top']}
            content={(
              <div className="alert alert-success d-flex flex-row">
                <i className="ion-checkmark-round mr-3 align-self-center" />
                <div>
                  {t("Copied to clipboard!")}
                </div>
              </div>
            )}
          >
            <h3 className="green-color d-inline-block">
              {generatedPwd}
            </h3>
          </Popover>
          <br />
          <CopyToClipboard text={generatedPwd}
                           onCopy={this.afterCopyToClipboard.bind(this)}>
            <button className="btn btn-link primary-color">
              <i className="icon ion-clipboard mr-2" />
              {t("Copy to clipboard")}
            </button>
          </CopyToClipboard>
        </div>
        <div className="mt-2">
          <p>
            {t("Passwords are not stored by Verifyle.")}
          </p>
        </div>
        <div className="text-center mt-2">
          <Button className={'btn btn-secondary mr-3'} onClick={this.closeModal.bind(this)}>{t("Keep Password")}</Button>
          <Button className={'btn btn-primary'} onClick={this.setChangePassword.bind(this, true)}>{t("Change Password")}</Button>
        </div>
      </div>
    )
  }
  
  renderChangePassword(){
    let { t } = this.props;
    let { validationErr, generatedPwd, newPassword, cfmPassword } = this.state;
    
    return (
      <div className="modal-body">
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Current Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     disabled={true}
                     value={generatedPwd}/>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("New Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     value={newPassword}
                     onChange={(evt) => this.setState({newPassword: evt.target.value})}
                     placeholder={t('Enter your new password')}/>
            </div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col'}>
            <div className="form-group">
              <label>{t("Confirm Password")}</label>
              <input className={'form-control'}
                     type={'password'}
                     value={cfmPassword}
                     onChange={(evt) => this.setState({cfmPassword: evt.target.value})}
                     placeholder={t('Confirm your new password')}/>
            </div>
          </div>
        </div>
        {validationErr.length > 0 && <ValidationErrors errors={validationErr}/>}
        <div className="text-right">
          <Button className={'btn btn-secondary mr-3'} onClick={this.setChangePassword.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} onClick={this.doChangePassword.bind(this)}>{t("Change Password")}</Button>
        </div>
      </div>
    )
  }
  
  render() {
    let {changingPassword} = this.state;
    
    return (
      <div className="modal-content">
        {!changingPassword &&
          this.renderShowPassword()
        }
        {changingPassword &&
        this.renderChangePassword()
        }
      </div>
    )
  }
}

const styles = {
  tooltipWrap: {
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: colors.DARK,
    color: colors.LIGHT,
    fontSize: '12px'
  }
}

const mapStateToProps = (state) => {
  return {
  
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...sharedActions.mapToDispatch(dispatch),
    ...modalActions.mapToDispatch(dispatch)
  };
};

GeneratedPasswordDialog.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(GeneratedPasswordDialog));
