import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types'
import GeneralTab from "../account/GeneralTab";
import {Waypoint} from "react-waypoint";
import log from "../../../util/log";
import enums from "../../../util/enums";
import colors from "../../../util/colors";
import _ from 'lodash'
import MouseRectangleSelection from "../components/MouseRectangleSelection";
import PdfSignatureRequestOverlay from "./PdfSignatureRequestOverlay";
import SignatureRequest from "../../../models/SignatureRequest";
import PdfSignatureFulfillOverlay from "./PdfSignatureFulfillOverlay";
import PdfPreview from "./PdfPreview";

class PdfPage extends Component {
  
  //This came from the pdf.js viewer.
  //https://mozilla.github.io/pdf.js/web/viewer.html
  static getOutputScale (ctx) {
    var devicePixelRatio = window.devicePixelRatio || 1;
    var backingStoreRatio = ctx.webkitBackingStorePixelRatio || ctx.mozBackingStorePixelRatio || ctx.msBackingStorePixelRatio || ctx.oBackingStorePixelRatio || ctx.backingStorePixelRatio || 1;
    var pixelRatio = devicePixelRatio / backingStoreRatio;
    return {
      sx: pixelRatio,
      sy: pixelRatio,
      scaled: pixelRatio !== 1
    };
  }
  
  isRendering = false;
  renderAgain = false;
  
  //The 300px is a little arbitrary.  I want the place holder to be something large enough
  //So that they vertically tile for the Waypoint detection to more easily figure out what's going on.
  INITIAL_STATE = {
    canvasHeight : 1,
    canvasWidth : 1,
    canvasStyleHeight : '300px',
    canvasStyleWidth : '100%'
  };
  
  constructor(props){
    super(props);
  
    this.contextRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.state = _.extend({}, this.INITIAL_STATE);
  }
  
  componentDidMount() {
    if(this.props.onCanvasRef) {
      this.props.onCanvasRef(this.props.page.pageIndex, this)
    }
    
    this.updateCanvasSize();
  }
  
  componentWillUnmount(){
    if(this.props.onCanvasRef) {
      this.props.onCanvasRef(this.props.page.pageIndex, undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.isVisible && this.props.isVisible){
      log.log('rendering page', this.props.page.pageIndex);
      this.renderPage();
    }
    else if(prevProps.scale !== this.props.scale){
      if(this.props.isVisible){
        log.log('rendering page', this.props.page.pageIndex);
        this.renderPage();
      }
      else{
        this.updateCanvasSize();
      }
    }
    else if(prevProps.windowSigningState !== this.props.windowSigningState){
      this.renderPage();
    }
    else if(prevProps.isEditingSignatureRequest !== this.props.isEditingSignatureRequest){
      this.renderPage();
    }
  }
  
  getPagePositionInScroll(){
    let node = this.wrapperRef.current;
    let rect = node.getBoundingClientRect();
  
    //log.log('page rect', this.props.page.pageIndex, rect);
  
    return {
      height : rect.height,
      width : rect.width,
      top : node.offsetTop,
      left : node.offsetLeft,
      right : node.offsetLeft + rect.width,
      bottom : node.offsetTop + rect.height
    }
  }
  
  getCanvasDimensions(){
    let node = this.contextRef.current;
    let rect = node.getBoundingClientRect();
    
    //log.log('page rect', this.props.page.pageIndex, rect);
    
    return {
      height : rect.height,
      width : rect.width,
      top : node.offsetTop,
      left : node.offsetLeft,
      right : node.offsetLeft + rect.width,
      bottom : node.offsetTop + rect.height
    }
  }
  
  updateCanvasSize(){
    //This calculates the size that the canvas will render to, based on a shared
    //hidden canvas that is always present in the modal window.
    //I do this so the scroll window size doesn't need to be recalculated as frequently.
    let { page, scale, sharedCanvasRef } = this.props;
    let viewport = page.getViewport({scale: 1}).clone({ scale });
    let ctx = sharedCanvasRef.current.getContext('2d', {
      alpha: false
    });
  
    let outputScale = PdfPage.getOutputScale(ctx);
  
    this.setState({
      canvasHeight : viewport.height * outputScale.sy | 0,
      canvasWidth : viewport.width * outputScale.sx | 0,
      canvasStyleHeight : viewport.height + 'px',
      canvasStyleWidth : viewport.width + 'px'
    })
  }
  
  renderPage(){
    if(this.isRendering){
      this.renderAgain = true;
      return;
    }
    
    if(!this.contextRef.current.getContext){
      log.warn('canvas does not exist', this.props.page.pageIndex, this.contextRef);
      this.updateCanvasSize();
      return;
    }
    
    this.isRendering = true;
    
    let { page, scale } = this.props;
    let viewport = page.getViewport({scale: 1}).clone({ scale })
    let ctx = this.contextRef.current.getContext('2d', {
      alpha: false
    });
    
    if(!ctx){
      log.warn('canvas context does not exist', this.props.page.pageIndex, this.contextRef);
      this.updateCanvasSize();
      return;
    }
    
    let outputScale = PdfPage.getOutputScale(ctx);
    
    this.setState({
      canvasHeight : viewport.height * outputScale.sy | 0,
      canvasWidth : viewport.width * outputScale.sx | 0,
      canvasStyleHeight : viewport.height + 'px',
      canvasStyleWidth : viewport.width + 'px'
    })
    
    let drawViewport = viewport.clone({scale});
  
    //Word to the wise...you CANNOT call ctx.scale() in here.
    //Doing this works SOMETIMES, but causes render problems on certain pdfs.  bug 1726
    //Instead, you're apparently supposed to use this undocumented method with this
    //transform property below.
    // I got that out of the pdf.js viewer code here - https://mozilla.github.io/pdf.js/web/viewer.html
    //I can't find any further documentation on this.
    let renderContext = {
      canvasContext: ctx,
      transform : !outputScale.scaled ? null : [outputScale.sx, 0, 0, outputScale.sy, 0, 0],
      viewport: drawViewport
    };
    
    return page.render(renderContext).promise
      .then(() => {
        if(this.renderAgain){
          this.renderAgain = false;
          this.isRendering = false;
          log.log('RERENDERING PAGE', this.props.page.pageIndex);
          return this.renderPage();
        }
      })
      .finally(() => {
        this.isRendering = false;
      })
  }
  
  onMouseSelectionFinished(res){
    log.log('mouse selection finished', res);
  
    let width = Math.abs(res.origin.x - res.target.x);
    let height = Math.abs(res.origin.y - res.target.y);
    
    if(width < 60 || height < 20){
      log.log('box too small, ignoring', width, height);
      return;
    }
    
    let coords = {
      x : res.origin.x - PdfSignatureRequestOverlay.calculateOverlayRelativeScale(PdfSignatureRequestOverlay.LEFT_BUTTON_WIDTH, this.props.scale, this.props.scale),
      y : res.origin.y
    }
    
    this.props.addSignatureRequest({
      id : _.uniqueId('vf-sig-request-overlay-'),
      pageIndex : this.props.page.pageIndex,
      coords,
      scale : this.props.scale,
      width,
      height,
      signatureType : SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE
    });
  }
  
  onSignatureOverlayTypeChange(id, newType){
    
    let update = {
      id,
      signatureType : newType
    }
    
    //Make sure this gets set to an empty string, and not null, or react complains that
    //you're changing the input from controlled to uncontrolled.
    if(newType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER){
      update.signatureCustomLabel = '';
    }
    
    this.props.updateSignatureRequest(update)
  }
  
  onSignatureOverlayCustomLabelChange(id, val){
    this.props.updateSignatureRequest({
      id,
      signatureCustomLabel : val
    })
  }
  
  onSignatureOverlayMove(id, coords){
  
    this.props.updateSignatureRequest({
      id,
      coords
    })
  }
  
  onDeleteSignature(id){
    this.props.deleteSignatureRequest(id);
  }
  
  onSignatureConfirmClick(id){
    this.props.signatureRequestOverlayConfirmed(id);
  }
  
  onSignatureMarkerSelect(id){
    this.props.signatureRequestOverlaySelected(id);
  }
  
  render() {
    let {
      canvasHeight,
      canvasWidth,
      canvasStyleHeight,
      canvasStyleWidth
    } = this.state;
    
    let {
      isVisible,
      page,
      isEditingSignatureRequest,
      scale,
      signatureRequestOverlays,
      isFulfillingSignatureRequest,
      windowSigningState
    } = this.props;
    
    return (
      <div ref={this.wrapperRef}
           style={PdfPage.styles.pageWrap}>
        <div style={windowSigningState === enums.WINDOW_SIGNING_STATUS.V1_SIGNING ? {display: 'contents'} : (isVisible ? PdfPage.styles.canvasWrap : null)}>
          {!isFulfillingSignatureRequest && signatureRequestOverlays.map((overlay) => {
            return (
              <PdfSignatureRequestOverlay
                key={overlay.id}
                overlay={overlay}
                viewScale={scale}
                allowChanges={isEditingSignatureRequest}
                isFulfillingSignatureRequest={isFulfillingSignatureRequest}
                onSignatureCustomLabelChange={this.onSignatureOverlayCustomLabelChange.bind(this, overlay.id)}
                onSignatureTypeChange={this.onSignatureOverlayTypeChange.bind(this, overlay.id)}
                deleteSignature={this.onDeleteSignature.bind(this, overlay.id)}
                onSignatureMove={this.onSignatureOverlayMove.bind(this, overlay.id)} />
            )
          })}
          {isFulfillingSignatureRequest && signatureRequestOverlays.map((overlay) => {
            return (
              <PdfSignatureFulfillOverlay
                key={overlay.id}
                overlay={overlay}
                viewScale={scale}
                onRef={(id, ref) => this.props.onFulfillSignatureOverlayRef(id, ref)}
                onMarkerSelect={this.onSignatureMarkerSelect.bind(this, overlay.id)}
                onConfirmClick={this.onSignatureConfirmClick.bind(this, overlay.id)} />
            )
          })}
    
          {isVisible &&
          <MouseRectangleSelection disabled={!isEditingSignatureRequest}
                                   onSelectFinished={this.onMouseSelectionFinished.bind(this)}>
            <canvas height={canvasHeight}
                    width={canvasWidth}
                    className={'pdf-page-canvas pdf-canvas-' + page.pageIndex}
                    style={{
                      ...(windowSigningState === enums.WINDOW_SIGNING_STATUS.V1_SIGNING ? PdfPage.styles.canvas : null), ...(isEditingSignatureRequest ? PdfPage.styles.canvasCrosshair : null), ...{
                        width: canvasStyleWidth,
                        height: canvasStyleHeight
                      }
                    }}
                    ref={this.contextRef}/>
          </MouseRectangleSelection>
          }
        </div>
        
        {!isVisible &&
        <div style={{...PdfPage.styles.canvasPlaceholder, width: canvasStyleWidth, height: canvasStyleHeight}}
             className="d-inline-block light-bg"
             ref={this.contextRef}/>
        }
      </div>
    )
  }
}

PdfPage.CANVAS_MARGIN = 20;
PdfPage.styles = {
  pageWrap : {
    textAlign: 'center',
    backgroundColor : colors.TRANSPARENT,
    display: 'flex',
    justifyContent : 'center'
  },
  canvas : {
    margin: PdfPage.CANVAS_MARGIN + 'px',
  },
  canvasWrap : {
    position : 'relative',
    margin : PdfPage.CANVAS_MARGIN + 'px'
  },
  canvasPlaceholder : {
    margin : PdfPage.CANVAS_MARGIN + 'px'
  },
  canvasCrosshair : {
    cursor : 'crosshair'
  }
}

PdfPage.propTypes = {
  pdf : PropTypes.object.isRequired,
  page : PropTypes.object.isRequired,
  scale : PropTypes.number.isRequired,
  isVisible : PropTypes.bool.isRequired,
  sharedCanvasRef : PropTypes.object.isRequired,
  onCanvasRef : PropTypes.func.isRequired,
  
  isFulfillingSignatureRequest : PropTypes.bool.isRequired,
  isEditingSignatureRequest : PropTypes.bool.isRequired,
  addSignatureRequest : PropTypes.func.isRequired,
  updateSignatureRequest : PropTypes.func.isRequired,
  deleteSignatureRequest :PropTypes.func.isRequired,
  signatureRequestOverlays : PropTypes.array.isRequired,
  signatureRequestOverlaySelected : PropTypes.func.isRequired,
  signatureRequestOverlayConfirmed : PropTypes.func.isRequired,
  onFulfillSignatureOverlayRef : PropTypes.func.isRequired,
  
  windowSigningState : PropTypes.string.isRequired,
}

export default PdfPage;
