
const enums = {
  WINDOW_SIGNING_STATUS : {
    NONE : 'none',
    V1_SIGNING : 'v1_signing',
    V2_SIGNING : 'v2_signing'
  }
}

export default enums;
