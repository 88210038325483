import c from '../../../util/const'
import log from '../../../util/log'
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import ReactToPrint from 'react-to-print';
import _ from 'lodash'
import PropTypes from 'prop-types';
import workspaceActions from "../../../actions/workspace-actions";
import modalActions from "../../../actions/modal-actions";
import filters from "../../../helpers/filters";
import Button from "../elements/Button";
import sapi from "../../../util/sapi";
import Image from "../elements/Image";
import Promise from "bluebird";
import PreviewWindow from "../../modals/PreviewWindow";
import PdfPrintPreviewSvc from "./PdfPrintPreviewSvc";
import SignatureRequest from "../../../models/SignatureRequest";
import Popover from "react-tiny-popover";
import colors from "../../../util/colors";
import pdfPreviewActions from "../../../actions/pdf-preview-actions";
import {getMessageForError} from "../../../util/errors";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import {withVFTranslation} from "../../../util/withVFTranslation";

class PDFSignatureRequestHeader extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      showingGuestTooltip: false,
      showingTermsTooltip: false,
      showingSMSTooltip: false
    }
  }
  
  showGuestTooltip(){
    log.log('show guest tooltip')
    this.setState({showingGuestTooltip : true})
  }
  
  showTermsTooltip(){
    this.setState({showingTermsTooltip : true})
  }
  
  showSMSTooltip(){
    this.setState({showingSMSTooltip : true})
  }
  
  hideSMSTooltip(){
    this.setState({showingSMSTooltip : false})
  }
  
  hideGuestTooltip() {
    this.setState({showingGuestTooltip: false})
  }
  
  hideTermsTooltip() {
    this.setState({showingTermsTooltip: false})
  }
  
  termsEditClick(){
    this.hideTermsTooltip();
    let { signatureRequestData } = this.props;
  
    this.props.showEditSignatureRequestTerms(
      signatureRequestData.terms,
      signatureRequestData.smsNumber,
      (res) => {
        this.props.pdfActions.updateSignatureRequest(res.terms, res.smsNumber);
      })
  }
  
  smsEditClick(){
    this.hideSMSTooltip()
    let { signatureRequestData } = this.props;
  
    this.props.showEditSignatureRequestTerms(
      signatureRequestData.terms,
      signatureRequestData.smsNumber,
      (res) => {
        this.props.pdfActions.updateSignatureRequest(res.terms, res.smsNumber);
      })
  }
  
  getGuestTooltipContent() {
    let { signatureRequestData } = this.props;
    
    return (
      <Fragment>
        <div style={styles.tooltipWrap}>
          <p className="mb-1">
            {signatureRequestData.guest_info.first_name} {signatureRequestData.guest_info.last_name}
          </p>
          <p className="mb-1">
            {signatureRequestData.guest_info.email_address}
          </p>
        </div>
      </Fragment>
    )
  }
  
  getTermsTooltipContent() {
    let { signatureRequestData, isEditing, t } = this.props;
    
    return (
      <Fragment>
        <div style={styles.tooltipWrap}>
          <p className="mb-1">
            {signatureRequestData.terms}
          </p>
          {isEditing &&
          <p className="mb-1 text-right">
            <a style={styles.tooltipLink}
               className="btn btn-link p-0 light-color"
               onClick={this.termsEditClick.bind(this)}>
              {t("Edit")}
            </a>
          </p>
          }
        </div>
      </Fragment>
    )
  }
  
  getSMSTooltipContent() {
    let { signatureRequestData, isEditing, t } = this.props;
    
    let formattedNumber = formatPhoneNumberIntl(signatureRequestData.smsNumber);
    return (
      <Fragment>
        <div style={styles.tooltipWrap}>
          <p className="mb-1">
            {t("A 7-digit Signature Code will be sent to:")}
          </p>
          <p className="mb-1">
            {formattedNumber}
          </p>
          {isEditing &&
          <p className="mb-1 text-right">
            <a style={styles.tooltipLink}
               className="btn btn-link p-0 light-color"
               onClick={this.smsEditClick.bind(this)}>
              {t("Edit")}
            </a>
          </p>
          }
        </div>
      </Fragment>
    )
  }
  
  cancelRequest() {
    log.log('cancel request click')
    this.props.cancelSignatureClick();
  }
  
  editRequestClick() {
    log.log('edit request click')
    this.props.editRequestClick();
  }
  
  submitRequestClick() {
    log.log('submit request click')
    this.props.submitSignatureClick();
  }
  
  cancelEditRequest(){
    this.props.cancelEditRequestClick();
  }
  
  renderIncompleteRequest() {
    let {signatureRequestData, hasChanges, t} = this.props;
    let {showingGuestTooltip, showingTermsTooltip, showingSMSTooltip} = this.state;
    
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <p className="mb-1" style={{fontSize: '14px'}}>
                <span>{t("The signature request for")} </span>
                <Popover
                  isOpen={showingGuestTooltip}
                  position={'bottom'}
                  disableReposition
                  onClickOutside={this.hideGuestTooltip.bind(this)}
                  content={() => this.getGuestTooltipContent()}>
                  <span onClick={this.showGuestTooltip.bind(this)} style={styles.tooltipItem}>
                    {signatureRequestData.guest_info.first_name} {signatureRequestData.guest_info.last_name}
                  </span>
                </Popover>
                {signatureRequestData.terms &&
                <Fragment>
                  <span> {t("with")} </span>
                  <Popover
                    isOpen={showingTermsTooltip}
                    position={'bottom'}
                    disableReposition
                    onClickOutside={this.hideTermsTooltip.bind(this)}
                    content={() => this.getTermsTooltipContent()}>
                    <span onClick={this.showTermsTooltip.bind(this)} style={styles.tooltipItem}>{t("Terms")}</span>
                  </Popover>
                </Fragment>
                }
                {signatureRequestData.smsNumber &&
                <Fragment>
                  <span> {signatureRequestData.terms ? t('and') : t('with')} </span>
                  <Popover
                    isOpen={showingSMSTooltip}
                    position={'bottom'}
                    disableReposition
                    onClickOutside={this.hideSMSTooltip.bind(this)}
                    content={() => this.getSMSTooltipContent()}>
                    <span onClick={this.showSMSTooltip.bind(this)} style={styles.tooltipItem}>{t("Signature Code")}</span>
                  </Popover>
                </Fragment>
                }
                <span> {t("is incomplete.")} </span>
                {(!signatureRequestData.terms && !signatureRequestData.smsNumber) &&
                <span onClick={this.termsEditClick.bind(this)} style={styles.tooltipItem}>
                  {t("Add Requirements.")}
                </span>
                }
              </p>
              <p className="mb-1" style={{fontSize: '14px'}}>
                {t("Click and drag on document to add input fields.")}
              </p>
            </div>
            <div className="col-sm-auto text-right" style={{lineHeight: '52px'}}>
              <Button className="btn btn-sm btn-outline-light to_orange mr-3" onClick={this.cancelRequest.bind(this)}>
                {t("Cancel Request")}
              </Button>
              <Button disabled={!hasChanges} className="btn btn-sm btn-outline-light to_orange" onClick={this.submitRequestClick.bind(this)}>
                {t("Submit Request")}
              </Button>
            </div>
          </div>
        </div>
        
      </>
    )
  }
  
  renderEditRequest() {
    let { signatureRequestData, hasChanges, isEditing, saveChangesDisabled, t } = this.props;
    let {showingGuestTooltip, showingTermsTooltip, showingSMSTooltip} = this.state;

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <span style={{fontSize: '14px', verticalAlign: 'text-top'}}>
                <Popover
                  isOpen={showingGuestTooltip}
                  position={'bottom'}
                  disableReposition
                  onClickOutside={this.hideGuestTooltip.bind(this)}
                  content={() => this.getGuestTooltipContent()}>
                  <span onClick={this.showGuestTooltip.bind(this)} style={styles.tooltipItem}>
                    {signatureRequestData.guest_info.first_name} {signatureRequestData.guest_info.last_name}
                  </span>
                </Popover>
                <span> {t("has been notified of your request")}{(!signatureRequestData.terms && !signatureRequestData.smsNumber) ? '. ' : ''}</span>
                
                {(!signatureRequestData.terms && !signatureRequestData.smsNumber) &&
                <span onClick={this.termsEditClick.bind(this)} style={styles.tooltipItem}>
                  {t("Add Requirements")}
                </span>
                }
                
                {(signatureRequestData.terms || signatureRequestData.smsNumber) &&
                  <span> {t("with")} </span>
                }
                
                {signatureRequestData.terms &&
                <Fragment>
                  <Popover
                    isOpen={showingTermsTooltip}
                    position={'bottom'}
                    disableReposition
                    onClickOutside={this.hideTermsTooltip.bind(this)}
                    content={() => this.getTermsTooltipContent()}>
                      <span onClick={this.showTermsTooltip.bind(this)} style={styles.tooltipItem}>
                        {t("Terms")}
                      </span>
                  </Popover>
                </Fragment>
                }
  
                {(signatureRequestData.terms && signatureRequestData.smsNumber) &&
                <span> {t("and")} </span>
                }
                
                {signatureRequestData.smsNumber &&
                <Fragment>
                  <Popover
                    isOpen={showingSMSTooltip}
                    position={'bottom'}
                    disableReposition
                    onClickOutside={this.hideSMSTooltip.bind(this)}
                    content={() => this.getSMSTooltipContent()}>
                      <span onClick={this.showSMSTooltip.bind(this)} style={styles.tooltipItem}>
                        {t("Signature Code")}
                      </span>
                  </Popover>
                </Fragment>
                }
                <span>.</span>
              </span>
            </div>
            <div className="col-sm-auto text-right">
              {isEditing &&
              <Fragment>
                <span className="mr-3" style={{fontSize: '14px', verticalAlign: 'text-top'}}>{t("Signer will be notified of your changes.")}</span>
                <Button className="btn btn-sm btn-outline-light to_orange mr-3" onClick={this.cancelEditRequest.bind(this)}>
                  {t("Cancel")}
                </Button>
                <Button className="btn btn-sm btn-outline-light to_orange" disabled={saveChangesDisabled} onClick={this.submitRequestClick.bind(this)}>
                  {t("Save Changes")}
                </Button>
              </Fragment>
    
              }
              {!isEditing &&
              <Fragment>
                <Button className="btn btn-sm btn-outline-light to_orange mr-3" onClick={this.cancelRequest.bind(this)}>
                  {t("Cancel Request")}
                </Button>
                <Button className="btn btn-sm btn-outline-light to_orange" onClick={this.editRequestClick.bind(this)}>
                  {t("Edit Request")}
                </Button>
              </Fragment>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
  
  render() {
    let {signatureRequestData} = this.props;
    
    return (
      <Fragment>
        <div className="signing-request-bg light-color py-2">
          {(signatureRequestData.status.sign_status === SignatureRequest.SIGN_STATUS.INCOMPLETE ||
            signatureRequestData.status.sign_status === SignatureRequest.SIGN_STATUS.NONE) &&
          this.renderIncompleteRequest()
          }
          {signatureRequestData.status.sign_status === SignatureRequest.SIGN_STATUS.REQUESTED &&
          this.renderEditRequest()
          }
        </div>
      </Fragment>
    )
  }
}

const styles = {
  tooltipItem: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  tooltipWrap: {
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: colors.DARK,
    color: colors.LIGHT,
    fontSize: '12px'
  },
  tooltipLink: {
    fontSize: '12px',
    textDecoration: 'underline',
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    pdfActions : {...pdfPreviewActions.mapToDispatch(dispatch)},
  };
};

PDFSignatureRequestHeader.propTypes = {
  signatureRequestData: PropTypes.object.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isEditing : PropTypes.bool.isRequired,
  cancelSignatureClick: PropTypes.func.isRequired,
  submitSignatureClick: PropTypes.func.isRequired,
  editRequestClick: PropTypes.func.isRequired,
  cancelEditRequestClick: PropTypes.func.isRequired,
  saveChangesDisabled: PropTypes.bool.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PDFSignatureRequestHeader));
