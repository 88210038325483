import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import { isFirefox } from 'react-device-detect';
import PropTypes from 'prop-types';

import Draggable from "react-draggable";
import log from '../../../util/log';
import colors from "../../../util/colors";
import config from "../../../util/config";
import Button from "../elements/Button";
import classnames from 'classnames';
import _ from 'lodash'
import {buildFontPicker, fontConstants, buildFontOption} from "../../../util/font-constants";
import PdfPreview from "./PdfPreview";
import {withVFTranslation} from "../../../util/withVFTranslation";

class PdfSigningOverlay extends Component {
  
  fontSizes = [
    {"display":"8", "val":"8px"},
    {"display":"10", "val":"10px"},
    {"display":"12", "val":"12px"},
    {"display":"14", "val":"14px"},
    {"display":"16", "val":"16px"},
    {"display":"18", "val":"18px"},
    {"display":"20", "val":"20px"},
    {"display":"22", "val":"22px"},
    {"display":"24", "val":"24px"},
  ]
  
  //Hack for the initial position
  OVERLAY_WIDTH = 358
  
  INITIAL_STATE = {
    font : PdfSigningOverlay.FONTS.LA_BELLE_AURORE,
    fontSize : '14px',
    sigText : '',
    controlledPosition : null,
    isDragging : false
  }
  
  constructor(props){
    super(props);
    
    this.inputRef = React.createRef();
    this.wrapRef = React.createRef();
    
    this.state = this.INITIAL_STATE;
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this.props.id, this)
    }
    
    document.addEventListener('mousedown', this.handleMouseDown.bind(this), false);
    
    this.onOverlayActivate();
  }
  
  initializePosition(signaturePositionList){
    
    let resCoords = {x : 0, y : 0};
    let scrollWindow = document.getElementById(PdfPreview.MODAL_SCROLL_CONTAINER);
    resCoords.y = scrollWindow.scrollTop + 200;
    resCoords.x = (scrollWindow.scrollLeft + (scrollWindow.offsetWidth / 2)) - (this.OVERLAY_WIDTH / 2);
  
    const findMatchingPosition = (coords) => _.find(signaturePositionList, (position) => coords.x === position.x && coords.y === position.y);
    
    const pxIncrement = 20;
    const positionSequence = [
      {dX : pxIncrement, dY : pxIncrement},
      {dX : -(pxIncrement * 2), dY : 0},
      {dX : 0, dY : -(pxIncrement * 2)},
      {dX : (pxIncrement * 2), dY : 0},
      {dX : 0, dY : 0}, //original position
    ]
    let tryCount = 0;
    let sequenceIndex = 0;
    let positionInLoop = resCoords;
    let newOverlayPosition = null;
    while(!newOverlayPosition){
      if(!positionSequence[sequenceIndex]){
        //If you've run through the sequence, bail and return the initial position.
        newOverlayPosition = resCoords;
      }
      else {
        let positionMatch = findMatchingPosition(positionInLoop);
        if (positionMatch) {
          let sequence = positionSequence[sequenceIndex];
          positionInLoop = {
            x: positionInLoop.x + sequence.dX,
            y: positionInLoop.y + sequence.dY
          }
          sequenceIndex++;
        }
        else {
          newOverlayPosition = positionInLoop;
        }
  
        tryCount++;
      }
    }
    
    log.log('picked overlay position', newOverlayPosition);
    
    this.setState({
      controlledPosition : newOverlayPosition
    })
  }
  
  getFontOptionPicker(){
    let { t } = this.props;
    
    return buildFontPicker([
      buildFontOption(t('Handwriting Font'), fontConstants.LA_BELLE_AURORE),
      buildFontOption(t('Helvetica'), fontConstants.HELVETICA),
      buildFontOption(t('Times New Roman'), fontConstants.TIMES_NEW_ROMAN),
      buildFontOption(t('Courier'), fontConstants.COURIER)
    ])
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(this.props.id, undefined);
    }
  
    document.removeEventListener('mousedown', this.handleMouseDown.bind(this), false);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.isActive && this.props.isActive){
      this.onOverlayActivate();
    }
    // else if(prevProps.isActive && !this.props.isActive){
    //   this.deactivateOverlay();
    // }
  }
  
  handleMouseDown(e){
    if(!this.wrapRef.current){
      return;
    }
    
    if(this.wrapRef.current.contains(e.target)){
      this.props.setActiveOverlay(this.props.id, true);
      return;
    }
    
    this.deactivateOverlay();
  }
  
  deactivateOverlay(){
    let { setActiveOverlay } = this.props;
    let { sigText } = this.state;
  
    if(!sigText || sigText.length === 0){
      this.deleteSig();
    }
    else{
      setActiveOverlay(this.props.id, false);
    }
  }
  
  onOverlayActivate(){
    this.doFocusInput();
  }
  
  doFocusInput(){
    setTimeout(() => {
      if(this.inputRef.current){
        this.inputRef.current.focus();
      }
    })
  }
  
  onFontChange(newVal){
    this.setState({font : newVal})
    this.doFocusInput();
  }
  
  onFontSizeChange(newVal){
    this.setState({fontSize : newVal})
    this.doFocusInput();
  }
  
  onSigTextChange(evt){
    this.setState({sigText : evt.target.value}, () => {
      this.props.validateOverlay(this.props.id);
    });
  }
  
  handleDragStart(e, position){
    //log.log('drag start', e, position);
  
    const {x, y} = position;
    this.setState({
      isDragging : true,
      controlledPosition :{x, y}
    })
  }
  
  handleDrag(e, position){
    //log.log('handleDrag', e, position);
  
    const {x, y} = position;
    this.setState({
      controlledPosition :{x, y}
    })
  }
  
  handleDragStop(e, position){
    const {x, y} = position;
    this.setState({
      isDragging : false,
      controlledPosition :{x, y}
    })
    
    this.props.validateOverlay(this.props.id);
    this.doFocusInput();
  }
  
  deleteSig(){
    this.setState(_.extend({}, this.INITIAL_STATE));
    this.props.deleteSignature(this.props.id);
  }
  
  getFontClass(){
    let { font } = this.state;
    
    let cls = '';
    _.each(this.getFontOptionPicker(), (opt) => {
      if(opt.val === font){
        cls = opt.cls;
      }
    })
    
    return cls;
  }
  
  getSignaturePositionOffsets(){
    let { font, fontSize } = this.state;
    let node = this.inputRef.current;
    let nodeStyle = window.getComputedStyle(node)
    
    let paddingLeft = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('padding-left'))
    let paddingTop = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('padding-top'))
    let inputLineHeight = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('line-height'))
    var inputLineHeightOffset = (inputLineHeight - PdfSigningOverlay.parseValFromPx(fontSize)) / 2;
    let verticalOffset = PdfSigningOverlay.getVerticalFontOffset(font, fontSize);
    
    return {
      left : node.offsetLeft + paddingLeft,
      top : node.offsetTop + paddingTop + inputLineHeightOffset + verticalOffset
    }
  }
  
  hasSignatureText(){
    return this.state.sigText.length > 0;
  }
  
  setControlledPosition(x, y){
    this.setState({
      controlledPosition : {x, y}
    })
  }
  
  getControlledPosition(){
    return {
      x : this.state.controlledPosition.x,
      y : this.state.controlledPosition.y
    }
  }
  
  getOverlayValues(){
    let {
      font,
      fontSize,
      sigText,
      controlledPosition
    } = this.state;
    
    let { id } = this.props;
    let offsets = this.getSignaturePositionOffsets();
    
    let signaturePosition = {
      x : controlledPosition.x + offsets.left,
      y : controlledPosition.y + offsets.top
    }
    
    return {
      id,
      font,
      fontSize,
      sigText,
      overlayPosition : controlledPosition,
      signaturePosition
    }
  }
  
  render(){
    let {
      font,
      fontSize,
      sigText,
      isDragging,
      controlledPosition } = this.state;
    let {
      isActive,
      isValidPosition
    } = this.props;
    
    let zIndex = 10;
    let indicatorColor = colors.SIGNING;
    let overlayColor = colors.SIGNING;
    let headerStyle = PdfSigningOverlay.styles.header;
    if(!isActive){
      zIndex = 1;
      overlayColor = colors.TRANSPARENT;
      headerStyle = {...headerStyle, ...PdfSigningOverlay.styles.invisible}
    }
  
    if(isDragging){
      overlayColor = colors.SIGNING_HOVER;
      indicatorColor = colors.SIGNING_HOVER;
    }
    else if(!isValidPosition){
      overlayColor = colors.SIGNING_INVALID;
      indicatorColor = colors.SIGNING_INVALID;
    }
    
    if(!controlledPosition){
      return ( < Fragment /> )
    }
    
    return (
      <Draggable
        cancel={'select, button, .not-draggable'}
        bounds={'parent'}
        scale={1}
        position={controlledPosition}
        onStart={this.handleDragStart.bind(this)}
        onDrag={this.handleDrag.bind(this)}
        onStop={this.handleDragStop.bind(this)}>
        <div ref={this.wrapRef} style={{...PdfSigningOverlay.styles.overlay, zIndex}}>
          <div>
            <div style={{...headerStyle, backgroundColor : overlayColor}}>
              
              {/*Pretty disappointed in react here.  In firefox we apparently can't get <select> values from the onChange handler here*/}
              {/*So our fix is to listen to click events on <option> elements, which then doesn't work anywhere else.  Pretty sloppy.*/}
              {isFirefox &&
              <Fragment>
                <select className="form-control mr-2"
                        style={PdfSigningOverlay.styles.fontPicker}
                        value={font}
                        onChange={(evt) => evt.preventDefault()}>
                  { this.getFontOptionPicker().map((option) => {
                    //some problem between react and firefox makes us watch the onClick event on <option>
                    //instead of watching onChange on the <select>.  that's crap guys.
                    //I think it's this - https://github.com/facebook/react/issues/12584
                    return <option className={option.cls}
                                   key={option.val}
                                   onClick={this.onFontChange.bind(this, option.val)}
                                   value={option.val}>
                      {option.display}
                    </option>
                  })}
                </select>
                <select className="form-control mr-2"
                        style={PdfSigningOverlay.styles.fontSizePicker}
                        value={fontSize}
                        ref={this.fontSizeRef}
                        onChange={(evt) => evt.preventDefault()}>
                  { this.fontSizes.map((option) => {
                    //some problem between react and firefox makes us watch the onClick event on <option>
                    //instead of watching onChange on the <select>.  that's crap guys.
                    //I think it's this - https://github.com/facebook/react/issues/12584
                    return <option key={option.val}
                                   onClick={this.onFontSizeChange.bind(this, option.val)}
                                   value={option.val}>
                      {option.display}
                    </option>
                  })}
                </select>
              </Fragment>
              }
              {!isFirefox &&
              <Fragment>
                <select className="form-control mr-2"
                        style={PdfSigningOverlay.styles.fontPicker}
                        value={font}
                        onChange={(evt) => this.onFontChange(evt.target.value)}>
                  { this.getFontOptionPicker().map((option) => {
                    //some problem between react and firefox makes us watch the onClick event on <option>
                    //instead of watching onChange on the <select>.  that's crap guys.
                    //I think it's this - https://github.com/facebook/react/issues/12584
                    return <option className={option.cls}
                                   key={option.val}
                                   value={option.val}>
                      {option.display}
                    </option>
                  })}
                </select>
                <select className="form-control mr-2"
                        style={PdfSigningOverlay.styles.fontSizePicker}
                        value={fontSize}
                        ref={this.fontSizeRef}
                        onChange={(evt) => this.onFontSizeChange(evt.target.value)}>
                  { this.fontSizes.map((option) => {
                    //some problem between react and firefox makes us watch the onClick event on <option>
                    //instead of watching onChange on the <select>.  that's crap guys.
                    //I think it's this - https://github.com/facebook/react/issues/12584
                    return <option key={option.val}
                                   value={option.val}>
                      {option.display}
                    </option>
                  })}
                </select>
              </Fragment>
              }
              <Button className="btn signing-v1-delete-signature" onClick={this.deleteSig.bind(this)}>
                <i className="icon ion-trash-a" />
              </Button>
            </div>
            <div style={PdfSigningOverlay.styles.bottomRow}>
              <i style={{...PdfSigningOverlay.styles.iconIndicator, color : indicatorColor}}
                 className="icon ion-arrow-right-b" />
              <input className={classnames("form-control d-inline-block not-draggable", this.getFontClass())}
                     style={{...PdfSigningOverlay.styles.input, fontSize}}
                     onChange={this.onSigTextChange.bind(this)}
                     ref={this.inputRef}
                     type="text"
                     value={sigText}/>
            </div>
          </div>
        </div>
      </Draggable>
    )
  }
}

PdfSigningOverlay.FONTS = {
  HELVETICA : fontConstants.HELVETICA.familyName,
  LA_BELLE_AURORE : fontConstants.LA_BELLE_AURORE.familyName,
  TIMES_NEW_ROMAN : fontConstants.TIMES_NEW_ROMAN.familyName,
  COURIER : fontConstants.COURIER.familyName,
}

PdfSigningOverlay.getVerticalFontOffset = (font, fontSize) => {
  
  let parsedFontSize = PdfSigningOverlay.parseValFromPx(fontSize);
  
  let offsetLookup = null;
  switch (font){
    case PdfSigningOverlay.FONTS.HELVETICA:
      offsetLookup = [
        { from : 8, to: 8 , offset : -2},
        { from : 10, to: 10 , offset : -1},
        { from : 12, to: 14 , offset : -2},
        { from : 16, to: 18 , offset : -3},
        { from : 20, to: 24 , offset : -4},
      ]
      break;
    case PdfSigningOverlay.FONTS.LA_BELLE_AURORE:
      offsetLookup = [
        { from : 8, to: 8 , offset : -3},
        { from : 10, to: 12 , offset : -4},
        { from : 14, to: 14 , offset : -5},
        { from : 16, to: 16 , offset : -6},
        { from : 18, to: 18 , offset : -7},
        { from : 20, to: 20 , offset : -7},
        { from : 22, to: 22 , offset : -8},
        { from : 24, to: 24 , offset : -9},
      ]
      break;
    case PdfSigningOverlay.FONTS.COURIER:
      offsetLookup = [
        { from : 8, to: 8 , offset : -2},
        { from : 10, to: 14 , offset : -3},
        { from : 16, to: 18 , offset : -4},
        { from : 20, to: 20 , offset : -5},
        { from : 22, to: 22 , offset : -6},
        { from : 24, to: 24 , offset : -6},
      ]
      break;
    case PdfSigningOverlay.FONTS.TIMES_NEW_ROMAN:
      offsetLookup = [
        { from : 8, to: 12 , offset : -2},
        { from : 14, to: 20 , offset : -3},
        { from : 22, to: 24 , offset : -4},
      ]
      break;
  }
  
  let offset = 0;
  _.each(offsetLookup, (entry) => {
    if(parsedFontSize >= entry.from && parsedFontSize <= entry.to){
      offset = entry.offset;
    }
  })
  return offset;
}

PdfSigningOverlay.parseValFromPx = (pxVal) => {
  return +pxVal.split('px')[0];
}

PdfSigningOverlay.styles = {
  overlay : {
    position: 'absolute',
  },
  invisible : {
    userSelect : 'none',
    pointerEvents : 'none',
    opacity : 0,
    pointer : 'default'
  },
  header : {
    color : colors.LIGHT,
    paddingLeft: '40px',
    paddingRight: '40px',
    borderTopLeftRadius: '40px',
    borderTopRightRadius: '40px',
    display : 'flex',
    paddingTop : '5px',
    paddingBottom : '5px',
    cursor : 'pointer'
  },
  bottomRow : {
    display : 'flex',
    cursor : 'pointer'
  },
  iconIndicator: {
    fontSize: '30px',
    lineHeight : '38px',
  },
  fontPicker : {
    minWidth: '170px'
  },
  fontSizePicker :{
    minWidth: '55px'
  },
  input : {
    padding : '0px 5px',
    color : colors.BLACK,
    verticalAlign: 'baseline',
    lineHeight : '38px',
    boxShadow : 'none',
    border : 'none',
    backgroundColor: colors.TRANSPARENT,
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

PdfSigningOverlay.propTypes = {
  id : PropTypes.string.isRequired,
  isActive : PropTypes.bool.isRequired,
  isValidPosition : PropTypes.bool.isRequired,
  validateOverlay : PropTypes.func.isRequired,
  setActiveOverlay : PropTypes.func.isRequired,
  deleteSignature : PropTypes.func.isRequired,
  onRef : PropTypes.func.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfSigningOverlay));
