import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Draggable from "react-draggable";
import log from '../../../util/log';
import colors from "../../../util/colors";
import config from "../../../util/config";
import Button from "../elements/Button";
import classnames from 'classnames';
import _ from 'lodash'
import SignatureRequest from "../../../models/SignatureRequest";
import moment from 'moment';
import filters from "../../../helpers/filters";
import utils from "../../../util/util";
import PdfSigningOverlay from "./PdfSigningOverlay";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class PdfSignatureFulfillOverlay extends Component {
  
  INITIAL_STATE = {
    customText : '',
    confirmEnabled : false,
    customDate : null,
  }
  
  constructor(props){
    super(props);
    
    this.signatureRef = React.createRef();
    
    this.state = _.extend({}, this.INITIAL_STATE);
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this.props.overlay.id, this)
    }
    
    if(!this.state.customDate){
      this.setState({customDate : moment().unix()})
    }
  }
  
  componentWillUnmount() {
    this.setState({customDate : null})
    if(this.props.onRef) {
      this.props.onRef(this.props.overlay.id, null)
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.overlay !== this.props.overlay){
      if(this.props.overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER && !prevProps.overlay.selected && this.props.overlay.selected){
        log.log('focusing custom input')
        this.signatureRef.current.focus();
      }
    }
  }
  
  //investigate
  getOverlayPositionOffsetsForCustomType(){
    let node = this.signatureRef.current;
    let nodeStyle = window.getComputedStyle(node)
  
    let paddingLeft = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('padding-left'))
    let paddingTop = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('padding-top'))
    let borderLeft = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('border-width-left'))
    let borderTop = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('border-width-top'))
    let inputLineHeight = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('line-height'))
    let inputLineHeightOffset = (inputLineHeight - CUSTOM_INPUT_FONT_SIZE) / 2;
    let verticalOffset = PdfSigningOverlay.getVerticalFontOffset(PdfSigningOverlay.FONTS.HELVETICA, CUSTOM_INPUT_FONT_SIZE + 'px');
  
    return {
      left : paddingLeft + borderLeft + PdfSignatureFulfillOverlay.LEFT_BUTTON_WIDTH,
      top : paddingTop + borderTop + inputLineHeightOffset + verticalOffset
    }
  }
  
  //investigate
  getOverlayPositionOffsetsForSignedDate(){
    let node = this.signatureRef.current;
    let nodeStyle = window.getComputedStyle(node)
  
    let borderLeft = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('border-width-left'))
    let borderTop = PdfSigningOverlay.parseValFromPx(nodeStyle.getPropertyValue('border-width-top'))
    let verticalOffset = PdfSigningOverlay.getVerticalFontOffset(PdfSigningOverlay.FONTS.COURIER, SIGNED_DATE_FONT_SIZE + 'px');
  
    let hackHeight = 3;
    return {
      left : borderLeft + PdfSignatureFulfillOverlay.LEFT_BUTTON_WIDTH,
      top : borderTop + hackHeight + verticalOffset
    }
  }
  
  //investigate
  getOverlayDataResult(){
    let { overlay } = this.props;
    if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE){
      let offsets = this.getOverlayPositionOffsetsForSignedDate();
      return {
        x : overlay.coords.x + offsets.left,
        y : overlay.coords.y + offsets.top,
        text : filters.momentFilter(moment().unix(), 'YYYY-MM-DD'),
        font : PdfSigningOverlay.FONTS.COURIER,
        fontSize : `${this.scaleRelativeToOverlay(14)}px`,
      }
    }
    else if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER){
      let offsets = this.getOverlayPositionOffsetsForCustomType()
      return {
        x : overlay.coords.x + offsets.left,
        y : overlay.coords.y + offsets.top,
        text : this.state.customText,
        font : PdfSigningOverlay.FONTS.HELVETICA,
        fontSize : `${this.scaleRelativeToOverlay(CUSTOM_INPUT_FONT_SIZE)}px`
      }
    }
    else{
      throw new Error('unsupported signature type ' + overlay.signatureType);
    }
  }
  
  generateOverlayImage(){
    let { accountSignatures, overlay } = this.props;
    
    return new Promise((resolve, reject) => {
      let scaledOverlayWidth = overlay.width;
      let scaledOverlayHeight = overlay.height;
      if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE){
        utils.calculateResizedImage(accountSignatures.sign_image, scaledOverlayWidth, scaledOverlayHeight)
          .then((newDimensions) => {
            resolve({
              width : newDimensions.width,
              height : newDimensions.height,
              data : accountSignatures.sign_image
            })
          })
          .catch((err) => {
            reject(err);
          })
      }
      else if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.INITIALS){
        utils.calculateResizedImage(accountSignatures.init_image, scaledOverlayWidth, scaledOverlayHeight)
          .then((newDimensions) => {
            resolve({
              width : newDimensions.width,
              height : newDimensions.height,
              data : accountSignatures.init_image
            })
          })
          .catch((err) => {
            reject(err);
          })
      }
      else{
        throw new Error('unsupported signature type ' + overlay.signatureType);
      }
    })
  }
  
  static calculateOverlayRelativeScale(val, overlayScale, viewScale){
    return val * (viewScale / overlayScale);
  }
  
  scaleRelativeToOverlay(val){
    let { overlay, viewScale } = this.props;
    return PdfSignatureFulfillOverlay.calculateOverlayRelativeScale(val, overlay.scale, viewScale);
  }
  
  onCustomTextChange(evt){
    this.setState({customText : evt.target.value});
  }
  
  onConfirmClick(){
    this.props.onConfirmClick()
  }
  
  onMarkerClick(){
    this.props.onMarkerSelect();
  }
  
  getConfirmButtonDisabled(){
    let { overlay } = this.props;
    let { customText } = this.state;
    
    if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER){
      return customText.length === 0;
    }
    return false;
  }
  
  render(){
    let {
      overlay,
      accountSignatures,
      t
    } = this.props;
    let { customText, customDate } = this.state;
  
    let scaledOverlayWidth = this.scaleRelativeToOverlay(overlay.width);
    let scaledOverlayHeight = this.scaleRelativeToOverlay(overlay.height);
    
    let boxHeight = scaledOverlayHeight;
    if(overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE && !overlay.confirmed){
      boxHeight += this.scaleRelativeToOverlay(DATE_FIELD_WARNING_SIZE);
    }
    
    return (
      <>
        {accountSignatures &&
        <Draggable
          handle=".handle"
          disabled={true}
          bounds={'parent'}
          position={{
            x : this.scaleRelativeToOverlay(overlay.coords.x),
            y : this.scaleRelativeToOverlay(overlay.coords.y)
          }}>
          <div style={{
            position : 'absolute',
            zIndex : 1000000
          }}>
            <div className="d-flex" style={{height: `${boxHeight}px`}}>
              <div>
                <button onClick={this.onMarkerClick.bind(this)}
                        className="btn btn-icon handle"
                        style={{
                          paddingTop: '0px',
                          paddingLeft: this.scaleRelativeToOverlay(12) + 'px',
                          paddingRight: this.scaleRelativeToOverlay(12) + 'px',
                          fontSize: `${this.scaleRelativeToOverlay(32)}px`,
                          color: colors.SIGNING_REQUEST,
                          boxShadow: 'none',
                          display: 'block',
                          height: this.scaleRelativeToOverlay(30) + 'px',
                          maxHeight: scaledOverlayHeight + 'px',
                        }}>
                <i className={`icon icon-top ion-bookmark has-pointer ${overlay.confirmed ? 'green-color' : 'signature-request-color'}`} />
                </button>
              </div>
          
              {overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.OTHER && (overlay.selected || overlay.confirmed) &&
              <input className={`form-control flex-grow-1 signature-request-input ${overlay.confirmed ? 'confirmed' : ''}`}
                     style={{
                       padding : '0px 5px 0px 5px',
                       width: `${scaledOverlayWidth}px`,
                       height: `${scaledOverlayHeight}px`,
                       lineHeight: `${scaledOverlayHeight}px`,
                       fontSize : `${this.scaleRelativeToOverlay(CUSTOM_INPUT_FONT_SIZE)}px`,
                     }}
                     value={customText}
                     placeholder={overlay.signatureCustomLabel}
                     onChange={this.onCustomTextChange.bind(this)}
                     ref={this.signatureRef}
                     disabled={overlay.confirmed}/>
              }
          
              {overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNATURE && (overlay.selected || overlay.confirmed) &&
              <div className={`signature-request-input ${overlay.confirmed ? 'confirmed' : ''}`}
                   ref={this.signatureRef}
                   style={{
                     textAlign: 'left',
                     width: `${scaledOverlayWidth}px`,
                     height: `${scaledOverlayHeight}px`
                   }}>
                <img src={accountSignatures.sign_image}
                     style={{
                       verticalAlign : 'super',
                       maxWidth: `${scaledOverlayWidth}px`,
                       maxHeight: `${scaledOverlayHeight}px`
                     }}/>
              </div>
              }
          
              {overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.INITIALS && (overlay.selected || overlay.confirmed) &&
              <div className={`signature-request-input ${overlay.confirmed ? 'confirmed' : ''}`}
                   ref={this.signatureRef}
                   style={{
                     textAlign: 'left',
                     width: `${scaledOverlayWidth}px`,
                     height: `${scaledOverlayHeight}px`
                   }}>
                <img src={accountSignatures.init_image}
                     style={{
                       verticalAlign : 'super',
                       maxWidth: `${scaledOverlayWidth}px`,
                       maxHeight: `${scaledOverlayHeight}px`
                     }}/>
              </div>
              }
          
              {overlay.signatureType === SignatureRequest.SIGNATURE_REQUEST_TYPE.SIGNED_DATE && (overlay.selected || overlay.confirmed) &&
              <div className={`signature-request-input text-left ${overlay.confirmed ? 'confirmed' : ''}`}
                   ref={this.signatureRef}
                   style={{
                     ...{
                       width: `${scaledOverlayWidth}px`,
                       height: `${boxHeight}px`
                     }
                   }}>
                <div className="courier"
                     style={{fontSize : `${this.scaleRelativeToOverlay(SIGNED_DATE_FONT_SIZE)}px`}}>
                  {filters.momentFilter(customDate, 'YYYY-MM-DD')}
                </div>
                {!overlay.confirmed &&
                <div
                  style={{
                    fontSize : '8px',
                    height: `${this.scaleRelativeToOverlay(DATE_FIELD_WARNING_SIZE)}px`,
                    lineHeight: `${this.scaleRelativeToOverlay(DATE_FIELD_WARNING_SIZE)}px`
                  }}>
                  ({t("Dated on Submission")})
                </div>
                }
              </div>
              }
        
            </div>
            {!overlay.confirmed && overlay.selected &&
            <div className="text-right pt-1">
              <button onClick={this.onConfirmClick.bind(this)}
                      style={{
                        transform: `scale(${this.scaleRelativeToOverlay(1)}, ${this.scaleRelativeToOverlay(1)})`,
                        transformOrigin: 'right top',
                      }}
                      disabled={this.getConfirmButtonDisabled()}
                      className="btn btn-sm btn-signing">
                {t("Confirm")}
              </button>
            </div>
            }
          </div>
        </Draggable>
        }
      </>
    )
  }
}

const DATE_FIELD_WARNING_SIZE = 12;

const CUSTOM_INPUT_FONT_SIZE = 16;
const SIGNED_DATE_FONT_SIZE = 14;

const mapStateToProps = (state) => {
  return {
    accountSignatures : state.shared.signatures
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

//This is calculated at scale = 1.  I need this from outside the component in order to calculate
//Proper overlay positions with the draggable handle.
//Just a heads up that I just measured this in the browser and hardcoded the size.  It's probably
//possible to calculate this and pass it around, but that involves references to rendered components which is harder to deal with in code.
PdfSignatureFulfillOverlay.LEFT_BUTTON_WIDTH = 38;

PdfSignatureFulfillOverlay.propTypes = {
  viewScale : PropTypes.number.isRequired,
  overlay : PropTypes.object.isRequired,
  onConfirmClick : PropTypes.func.isRequired,
  onMarkerSelect : PropTypes.func.isRequired,
  onRef : PropTypes.func,
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(PdfSignatureFulfillOverlay));
