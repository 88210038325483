import c from '../../util/const';
import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import log from "../../util/log";
import sapi from '../../util/sapi';
import Loading from "../partials/util/Loading";

import workspaceActions from '../../actions/workspace-actions';
import _ from 'lodash';
import Promise from 'bluebird';
import Image from "../partials/elements/Image";
import filters from "../../helpers/filters";
import DocInfo from "../partials/doc-preview/DocInfo";
import ImgPreview from "../partials/doc-preview/ImgPreview";
import PdfPreview from "../partials/pdf-preview/PdfPreview";
import TextPreview from "../partials/doc-preview/TextPreview";
import SvgPreview from "../partials/doc-preview/SvgPreview";
import classnames from 'classnames'
import AnimateHeight from "react-animate-height";
import utils from "../../util/util";
import pdfPreviewActions from "../../actions/pdf-preview-actions";

class PDFSignatureRequestWindow extends Component {
  
  constructor(props) {
    super(props);
    
    this.props.pdfActions.loadSignatureRequestData(this.props.modalProps.doesSignatureRequestExist, this.props.modalProps.signatureRequestData);
  }
  
  render() {
    let { signatureRequestData } = this.props.modalProps;
    return (
      <PdfPreview forum_id={signatureRequestData.forum_id}
                  host_uid={signatureRequestData.host_uid}
                  doc_id={signatureRequestData.doc_id}
                  chat_id={signatureRequestData.chat_id}
                  onInitiateSignatureRequest={_.noop}
                  doc_info={signatureRequestData.doc_info}
                  close={this.props.close}/>
    )
  }
}

PDFSignatureRequestWindow.MODAL_XL = true;

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshWorkspace : (forum_id) => dispatch(workspaceActions.refreshWorkspace(forum_id)),
    refreshDocs : (forum_id, host_uid) => dispatch(workspaceActions.refreshDocs(forum_id, host_uid)),
    pdfActions : {...pdfPreviewActions.mapToDispatch(dispatch)},
  };
};

PDFSignatureRequestWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(PDFSignatureRequestWindow);
