import c from '../util/const';
import vfLocalStorage from '../util/local-storage';
import sapi from '../util/sapi';
import log from '../util/log';

import Promise from 'bluebird';

let testSavedAuthentication = () => {
  let token = vfLocalStorage.get(c.localstorage.token);
  
  if(!token){
    return Promise.reject("No Token in Local Storage");
  }
  
  let sid = vfLocalStorage.get(c.localstorage.sid);
  let vip = vfLocalStorage.get(c.localstorage.vip);
  
  //We later require all of these keys to exist, and this gets set at login time.
  if(!sid || !vip){
    return Promise.reject("Local storage misconfigured");
  }
  
  return sapi.Ping.test(token)
}

let hasSavedAuthentication = () => {
  let token = vfLocalStorage.get(c.localstorage.token);
  
  return token && token.length > 0;
}

export default {
  hasSavedAuthentication,
  testSavedAuthentication
}