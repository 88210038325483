import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import sapi from "../../../../util/sapi";
import log from "../../../../util/log";
import Image from "../../elements/Image";
import colors from "../../../../util/colors";
import utils from "../../../../util/util";
import _ from 'lodash'
import ValidationErrors from "../../components/ValidationErrors";
import {fontConstants, buildFontPicker, buildFontOption} from "../../../../util/font-constants";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class AccountSignaturesPanel extends Component {
  
  constructor(props) {
    super(props);
    
    this.signatureRef = React.createRef();
    this.signatureTextAreaRef = React.createRef();
    this.initialsRef = React.createRef();
    this.initialsTextAreaRef = React.createRef();
  
    let {accountInfo} = props;
    
    let firstState = {
      sigFont : fontConstants.LA_BELLE_AURORE.familyName,
      sigFontCls : fontConstants.LA_BELLE_AURORE.class,
      sigCanvasSize : {width : 1, height : 1},
      initCanvasSize : {width :1, height : 1},
      initFont : fontConstants.LA_BELLE_AURORE.familyName,
      initFontCls : fontConstants.LA_BELLE_AURORE.class,
      saving : false,
      showSignatureImage : !!(props.accountSignatures && props.accountSignatures.sign_image),
      showInitialsImage : !!(props.accountSignatures && props.accountSignatures.init_image),
      validationErr : []
    };
    
    if(!props.accountSignatures){
      firstState.sigText = `${accountInfo.first_name} ${accountInfo.last_name}`
      firstState.initText = `${accountInfo.first_name[0]}${accountInfo.last_name[0]}`.toUpperCase()
    }
    else{
      firstState.sigText = "";
      firstState.initText = "";
    }
    
    this.state = firstState;
  }
  
  componentDidMount() {
    const {modalProps} = this.props;
    
    if(this.props.onRef){
      this.props.onRef(this);
    }
    
    if(!this.props.accountSignatures) {
      this.redrawSignatureCanvasEnsuringFontIsReady();
      this.redrawInitialsCanvasEnsuringFontIsReady();
    }
  }
  
  redrawSignatureCanvasEnsuringFontIsReady(){
    setTimeout(() => {
      this.redrawSignatureCanvas()
        .then(() => {
          setTimeout(() => {
            this.redrawSignatureCanvas()
          }, 100)
        })
    })
  }

  redrawInitialsCanvasEnsuringFontIsReady(){
    setTimeout(() => {
      this.redrawInitialsCanvas()
        .then(() => {
          setTimeout(() => {
            this.redrawInitialsCanvas()
          }, 100)
        })
    })
  }

  componentWillUnmount() {
    if(this.props.onRef){
      this.props.onRef(undefined);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.accountInfo.first_name !== this.props.accountInfo.first_name){
      this.redrawSignatureCanvasEnsuringFontIsReady();
      this.redrawInitialsCanvasEnsuringFontIsReady();
    }
    else if(prevProps.accountInfo.last_name !== this.props.accountInfo.last_name){
      this.redrawSignatureCanvasEnsuringFontIsReady();
      this.redrawInitialsCanvasEnsuringFontIsReady();
    }
    if(prevProps.accountSignatures !== this.props.accountSignatures){
      this.setState({
        showSignatureImage : false,
        showInitialsImage : false
      }, () => {
        this.redrawSignatureCanvasEnsuringFontIsReady();
        this.redrawInitialsCanvasEnsuringFontIsReady();
      })
    }
  }

  getFontOptionPicker(){
    let { t } = this.props;
    return buildFontPicker([
      buildFontOption(t('Handwriting Font'), fontConstants.LA_BELLE_AURORE),
      buildFontOption(t('Cedarville Cursive'), fontConstants.CEDARVILLE_CURSIVE),
      buildFontOption(t('Sacramento'), fontConstants.SACRAMENTO),
      buildFontOption(t('Dancing Script'), fontConstants.DANCING_SCRIPT)
    ])
  }
  
  waitForSigCanvas(){
    return utils.waitForCondition(() => {
      return !!this.signatureRef.current;
    }, 50)
  }

  waitForInitCanvas(){
    return utils.waitForCondition(() => {
      return !!this.initialsRef.current;
    }, 50)
  }

  getPixelRatio(canvas) {
    var ctx = canvas.getContext("2d"),
      dpr = window.devicePixelRatio || 1,
      bsr = ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio || 1;
    
    return dpr / bsr;
  }
  
  redrawSignatureCanvas() {
    return new Promise((resolve, reject) => {
      this.waitForSigCanvas()
        .then(() => {
          let {sigFont, sigText} = this.state;
          
          let text = sigText;
          let ctx = this.signatureRef.current.getContext('2d');
          ctx.font = `30px ${sigFont}`;
          let measuredText = ctx.measureText(text);
          this.setState({
            sigCanvasSize: {
              width: measuredText.width + 12,
              height: AccountSignaturesPanel.SIG_IMG_HEIGHT
            }
          }, () => {
            setTimeout(() => {
              if(this.signatureRef && this.signatureRef.current) {
                let ctx = this.signatureRef.current.getContext('2d');
                ctx.clearRect(0, 0, this.signatureRef.current.width, this.signatureRef.current.height);
                ctx.font = `30px ${sigFont}`;
                ctx.fillText(text, 2, 32);
              }
              resolve(true);
            })
          })
        })
    })
  }
  
  redrawInitialsCanvas(){
    return new Promise((resolve, reject) => {
      this.waitForInitCanvas()
        .then(() => {
          let {initFont, initText} = this.state;
      
          let text = initText;
          let ctx = this.initialsRef.current.getContext('2d');
          ctx.font = `30px ${initFont}`;
          let measuredText = ctx.measureText(text);
          this.setState({
            initCanvasSize: {
              width: measuredText.width + 12,
              height: AccountSignaturesPanel.SIG_IMG_HEIGHT
            }
          }, () => {
            setTimeout(() => {
              if(this.initialsRef && this.initialsRef.current) {
                let ctx = this.initialsRef.current.getContext('2d');
                ctx.clearRect(0, 0, this.initialsRef.current.width, this.initialsRef.current.height);
                ctx.font = `30px ${initFont}`;
                ctx.fillText(text, 2, 32);
              }
              resolve(true);
            })
          })
        })
    })
  }
  
  reset() {
    let {accountInfo, accountSignatures} = this.props;
  
    let resetState = {
      sigFont : fontConstants.LA_BELLE_AURORE.familyName,
      sigFontCls : fontConstants.LA_BELLE_AURORE.class,
      sigCanvasSize : {width : 1, height : 11},
      initCanvasSize : {width :1, height : 1},
      initFont : fontConstants.LA_BELLE_AURORE.familyName,
      initFontCls : fontConstants.LA_BELLE_AURORE.class,
      saving : false,
      showSignatureImage : !!(accountSignatures && accountSignatures.sign_image),
      showInitialsImage : !!(accountSignatures && accountSignatures.init_image),
      validationErr : []
    }
  
    if(!this.props.accountSignatures){
      resetState.sigText = `${accountInfo.first_name} ${accountInfo.last_name}`
      resetState.initText = `${accountInfo.first_name[0]}${accountInfo.last_name[0]}`.toUpperCase()
    }
    else{
      resetState.sigText = "";
      resetState.initText = "";
    }
    
    this.setState(resetState);
  
    this.redrawSignatureCanvasEnsuringFontIsReady();
    this.redrawInitialsCanvasEnsuringFontIsReady();
  }
  
  onSigFontChange(evt){
    let opt = _.find(this.getFontOptionPicker(), (opt) => opt.val === evt.target.value);
    
    this.setState({
      sigFont : evt.target.value,
      sigFontCls : opt.cls,
      showSignatureImage : false
    }, () => {
      this.redrawSignatureCanvasEnsuringFontIsReady();
    })
  }
  
  onSigImgClick(){
    this.setState({
      showSignatureImage : false
    }, () => {
      this.redrawSignatureCanvasEnsuringFontIsReady();
      utils.waitForCondition(() => {
        return !!this.signatureTextAreaRef;
      })
        .then(() => {
          this.signatureTextAreaRef.current.focus();
        })
    })
  }
  
  onInitImgClick(){
    this.setState({
      showInitialsImage : false
    }, () => {
      this.redrawInitialsCanvasEnsuringFontIsReady();
      utils.waitForCondition(() => {
          return !!this.initialsTextAreaRef;
        })
        .then(() => {
          this.initialsTextAreaRef.current.focus();
        })
    })
  }
  
  onInitFontChange(evt){
    let opt = _.find(this.getFontOptionPicker(), (opt) => opt.val === evt.target.value);
    
    this.setState({
      initFont : evt.target.value,
      initFontCls : opt.cls,
      showInitialsImage : false
    }, () => {
      this.redrawInitialsCanvasEnsuringFontIsReady();
    })
  }
  
  generateImages(){
    let { accountSignatures } = this.props;
    let { showInitialsImage, showSignatureImage } = this.state;
  
    let sig = null;
    let init = null;
    if(showInitialsImage){
      init = accountSignatures.init_image;
    }
    else{
      init = this.initialsRef.current.toDataURL('image/png', 1.0);
    }
    if(showSignatureImage){
      sig = accountSignatures.sign_image;
    }
    else{
      sig = this.signatureRef.current.toDataURL('image/png', 1.0);
    }
    
    return {
      sign_image : sig,
      init_image : init
    }
  }
  
  onSignatureTextUpdate(evt){
    this.setState({sigText : evt.target.value}, () => {
      this.redrawSignatureCanvasEnsuringFontIsReady();
    })
  }
  
  onInitTextUpdate(evt){
    this.setState({initText : evt.target.value}, () => {
      this.redrawInitialsCanvasEnsuringFontIsReady();
    })
  }
  
  doValidation(){
    let {
      showSignatureImage,
      showInitialsImage,
      initText,
      sigText
    } = this.state;
    
    let err = [];
    if(!showSignatureImage){
      if(sigText.length === 0){
        err.push(this.props.t("Please enter your signature"));
      }
    }
    
    if(!showInitialsImage){
      if(initText.length === 0){
        err.push(this.props.t("Please enter your initials"));
      }
    }
    
    this.setState({validationErr : err});
    return err.length === 0;
  }
  
  render(){
    let { accountSignatures, t } = this.props;
    let {
      sigFont,
      initFont,
      sigCanvasSize,
      initCanvasSize,
      showSignatureImage,
      showInitialsImage,
      sigText,
      sigFontCls,
      initText,
      initFontCls,
      validationErr
    } = this.state;
    
    return(
      <>
        <div className="row">
          <div className="col-7">
            <div className="form-inline mb-2">
              <label className="mr-2 ml-4">{t('Signature')}</label>
              <select className="form-control"
                      value={sigFont}
                      onChange={this.onSigFontChange.bind(this)}>
                { this.getFontOptionPicker().map((option) => {
                  return <option className={option.cls}
                                 key={option.val}
                                 value={option.val}>
                    {option.display}
                  </option>
                })}
              </select>
            </div>
            <div style={styles.signatureBox}>
              {showSignatureImage &&
              <div style={styles.sigImg}
                   onClick={this.onSigImgClick.bind(this)}>
                {accountSignatures && <Image imgHeight={AccountSignaturesPanel.SIG_IMG_HEIGHT} src={accountSignatures.sign_image} alt={t('Signature')} /> }
              </div>
              }
              {!showSignatureImage &&
              <>
              <textarea className={"m-auto text-center d-table position-absolute no-resize " + sigFontCls}
                        style={styles.textarea}
                        maxLength={AccountSignaturesPanel.SIG_MAX_LENGTH}
                        onChange={this.onSignatureTextUpdate.bind(this)}
                        ref={this.signatureTextAreaRef}
                        value={sigText}/>
                <canvas ref={this.signatureRef}
                        width={sigCanvasSize.width}
                        height={sigCanvasSize.height}
                        style={{
                          zIndex : 5,
                          width : `${sigCanvasSize.width}px`,
                          height : `${sigCanvasSize.height}px`
                        }}
                        className="m-auto text-center d-table invisible position-absolute" />
              </>
              }
      
            </div>
          </div>
          <div className="col-5 ">
            <div className="form-inline mb-2">
              <label className="mr-2 ml-4">{t("Initials")}</label>
              <select className="form-control"
                      value={initFont}
                      onChange={this.onInitFontChange.bind(this)}>
                { this.getFontOptionPicker().map((option) => {
                  return <option className={option.cls}
                                 key={option.val}
                                 value={option.val}>
                    {option.display}
                  </option>
                })}
              </select>
            </div>
            <div style={styles.signatureBox}>
              {showInitialsImage &&
              <div style={styles.sigImg}
                   onClick={this.onInitImgClick.bind(this)}>
                {accountSignatures && <Image imgHeight={AccountSignaturesPanel.SIG_IMG_HEIGHT} src={accountSignatures.init_image} alt={t('Initials')} /> }
              </div>
              }
              {!showInitialsImage &&
              <>
              <textarea className={"m-auto text-center d-table position-absolute no-resize " + initFontCls}
                        style={styles.textarea}
                        maxLength={AccountSignaturesPanel.INIT_MAX_LENGTH}
                        onChange={this.onInitTextUpdate.bind(this)}
                        ref={this.initialsTextAreaRef}
                        value={initText}/>
                <canvas ref={this.initialsRef}
                        width={initCanvasSize.width}
                        height={initCanvasSize.height}
                        style={{
                          zIndex : 5,
                          width: `${initCanvasSize.width}px`,
                          height: `${initCanvasSize.height}px`
                        }}
                        className="m-auto text-center d-table invisible position-absolute"/>
              </>
              }
            </div>
          </div>
        </div>
        {validationErr.length > 0 &&
          <div className="row">
            <div className="col mt-2">
              <ValidationErrors errors={validationErr} />
            </div>
          </div>
        }
      </>
    )
  }
  
}

AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT = 167;
AccountSignaturesPanel.SIG_MAX_LENGTH = 40;
AccountSignaturesPanel.INIT_MAX_LENGTH = 5;
AccountSignaturesPanel.SIG_IMG_HEIGHT = 55;

const styles = {
  signatureBox : {
    border : `1px solid ${colors.LIGHT_GREY}`,
    borderRadius : '5px',
    textAlign : 'center',
    minHeight : AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT + 'px',
    position : 'relative',
    overflow : 'hidden'
  },
  textarea : {
    border: 'none',
    fontSize : '30px',
    lineHeight : '30px',
    paddingTop: '56px',
    zIndex: 6,
    width: "100%",
    height: "100%",
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    overflow:'hidden'
  },
  sigImg : {
    marginTop: (AccountSignaturesPanel.MAX_TEXTAREA_HEIGHT - AccountSignaturesPanel.SIG_IMG_HEIGHT) / 2 + 'px'
  }
}

AccountSignaturesPanel.propTypes = {
  onRef : PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    accountSignatures : state.shared.signatures,
    accountInfo : state.shared.accountInfo
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(AccountSignaturesPanel));
