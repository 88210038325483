import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../util/api';
import log from '../../../util/log';
import c from '../../../util/const';
import { getMessageForError } from "../../../util/errors";

import PropTypes from 'prop-types';

import FancyInput from '../elements/FancyInput';

import authActions from "../../../actions/auth-actions";
import modalActions from '../../../actions/modal-actions';
import PulseButton from "../elements/PulseButton";
import _ from "lodash";
import utils from "../../../util/util";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../util/withVFTranslation";

class EmailCard extends Component {
  
  constructor(props){
    super(props);
    
    this.state = {
      email: props.email || '',
      memberNumber: props.memberNumber || '',
      doValidationErr: null,
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  
    //If member number is
    let defaultClassId = _.get(this.props.institution, 'info_json.default_class_id');
    if(defaultClassId){
      utils.waitForCondition(() => {
        return !!this.memberNumberField;
      })
        .then(() => {
          this.memberNumberField.focus();
        })
    }
  }

  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  triggerValidationErr() {
    if(this.emailField){
      this.emailField.triggerValidationErr();
    }
  }

  focusEmail(){
    this.emailField.focus();
  }

  getValidatedEmailValue(){
    let { email } = this.state;

    if(email && email.length > 0){
      return email;
    }
    else{
      return '';
    }
  }

  validateForm() {
    let { email } = this.state;
  
    this.props.setValidationErrors([]);
    this.setState({
      doValidationErr: false,
    });

    if(!email || email.length === 0){
      this.setState({
        doValidationErr: true,
      });
      this.emailField.triggerValidationErr();
      return false;
    }
    if(!this.validateMemberNumberRegexIfNeeded()){
      this.setState({
        doValidationErr: true,
      });
      this.memberNumberField.triggerValidationErr();
      return false;
    }
    else{
      return true;
    }
  }

  validateMemberNumberRegexIfNeeded(){
    
    //bug 2386 - we want to allow for a custom regex for a member number.
    //just load
    
    let defaultClassId = _.get(this.props.institution, 'info_json.default_class_id');
    let memberNumberFormat = _.get(this.props.institution, 'info_json.member_number_format');
    if(defaultClassId && memberNumberFormat){
      let result = null;
      try{
        result = this.state.memberNumber.match(memberNumberFormat);
      }
      catch(err){
        console.error('invalid regex', memberNumberFormat, err);
      }
      return result && result.length === 1;
    }
    return true;
  }
  
  doNextButtonClicked(){
    
    //Prevents a problem, where if you hit enter and immediately start typing a password
    //Sometimes a couple password characters would end up in the email field.  bug 2588
    if(this.emailField){
      this.emailField.blur();
    }
    
    let { email, memberNumber } = this.state;
    let { setEmail, setMemberNumber, setStep, institution, t } = this.props;

    if(!this.validateForm()){
      return;
    }

    this.props.setLoading(true);
    setEmail(email);
    
    let lookup = null;
    if(memberNumber){
      setMemberNumber(memberNumber);
      lookup = api.Lookup.post(email, institution.inst_id, memberNumber);
    }
    else{
      lookup = api.Lookup.post(email);
    }
    
    lookup
      .then((res) => {
        if(res && res.data && res.data.verified_flag){
          setStep(c.authSteps.password)
        }
        else{
          setStep(c.authSteps.signup)
        }
      })
      .catch((err) => {
        log.log('error on lookup', err);
        if(err.name === 'APP_NOT_FOUND'){
          setStep(c.authSteps.signup)
        }
        else{
          this.props.setValidationErrors([
            getMessageForError(err, t)
          ]);
        }
      })
      .finally(() => {
        this.props.setLoading(false);
      })
  }

  onEmailChange(val){
    this.props.setEmail(val);
    this.setState ({
      email: val
    })
  }
  
  onMemberNumberFocus(evt){
    let {
      step,
      setStep,
      setEmailFieldVisible,
      setPreventEmailDisabled,
      preventNavOnEmailFocus
    } = this.props;
  
    //On safari, when you click on the saved password dialog for some reason it fires a focus event on the email input
    //I'm not sure why this is, but it comes through as not trusted, and user input focus events come through as trusted,
    //Which is what we want.  Still weird.  Bug 1829
    if(evt && evt.nativeEvent && !evt.nativeEvent.isTrusted){
      log.log('received programatic focus on member card.  ignoring');
      return;
    }
  
    if(step !== c.authSteps.email && !preventNavOnEmailFocus){
      setStep(c.authSteps.email);
      setEmailFieldVisible(true);
      setPreventEmailDisabled(false);
    }
  }
  
  onEmailFocus(evt){
    let {
      step,
      setStep,
      setEmailFieldVisible,
      setPreventEmailDisabled,
      preventNavOnEmailFocus
    } = this.props;

    //On safari, when you click on the saved password dialog for some reason it fires a focus event on the email input
    //I'm not sure why this is, but it comes through as not trusted, and user input focus events come through as trusted,
    //Which is what we want.  Still weird.  Bug 1829
    // if(evt && evt.nativeEvent && !evt.nativeEvent.isTrusted){
    //   log.log('received programatic focus on email card.  ignoring');
    //   return;
    // }
    //
    // log.log('onEmailFocus', step, preventNavOnEmailFocus);
    // if(step !== c.authSteps.email && !preventNavOnEmailFocus){
    //   setStep(c.authSteps.email);
    //   setEmailFieldVisible(true);
    //   setPreventEmailDisabled(false);
    // }
  }
  
  onMemberNumberChange(val){
    this.setState ({
      memberNumber: val
    })
  }

  showMemberNumberInfo(text){
    let { t } = this.props;
    this.props.showAuthInfo(
      t('Member Number'),
      <div>
        <div>
          <p>
            {text}
          </p>
        </div>
      </div>
    )
  }
  
  showInfo(){
    let { t } = this.props;
    this.props.showAuthInfo(
      t('Why do we need an email address?'),
      <div>
        <div>
          <p>
            {t("Verifyle is a system for secure communication. We need to verify an email address for you so the people you share with can know it’s really you. We also use your email address to let you know when others in Verifyle have shared a message or file with you.")}
          </p>
          <p>
            {t("Verifyle doesn’t show ads and it doesn’t sell user information &mdash; not anonymously, not in aggregate, not ever. You can get more details in our")} <a target={'_blank'} href={c.links.privacy}>{t("Privacy Policy")}</a>.
          </p>
          <p className="pt-3">
            {t("Contact us at")}
            <br/>
            <a href={c.links.mailToSupport}>{t("support@verifyle.com")}</a>
          </p>
        </div>
      </div>
    )
  }

  render() {
    let { step, institution, t } = this.props;
    let { doValidationErr } = this.state;
    
    let memberNumberInfo = _.get(institution, 'info_json.member_number_info');
    let memberNumberAttrs = {};
    if(memberNumberInfo){
      memberNumberAttrs.infoBtnCls = 'ion-ios-information-outline auth-info-icon has-pointer';
      memberNumberAttrs.onInfoClick = this.showMemberNumberInfo.bind(this, memberNumberInfo);
    }
    
    return (
      <div>
        {institution && institution.info_json && institution.info_json.default_class_id &&
        <div className={'row'}>
          <div className={'col'}>
            <div className={'form-group'}>
              <FancyInput onRef={ref => (this.memberNumberField = ref)}
                          placeholder={t('Member Number')}
                          inputDisabled={false}
                          fieldType={'text'}
                          onFocus={this.onMemberNumberFocus.bind(this)}
                          inputValue={this.state.memberNumber}
                          onEnter={this.doNextButtonClicked.bind(this)}
                          onChange={this.onMemberNumberChange.bind(this)}
                          {...memberNumberAttrs}/>
            </div>
          </div>
        </div>
        }
        <div className={'row'}>
          <div className={'col'}>
            <div className={'form-group'}>
              <FancyInput onRef={ref => (this.emailField = ref)}
                          placeholder={t('Username')}
                          inputDisabled={false}
                          fieldType={'text'}
                          isValid={!doValidationErr}
                          hideInfoBtn={step !== c.authSteps.email}
                          inputValue={this.state.email}
                          infoBtnCls={'ion-ios-information-outline auth-info-icon has-pointer'}
                          onFocus={this.onEmailFocus.bind(this)}
                          onInfoClick={this.showInfo.bind(this)}
                          onEnter={this.doNextButtonClicked.bind(this)}
                          onChange={this.onEmailChange.bind(this)}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//There's some complicated interactions here around navigating directly
//to the boarding card, in which case email hasn't been initialized and we want
//the user to fill it in at that time.  This is the only case this is necessary and
//needs some extra controls around it.
EmailCard.propTypes = {
  onRef : PropTypes.func,
  setLoading : PropTypes.func.isRequired,
  setValidationErrors : PropTypes.func.isRequired,
  preventNavOnEmailFocus: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    email : state.auth.email,
    step : state.auth.step,
    institution : state.auth.institution,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStep: step => dispatch(authActions.setNextStep(step)),
    setEmail: email => dispatch(authActions.setEmail(email)),
    setMemberNumber: number => dispatch(authActions.setMemberNumber(number)),
    showAuthInfo: (title, contents, cb) => dispatch(modalActions.showAuthInfo(title, contents, cb)),
    setEmailFieldVisible : isVisible => dispatch(authActions.setEmailFieldVisible(isVisible)),
    setPreventEmailDisabled : preventDisabled => dispatch(authActions.setPreventEmailDisabled(preventDisabled))
  };
};

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmailCard));
