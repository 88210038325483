import React, {Component, Fragment} from 'react';
import sharedActions from "../../actions/shared-actions";
import homeActions from "../../actions/home-actions";
import appActions from "../../actions/app-actions";
import modalActions from "../../actions/modal-actions";
import {withVFTranslation} from "../../util/withVFTranslation";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import Header from "../partials/components/Header";
import SearchWindow from "../modals/SearchWindow";
import AdminHeader from "../partials/components/AdminHeader";
import NotificationIcon from "../partials/components/NotificationIcon";
import colors from "../../util/colors";
import _ from "lodash";
import ScrollingTabView from "../partials/components/ScrollingTabView";
import vfLocalStorage from "../../util/local-storage";
import c from "../../util/const";
import sapi from "../../util/sapi";
import log from "../../util/log";
import classNames from "classnames";
import GuestList from "../partials/rows/GuestList";
import DMPanel from "../partials/chat/DMPanel";
import MemberList from "../partials/rows/MemberList";
import {getMessageForError} from "../../util/errors";

class AdminDashboard extends Component {
  
  //allowed sorts - first_name, last_name, or active_flag
  //should be a server sort
  
  SORTS = {
    USER_FIRST_NAME_ASC : 'user_first_name_asc',
    USER_FIRST_NAME_DESC : 'user_first_name_desc',
    USER_LAST_NAME_ASC : 'user_last_name_asc',
    USER_LAST_NAME_DESC : 'user_last_name_desc',
    ACTIVE : 'user_active',
    INACTIVE : 'user_inactive'
  }
  
  SERVER_SORT_TYPES = {
    FIRST_NAME : 'first_name',
    LAST_NAME : 'last_name',
    ACTIVE : 'active_flag'
  }
  
  MEMBER_TAB = 'admin-console-member-tab';
  TABVIEW_ID = 'admin-console-scroll-tabview';
  
  PAGE_SIZE = 100;
  
  constructor(props) {
    super(props);
    
    this.setMemberSort = this.setMemberSort.bind(this);
    
    let loadedContactSort = vfLocalStorage.get(c.localstorage.contactSort);
  
    if(!loadedContactSort || _.values(this.SORTS).indexOf(loadedContactSort) < 0){
      loadedContactSort = this.SORTS.USER_LAST_NAME_ASC;
    }
    
    this.state = {
      contactSort : loadedContactSort,
      loading: false,
      guestListScrollRef : null,
      sortedMembers : null,
      
      isImporting : false,
      isFinishedImporting : false,
      previousImportQueue : null,
      
      adminInfo : null,
      memberList : null,
      
      pageNumber : 0,
      hasMoreMembers : true,
      isLoadingMoreMembers : false
    }
  }
  
  componentDidMount() {
    this.refreshData();
  }
  
  refreshData(){
    this.refreshMemberList();
    Promise.all([
        sapi.Member.info(),
      ])
      .then((res) => {
        this.setState({
          adminInfo : res[0].data,
        })
      })
      .catch((err) => {
        log.log('error loading dashboard', err);
      })
  }
  
  loadMoreMembers(){
    let { hasMoreMembers } = this.state;
    if(!hasMoreMembers){
      log.log('loadMoreMembers, no more results');
      return;
    }
    log.log('loadMoreMembers');
    
    this.setState({isLoadingMoreMembers : true})
    let query = this.getServerSortParams();
    
    let offset = this.state.pageNumber + 1;
    this.setState({pageNumber : offset})
    
    sapi.Member.list(query.sort, query.reverseSort, query.limit, offset)
      .then((res) => {
        this.setState({
          memberList : [...this.state.memberList, ...res.data],
          hasMoreMembers : res.data.length === this.PAGE_SIZE,
          isLoadingMoreMembers : false
        })
      })
      .catch((err) => {
        log.log('error loading dashboard', err);
        this.setState({isLoadingMoreMembers : false})
      })
  }
  
  getServerSortParams(){
    let {
      contactSort,
    } = this.state;
    let serverSort = null;
    let doReverseSort = false;
    let limit = this.PAGE_SIZE;
    if(contactSort === this.SORTS.USER_LAST_NAME_DESC){
      serverSort = this.SERVER_SORT_TYPES.LAST_NAME;
      doReverseSort = true;
    }
    else if(contactSort === this.SORTS.USER_LAST_NAME_ASC){
      serverSort = this.SERVER_SORT_TYPES.LAST_NAME;
      doReverseSort = false;
    }
    else if(contactSort === this.SORTS.USER_FIRST_NAME_ASC){
      serverSort = this.SERVER_SORT_TYPES.FIRST_NAME;
      doReverseSort = false;
    }
    else if(contactSort === this.SORTS.USER_FIRST_NAME_DESC){
      serverSort = this.SERVER_SORT_TYPES.FIRST_NAME;
      doReverseSort = true;
    }
    else if(contactSort === this.SORTS.ACTIVE){
      serverSort = this.SERVER_SORT_TYPES.ACTIVE;
      doReverseSort = true;
    }
    else if(contactSort === this.SORTS.INACTIVE){
      serverSort = this.SERVER_SORT_TYPES.ACTIVE;
      doReverseSort = false;
    }
    
    return {
      sort : serverSort,
      reverseSort : doReverseSort,
      limit,
    }
  }
  
  refreshMemberList(){
    let query = this.getServerSortParams();
    
    sapi.Member.list(query.sort, query.reverseSort, query.limit, 0)
      .then((res) => {
        log.log('refreshMemberList done', res, res.data.length < this.PAGE_SIZE);
        this.setState({
          memberList : res.data,
          hasMoreMembers : res.data.length === this.PAGE_SIZE
        })
      })
      .catch((err) => {
        log.log('error loading dashboard', err);
      })
      .finally(() => {
        this.monitorContactImportQueue()
      })
  }
  
  setMemberSort(sort){
    log.log('setMemberSort', sort);
    vfLocalStorage.set(c.localstorage.contactSort, sort);
    this.setState({
      contactSort : sort
    }, () => {
      this.refreshMemberList();
    })
  }
  
  onRemoveMember(member){
    let { t } = this.props;
    let { adminInfo } = this.state;
    log.log('onRemoveMember', member);
  }
  
  onMemberClick(member){
    log.log('onMemberClick', member);
    
    let {
      adminInfo
    } = this.state;
    
    this.props.showMemberInfoWindow(member, adminInfo, (res) => {
      log.log('show member info res', res);
      if(res){
        this.refreshMemberList();
      }
    })
  }
  
  onGuestListRef(ref){
    this.setState({guestListScrollRef : ref})
  }
  
  renderMemberContents(){
    let { t } = this.props;
    let { memberList, hasMoreMembers, isLoadingMoreMembers } = this.state;
    
    if(!memberList){
      return null;
    }
    
    if(memberList.length === 0){
      return (
        <div className="text-center"  style={{marginTop: '15vh'}}>
          <h4 className="dark-color">
            {t("There are no members currently in this institution.  Add one below.")}
          </h4>
          {/*<p className="secondary-text-color">*/}
          {/*  {t("Are you sure this is a real institution?")}*/}
          {/*</p>*/}
          <div className="mt-5">
            <button className="btn btn-lg btn-primary"
                    onClick={this.memberAddClick.bind(this)}>
              {t("Add a Member")}
            </button>
          </div>
        </div>
      )
    }
  
    log.log('render member contents', memberList, hasMoreMembers, isLoadingMoreMembers);
    return (
      <div className="row h-auto">
        <div style={{
          paddingRight: '0px',
          marginRight: '-5px',
          overflowY: 'auto'
        }} className={classNames('col center-col tab-view')}>
          <MemberList contacts={memberList}
                      hasMoreItems={hasMoreMembers}
                      isLoadingMoreMembers={isLoadingMoreMembers}
                      onRemoveGuest={this.onRemoveMember.bind(this)}
                      onLoadMoreItems={this.loadMoreMembers.bind(this)}
                      guestClick={this.onMemberClick.bind(this)}
                      onRef={this.onGuestListRef.bind(this)}/>
        </div>
      </div>
    )
  }
  
  refreshAfterImportClick(){
    this.setState({
      isFinishedImporting : false
    })
    let scrollRef = _.get(this.state, 'guestListScrollRef');
    if(scrollRef){
      scrollRef.scrollToIndex(0);
    }
    this.refreshMemberList()
  }
  
  monitorContactImportQueue() {
    return sapi.Contacts.queue()
      .then((res) => {
        
        let queueRes = res.data;
        if (queueRes.status && queueRes.status.undone > 0) {
          
          this.setState({
            isImporting : true,
            isFinishedImporting : false,
            previousImportQueue : queueRes
          })
          
          setTimeout(() => {
            log.log('running new queue pass');
            return this.monitorContactImportQueue();
          }, 5000);
        }
        else if(this.state.isImporting && !this.state.isFinishedImporting){
          //Then we're finished here, set the flag and clear things out.
          this.setState({
            isImporting : false,
            previousImportQueue : null,
            isFinishedImporting : true
          })
        }
      })
  }
  
  memberAddClick(){
    this.props.showAddContactWindow(this.state.adminInfo, true, (res) => {
      if(res){
        //admin dashboard doesn't need this right now, but
        //we could deal with this result if we needed, but let's just refresh things.
        
        //responses can come back in different ways when using bulk import.
        //Depending on if anything gets queued or not, we might get back a list on data, or data.contact
        //Parse that into an array, and then select the first in the list.
        // let contactArray = null;
        // if(_.isArray(_.get(res, 'data.contact'))){
        //   contactArray = _.get(res, 'data.contact');
        //   let guestUids = _.map(contactArray, 'guest_uid');
        //   this.props.updateDMPreviews(guestUids);
        // }
        // else if(_.get(res, 'data')){
        //   contactArray = _.get(res, 'data');
        //   let guestUids = _.map(contactArray, 'guest_uid');
        //   this.props.updateDMPreviews(guestUids);
        // }
        // else{
        //   contactArray = [res];
        //   this.props.updateDMPreviews([res.guest_uid]);
        // }
    
        this.refreshData();
      }
    })
  }
  
  getSortOptions(){
    let { t } = this.props;
    return {
      [this.SORTS.USER_FIRST_NAME_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.USER_FIRST_NAME_ASC,
        display: t('First Name')
      },
      [this.SORTS.USER_FIRST_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.USER_FIRST_NAME_DESC,
        display: t('First Name')
      },
      [this.SORTS.USER_LAST_NAME_ASC]: {
        icon: 'ion-android-arrow-up',
        id: this.SORTS.USER_LAST_NAME_ASC,
        display: t('Last Name')
      },
      [this.SORTS.USER_LAST_NAME_DESC]: {
        icon: 'ion-android-arrow-down',
        id: this.SORTS.USER_LAST_NAME_DESC,
        display: t('Last Name')
      },
  
  
      [this.SORTS.INACTIVE] : {
        icon: 'ion-ios-circle-outline',
        id: this.SORTS.INACTIVE,
        display: t('Inactive')
      },
      [this.SORTS.ACTIVE]: {
        icon: 'ion-ios-checkmark-outline',
        id: this.SORTS.ACTIVE,
        display: t('Active')
      },
    }
  }
  
  renderSortOptionRow(activeSort, option1, option2, optionIfNeither, doSelectSort){
    let sortOptions = this.getSortOptions();
    return (
      <a onClick={doSelectSort.bind(this, activeSort === option1 ? option2 : option1)}
         className={`sort-row list-group-item list-group-item-action ${(activeSort === option1 || activeSort === option2) ? 'active' : ''}`}>
        {activeSort === option1 &&
        <Fragment>
          <i className={`icon ${sortOptions[option1].icon} mr-2`}/>
          {sortOptions[option1].display}
        </Fragment>
        }
        {activeSort === option2 &&
        <Fragment>
          <i className={`icon ${sortOptions[option2].icon} mr-2`}/>
          {sortOptions[option2].display}
        </Fragment>
        }
        {activeSort !== option2 && activeSort !== option1 &&
        <Fragment>
          <i className={`icon mr-2`}/>
          {sortOptions[optionIfNeither].display}
        </Fragment>
        }
      </a>
    )
  }
  
  renderTabView(){
    let { t } = this.props;
    let {
      contactSort,
      isImporting,
      isFinishedImporting,
      previousImportQueue,
    } = this.state;
    let tabs = [];
    
    let importTotal = 0;
    if(isImporting && previousImportQueue){
      importTotal = previousImportQueue.status.error + previousImportQueue.status.done + previousImportQueue.status.undone;
    }
    
    tabs.push({
      id : this.MEMBER_TAB,
      tabRenderFn: (tab) => {
        return (
          <div className="d-flex" style={{marginLeft : '10px'}}>
            <h4 className={'m-0 home-accordion-header'}>
              <NotificationIcon iconColorOverride={colors.DARK}
                                iconCls="ion-android-person"
                                value={0} />
              {t("Members")}
              <i onClick={this.memberAddClick.bind(this)}
                 className={`btn btn-lg btn-icon ion-ios-plus-outline no-focus tab-add-btn`} />
            </h4>
            <div className="pl-3">
              {isImporting && previousImportQueue &&
                <div style={{marginTop: '17px'}}>
                  Importing {previousImportQueue.status.undone} of {importTotal}...
                </div>
              }
              {isFinishedImporting &&
              <div style={{marginTop: '13px'}}>
                Import finished!
                <button className="btn btn-sm btn-primary ml-2"
                        onClick={this.refreshAfterImportClick.bind(this)}>
                  {t("Refresh Members")}
                </button>
              </div>
              }
            </div>
          </div>
        )
      },
      isSelected : true,
      listRenderFn : () => {
        return this.renderMemberContents();
      },
      getSortingPopoverContent: () => {
        return (
          <Fragment>
            {/*{this.renderSortOptionRow(contactSort, this.SORTS.ACTIVE, this.SORTS.INACTIVE, this.SORTS.ACTIVE, this.setMemberSort)}*/}
            {this.renderSortOptionRow(contactSort, this.SORTS.USER_FIRST_NAME_ASC, this.SORTS.USER_FIRST_NAME_DESC, this.SORTS.USER_FIRST_NAME_ASC, this.setMemberSort)}
            {this.renderSortOptionRow(contactSort, this.SORTS.USER_LAST_NAME_ASC, this.SORTS.USER_LAST_NAME_DESC, this.SORTS.USER_LAST_NAME_ASC, this.setMemberSort)}
          </Fragment>
        )
      },
      getSortLabel: () => {
        return this.getSortOptions()[contactSort].display
      }
    })
  
    return (
      <div className="row">
        <div className="col p-0">
          <ScrollingTabView id={this.TABVIEW_ID}
                            scrollOffset={70}
                            tabs={tabs}
                            customTabHeight={58} />
        </div>
      </div>
    )
  }
  
  render(){
    let { adminInfo } = this.state;
    return (
      <div className={'column-scroll-layout has-header'}>
        <Helmet>
          <meta name="viewport" content="" />
        </Helmet>
        <AdminHeader isLockedToTop={true} adminInfo={adminInfo} showAcctHeader={true} />
        <div className="container-fluid">
          {this.renderTabView()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    qsEmail : state.app.qsEmail,
    qsActionNeeded : state.app.qsActionNeeded,
    email : state.auth.email,
    accountInfo : state.shared.accountInfo,
    unarchivedWorkspaces: state.home.unarchivedWorkspaces,
    archivedWorkspaces: state.home.archivedWorkspaces,
    workspaceNotifyCount : state.home.workspaceNotifyCount,
    directMessageNotifyCount : state.shared.directMessageNotifyCount,
    directMessages : state.shared.directMessages,
    dmPreviewLookup : state.shared.dmPreviewLookup,
    activeDM: state.home.activeDM,
    activeDMDocs: state.home.activeDMDocs,
    activeDMMessageBlocks: state.home.activeDMMessageBlocks,
    contacts : state.shared.contacts,
    stripeData : state.shared.stripeData,
    allStartupCallsFinished : state.shared.allStartupCallsFinished,
    firstStartupCallsFinished : state.shared.firstStartupCallsFinished
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    startup : () => dispatch(sharedActions.startup()),
    updateWorkspaces : () => dispatch(sharedActions.updateWorkspaces()),
    updateDirectMessages: () => dispatch(sharedActions.updateDirectMessages()),
    updateContacts: () => dispatch(sharedActions.updateContacts()),
    updateDMPreviews: (guest_uids) => dispatch(sharedActions.updateDMPreviews(guest_uids)),
    setActiveDM: (dm) => dispatch(homeActions.setActiveDM(dm)),
    clearActiveDM: () => dispatch(homeActions.clearActiveDM()),
    refreshActiveDMMessages : () => dispatch(homeActions.refreshActiveDMMessages()),
    setQsEmail: (email) => dispatch(appActions.setQsEmail(email)),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    logout : () => dispatch(appActions.logout()),
    setApplicationError : (error) => dispatch(appActions.setApplicationError(error)),
    updateAccountInfo: () => dispatch(sharedActions.updateAccountInfo()),
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)));
