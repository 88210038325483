import config from './config';
import c from './const';

export default {
  redirectToApp(router, qsObj, vip){
    if(!config.authGoesToVIP){
      router.push('/admin');
      return;
    }
    else{
      //This is to replicate the redirect between extjs welcome/tfa/ui apps
      //Since we consume the querystring, we need to forward the parameters along
      //to the ui app
      let qs = this.getQS(qsObj);
      window.location.href = `https://${vip}/app/${qs}`
    }
  },
  
  getQS(qs){
    let url = '';
    
    if(!qs){
      return url;
    }
    
    if(qs[c.querystring.forum_id] && (qs[c.querystring.thread_id] || qs[c.querystring.doc_id])){
      if(qs[c.querystring.thread_id]){
        url += `#?${c.querystring.forum_id}=${qs[c.querystring.forum_id]}&${c.querystring.thread_id}=${qs[c.querystring.thread_id]}`;
      }
      else{
        url += `#?${c.querystring.forum_id}=${qs[c.querystring.forum_id]}&${c.querystring.doc_id}=${qs[c.querystring.doc_id]}`;
      }
    }
    else if(qs[c.querystring.goto_dnd]){
      url += `#?${c.querystring.goto_dnd}=${qs[c.querystring.goto_dnd]}`;
    }
    else if(qs[c.querystring.goto_block_user] && qs[c.querystring.guest_uid]){
      url += `#?${c.querystring.goto_block_user}=${qs[c.querystring.goto_block_user]}&${c.querystring.guest_uid}=${qs[c.querystring.guest_uid]}`;
    }
  
    return url;
  }
}
