import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "../../elements/Button";
import log from "../../../../util/log";
import sapi from "../../../../util/sapi";

import sharedActions from "../../../../actions/shared-actions"
import accountActions from "../../../../actions/account-actions"
import ValidationErrors from "../../components/ValidationErrors";
import Loading from "../../util/Loading";
import Account from "../../../pages/Account";
import UploadHelper from "../../components/UploadHelper";
import Image from "../../elements/Image";
import AccountSignaturesPanel from "./AccountSignaturesPanel";
import modalActions from "../../../../actions/modal-actions";
import {getMessageForError} from "../../../../util/errors";
import {withTranslation} from "react-i18next";
import {withVFTranslation} from "../../../../util/withVFTranslation";

class EditSignatureCtrl extends Component {
  
  constructor(props){
    super(props);
    
    this.state = {
      editSignatureRef : null,
      isSaving : false
    }
  }
  
  componentDidMount() {
    if(this.props.onRef) {
      this.props.onRef(this)
    }
  }
  
  componentWillUnmount(){
    if(this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  init() {
    let { accountInfo, logo } = this.props;
    
    this.setState({
    
    })
  }
  
  save(){
    let { editSignatureRef } = this.state;
    let { t } = this.props;
    
    if(!editSignatureRef.doValidation()){
      return;
    }
    
    this.setState({isSaving: true});
    
    let signatures = editSignatureRef.generateImages();
    return sapi.AccountInfo.addSignatures(signatures.sign_image, signatures.init_image)
      .then((res) => {
        log.log('finished adding signatures', res);
        return Promise.all([
          this.props.updateSignatures(),
          this.props.updateAccountInfo()
          ])
      })
      .then((res) => {
        this.setState({isSaving : false});
        if(this.state.editSignatureRef){
          this.state.editSignatureRef.reset();
        }
        setTimeout(() => {
          this.props.doClose();
        })
      })
      .catch((err) => {
        log.error('error generating images', err);
        this.props.showAlert(t("Error saving signatures"), getMessageForError(err, t));
        this.setState({isSaving : false})
      })
  }
  
  deleteSignature(){
    this.setState({isSaving : true})
    return sapi.AccountInfo.deleteSignatures()
      .then(() => {
        return Promise.all([
          this.props.updateSignatures(),
          this.props.updateAccountInfo()
        ])
      })
      .then(() => {
        this.setState({isSaving : false})
        setTimeout(() => {
          this.props.doClose();
        })
        
        setTimeout(() => {
          if(this.state.editSignatureRef){
            this.state.editSignatureRef.reset();
          }
        }, 250)
      })
      .catch((err) => {
        log.error('error deleting images', err);
        this.setState({isSaving : false})
      })
  }
  
  cancel(){
    let { doClose } = this.props;
  
    if(this.state.editSignatureRef){
      this.state.editSignatureRef.reset();
    }
    
    doClose();
  }
  
  render() {
    let { signatures, t } = this.props;
    let { isSaving } = this.state;
    
    return (
      <div className={'mx-5 mt-3 mb-3'}>
        <div className="row">
          <div className="col">
            <AccountSignaturesPanel onRef={(ref) => this.setState({editSignatureRef : ref})} />
          </div>
        </div>
        
        <div className={'row mt-3'}>
          <div className="col">
            <div className="text-left">
              <Button disabled={isSaving || !signatures}
                      className="btn btn-danger"
                      onClick={this.deleteSignature.bind(this)}>{t("Delete Signature")}</Button>
            </div>
          </div>
          <div className={'col'}>
            <div className={'text-right'}>
              {isSaving &&
              <Loading inline={true}
                       className={'mr-2'}
                       size={'sm'}/>
              }
              <Button disabled={isSaving}
                      className={'btn btn-secondary mr-2'}
                      onClick={this.cancel.bind(this)}>{t("Cancel")}</Button>
              <Button disabled={isSaving}
                      className={'btn btn-primary'}
                      onClick={this.save.bind(this)}>{t("Save")}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditSignatureCtrl.styles = {
  dragDrop : {
    maxHeight: '160px',
    padding: '5px',
    border: 'dashed 1px black',
    textAlign: 'center',
  },
  dragDropEmpty : {
    height: '160px',
    lineHeight: '160px',
    padding: '5px',
    border: 'dashed 1px black',
    textAlign: 'center'
  },
  clearButton : {
    position: 'absolute',
    top: '-15px',
    right: '0px',
    border: '10px solid',
    zIndex: '100',
    borderRadius: '50%'
  }
}

EditSignatureCtrl.propTypes = {
  doClose : PropTypes.func.isRequired,
  onRef: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    logo : state.shared.logo,
    accountInfo: state.shared.accountInfo,
    signatures : state.shared.signatures,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountInfo : () => dispatch(sharedActions.updateAccountInfo()),
    updateSignatures : () => dispatch(sharedActions.updateSignatures()),
    ...modalActions.mapToDispatch(dispatch)
  };
};
export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditSignatureCtrl)));
