import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import ColorGenerator from "../../../helpers/color-generator";
import colors from "../../../util/colors";

class DocBadge extends PureComponent {
  
  render(){
    let { doc, small } = this.props;

    let colorStyle = null;
    if(this.props.overrideColor){
      colorStyle = {backgroundColor : this.props.overrideColor}
    }
    else{
      colorStyle = {backgroundColor : ColorGenerator.generateColorFromId(doc.doc_id)}
    }
    
    return (
      <div className="light-color" style={{...(small ? styles.badgeStyleSmall : styles.badgeStyle), ...colorStyle}}>
        <i style={styles.iconStyle} className="icon ion-document-text" />
      </div>
    )
  }
  
}

const styles = {
  iconStyle : {
    lineHeight : '30px'
  },
  badgeStyle : {
    fontSize: '22px',
    padding: '5px',
    borderRadius: '6px',
    width: '48px',
    height: '48px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '36px',
    border: `1px solid ${colors.LIGHT}`
  },
  badgeStyleSmall : {
    fontSize: '8px',
    padding: '5px',
    borderRadius: '3px',
    width: '22px',
    height: '22px',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '10px',
    border: `1px solid ${colors.LIGHT}`
  }
}

DocBadge.propTypes = {
  doc : PropTypes.object.isRequired,
  overrideColor : PropTypes.string,
  small : PropTypes.bool
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DocBadge);
