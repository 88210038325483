import c from '../util/const';
import _ from 'lodash'

let initialState = {
  modals : [],
}

const modal = (state = initialState, action) => {
  switch (action.type) {
  
    case c.actions.modal.show:
      
      let update = _.concat([], state.modals);
      update.push({
        modalType: action.modalType,
        modalProps: action.modalProps,
        id : _.uniqueId('vf-modal-dialog-')
      })
      
      return {
        ...state,
        modals : update
      }
      
    case c.actions.modal.updateSize:
      let sizeUpdate = _.concat([], state.modals);
      
      _.each(sizeUpdate, (modal) => {
        if(modal.id === action.id){
          modal.isLarge = action.isLarge;
        }
      })
  
      return {
        ...state,
        modals : sizeUpdate
      }
    
    case c.actions.modal.close:
  
      let copy = _.concat([], state.modals);
      _.remove(copy, (modal) => {return modal.id === action.id})
      
      return {
        ...state,
        modals : copy
      }
    
    case c.actions.modal.cleanup:
      return {...initialState}
      
    default:
      return state;
  }
}

export default modal;
