import React from 'react';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from "./util/config";
import moment from 'moment';
import log from "./util/log";
import _ from "lodash";
import c from "./util/const";

import 'moment/locale/fr';


const lookupCode = (code) => {
    if(!code){
        return c.language.en_us
    }
    if (_.startsWith(code.toLowerCase(), 'en')){
        return c.language.en_us;
    }
    else if(_.startsWith(code.toLowerCase(), 'fr')){
        return c.language.fr_fr;
    }
    else{
        console.warn('unsupported language');
        return c.language.en_us
    }
}

const customLangDetector = new LanguageDetector();
customLangDetector.addDetector({
    name: 'customLangDetector',
    lookup(options) {
        let found = [];
        
        if (typeof navigator !== 'undefined') {
            if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
                for (let i=0; i < navigator.languages.length; i++) {
                    found.push(navigator.languages[i]);
                }
            }
            if (navigator.userLanguage) {
                found.push(navigator.userLanguage);
            }
            if (navigator.language) {
                found.push(navigator.language);
            }
        }
        
        //This is kind of weird.  Whatever you return from here, it apparently chooses the first in the array.
        //This code is supposedly exactly the same as the "navigator" detector, which doesn't work for me
        //on windows, maybe everywhere?  This is pretty finicky stuff since it's all gettign loaded magically by i18n
        
        //Whatever is returned from here is basically the language code we end up using.
        //Just beware that different platforms might return different things.
        //I've seen ['fr', 'en', 'fr-FR', 'en-US'] come through.  I'm sure this logic will need to be tweaked as we add
        //new languages.
        
        // console.log('customLangDetector found', found);
        if(found.length > 0){
            return lookupCode(found[0]);
        }
        else{
            return undefined;
        }
    }
})

i18n.on('languageChanged', (lng) => {
    let mLang = _.split(lng, '-')[0].toLowerCase();
    log.log('setting moment locale', mLang)
    moment.locale(mLang);
})

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(customLangDetector)
  .init({
      debug: config.debug,
      fallbackLng: (code) => {
          // log.log('i18n fallbackLng called', code);
          return lookupCode(code);
      },
      detection: {
          order: [
              'querystring',
              'localStorage',
              'customLangDetector',
              // 'navigator'
          ],
          
          // cache user language on
          // caches: [],
          // excludeCacheFor: ['cimode'],
      },
      backend: {
          loadPath: '/translations/{{lng}}.json'
      },
      
      supportedLngs: [c.language.en_us, c.language.fr_fr],
      load: 'currentOnly',
      
      transSupportBasicHtmlNodes : true,
      
      useCookie: false,
      useLocalStorage: false,
      keySeparator: '',
      nsSeparator: false,
      pluralSeparator: false,
      contextSeparator: false,
      dotNotation: false,
      react: {
          useSuspense: false,
          
          //This is part of a workaround for a potential i18next crash.
          //We've seen crashes of similar form but I haven't been able to reproduce
          //I don't know for sure if this is the problem we were seeing, but it seems like
          //a more straightforward way to render <Trans> components anyway.
          //https://github.com/i18next/react-i18next/issues/1254
          defaultTransParent: function MyDiv ({children}) {
              return <div style={{ display: 'inline' }}>{children}</div>
          }
      },
      interpolation: {
          escapeValue: false // react already safes from xss
      }
  })

export default i18n;
