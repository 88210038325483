import c from '../../util/const';
import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import PropTypes from 'prop-types';

import Button from '../partials/elements/Button';
import log from "../../util/log";
import sapi from '../../util/sapi';
import Loading from "../partials/util/Loading";
import sharedActions from '../../actions/shared-actions';
import Promise from 'bluebird';
import config from "../../util/config";
import appActions from "../../actions/app-actions";
import {withRouter} from "react-router-dom";
import {withVFTranslation} from "../../util/withVFTranslation";

class Notifications extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      groupedNotifications: [],
      notifications: []
    }
  }
  
  componentDidMount() {
    // let { refreshWorkspace, refreshDocs } = this.props;
    // let { doc_id, forum_id, host_uid } = this.props.modalProps;
    
    this.setState({loading: true})
    this.updateNotifications()
      .finally(() => {
        this.setState({loading: false})
      })
  }
  
  updateNotifications() {
    
    return Promise.all([
        sapi.Notify.list()
      ])
      .then((res) => {
        let notifications = res[0].data;
        let lookup = {};
        _.each(notifications, (n) => {
          if (lookup[n.guest_uid]) {
            lookup[n.guest_uid].push(n);
          }
          else {
            lookup[n.guest_uid] = [n];
          }
        })
        
        let grouped = [];
        _.each(_.keys(lookup), (uid) => {
          grouped.push({
            guest: lookup[uid][0],
            notifications: lookup[uid]
          })
        })
        
        this.setState({
          groupedNotifications: grouped,
          notifications
        })
        
        return grouped;
      })
      .catch((err) => {
        log.log('error loading notifications', err);
      })
    
  }
  
  closeModal() {
    this.props.close();
  }
  
  deleteNotificationGroup(group) {
    let {updateNotifications} = this.props;
    
    sapi.Notify.deleteUserAlerts(group.guest.guest_uid)
      .then(() => {
        return Promise.all([
          this.updateNotifications(),
          updateNotifications()
        ])
      })
      .then((res) => {
        let notifications = res[0];
        if (notifications.length === 0) {
          this.closeModal();
        }
      })
  }
  
  deleteNotification(notification) {
    let {updateNotifications} = this.props;
    
    sapi.Notify.deleteAlert(notification.alert_id)
      .then(() => {
        return Promise.all([
          this.updateNotifications(),
          updateNotifications()
        ])
      })
      .then((res) => {
        let notifications = res[0];
        if (notifications.length === 0) {
          this.closeModal();
        }
      })
  }
  
  waitForNotifyCountToHitZero(){
    let timesChecked = 0;
    let pollCount = () => {
      timesChecked++;
      return new Promise((resolve, reject) => {
        if(timesChecked === 5){
          reject('checked 5 times, done waiting');
          return;
        }
        
        sapi.Notify.count()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err);
          })
      })
    }
    
    return pollCount()
      .then((res) => {
        if(res.data.notification_count === 0){
          return true;
        }
        else{
          return pollCount();
        }
      })
  }
  
  doFlushNotifications(){
    //weird helper because the backend reports flush() has finished before the count is down to 0.
    //We've gone back and forth on how to fix this, I guess this is the best.  bug 2164
    return sapi.Notify.flush()
      .then(() => {
        return this.waitForNotifyCountToHitZero();
      })
  }
  
  sendNotificationsAndLogout() {
    let {history, logout} = this.props;
  
    this.doFlushNotifications()
      .then((res) => {
        this.closeModal();
        logout();
        history.push('/');
      })
  }
  
  sendNotifications() {
    let {updateNotifications} = this.props;
    
    this.doFlushNotifications()
      .then(() => {
        return Promise.all([
          this.updateNotifications(),
          updateNotifications()
        ])
      })
      .then((res) => {
        this.closeModal();
      })
  }
  
  render() {
    let { t } = this.props;
    let {
      loading,
      groupedNotifications
    } = this.state;
    
    if (loading) {
      return (
        <div className="modal-content">
          <div className="modal-body">
            <Loading centered size={'sm'}/>
          </div>
        </div>
      )
    }
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Notifications")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
      
          {groupedNotifications.map((group) => {
            return (
              <div key={group.guest.guest_uid} className="card mb-2">
                <div className="card-header py-2">
                  <div className={'row'}>
                    <div className={'col-10'}>
                      <h5 className={'mt-2 mb-0'}>
                        {group.guest.notified_first_name} {group.guest.notified_last_name}
                      </h5>
                    </div>
                    <div className={'col-2'}>
                      <Button onClick={this.deleteNotificationGroup.bind(this, group)}
                              className={'btn btn-icon text-danger ion-close'}/>
                    </div>
              
                  </div>
                </div>
                {group.notifications.map((notification) => {
                  return (
                    <div key={notification.alert_id} className={'card-body py-2'}>
                      <div className={'row'}>
                        <div className={'col-10'}>
                          {notification.forum_label &&
                            <Fragment>
                              <p className={'mb-0'}>
                                {notification.label}
                              </p>
                              <p className={'mb-0'}>
                                {t("Workspace:")} {notification.forum_label}
                              </p>
                            </Fragment>
                          }
                          {!notification.forum_label &&
                          <Fragment>
                            <p className={'mb-0'}>
                              {t("Private Message")}
                            </p>
                            <p className={'mb-0'} />
                          </Fragment>
                          }
                        </div>
                        <div className={'col-2'}>
                          <Button onClick={this.deleteNotification.bind(this, notification)}
                                  className={'btn btn-icon text-danger ion-close'}/>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
    
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this)}>{t("Close")}</Button>
          <Button className={'btn btn-primary'} onClick={this.sendNotifications.bind(this)}>{t("Send")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(appActions.logout()),
    updateNotifications: () => dispatch(sharedActions.updateNotifications())
  };
};

Notifications.propTypes = {
  close: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired
}

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications)));
