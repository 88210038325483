import React, {Component} from 'react';
import {connect} from "react-redux";
import c from '../../util/const'
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../partials/elements/Button';
import ValidationErrors from "../partials/components/ValidationErrors";
import log from "../../util/log";
import sapi from "../../util/sapi";
import {modalview} from "react-ga";
import modalActions from "../../actions/modal-actions";
import NotificationIcon from "../partials/components/NotificationIcon";
import {getMessageForError} from "../../util/errors";
import ExpandableRow from "../partials/components/ExpandableRow";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import {withVFTranslation} from "../../util/withVFTranslation";
import Scroll from "react-scroll";

const WINDOW_MODE = {
  GUEST : 'guest',
  TERMS : 'terms'
}

class RequestSignatureWindow extends Component {
  
  CONTAINER_ID = 'request-signature-window-container'
  TERMS_SECTION_ID = 'terms-header';
  SMS_SECTION_ID = 'sms-header'
  
  constructor(props) {
    super(props);
    
    let { t } = props;
    let isEditMode = _.get(props, 'modalProps.isEditMode', false);
    if(isEditMode){
      this.state = {
        isEditMode : true,
        selectedGuestUid : null,
        mode : WINDOW_MODE.TERMS,
        terms : props.terms || t('I agree to do business electronically.'),
        requireTerms : !!(props.terms && props.terms.length > 0),
        requireSMS : !!(props.smsNumber && props.smsNumber.length > 0),
        smsNumber : props.smsNumber || '',
        validationErr : [],
        loadedContacts : false,
        allContacts : [],
        filteredContacts : [],
        expandContacts : false,
        contactSearch : ''
      }
    }
    else{
      this.state = {
        isEditMode : false,
        selectedGuestUid : _.get(props, 'modalProps.selectedGuest.guest_uid', null),
        mode : props.is_dm ? WINDOW_MODE.TERMS : WINDOW_MODE.GUEST,
        terms : props.terms || t('I agree to do business electronically.'),
        requireTerms : true,
        requireSMS : false,
        smsNumber : props.smsNumber || '',
        validationErr : [],
        loadedContacts : false,
        allContacts : [],
        filteredContacts : [],
        expandContacts : false,
        contactSearch : ''
      }
    }
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  nextClick(){
    let { mode } = this.state;
    
    if(mode === WINDOW_MODE.GUEST){
      this.setState({mode : WINDOW_MODE.TERMS})
    }
    else{
      this.submitTerms();
    }
  }
  
  submitTerms(){
    if(!this.doValidation()){
      return;
    }
    
    let { modalProps } = this.props;
    let {
      forum_id,
      chat_id,
      mesg_id,
      doc_id
    } = modalProps;
    let {
      selectedGuestUid,
      terms,
      requireTerms,
      requireSMS,
      smsNumber
    } = this.state;
    
    let result = {
      forum_id,
      chat_id,
      mesg_id,
      doc_id,
      signer_uid : selectedGuestUid
    }
    
    if(requireTerms){
      result.terms = terms;
    }
    if(requireSMS){
      result.smsNumber = smsNumber;
    }
    
    this.closeModal(result);
  }
  
  doValidation(){
    let {
      terms,
      requireTerms,
      requireSMS,
      smsNumber
    } = this.state;
    let { t } = this.props;
    
    let err = [];
    if(requireTerms && (!terms || terms.length === 0)){
      err.push(t("Please either enter terms, or do not require them."));
    }
    
    if(requireSMS){
      //There's decent rational for not being super strict with this phone number
      //validation.  Read the library's thoughts here: https://gitlab.com/catamphetamine/libphonenumber-js#using-phone-number-validation-feature
      if(!isPossiblePhoneNumber(smsNumber)){
        err.push(t("Please enter a valid phone number."))
      }
    }
    
    this.setState({validationErr : err});
    return err.length === 0;
  }
  
  onGuestCheckChange(guest_uid, evt){
    this.setState({selectedGuestUid : guest_uid})
  }
  
  setRequireTerms(requireTerms){
    this.setState({requireTerms})
    this.scrollToElement(this.TERMS_SECTION_ID)
  }
  
  setRequireSMS(requireSMS){
    this.setState({requireSMS});
    this.scrollToElement(this.SMS_SECTION_ID)
  }
  
  renderGuestMode(){
    let {modalProps, t} = this.props;
    let {
      filename,
      suggestedGuests,
      selectedGuest,
      terms
    } = modalProps;
    let {
      selectedGuestUid,
      expandContacts,
      filteredContacts,
      contactSearch
    } = this.state;
    
    return (
      <div className="modal-body">
        <h5>{t("Document:")} {filename}</h5>
        <p className="mb-0">{t("Who should sign this document?")}</p>
        <div className="p-2">
          {suggestedGuests.map((guest) => {
            return (
              <div key={guest.guest_uid} className="form-check">
                <input className="form-check-input"
                       type="radio"
                       name="guestRadio"
                       onChange={this.onGuestCheckChange.bind(this, guest.guest_uid)}
                       checked={selectedGuestUid === guest.guest_uid}
                       value={guest.guest_uid}/>
                <label className="form-check-label">
                  <div>{guest.first_name} {guest.last_name}</div>
                  <div>{guest.email_address}</div>
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  
  scrollToElement(elName){
    setTimeout(() => {
      Scroll.scroller.scrollTo(elName, {
        containerId: this.CONTAINER_ID,
        duration: 300,
        smooth: true,
      })
    }, 250)
  }
  
  renderTermsMode(){
    let {modalProps, t} = this.props;
    let {
      filename
    } = modalProps;
    let {
      requireTerms,
      requireSMS,
      smsNumber,
      terms,
      validationErr
    } = this.state;
    
    return (
      <div id={this.CONTAINER_ID} className="modal-body">
        <h5>{t("Document:")} {filename}</h5>
        <p>{t("Does this signer need to agree to terms?")}</p>
        <div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="termsRadio"
                   onChange={this.setRequireTerms.bind(this, true)}
                   checked={requireTerms}
                   value={requireTerms}/>
            <label className="form-check-label">
              <div className="bol">{t("Yes, require the signer to agree to the terms below.")}</div>
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="termsRadio"
                   onChange={this.setRequireTerms.bind(this, false)}
                   checked={!requireTerms}
                   value={!requireTerms}/>
            <label className="form-check-label">
              <div className="bol">{t("No, do not include a statement of terms.")}</div>
            </label>
          </div>
        </div>
        <ExpandableRow isActive={requireTerms}>
          <div id={this.TERMS_SECTION_ID} className="form-group mt-3">
            <label>{t("Statement of Terms")}</label>
            <textarea className="form-control no-resize"
                      rows={3}
                      disabled={!requireTerms}
                      value={terms}
                      maxLength={c.limits.maxTermsChars}
                      onChange={(evt) => this.setState({terms: evt.target.value})}
                      placeholder={t('I agree to do business electronically.')} />
          </div>
        </ExpandableRow>
        
        <p className="mt-2">{t("Do you want to add SMS verification?")}</p>
        <div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="smsRadio"
                   onChange={this.setRequireSMS.bind(this, false)}
                   checked={!requireSMS}
                   value={!requireSMS}/>
            <label className="form-check-label">
              <div className="bol">{t("No, don't send a Signature Code.")}</div>
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input"
                   type="radio"
                   name="smsRadio"
                   onChange={this.setRequireSMS.bind(this, true)}
                   checked={requireSMS}
                   value={requireSMS}/>
            <label className="form-check-label">
              <div className="bol">{t("Yes, require the signer to input a one-time Signature Code.")}</div>
            </label>
          </div>
        </div>
        <ExpandableRow isActive={requireSMS}>
          <div id={this.SMS_SECTION_ID} className="form-group mt-3">
            <label>{t("Signer's Verified Phone Number")}</label>
            <div className="d-flex">
              
              {/*One important note here, I chose to embed the flags rather than rely on unicode because of varying OS support*/}
              {/*Passing flags in increases the package size a little, but not that much*/}
              {/*I think this is probably alright*/}
              <PhoneInput
                flags={flags}
                international
                className="form-control mr-3 w-50"
                defaultCountry="US"
                placeholder={t("Enter phone number")}
                value={smsNumber}
                onChange={(val) => this.setState({smsNumber : val})}/>
              <div className="text-muted small">
                <p className="mb-0">{t("Be sure to include country code.")}</p>
                <p className="mb-0">{t("e.g. + 1 408 555 1234")}</p>
              </div>
            </div>
          </div>
        </ExpandableRow>
        
        {validationErr.length > 0 && <ValidationErrors errors={validationErr} /> }
      </div>
    )
  }
  
  render() {
    let { t } = this.props;
    let { selectedGuestUid, mode, isEditMode } = this.state;
    
    return (
      <div className="modal-content">
  
        <div className="modal-header">
          <h5 className="modal-title">{t("Request Signature")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
    
        {mode === WINDOW_MODE.GUEST &&
        this.renderGuestMode()
        }
        {mode === WINDOW_MODE.TERMS &&
        this.renderTermsMode()
        }
        
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'}
                  disabled={mode === WINDOW_MODE.GUEST && !selectedGuestUid}
                  onClick={this.nextClick.bind(this)}>{isEditMode ? t('Save') : t('Next')}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showAlert : (title, msg, cb) => dispatch(modalActions.showAlert(title, msg, cb))
  };
};

RequestSignatureWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(RequestSignatureWindow));
