import {loadStripe} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import React from "react";
import {connect} from "react-redux";
import _ from "lodash";
import log from "../util/log";
import api from "../util/api";

//IMPORTANT!  This should not be used on a modal dialog, because
//They potentially have MODAL_LARGE, MODAL_XL flags on them, which doesn't
//get passed properly to ModalRoot.  This is a flaw of the ModalRoot system that I should fix.

export const withStripeElements = (ChildComponent) => {
  class StripeInjectedComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        stripePromise : null
      }
    }
    
    componentDidMount() {
      api.Stripe.pubKey()
        .then((res) => {
          let pub_key = _.get(res, 'data.pub_key');
          this.setState({
            stripePromise : pub_key ? loadStripe(pub_key) : null
          })
        })
        .catch((err) => {
          log.error('error getting stripe pub key', err);
        })
    }
  
    render(){
      return (
        <Elements stripe={this.state.stripePromise}>
          <ElementsConsumer>
            {({stripe, elements}) => {
              return <ChildComponent {...this.props}
                                     stripe={stripe}
                                     elements={elements}/>;
            }}
          </ElementsConsumer>
        </Elements>
      )
    }
  }
  
  const mapStateToProps = (state) => {
    return {
    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
    
    };
  };
  
  return connect(mapStateToProps, mapDispatchToProps)(StripeInjectedComponent);
}
