import React, {PureComponent, Fragment} from 'react';
import Image from "../elements/Image";
import PropTypes from 'prop-types';

import companyLogo from '../../../resources/logo-w-text-blue.png'
import proCompanyLogo from '../../../resources/pro-logo-w-text-blue.png'
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AccountHeader from "./AccountHeader";
import Button from "../elements/Button";
import _ from 'lodash';
import appActions from '../../../actions/app-actions';
import modalActions from '../../../actions/modal-actions';
import log from "../../../util/log";
import colors from "../../../util/colors";
import WorkspaceBadge from "../badges/WorkspaceBadge";
import NotificationIcon from "./NotificationIcon";
import SearchWindow from "../../modals/SearchWindow";
import homeActions from "../../../actions/home-actions";
import workspaceActions from "../../../actions/workspace-actions";
import Scroll from "react-scroll";
import sharedActions from "../../../actions/shared-actions";
import { withTranslation } from 'react-i18next';
import {withVFTranslation} from "../../../util/withVFTranslation";
import AdminAccountHeader from "./AdminAccountHeader";

class AdminHeader extends PureComponent {

  constructor(props){
    super(props);
    
    this.state = {
    
    }
  }
  
  render() {
    let {
      showAcctHeader,
      showBackButton,
      isLockedToTop,
      logo,
      accountLogo,
      workspace,
      adminInfo,
      t
    } = this.props;
    
    let lockStyle = null;
    if(isLockedToTop){
      lockStyle = {
        position : 'absolute',
        top : '0',
        left: '0',
        right : '0'
      }
    }

    let headerLogo = null;
    if(workspace){
      headerLogo = logo;
    }
    else{
      headerLogo = accountLogo;
    }
    
    return (
      <div className={'vf-header'} style={lockStyle}>
        <div className={'container-fluid'}>
          <div className={'row'} style={{height: AdminHeader.HEADER_HEIGHT + 'px'}}>
            
            <div className="col-4" ></div>
            
            <div className="col-4 text-center">
              <div className="d-flex justify-content-center h-100">
                <Image className={'mt-2'} src={companyLogo} alt={t('Logo')} imgHeight={50}/>
                {headerLogo &&
                <Fragment>
                  <div className="d-inline-block" style={{
                    borderLeft: '1px solid ' + colors.PRIMARY,
                    margin: '15px'
                  }} />
                  <Image className={'mt-2'}
                         src={headerLogo}
                         alt={t('Logo')}
                         imgHeight={50}/>
                </Fragment>
                }
              </div>
            </div>
  
            <div className="col-4">
              <div className="d-flex justify-content-end">
                {showAcctHeader &&
                <div className="pr-3">
                  <AdminAccountHeader adminInfo={adminInfo}/>
                </div>
                }
              </div>
            </div>
            
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  searchWrap : {
    marginTop : '15px'
  },
  searchInputAddon : {
    paddingRight : '2px',
    backgroundColor: colors.LIGHT
  },
  searchInput : {
    borderLeft : 'none',
  },
  backButtonWrap :{
    minWidth : '60px'
  },
  backLink : {
    marginTop: '-5px',
    fontSize : '20px'
  },
  badgeIcon : {
    lineHeight : '30px',
    marginTop: '18px',
    cursor: 'pointer'
  }
}

AdminHeader.HEADER_HEIGHT = 70;

AdminHeader.propTypes = {
  showAcctHeader: PropTypes.bool,
  isLockedToTop: PropTypes.bool,
  adminInfo : PropTypes.object
}

const mapStateToProps = (state) => {
  return {
  
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...modalActions.mapToDispatch(dispatch),
    ...sharedActions.mapToDispatch(dispatch)
  };
};

export default withVFTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminHeader)));
