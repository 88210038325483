import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import PropTypes from 'prop-types';
import colors from "../../util/colors";
import Button from '../partials/elements/Button';
import UserBadge from "../partials/badges/UserBadge";
import Loading from "../partials/util/Loading";
import sapi from "../../util/sapi";
import log from "../../util/log";
import modalActions from "../../actions/modal-actions";
import {getMessageForError} from "../../util/errors";
import classnames from "classnames";
import Popover from "react-tiny-popover";
import utils from "../../util/util";
import ColorGenerator from "../../helpers/color-generator";
import {last} from "pdf-lib";
import he from "he";
import accountActions from "../../actions/account-actions";
import Promise from 'bluebird'
import c from "../../util/const";
import appActions from "../../actions/app-actions";
import {withVFTranslation} from "../../util/withVFTranslation";

class MemberInfoWindow extends Component {
  
  constructor(props) {
    super(props);
  
    this.state = {
      loading : true,
      contactInfo : null,
      validationErr : [],
      email : '',
      first_name : '',
      last_name : '',
      title : '',
      company : '',
      phone : '',
      address : '',
      note : ''
    }
  }
  
  componentDidMount() {
    let { t } = this.props;
    this.setState({loading : true})
    this.refreshContactInfo()
      .catch((err) => {
        log.error('error getting member', err);
        this.props.showAlert(t("Error loading member info"), getMessageForError(err, t), () => {
          this.closeModal(false)
        })
      })
      .finally(() => {
        this.setState({loading : false})
      })
  }
  
  refreshContactInfo(){
    let { member } = this.props.modalProps;
    return sapi.Contacts.get(member.guest_uid)
      .then((res) => {
        
        if(res.data.address){
          res.data.address = he.decode(res.data.address);
        }
        if(res.data.note){
          res.data.note = he.decode(res.data.note);
        }
        
        this.setState({
          contactInfo : res.data,
          email : res.data.email,
          first_name : res.data.first_name || '',
          last_name : res.data.last_name || '',
          title : res.data.title || '',
          company : res.data.company || '',
          phone : res.data.phone || '',
          address : res.data.address || '',
          note : res.data.note || '',
        })
      })
  }
  
  closeModal(res) {
    let {close} = this.props;
    
    close(res);
  }
  
  deleteContact(){
    let { t } = this.props;
    let { member, adminInfo } = this.props.modalProps;
  
    this.props.showConfirm(t("Are you sure?"), t("Are you sure you want to remove this member?"), (res) => {
      if(res){
        let class_id = _.get(adminInfo, 'info_json.default_class_id', 1);
        sapi.Member.remove(adminInfo.inst_id, member.guest_uid, class_id)
          .then((res) => {
            this.closeModal(res);
          })
          .catch((err) => {
            log.log('error removing member', err);
            this.props.showAlert(t("Unable to delete member"), getMessageForError(err, t));
          })
      }
    })
  }
  
  sendInvitationEmail() {
    let { t } = this.props;
    let {contactInfo} = this.state;
    this.props.showConfirm(t('Are you sure?'),
      t("Are you sure you want Verifyle to send a new invitation email to ") + contactInfo.first_name + " " +  contactInfo.last_name + t("?"),
      (res) => {
        if(res){
          sapi.Contacts.sendInvitationLink(contactInfo.guest_uid)
            .then((res) => {
              log.log('invitation sent', res);
              this.props.showAlert(t('Success'), t('Invitation email sent.'));
            })
            .catch((err) => {
              log.error('error sending invitation link', err);
              this.props.showAlert(t('Failed to send invitation'), getMessageForError(err, t));
            })
        }
      })
  }
  
  renderStatus(status){
    let { t } = this.props;
    
    if(status === MemberInfoWindow.CONFIRM_STATUS.GUEST_CONFIRM){
      return (
        <p className="font-italic secondary-text-color">
          {t("- Confirmed -")}
        </p>
      )
    }
    else if(status === MemberInfoWindow.CONFIRM_STATUS.GUEST_DELETED){
      return (
        <p className="font-italic secondary-text-color">
          {t("- Deleted -")}
        </p>
      )
    }
    else if(status === MemberInfoWindow.CONFIRM_STATUS.GUEST_PENDING){
      return (
        <div className="mb-2">
          <p className="font-italic secondary-text-color mb-0">
            {t("- Pending -")}
          </p>
          {/*<div>*/}
          {/*  <button className="btn btn-link primary-color"*/}
          {/*          onClick={this.sendInvitationEmail.bind(this)}>*/}
          {/*    {t("Send Invitation Email")}*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      )
    }
  }
  
  renderView(){
    let { t } = this.props;
    let { contactInfo } = this.state;

    if(!contactInfo){
      return null;
    }

    return (
      <div className="text-center mt-5" style={{ minHeight : '300px' }}>
        <UserBadge large={true} guest={contactInfo}/>
        <h3 className="mt-3">
          {contactInfo.first_name} {contactInfo.last_name}
        </h3>
    
        {contactInfo.title &&
        <h4>
          {contactInfo.title}
        </h4>
        }
        {contactInfo.company &&
        <h4 className="font-weight-bold">
          {contactInfo.company}
        </h4>
        }
        {contactInfo.email &&
        <p>
          {contactInfo.email}
        </p>
        }
        {contactInfo.phone &&
        <p>
          {contactInfo.phone}
        </p>
        }
        {contactInfo.address &&
        <p>
          {contactInfo.address}
        </p>
        }
        {contactInfo.note &&
        <p>
          {contactInfo.note}
        </p>
        }
    
        {contactInfo.status &&
        this.renderStatus(contactInfo.status)
        }
        {!contactInfo.email_alert_flag && contactInfo.status !== MemberInfoWindow.CONFIRM_STATUS.GUEST_DELETED &&
        <div style={styles.blockedBadge} className="d-inline-block">
          {t("BLOCKED")}
        </div>
        }
  
        <div className="text-center">
          <button className="btn btn-danger"
                  onClick={this.deleteContact.bind(this)}>
            {t("Remove Member")}
          </button>
        </div>
        
      </div>
    )
  }
  
  render() {
    let { t } = this.props;
    let { loading } = this.state;
  
    if (loading) {
      return (
        <div className="modal-content">
          <div className="modal-body">
            <Loading centered size={'sm'}/>
          </div>
        </div>
      )
    }
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body position-relative">
          {this.renderView()}
        </div>
      </div>
    )
  }
}

MemberInfoWindow.CONFIRM_STATUS = {
  GUEST_PENDING : 'GUEST_PENDING',
  GUEST_CONFIRM : 'GUEST_CONFIRM',
  GUEST_DELETED : 'GUEST_DELETED'
}

const styles = {
  topLeftButton : {
    position : 'absolute',
    top: '15px',
    left : '30px',
    fontSize : '60px',
    cursor: 'pointer',
    opacity: 1
  },
  topRightButton : {
    position : 'absolute',
    top: '5px',
    right : '16px',
    fontSize : '30px',
    zIndex : 1000,
    cursor: 'pointer'
  },
  blockedBadge : {
    backgroundColor : colors.ASSERTIVE,
    color : colors.LIGHT,
    borderRadius: '3px',
    padding : '3px 5px'
  },
  menuItem : {
    padding : '6px 15px',
    borderTopColor : colors.TRANSPARENT,
    borderBottomColor : colors.TRANSPARENT
  },
  menuItemBottom : {
    padding : '7px 15px',
    borderTopColor : colors.TRANSPARENT
  },
  gearIcon : {
    fontSize: '18px'
  },
  menuIcons : {
    fontSize: '20px',
    minWidth: '25px',
    verticalAlign : 'baseline',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'center'
  },
}

const mapStateToProps = (state) => {
  return {
    qs : state.app.qs,
    qsActionNeeded : state.app.qsActionNeeded,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserBlockList: () => dispatch(accountActions.updateUserBlockList()),
    setQsActionNeeded: (actionNeeded) => dispatch(appActions.setQsActionNeeded(actionNeeded)),
    ...modalActions.mapToDispatch(dispatch)
  }
};

MemberInfoWindow.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(MemberInfoWindow));
