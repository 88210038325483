import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import c from '../../util/const';
import _ from 'lodash';
import ConfirmDialog from './ConfirmDialog'
import AlertDialog from "./AlertDialog";
import AuthInfoDialog from "./AuthInfoDialog";
import ModalContainer from "./ModalContainer";
import NewItemDialog from "./NewItemDialog";
import log from "../../util/log";
import modalActions from "../../actions/modal-actions";
import PreviewWindow from "./PreviewWindow";
import AddDocWindow from "./AddDocWindow";
import Notifications from "./Notifications";
import RenameDialog from "./RenameDialog";
import SearchWindow from "./SearchWindow";
import DownloadMultipleWindow from "./DownloadMultipleWindow";
import RequestSignatureWindow from "./RequestSignatureWindow";
import PDFSignatureRequestWindow from "./PDFSignatureRequestWindow";
import PdfSubmitDialog from "./PdfSubmitDialog";
import AgreeToTermsDialog from "./AgreeToTermsDialog";
import AddAccountSignaturesDialog from "./AddAccountSignaturesDialog";
import SubmitSignedPdfDialog from "./SubmitSignedPdfDialog";
import AddGuestWindow from "./AddGuestWindow";
import AddContactWindow from "./AddContactWindow";
import ManageObjectPermissionsWindow from "./ManageObjectPermissionsWindow";
import ContactInfoWindow from "./ContactInfoWindow";
import ContactConfirmDialog from "./ContactConfirmDialog";
import GeneratedPasswordDialog from "./GeneratedPasswordDialog";
import DocViewHistory from "./DocViewHistory";
import MesgEditWindow from "./MesgEditWindow";
import MesgHistoryWindow from "./MesgHistoryWindow";
import UpgradeDialog from "./UpgradeDialog";
import NewWorkspaceDialog from "./NewWorkspaceDialog";
import TutorialWindow from "./TutorialWindow";
import AttachDocToThreadDialog from "./AttachDocToThreadDialog";
import SigningMetadataWindow from "./SigningMetadataWindow";
import ContactUsWindow from "./ContactUsWindow";
import HelpResourceWindow from "./HelpResourceWindow";
import StripeCheckoutDialog from "./StripeCheckoutDialog";
import UpgradeDialogNew from "./UpgradeDialogNew";
import PromoCodeDialog from "./PromoCodeDialog";
import SMSVerificationDialog from "./SMSVerificationDialog";
import ChangePasswordWindow from "./ChangePasswordWindow";
import MemberInfoWindow from "./MemberInfoWindow";

let MODAL_COMPONENTS = {}
MODAL_COMPONENTS[c.modal.authInfo] = AuthInfoDialog;
MODAL_COMPONENTS[c.modal.newItem] = NewItemDialog;
MODAL_COMPONENTS[c.modal.preview] = PreviewWindow;
MODAL_COMPONENTS[c.modal.addDoc] = AddDocWindow;
MODAL_COMPONENTS[c.modal.notifications] = Notifications;
MODAL_COMPONENTS[c.modal.renameItem] = RenameDialog;
MODAL_COMPONENTS[c.modal.search] = SearchWindow;
MODAL_COMPONENTS[c.modal.downloadMultiple] = DownloadMultipleWindow;
MODAL_COMPONENTS[c.modal.requestSignature] = RequestSignatureWindow;
MODAL_COMPONENTS[c.modal.pdfSignatureRequest] = PDFSignatureRequestWindow;
MODAL_COMPONENTS[c.modal.addGuestWindow] = AddGuestWindow;
MODAL_COMPONENTS[c.modal.addContactWindow] = AddContactWindow;
MODAL_COMPONENTS[c.modal.manageObjectPermissionsWindow] = ManageObjectPermissionsWindow;
MODAL_COMPONENTS[c.dialog.confirm] = ConfirmDialog;
MODAL_COMPONENTS[c.dialog.alert] = AlertDialog;
MODAL_COMPONENTS[c.dialog.pdf_submit] = PdfSubmitDialog;
MODAL_COMPONENTS[c.dialog.agree_to_terms] = AgreeToTermsDialog;
MODAL_COMPONENTS[c.dialog.add_signatures] = AddAccountSignaturesDialog;
MODAL_COMPONENTS[c.dialog.submit_signed_doc] = SubmitSignedPdfDialog;
MODAL_COMPONENTS[c.modal.contactInfoWindow] = ContactInfoWindow;
MODAL_COMPONENTS[c.dialog.contactConfirm] = ContactConfirmDialog;
MODAL_COMPONENTS[c.modal.generatedPasswordDialog] = GeneratedPasswordDialog;
MODAL_COMPONENTS[c.modal.docViewHistory] = DocViewHistory;
MODAL_COMPONENTS[c.modal.mesgEditWindow] = MesgEditWindow
MODAL_COMPONENTS[c.modal.mesgHistoryWindow] = MesgHistoryWindow;
MODAL_COMPONENTS[c.modal.upgradeDialog] = UpgradeDialog;
MODAL_COMPONENTS[c.modal.newWorkspace] = NewWorkspaceDialog;
MODAL_COMPONENTS[c.modal.tutorialWindow] = TutorialWindow;
MODAL_COMPONENTS[c.modal.attachDocToThreadWindow] = AttachDocToThreadDialog;
MODAL_COMPONENTS[c.modal.signingMetadata] = SigningMetadataWindow;
MODAL_COMPONENTS[c.modal.contactUsWindow] = ContactUsWindow;
MODAL_COMPONENTS[c.modal.helpResourceWindow] = HelpResourceWindow;
MODAL_COMPONENTS[c.modal.stripeCheckoutDialog] = StripeCheckoutDialog;
MODAL_COMPONENTS[c.modal.upgradeDialogNew] = UpgradeDialogNew;
MODAL_COMPONENTS[c.modal.addPromoCodeDialog] = PromoCodeDialog;
MODAL_COMPONENTS[c.modal.smsVerificationDialog] = SMSVerificationDialog;
MODAL_COMPONENTS[c.modal.changePassword] = ChangePasswordWindow;
MODAL_COMPONENTS[c.modal.memberInfoWindow] = MemberInfoWindow;

class ModalRoot extends Component {

  Z_INDEX_START = 1049;
  
  constructor(props){
    super(props);

    this.state = {
      containerRefs : []
    }
  }
  
  onContainerRef(id, ref){
    let update = _.concat([], this.state.containerRefs);
    
    update.push({
      id, ref
    })
    
    this.setState({
      containerRefs : update
    })
  }

  handleCallback(id, res){
    let { modals } = this.props;
    
    let found = _.find(modals, (modal) => {
      return modal.id === id;
    })
    
    let { callback } = found.modalProps;
    
    if(callback){
      setTimeout(() => {
        callback(res);
      })
    }
  }
  
  setLargeSize(id, isLarge){
    this.props.updateSize(id, isLarge);
  }
  
  //I'm not sure this is as clear as it should be.
  //ModalContainer needs to know when things close so it can dismiss the active dialog
  //and close things properly with animation.  I pass this close method around to the dialogs to be called.
  //So ModalRoot handles the close, and passes it to the modal container
  //I'm hoping that when we support showing multiple modals at once it will make it easier to keep track of.
  doClose(id, res){
    let update = _.concat([], this.state.containerRefs);
    let foundItem = _.remove(update, (item) => {
      return item.id === id;
    })[0]
    
    if(foundItem) {
      foundItem.ref.doClose(res);
      this.setState({containerRefs: update})
    }
  }

  render() {
    let {modals} = this.props;
  
    return (
      _.map(modals, (modal, modalIndex) => {
        let {modalType, modalProps, isLarge} = modal;
        let SpecificType = MODAL_COMPONENTS[modalType];
        
        let modalCls = '';
        if(SpecificType.MODAL_LARGE || isLarge){
          modalCls = 'modal-lg'
        }
        else if(SpecificType.MODAL_XL){
          modalCls = 'modal-xl'
        }
        else if(SpecificType.MODAL_SM){
          modalCls = 'modal-sm'
        }
        
        return (
          <Fragment key={modal.id}>
            <ModalContainer onRef={this.onContainerRef.bind(this, modal.id)}
                            callback={this.handleCallback.bind(this, modal.id)}
                            zIndex={(this.Z_INDEX_START + modalIndex + 2)}
                            closeModal={this.props.close.bind(this, modal.id)}>
              <div className={`modal-dialog ${modalCls}`} role="document">
                <SpecificType close={this.doClose.bind(this, modal.id)}
                              setLargeSize={this.setLargeSize.bind(this, modal.id)}
                              modalProps={modalProps}
                              {...modalProps}/>
              </div>
            </ModalContainer>
            <div style={{zIndex: (this.Z_INDEX_START + modalIndex + 1)}}
                 className={'modal-backdrop fade show'}/>
          </Fragment>
        )
      })
    )
  }
}

const mapStateToProps = (state) => {
  return {
    modals : state.modal.modals,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    close : (id) => dispatch(modalActions.close(id)),
    updateSize: (id, isLarge) => dispatch(modalActions.updateSize(id, isLarge))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
