import c from '../util/const';
import _ from 'lodash';
import log from "../util/log";

let initialState = {
  watermarkCanvasRef: null
}

const utility = (state = initialState, action) => {
  switch (action.type) {
    
    case c.actions.utility.setWatermarkCanvasRef:
      return {
        watermarkCanvasRef: action.ref
      }
    
    case c.actions.download.cleanup:
      return {...initialState}
      
    default:
      return state;
  }
}

export default utility;
