import moment from 'moment';
import c from './const';
import _ from 'lodash';
import he from 'he'
import log from "./log";

let utils = {
  //https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  b64DecodeUnicode: function (str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  },

  pickMemberAddClassId(adminInfo){
    //   "admin_uid" : "5ffdfe7c4969c18b",
    //   "classes" : [],
    //   "info_json" : {
    //     "body" : "Look, there&#39;s an svg here.  Also this is a really long body.  Like way longer than before.  It&#39;s so long that it will screw up the page format, or at least I hope it will, because that&#39;s what I&#39;m testing.  Some more extra long text here, and I&#39;m out of things to say.",
    //     "default_class_id" : 100,
    //     "header" : "This is the header!",
    //     "logo_ext" : "jpg",
    //     "logo_id" : "5ffe54c5d51b38a8",
    //     "member_number_format" : "^C?\\d{6,8}$",
    //     "member_number_info" : "This is some nice description text that shows up in the info button on the field, if it exists.  It shows up in a modal when you click it.  Cool!  Right?",
    //     "owner_uid" : "5ffdfe7c4969c18b",
    //     "url" : "http://swartzk.com/"
    //   },
    //   "inst_id" : "5ffe54c5bf847b93",
    //   "label" : "My Newest Inst"

    //The goal here is to pick the highest class_id available from either the classes array, or default_class_id from inst.

    log.log('pickMemberAddClassId', adminInfo);
    let highestClassId = -1;
    _.each(adminInfo.classes, (c) => {
      if(!c.stripe_id) {
        if (c.class_id >= highestClassId) {
          highestClassId = c.class_id;
        }
      }
    })

    let defaultClassId = _.get(adminInfo, 'info_json.default_class_id', -1);
    if(defaultClassId > highestClassId){
      return defaultClassId;
    }
    else{
      return highestClassId;
    }
  },

  waitFor(waitTime){
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, waitTime || 50)
    })
  },

  waitForCondition(evaluateFn, waitTime){
    return new Promise((resolve, reject) => {
      if(evaluateFn()){
        resolve(true);
      }
      else{
        setTimeout(() => {
          resolve(this.waitForCondition(evaluateFn, waitTime))
        }, waitTime || 50)
      }
    })
  },

  makeSuggestedFilename(originalName, useSignedSuffix){
    let fileNameWithoutExtension = '';
    let extensionIndex = originalName.lastIndexOf(".");
    if (extensionIndex > 0) {
      fileNameWithoutExtension = originalName.substr(0, extensionIndex);
    }
    else {
      fileNameWithoutExtension = originalName;
    }

    if(useSignedSuffix){
      return fileNameWithoutExtension + '_signed.pdf';
    }
    else{
      return fileNameWithoutExtension + '.pdf';
    }
  },

  //https://stackoverflow.com/questions/12168909/blob-from-dataurl
  convertDataURIToArrayBuffer(dataURI){
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return ab;
  },

  arrayBufferToBase64: function (buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  },

  htmlDecodeObj: (obj) => {
    _.forOwn(obj, (value, key) => {
      if (value && (typeof value === 'string' || value instanceof String)) {
        obj[key] = he.decode(value);
      }
      else if(value && (typeof value === 'object')){
        utils.htmlDecodeObj(value)
      }
    });
  },

  base64Encode: function (str) {
    return btoa(unescape(encodeURIComponent(str)));
  },

  calculateResizedImage(uri, targetWidth, targetHeight){
    return new Promise((resolve, reject) => {
      let sourceImage = new Image();

      sourceImage.onload = function () {
        log.log('source image', sourceImage.width, sourceImage.height);
        let ratio = Math.min(1,targetWidth / sourceImage.width, targetHeight / sourceImage.height);
        let newWidth = sourceImage.width * ratio;
        let newHeight = sourceImage.height * ratio;
        resolve( {width : newWidth, height : newHeight});
      }

      sourceImage.onerror = function (err) {
        reject(err);
      }

      sourceImage.src = uri;
    })
  },

  getMomentDate: function(val){
    return moment(new Date(+val * 1000));
  },

  bytesToSize: function (bytes, precision) {
    bytes = parseInt(bytes, 10);

    var kilobyte = 1024;
    var megabyte = kilobyte * 1024;
    var gigabyte = megabyte * 1024;
    var terabyte = gigabyte * 1024;

    if ((bytes >= 0) && (bytes < kilobyte)) {
      return bytes + ' B';
    } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
      return (bytes / kilobyte).toFixed(precision) + ' KB';
    } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
      return (bytes / megabyte).toFixed(precision) + ' MB';
    } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
      return (bytes / gigabyte).toFixed(precision) + ' GB';
    } else if (bytes >= terabyte) {
      return (bytes / terabyte).toFixed(precision) + ' TB';
    } else {
      return bytes + ' B';
    }
  },

  getFilenameExtension: function (filename) {
    return filename.substr((~-filename.lastIndexOf(".") >>> 0) + 2);
  },

  fileExtensionIsPDF: function(fileName){
    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return ext.toLowerCase() === 'pdf'
  },

  isPreviewable: function(fileName){

    return this.isTextPreview(fileName) || this.isPDFPreview(fileName) || this.isImgPreview(fileName) || this.isSvgPreview(fileName)
  },

  isTextPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.textExtensions.indexOf(ext) >= 0;
  },

  isPDFPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.pdfExtensions.indexOf(ext) >= 0;
  },

  isImgPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.imgExtensions.indexOf(ext) >= 0;
  },

  isSvgPreview: function(fileName){
    if(!fileName){
      return false;
    }

    var ext = this.getFilenameExtension(fileName.toLowerCase());
    return c.preview.svgExtensions.indexOf(ext) >= 0;
  },
}

export default utils;
