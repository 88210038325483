import React, {Component} from 'react';
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import Button from "../partials/elements/Button";
import log from "../../util/log";
import sapi from "../../util/sapi";
import ValidationErrors from "../partials/components/ValidationErrors";
import {getErrorMessage, getMessageForError} from "../../util/errors";
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import _ from 'lodash'
import he from "he";
import sentryHelper from "../../helpers/sentry-helper";
import {withVFTranslation} from "../../util/withVFTranslation";

class SMSVerificationDialog extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      smsDisplay : '',
      code : '',
      validationErr : []
    }
  }
  
  componentDidMount() {
    const {modalProps} = this.props;
    if(!modalProps.callback){
      throw Error('Showing modal without callback');
    }
    
    let sigRequest = this.props.signatureRequest;
    log.log('sms verification load', sigRequest);
    
    let formattedNumber = formatPhoneNumberIntl(sigRequest.smsNumber);
    
    // //I anticipate this number to be in the format "+10000000000", with no further
    // //formatting.  Right now we only support US numbers, so +1.
    // let lastTwoNumbers = sigRequest.smsNumber.substring(sigRequest.smsNumber.length - 2);
    // let displayNumber = `XXX-XXX-${lastTwoNumbers}`
    this.setState({
      smsDisplay : formattedNumber
    })
    
    this.sendVerificationCode();
  }
  
  sendVerificationCode(){
    let { t } = this.props;
    this.setState({validationErr : []});
    let sigRequest = this.props.signatureRequest;
    
    let sendCodePromise = null;
    if(sigRequest.dm_guest_uid){
      sendCodePromise = sapi.DM.addSignCode(sigRequest.status.sign_request_id, sigRequest.dm_guest_uid, sigRequest.smsNumber)
    }
    else{
      sendCodePromise = sapi.Workspace.addSignCode(sigRequest.status.sign_request_id, sigRequest.host_uid, sigRequest.smsNumber)
    }
  
    sendCodePromise
      .then((res) => {
        log.log('sign code add res', res);
      })
      .catch((err) => {
        log.log('sign code add err', err);
        sentryHelper.captureMessage('add-sign-code-err', err);
        //We cold get back something that looks like this.
        // {
        //   "data" : {
        //   "code" : 21211,
        //     "message" : "The &#39;To&#39; number +15555555555 is not a valid phone number.",
        //     "more_info" : "https://www.twilio.com/docs/errors/21211",
        //     "status" : 400
        // },
        //   "error" : {
        //   "code" : 101,
        //     "name" : "APP_REQ_INVALID"
        // },
        //   "success" : false
        // }
        let errMessage = _.get(err, 'body.data.message');
        let errName = _.get(err, 'error.name');
        
        log.log('error found', errMessage, errName);
        //Use the special error message if we have it.  If we don't, try whatever else we have.
        if(errMessage){
          this.setState({validationErr : [he.decode(errMessage)]});
        }
        else if(errName){
          this.setState({validationErr : [getErrorMessage(errName, t)]});
        }
        else{
          this.setState({validationErr : [getMessageForError(err, t)]});
        }
      })
  }
  
  closeModal(result) {
    let {close} = this.props;
    close(result);
  }
  
  sendCodeClick(){
    log.log('send code click');
    this.sendVerificationCode();
  }
  
  doValidation(){
    let err = [];
    let { t } = this.props;
    let {
      code
    } = this.state;
  
    if (!code || code.length === 0) {
      err.push(t("Please enter your Signature code"));
    }
    
    this.setState({validationErr : err});
    return err.length === 0;
  }
  
  validateCode(){
    this.setState({validationErr : []});
    if(!this.doValidation()){
      return;
    }
  
    let { code } = this.state;
    let { t } = this.props;
    let sigRequest = this.props.signatureRequest;
    let sendCodePromise = null;
    if(sigRequest.dm_guest_uid){
      sendCodePromise = sapi.DM.validateSignCode(sigRequest.status.sign_request_id, sigRequest.dm_guest_uid, code)
    }
    else{
      sendCodePromise = sapi.Workspace.validateSignCode(sigRequest.status.sign_request_id, sigRequest.host_uid, code)
    }
  
    sendCodePromise
      .then((res) => {
        log.log('validate sign code res', res);
        
        //then everything worked.  Let them in.
        this.closeModal(res);
      })
      .catch((err) => {
        log.log('validate sign code err', err);
        
        this.setState({
          validationErr : [ getMessageForError(err, t) ]
        })
      })
    
  }
  
  render() {
    let { t } = this.props;
    let {
      smsDisplay,
      code,
      validationErr
    } = this.state;
    
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{t("Signature Code")}</h5>
          <button type="button" className="close" onClick={this.closeModal.bind(this, false)} aria-label={t("Close")}>
            <i className="icon ion-ios-close-empty" />
          </button>
        </div>
        <div className="modal-body">
          <p>{t("The person requesting your signature requires that you input a one-time passcode.  Please check for a text message to:")}</p>
          <div className="m-3 text-center">
            <p className="font-italic secondary-text-color">{smsDisplay}</p>
          </div>
          <div className="text-center">
            <p>{t("Signature Code from Text Message:")}</p>
          </div>
          <div className="m-auto" style={{width: '200px'}}>
            <div className="text-center">
              <input type="text"
                     inputMode="numeric"
                     pattern="[0-9]*"
                     autoComplete="one-time-code"
                     onChange={(evt) => this.setState({code : evt.target.value})}
                     value={code}
                     placeholder={t("Enter 7-digit code")}
                     className="form-control"
                     aria-label="" />
            </div>
            <div className="text-right">
              <a className="btn btn-link px-0"
                 href="#"
                 onClick={this.sendCodeClick.bind(this)}>
                {t("Send a new code")}
              </a>
            </div>
          </div>
          {validationErr.length > 0 && <ValidationErrors errors={validationErr} /> }
        </div>
        <div className="modal-footer">
          <Button className={'btn btn-secondary'} onClick={this.closeModal.bind(this, false)}>{t("Cancel")}</Button>
          <Button className={'btn btn-primary'} onClick={this.validateCode.bind(this)}>{t("Submit")}</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

SMSVerificationDialog.propTypes = {
  close : PropTypes.func.isRequired,
  modalProps : PropTypes.object.isRequired
}

export default withVFTranslation()(connect(mapStateToProps, mapDispatchToProps)(SMSVerificationDialog));
